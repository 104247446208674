import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { EditProfile } from "../modals/edit-profile";
import { FollowersAll } from "../modals/followers-all";
import { Avatar } from "../users/avatar";
import ico_edit from "../../_images/ico-edit.svg";


const ProfileInformation = ({ user, me, following, followers }) => {
  const { t } = useTranslation("common");

  const [showFollowers, setShowFollowers] = useState(false);
  const [followersModal, setFollowersModal] = useState("following");
  const [showEditProfile, setShowEditProfile] = useState(false);

  //Handle close profile editing.
  const handleCloseEditProfile = () => {
    setShowEditProfile(false);
    window.location.reload()
  };

  //Show edit profile
  const handleShowEditProfile = (e) => {
    e.preventDefault();
    setShowEditProfile(true);
  };

  //Close modal
  const handleCloseFollowers = () => {
    setShowFollowers(false);
  };

  const handleShowFollowers = (e, type) => {
    e.preventDefault();
    setFollowersModal(type);
    setShowFollowers(true);
  };

  if (!user) {
    return null;
  }

  const full_name = user.current_user.name;
  const pos = full_name.indexOf(" ");
  const first_name = pos >= 0 ? full_name.substring(0, pos) : full_name;
  const last_name = pos >= 0 ? full_name.substring(pos) : "";

  return (
    <div className="profile__header d-flex">
      <div className="profile__main__image">
        <Avatar user={me} alt={user.current_user.display_name} />
      </div>
      <div className="profile__information">
        <div className="profile__name d-flex">
          <h1 className="user__name">
            <span className="main__name">{first_name}</span> {last_name}
          </h1>
          <a href="/" className="edit" onClick={handleShowEditProfile}>
            <img src={ico_edit} alt="Edit profile" />
          </a>
        </div>
        <span className="ocuppation">{me.field__professions}</span>
        {following && followers && (
          <div className="user__follows d-flex">
            <div className="followers">
              <a href="/" onClick={(e) => handleShowFollowers(e, "followers")}>
                <span className="f__counter">{followers.length}</span>
                {t("profile.followers")}
              </a>
            </div>
            <div className="follows">
              <a href="/" onClick={(e) => handleShowFollowers(e, "following")}>
                <span className="f__counter">{following.length}</span>
                {t("profile.following")}
              </a>
            </div>
          </div>
        )}
      </div>
      <EditProfile show={showEditProfile} onHide={handleCloseEditProfile} />

      {
        /* Modal followers/following */
        <FollowersAll
          show={showFollowers}
          followers={followers}
          following={following}
          selected={followersModal}
          onHide={handleCloseFollowers}
        />
      }
    </div>
  );
};

function mapState(state) {
  const { user, me } = state.authentication;
  const { following, followers } = state.users;
  return { user, me, following, followers };
}

const connectedProfileInformation = connect(mapState, null)(ProfileInformation);
export { connectedProfileInformation as ProfileInformation };
