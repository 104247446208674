import React from "react";
import { Link } from "@reach/router";
import { useTranslation } from "react-i18next";
import { FavoriteButton } from "./favorite-button";
import { CategoryLink } from "./category-link";
import { PublishDate } from "./publish-date";
import { url } from "../../_helpers";
import he from "he";

export default function ArticleCategorySecondary({ article }) {
  const { t, i18n } = useTranslation("common");
  const lang = i18n.language;

  const link = url(article.slug);

  return (
    <article itemScope="" itemType="http://schema.org/Article">
      <div className="article__cat d-flex">
        <p className="article__cat__tag" rel="category">
          <CategoryLink name={article.category} />
        </p>
        <span className="article__cat__separator">•</span>
        <PublishDate className="article__hour__tag" date={article.date} />
      </div>
      <h2 itemProp="headline">
        <Link to={link}>{he.decode(article.title)}</Link>
      </h2>
      <div className="autor__container d-flex">
        <p className="article__autor">
          <Link to={`/${lang}/${t("home.profile")}/${String(article.user).toLocaleLowerCase()}`}>
            <span itemProp="name">{article.display_name}</span>
          </Link>
        </p>

        <FavoriteButton id={article.id} />
      </div>
    </article>
  );
}
