import React, { useEffect, useState } from "react";
import { userService } from "../../_services";
import { ArticleProfile } from "../ui/article-profile";
import { LoadMore } from "../contents/load-more";
import { contentsConstants } from "../../_constants";
import { Spinner } from "../ui/spinner";

export const DetailContent = ({
  user,
  display,
  className,
  setTotal: parentTotal,
}) => {
  const [articles, setArticles] = useState([]);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  //Handle click.
  const handleMore = () => {
    setPage(page + 1);
  };

  useEffect(() => {
    setLoading(true);
    userService.myContents(user, page).then((response) => {
      if (parentTotal) {
        parentTotal(response.results);
      }
      setTotal(response.results);
      setArticles(articles.concat(response.articles));
      setLoading(false);
    });
  }, [user, page, setArticles, setTotal, parentTotal]);

  return (
    <div className={className}>
      {articles.map((article) => (
        <ArticleProfile display={display} article={article} key={article.id} />
      ))}

      {
        /* Load More button */
        !loading ? (
          <LoadMore
            page={page}
            totalItems={total}
            itemsByPage={contentsConstants.RESULTS_BY_MYCONTENT}
            onClick={handleMore}
          />
        ) : (
          <Spinner />
        )
      }
    </div>
  );
};
