import React from "react";
import { connect } from "react-redux";
import { contentsConstants } from "../../_constants";
import { modalActions } from "../../_actions";
import { articleService } from "../../_services";
import { withTranslation } from "react-i18next";
import { SearchInput } from "../search/search-input";
import { LoadMore } from "../contents/load-more";
import { Spinner } from "../ui/spinner";

import SearchCard from "../ui/search-card";

class SearchGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      types: "content",
      executed: false,
      query: "",
      categories: [],
      articles: [],
      total: 0,
      page: 0,
      loading: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props;

    //This code is executed due preset by external search queries.
    if (prevProps.value !== value) {
      const results = typeof value === "string";

      //Reset criterias.
      this.setState({
        types: "content", //Search default in content.
        query: value,
        executed: results,
        articles: [],
        categories: [],
        page: 0,
      });

      //Triggers search if any query is predefined.
      if (results) {
        this.fetchResults(value, null, 0);
      }
    }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value, executed: false });
  };

  handleOptionChange = (e) => {
    this.setState({ types: e.target.value, executed: false });
  };

  handleCategoriesChange = (categories) => {
    this.setState({ articles: [], categories: categories, page: 0 });
    this.fetchResults(null, categories, 0);
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ articles: [], page: 0 });
    this.fetchResults(null, null, 0);
  };

  //Execute service call
  fetchResults = (value, categories, page) => {
    value = value || this.state.query;
    categories = categories || this.state.categories;
    page = page === 0 ? 0 : page || this.state.page;

    const lang = this.props.i18n.language;

    /** Call Services  */
    this.setState({ loading: true });
    articleService
      .getGroupSearch(lang, value)
      .then((res) => res.json())
      .then((response) => {
        if (response) {
          this.setState({
            total: response.length,
            articles: response,
            executed: true,
            loading: false,
          });
        }
      })
      .catch(console.log);
  };

  //Handle click.
  handleMore = () => {
    const page = this.state.page + 1;
    this.fetchResults(null, null, page);
    this.setState({ page: page });
  };

  render() {
    const { t } = this.props;

    return (
      <div id="search" className="search-groups">
        <form className="search__form searh-group" onSubmit={this.handleSubmit}>
          <SearchInput
            query={this.state.query}
            handleChange={this.handleChange}
            handleClear={() => this.setState({ executed: false })}
            handleSubmit={this.handleSubmit}
          />
        </form>

        {this.state.executed && (
          <>
            <h4 className="search__results">{`${this.state.query}: ${
              this.state.total
            } ${t("search.results")}${this.state.total !== 1 ? "s" : ""}`}</h4>
            <div className="group__cards__container d-flex flex-wrap creation__group__form  max group-exist">
              {this.state.articles.map((article, id) => (
                <div
                  key={id}
                  className="group__card col-12 col-sm-12 col-md-4 col-lg-4"
                >
                  <div>
                    <div className="group__card col-12">
                      <SearchCard article={article} />
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {
              /* Load More button */
              !this.state.loading ? (
                <LoadMore
                  page={this.state.page}
                  totalItems={this.state.total}
                  itemsByPage={contentsConstants.RESULTS_BY_SEARCH}
                  onClick={this.handleMore}
                />
              ) : (
                <Spinner />
              )
            }
          </>
        )}
      </div>
    );
  }
}

function mapState(state) {
  const { search, query } = state.modals;
  let value = query;
  return { search, value };
}

const actionCreators = {
  hideSearch: modalActions.hideSearch,
  showSearch: modalActions.showSearch,
};

const connectedSearchGroup = connect(
  mapState,
  actionCreators
)(withTranslation("common")(SearchGroup));
export { connectedSearchGroup as SearchGroup };
