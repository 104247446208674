import React, { useState, useEffect } from "react";
import { LoadMore } from "../contents/load-more";
import { Spinner } from "../ui/spinner";
import { useTranslation } from "react-i18next";
import { articleService } from "../../_services";
import { contentsConstants } from "../../_constants";
import ArticleCategoryLatest from "../../_components/ui/article-category-latest";
import ArticleCategorySecondary from "../../_components/ui/article-category-secondary";

export default function LatestArticles({ className, category = "" }) {
  //Language hooks
  const { i18n, t } = useTranslation("common");
  const lang = i18n.language;

  //State
  const [articles1, setArticles1] = useState([]);
  const [articles2, setArticles2] = useState([]);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  //Get results
  // eslint-disable-next-line
  const fetchResults = (lang, category, p) => {
    setLoading(true);
    articleService
      .getArticleLatest(lang, category, p)
      .then((res) => res.json())
      .then((response) => {
        if (response) {
          setTotal(response.results);

          let l1 = Math.ceil(response.articles.length / 2);
          //let l2 = response.length - l1;

          setArticles1(articles1.concat(response.articles.slice(0, l1)));
          setArticles2(articles2.concat(response.articles.slice(l1)));
        }
        setLoading(false);
      })
      .catch(console.log);
  };

  useEffect(() => {
    /** Call Services  */
    fetchResults(lang, category, page);
  }, []);

  //Handle click.
  const handleMore = () => {
    const p = page + 1;
    fetchResults(lang, category, p);
    setPage(p);
  };

  if (!(articles1.length || articles2.length)) {
    return "";
  }

  return (
    <section className={`two__columns__videos ${className}`}>
      <div className="container">
        <h3 className="category__title">{t("articles.ttl_latest")}</h3>
        <div className="d-flex flex-column flex-sm-column flex-md-column flex-lg-row">
          <div className="col-12 col-sm-12 col-md-8 col-lg-8 d-flex flex-wrap big__column__articles">
            {articles1.map((article, id) => (
              <ArticleCategoryLatest article={article} key={id} />
            ))}
          </div>
          <div className="col-12 col-sm-12 col-md-5 col-lg-5 small__column__articles">
            {articles2.map((article, id) => (
              <ArticleCategorySecondary article={article} key={id} />
            ))}
          </div>
        </div>
      </div>

      {
        /* Load More button */
        !loading ? (
          <LoadMore
            page={page}
            totalItems={total}
            itemsByPage={contentsConstants.RESULTS_BY_CATEGORY}
            onClick={handleMore}
          />
        ) : (
          <Spinner />
        )
      }
    </section>
  );
}
