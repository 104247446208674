import React, { useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { DetailContent } from "./detail-content";

const MyContent = ({ me }) => {
  const { t } = useTranslation("common");
  const [total, setTotal] = useState(0);

  return (
    <div className="profile__dashboard my__content">
      <h2 className="dashboard__title">
        <span className="my__content__title">{t("menus.my_content")}</span>
        <span className="title__counter">
          {total} {t("profile.articles")}
        </span>
      </h2>

      <DetailContent
        user={me.name}
        className="my__articles__content"
        display="private"
        setTotal={setTotal}
      />
    </div>
  );
};

function mapState(state) {
  const { me } = state.authentication;
  return { me };
}

const connectedMyContent = connect(mapState)(MyContent);
export { connectedMyContent as MyContent };
