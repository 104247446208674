import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";

const ModalRejected = ( {rejected} ) => {
  const [show, setShow] = useState(rejected);
  const { t  } = useTranslation("common");


  const handleClose = () => {
    setShow(false)
    window.location.reload()

  };

  return (
    <Modal
      show={show}
      backdrop="static"
      keyboard={false}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <Modal.Header style={{ border: "none" }}></Modal.Header>
      <Modal.Body>
        <div className="tittle__premium">
          <h1 className="highlighted text-center">
            {t("premium.rejectedTitle")}
          </h1>
        </div>
        <p>{t("premium.rejectedText")}</p>
        <Button
          variant="primary"
          onClick={handleClose}
          className="modal__button"
        >
          {t("premium.btnText2")}
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default ModalRejected;
