import React from "react";
import { Link } from "@reach/router";
import { useTranslation } from "react-i18next";
import { PublishDate } from "./publish-date";
import { FavoriteButton } from "./favorite-button";
import { CategoryLink } from "./category-link";
import { url, path } from "../../_helpers";
import he from "he";

import play from "../../_images/ico-player.svg";

export const ArticleFeatured = ({ article }) => {
  //Language hooks
  const { i18n, t } = useTranslation("common");
  const lang = i18n.language;
  const link = url(article.slug);

  return (
    <article
      className="col-sm-12 col-md-6 col-lg-3"
      itemScope=""
      itemType="http://schema.org/Article"
    >
      <div className="article__cat d-flex">
        <p className="article__cat__tag" rel="category">
          <CategoryLink name={article.category} />
        </p>
        <span className="article__cat__separator">•</span>
        <PublishDate className="article__hour__tag" date={article.date} />
      </div>
      <figure className="article__image__content">
        {article.is_video === "True" && article.duration && (
          <span className="player__counter">{article.duration}</span>
        )}
        {article.is_video === "True" && (
          <Link className="player__btn" to={link}>
            <img itemProp="image" src={play} alt={t("articles.play_video")} />
          </Link>
        )}
        <Link to={link}>
          <img
            itemProp="image"
            src={path(article.thumbnail)}
            alt={he.decode(article.title)}
          />
        </Link>
      </figure>
      <div className="article__information">
        <h2 itemProp="headline">
          <Link to={link}>{he.decode(article.title)}</Link>
        </h2>
        <div className="autor__container d-flex">
          <p className="article__autor">
            <Link to={`/${lang}/${t("home.profile")}/${String(article.user).toLocaleLowerCase()}`}>
              <span itemProp="name">{article.display_name}</span>
            </Link>
          </p>

          <FavoriteButton id={article.id} />
        </div>
      </div>
    </article>
  );
};
