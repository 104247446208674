import React, { useEffect } from "react";
import ImageUploading from "react-images-uploading";

export const TeachContent = ({ type, value, onChange }) => {
  const [images, setImages] = React.useState([]);
  const maxNumber = 1;

  useEffect(() => {
    if (!value) {
      setImages([]);
    }
  }, [value]);

  const onChangeImage = (imageList, addUpdateIndex) => {
    // data for submit
    setImages(imageList);
    if (imageList.length > 0) {
      // onChange(data_url);
      onChange(imageList);
    } else {
      onChange("");
    }
  };

  return (
    <div className="create__image__txt">
      <ImageUploading
        multiple
        value={images}
        onChange={onChangeImage}
        maxNumber={maxNumber}
        dataURLKey="data_url"
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
        }) => (
          <div className="box__interactive">
            <div className="add__image">
              {imageList.length > 0 ? (
                imageList.map((image, index) => (
                  <div key={index}>
                    <div className="image-item__btn-wrapper">
                      <button
                        type="button"
                        onClick={() => onImageUpdate(index)}
                      >
                        Update
                      </button>
                      <button
                        type="button"
                        onClick={() => onImageRemove(index)}
                      >
                        Remove
                      </button>
                    </div>
                    <img
                      className="preview"
                      src={image["data_url"]}
                      alt=""
                      key={index}
                    />
                  </div>
                ))
              ) : (
                <div
                  className="profile__image__content"
                  onClick={onImageUpload}
                >
                  <div className="image__profile photo"></div>
                </div>
              )}
            </div>
          </div>
        )}
      </ImageUploading>
    </div>
  );
};
