import React, { useState, useEffect } from "react";
import { taxonomys } from "../../_services/taxonomys.service";
import { useTranslation } from "react-i18next";
import Select from "react-select";

export const Profession = ({ value, label, handler }) => {
  //Language hooks
  const { i18n, t } = useTranslation("common");
  const lang = i18n.language;
  const [profession, setProfession] = useState([]);
  const options = [];
  useEffect(() => {
    /** Call Services  */
    taxonomys
      .getProfessions(lang)
      .then((res) => res.json())
      .then((response) => {
        if (response) {
          setProfession(response);
        }
      })
      .catch(console.log);
  }, [lang, setProfession]);

  profession.map((item) => {
    options.push({
      value: item.id,
      label: t(item.category),
    });
  });

  return (
    <>
      <label htmlFor="inputDescription" className="no__floating">
        {t("register.select_profession")}
      </label>
      <Select
        options={options}
        onChange={handler}
        defaultValue={{ value: label }}
      />
    </>
  );
};
