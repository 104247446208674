import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Avatar } from "../users/avatar";
import { Spinner } from "../ui/spinner";
import { commentService } from "../../_services";
import { communityConstants } from "../../_constants";
import { LoadMore } from "./load-more";
import { Comment } from "../contents/comment";
import { modalActions } from "../../_actions";
import * as moment from "moment";

import comments_count from "../../_images/ico-comments-count.svg";

const Comments = ({
  id,
  quantity,
  discussion,
  me,
  user,
  showLogin,
  showRegister,
}) => {
  //Language hooks
  const { t, i18n } = useTranslation("common");
  const lang = i18n.language;
  const [open, setOpen] = useState(discussion === true);

  //Comments state
  const [loading, setLoading] = useState(false);
  const [loadingComments, setLoadingComments] = useState(false);
  const [pageComments, setPageComments] = useState(0);
  const [totalComments, setTotalComments] = useState(0);
  const [loadedFlag, setLoadedFlag] = useState(false);
  const [comment, setComment] = useState("");
  const [comments, setComments] = useState([]);

  /**
   *
   * @param {*} e
   */
  const handleChange = (e) => {
    setComment(e.target.value);
  };

  /**
   *
   * @param {*} e
   */
  const handleRegister = (e) => {
    e.preventDefault();
    showRegister();
  };

  /**
   *
   * @param {*} e
   */
  const handleLogin = (e) => {
    e.preventDefault();
    showLogin();
  };

  /**
   *
   * @param {*} e
   */
  const handleOpenComments = (e) => {
    e.preventDefault();
    setOpen(!open);
    if (!open) {
      handleFocus();

      if (!loadedFlag) {
        setLoadedFlag(true);
        fetchComments(0);
      }
    }
  };

  /**
   *
   * @param {*} e
   */
  const handleFocus = (e) => {
    setTimeout(() => {
      document.getElementById("myComment").focus();
    }, 200);
  };

  /**
   *
   */
  const handleMoreComments = () => {
    setPageComments(pageComments + 1);
    fetchComments(pageComments + 1);
  };

  /**
   *
   * @param {*} e
   */
  const handleSubmit = (e) => {
    e.preventDefault();
    if (String(comment).trim() !== "") {
      setLoading(true);
      commentService
        .addComment(lang, id, comment)
        .then((response) => {
          const data = {
            id: response.cid[0].value,
            body: response.comment_body[0].value,
            date: moment(response.created[0].value).format(
              "YYYY-MM-DD hh:mm:ss"
            ),
            likes: "0",
            author: me.id,
            user: me.name,
          };

          comments.unshift(data);
          setComments(comments);
          setLoading(false);
          setComment("");
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  /**
   *
   * @param {*} page
   */
  const fetchComments = (page) => {
    setLoadingComments(true);

    commentService
      .getComments(lang, id, page)
      .then((response) => {
        setComments(comments.concat(response.articles));
        setTotalComments(response.results);
        setLoadingComments(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadingComments(false);
      });
  };

  /**
   *
   */
  useEffect(() => {
    if (open) {
      if (!loadedFlag) {
        setLoadedFlag(true);
        fetchComments(0);
      }
    }
  }, [loadedFlag, open, setLoadedFlag]);

  return (
    <div className="comments__box">
      {
        /* Comments summary */
        discussion !== true && (
          <div className="comments__heading__box d-flex justify-content-between align-items-center">
            <div className="comments__title__box d-flex align-items-center">
              <span>{t("articles.discussion")}</span>
              <div className="comments__counter__box d-flex align-items-center">
                <img src={comments_count} alt="Comments" />
                <span>
                  {
                    //eslint-disable-next-line
                    quantity == 1
                      ? `1 ${t("contents.one_comment")}`
                      : `${quantity} ${t("contents.some_comments")}`
                  }
                </span>
              </div>
            </div>

            {!user ? (
              <div className="comments__toggle leave">
                <span>
                  {t("contents.want_participate")}{" "}
                  <a href="/" onClick={handleLogin}>
                    {t("user.login_button")}
                  </a>
                  {` ${t("user.or")} `}
                  <a href="/" onClick={handleRegister}>
                    {t("user.signin_button")}
                  </a>
                </span>
              </div>
            ) : (
              <div className={`comments__toggle ${open && "open"}`}>
                <a href="/" onClick={handleOpenComments}>
                  <span>{t("contents.comment")}</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="9"
                    viewBox="0 0 14 9"
                  >
                    <path
                      className="icon"
                      d="M6 4.9L1.9 0 0 0 6 7 12 0 10.1 0 6 4.9Z"
                      fill="#021431"
                    ></path>
                  </svg>
                </a>
              </div>
            )}
          </div>
        )
      }

      {/* First level comments */}
      {open && (
        <div className="comments__container">
          {/* Form to comment */}
          {user && (
            <div className="new__comment d-flex align-items-center">
              <div className="profile__image">
                <Avatar user={me} alt={me.name} />
              </div>
              <div className="form__comment">
                <form className="d-flex" onSubmit={handleSubmit}>
                  <input
                    id="myComment"
                    name="myComment"
                    type="text"
                    placeholder={t("contents.write")}
                    value={comment}
                    onChange={handleChange}
                  />

                  <button type="submit">
                    {loading ? (
                      <Spinner size="small" />
                    ) : (
                      t("contents.ttl_comment")
                    )}
                  </button>
                </form>
              </div>
            </div>
          )}

          {/* List of comments */}
          <div className="comments__list">
            {comments && (
              <ul>
                {comments.map((item) => (
                  <li className="comment__li" key={item.id}>
                    <Comment
                      id={id}
                      comment={item}
                      fetchComments={fetchComments}
                      depth="0"
                    />
                  </li>
                ))}
              </ul>
            )}
            {!loadingComments ? (
              <LoadMore
                page={pageComments}
                totalItems={totalComments}
                itemsByPage={communityConstants.COMMENTS_BY_PAGE}
                onClick={handleMoreComments}
              />
            ) : (
              <Spinner />
            )}

            {user ? null : (
              <div className="comment__cta">
                <div className="comments__toggle leave justify-content-center">
                  <span>
                    {t("contents.want_participate")}{" "}
                    <a href="/" onClick={handleLogin}>
                      {t("user.login_button")}
                    </a>
                    {` ${t("user.or")} `}
                    <a href="/" onClick={handleRegister}>
                      {t("user.signin_button")}
                    </a>
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

function mapState(state) {
  const { user, me } = state.authentication;
  return { user, me };
}

const actionCreators = {
  showLogin: modalActions.showLogin,
  showRegister: modalActions.showRegister,
};

const connectedComments = connect(mapState, actionCreators)(Comments);
export { connectedComments as Comments };
