import React, { useState } from "react";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { authentication } from "../../firebase/firebase";
import { useTranslation } from "react-i18next";
import success from "../../_images/Vector.svg";

import "../../css/register-form1.css";

const Sms2 = ({ cellphone, nextStep, loggingIn }) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [expanded, setExpanded] = useState(false);
  const [OTP, setOTP] = useState("");
  const [result, setResult] = useState(false);
  cellphone.value = phoneNumber;

  const { t } = useTranslation("common");

  const generateRecaptcha = () => {
    window.RecaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        // size: "invisible",
        callback: (response) => {},
      },
      authentication
    );
  };

  const requestOTP = (e) => {
    e.preventDefault();

    if (phoneNumber.length >= 12) {
      generateRecaptcha();
      let appVerifier = window.RecaptchaVerifier;
      signInWithPhoneNumber(authentication, phoneNumber, appVerifier)
        .then((confirmationResult) => {
          window.confirmationResult = confirmationResult;
        })
        .then(() => {
          setTimeout(() => {
            setExpanded(true);
          }, 5000);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const verifyOtp = async (e) => {
    e.preventDefault();

    if (OTP === "" || OTP === null) return;
    try {
      let confirmationResult = window.confirmationResult;
      await confirmationResult.confirm(OTP);
      nextStep();
    } catch (err) {
      setResult(true);
    }
  };

  return (
    <>
      <form onSubmit={requestOTP} style={{ marginBottom: "3rem" }}>
        <div className="block-form_sms">
          <div>
            <label htmlFor="inputDescription" className="no__floating">
              {t("register.select_phone")}
            </label>
            <PhoneInput
              id="cellphone"
              name="cellphone"
              className="PhoneInput"
              value={phoneNumber}
              onChange={setPhoneNumber}
              defaultCountry="CO"
            />
          </div>
          <div>
            <button
              className="btn btn-lg btn-primary btn-block btn-form_sms"
              type="submit"
            >
              {t("register.send_code_txt")}
            </button>
            {cellphone.error && (
              <small className="form-text text-muted">
                {t(cellphone.message)}
              </small>
            )}
          </div>
        </div>
      </form>

      <div id="recaptcha-container" style={{ marginBottom: "3rem" }}></div>
      {expanded === true ? (
        <>
          <div className="d-flex align-items-center">
            <img src={success} alt="success" />
            <label style={{margin: "0", padding: "5px"}}>{t("register.confirm_message")}</label>
          </div>
          <form onSubmit={verifyOtp}>
            <div className="block-form_sms">
              <div className="mb-4">
                <label htmlFor="otpInput" className="form-label">
                  {t("register.sms_code")}
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="otpInput"
                  onChange={(e) => setOTP(e.target.value)}
                />
              </div>
              {result && (
                <small className="form-text  text-danger ml-2">
                  {t("register.sms_small_text_2")}
                </small>
              )}
              <div>
                <button
                  className="btn btn-lg btn-primary btn-block"
                  type="submit"
                >
                  {t("buttons.confirm")}
                </button>
              </div>
            </div>
          </form>
        </>
      ) : null}
    </>
  );
};

export default Sms2;
