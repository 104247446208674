export const userConstants = {
  REGISTER_REQUEST: "USERS_REGISTER_REQUEST",
  REGISTER_SUCCESS: "USERS_REGISTER_SUCCESS",
  REGISTER_FAILURE: "USERS_REGISTER_FAILURE",

  CONTACT_REQUEST: "RESULT_CONTACT_REQUEST",
  GROUP_REQUEST: "RESULT_GROUP_REQUEST",

  REGISTER_2_REQUEST: "USERS_REGISTER_2_REQUEST",
  REGISTER_2_SUCCESS: "USERS_REGISTER_2_SUCCESS",
  REGISTER_2_FAILURE: "USERS_REGISTER_2_FAILURE",

  REGISTER_3_REQUEST: "USERS_REGISTER_3_REQUEST",
  REGISTER_3_SUCCESS: "USERS_REGISTER_3_SUCCESS",
  REGISTER_3_FAILURE: "USERS_REGISTER_3_FAILURE",

  LOGIN_REQUEST: "USERS_LOGIN_REQUEST",
  LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
  LOGIN_FAILURE: "USERS_LOGIN_FAILURE",

  FORGOT_PASSWORD_REQUEST: "USERS_FORGOT_PASSWORD_REQUEST",
  FORGOT_PASSWORD_SUCCESS: "USERS_FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_FAILURE: "USERS_FORGOT_PASSWORD_FAILURE",

  RESET_PASSWORD_REQUEST: "USERS_RESET_PASSWORD_REQUEST",
  RESET_PASSWORD_SUCCESS: "USERS_RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAILURE: "USERS_RESET_PASSWORD_FAILURE",

  LOGOUT: "USERS_LOGOUT",

  FOLLOWERS_REQUEST: "USERS_FOLLOWERS_REQUEST",
  FOLLOWERS_SUCCESS: "USERS_FOLLOWERS_SUCCESS",
  FOLLOWERS_FAILURE: "USERS_FOLLOWERS_FAILURE",

  FAVORITES_REQUEST: "USERS_FAVORITES_REQUEST",
  FAVORITES_SUCCESS: "USERS_FAVORITES_SUCCESS",
  FAVORITES_FAILURE: "USERS_FAVORITES_FAILURE",

  SELECT_COUNTRIES: 12,
  SELECT_PROFESSIONS: 10,
};
