import React from "react";
import { Link } from "@reach/router";
import { useTranslation } from "react-i18next";

export const LegalMenu = () => {
  //Language hooks
  const { i18n, t } = useTranslation("common");
  const lang = i18n.language;

  return (
    <ul className="navbar-nav flex-row">
      <li itemProp="url">
        <Link to={`/${lang}/${t("url.privacy")}`} itemProp="name">
          {t("menus.privacy")}
        </Link>
      </li>
      <li itemProp="url">
        <Link to={`/${lang}/${t("url.refund")}`} itemProp="name">
          {t("menus.refund")}
        </Link>
      </li>
      <li itemProp="url">
        <Link to={`/${lang}/${t("url.cookies")}`} itemProp="name">
          {t("menus.cookies")}
        </Link>
      </li>
      <li itemProp="url">
        <Link to={`/${lang}/${t("url.acceptable")}`} itemProp="name">
          {t("menus.acceptable")}
        </Link>
      </li>
      <li itemProp="url">
        <Link to={`/${lang}/${t("url.guest")}`} itemProp="name">
          {t("menus.guest")}
        </Link>
      </li>
      {/* <li itemProp="url">
        <Link to={`/${lang}/${t("url.commerce")}`} itemProp="name">
          {t("menus.commerce")}
        </Link>
      </li>
      <li itemProp="url">
        <Link to={`/${lang}/${t("url.sale")}`} itemProp="name">
          {t("menus.sale")}
        </Link>
      </li> */}
      
    </ul>
  );
};
