import React from "react";
import AuthorFollower from "./author-follower";
import AuthorFollowing from "./author-following";
import { Search } from "../modals";

const Followers = ({ type, users }) => {
  return (
    <div className="profile__dashboard my__content">
      <div className="following__content__info">
        <Search />
        <span className="counter_follows">
          {users != null ? users.length : 0} {type}
        </span>
        {users != null && (
          <>
            {users.map((info, i) =>
              type === "followers" ? (
                <span key={i}>
                  <AuthorFollower id={info.user} name={info.user} />
                </span>
              ) : (
                <span key={i}>
                  <AuthorFollowing id={info.user} name={info.user} />
                </span>
              )
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Followers;
