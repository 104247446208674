import React from "react";
import TagsCategories from "../common/tagsCategories";
import ReactHtmlParser from "react-html-parser";
import { useTranslation } from "react-i18next";
import { Sharer } from "./sharer";


export default function EventDetail({
  text,
  media,
  categories,
  buttonDownload,
}) {
  //Language hooks
  const { t, i18n } = useTranslation("common");
  const lang = i18n.language;

  return (
    <div className="col-md-8 col-lg-8 article__body">
      {ReactHtmlParser(text)}

      {
        /* Categories */
        categories && (
          <div className="related__topics tags">
            <div className="quick__cats">
              <TagsCategories
                url={`/${lang}/${t("url.results")}`}
                tags={categories}
              />
            </div>
          </div>
        )
      }

      {/* Share and Agenda */}
      <div className="agenda__event">
        <span>{t("events.share")}</span>
        <div className="share_download d-flex flex-column flex-sm-column flex-md-row flex-lg-row justify-content-between align-items-center">
          <Sharer className="floating__share__box d-flex justify-content-end col-2 col-sm-6 col-md-4" />
          <a
            // href={file}
            download
            className="agenda__btn"
            style={{
              borderRadius: "5px",
              border: "1px solid",
              padding: "0.5rem",
              textDecoration: "none",
            }}
          >
            {t("events.calendar")}
          </a>
        </div>
      </div>
    </div>
  );
}
