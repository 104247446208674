import React, { useState, useEffect } from "react";
import { Link } from "@reach/router";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { modalActions } from "../../_actions";

import logo_lg from "../../_images/main-logo.svg";
import logo_sm from "../../_images/ico-brand.svg";
import { userService } from "../../_services";

const MainMenu = ({
  isOpen,
  handleClose,
  loading,
  config,
  register,
  login,
  user,
  me,
}) => {
  //Language hooks
  const { i18n, t } = useTranslation("common");
  const lang = i18n.language;

  //Crate 'mobile' at state.
  const [mobile, setMobile] = useState(window.screen.width <= 1151);
  const [userP, setUserP] = useState("");

  //Login actions
  const handleLogin = (e) => {
    e.preventDefault();
    login();
  };

  //Register actions
  const handleRegister = (e) => {
    e.preventDefault();
    register();
  };

  //Execute on load.
  useEffect(() => {
    window.addEventListener("resize", () => {
      setMobile(window.screen.width <= 1151);
    });

    return () => {
      setMobile("");
    }
  }, []);

  useEffect(() => {
    /** Call Services  */
    // const { id } = JSON.parse(localStorage.getItem("me"));
    if (me !== undefined) {
      userService
        .summary(lang, me.id)
        .then((res) => res.json())
        .then((response) => {
          if (response[0].Roles === "Premium") {
            setUserP("Premium");
          } else {
            setUserP("Normal");
          }
        })
        .catch(console.log);

        return () => {
          setUserP("");
        }
    }
  }, [lang]);

  return (
    <div className="header__primary row">
      <Link to={`/${lang}`} className="navbar-brand d-flex align-items-center">
        <img itemProp="image" src={logo_lg} alt="Go to main page" />
      </Link>
      <nav
        id={mobile ? "navSidebar" : ""}
        className={mobile ? "sidenav" : ""}
        role="navigation"
        itemScope=""
        itemType="https://schema.org/SiteNavigationElement"
        style={isOpen ? { maxWidth: "100%" } : null}
      >
        <Link
          to={`/${lang}`}
          className="tech__icon mobile"
          itemProp="url"
          itemScope
          itemType="http://schema.org/Organization"
        >
          <img itemProp="logo" src={logo_sm} alt="Go to Home!" />
        </Link>

        {!(user || me) && (
          <div className="mobile__options mobile">
            {" "}
            <a
              href="/"
              className="btn btn-primary sign__header"
              onClick={handleRegister}
            >
              {t("user.signin_button")}
            </a>
            <a
              href="/"
              className="btn btn-secondary login__header"
              onClick={handleLogin}
            >
              {t("user.login_button")}
            </a>
          </div>
        )}
        <a href="/" className="closebtn mobile" onClick={(e) => handleClose(e)}>
          ×
        </a>
        <ul>
          <li itemProp="url">
            <Link to={`/${lang}/${t("url.community")}`} itemProp="name">
              {t("menus.community")}
            </Link>
          </li>

          {me?.Roles !== "Premium" ? (
            <li itemProp="url">
              <a
                href={`/${lang}/${t("url.contents")}`}
                itemProp="name"
                target="_blank"
                rel="noreferrer"
              >
                {t("menus.skills")}
              </a>
            </li>
          ) : (
            <li itemProp="url">
              <Link to={`/${lang}/${t("url.contents")}`} itemProp="name">
                {t("menus.skills")}
              </Link>
            </li>
          )}

          <li itemProp="url">
            <Link to={`/${lang}/${t("url.events")}`} itemProp="name">
              {t("menus.events")}
            </Link>
          </li>

          <li itemProp="url">
            <Link to={`/${lang}/${t("url.vendors")}`} itemProp="name">
              {t("menus.vendors")}
            </Link>
          </li>
          {userP === "Normal" && (
            <li itemProp="url">
              <Link to={`/${lang}/${t("url.premium")}`} itemProp="name">
                {t("menus.tech_premium")}
              </Link>
            </li>
          )}
        </ul>
        <div className="about__links mobile">
          <ul>
            <li itemProp="url">
              <Link to={`/${lang}/${t("url.about")}`} itemProp="name">
                {t("menus.about")}
              </Link>
            </li>
            <li itemProp="url">
              <Link to={`/${lang}/${t("url.contact")}`} itemProp="name">
                {t("menus.contact")}
              </Link>
            </li>
            <li itemProp="url">
              <a href="#newsletter" itemProp="name">
                {t("menus.newsletter")}
              </a>
            </li>
          </ul>
        </div>
      </nav>
      {/* <premium_modal id="premium_modal" /> */}
    </div>
  );
};

function mapState(state) {
  const { loading, config } = state.app;
  const { user, me } = state.authentication;
  return { user, me, loading, config };
}

const actionCreators = {
  register: modalActions.showRegister,
  login: modalActions.showLogin,
};

const connectedMainMenu = connect(mapState, actionCreators)(MainMenu);
export { connectedMainMenu as MainMenu };
