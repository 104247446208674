import React from "react";
import AuthorWall from "../contents/author-wall";
import { PostReactions } from "./post-reactions";
import { Stream } from "@cloudflare/stream-react";
import he from "he";
import { Link } from "@reach/router";
import { useTranslation } from "react-i18next";

export const PostFeed = ({ post }) => {
  //Language hooks
  const { i18n } = useTranslation("common");
  const lang = i18n.language;

  return (
    <article itemScope="" itemType="http://schema.org/Article">
      <AuthorWall id={post.uid} name={post.display_name} date={post.date} />
      <p className="post__text">{he.decode(post.summary)}</p>
      {post.field_has_video === "True" ? (
        <Stream controls src={post.field_cloudflare_id} />
      ) : (
        <figure className="article__image__content">
          <Link className="image__btn" to={`/${lang}/${post.id}`} state={{ post: { post } }}>
            <img
              itemProp="image"
              src={post.image}
              alt={he.decode(post.title)}
              state={{ post: { post } }}
            />
          </Link>
        </figure>
      )}
      <div className="article__information">
        <h2 itemProp="headline">
          <Link to={`/${lang}/${post.id}`} state={{ post: { post } }}>
            {he.decode(post.title)}
          </Link>
        </h2>
      </div>

      <PostReactions type="field_like_dislike" post={post} actions={true} />
    </article>
  );
};
