import React, { useEffect, useState } from "react";
import SEO from "../_components/common/seo";
import Layout from "../_components/common/layout";
import { navigate } from "@reach/router";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { MobileMenu } from "../_components/navigation/mobile-menu";
import { SearchVendorForm } from "../_components/forms/search-vendor-form";
import { vendorService } from "../_services";
import ico_search_partners from "../_images/ico-search-partner-darkblue.svg";

const VendorsPage = () => {
  const { t, i18n } = useTranslation("common");
  const [setDistributors] = useState([]);
  const [setPartners] = useState([]);
  const [setVendors] = useState([]);
  const lang = i18n.language;

  //Vendors
  useEffect(() => {
    let data = JSON.parse(sessionStorage.getItem("vendors"));
    if (!data) {
      vendorService
        .getFeaturedVendors(lang)
        .then((result) => {
          sessionStorage.setItem("vendors", JSON.stringify(result));
          setVendors(result);
        })
        .catch(console.log);
    } else {
      setVendors(data);
    }
  }, [lang]);

  //Distributors
  useEffect(() => {
    let data = JSON.parse(sessionStorage.getItem("distributors"));
    if (!data) {
      vendorService
        .getDistributors(lang)
        .then((result) => {
          sessionStorage.setItem(
            "distributors",
            JSON.stringify(result.articles)
          );
          setDistributors(result.articles);
        })
        .catch(console.log);
    } else {
      setDistributors(data);
    }
  }, [lang]);

  //Partners
  useEffect(() => {
    let data = JSON.parse(sessionStorage.getItem("partners"));
    if (!data) {
      vendorService
        .getPartners(lang)
        .then((result) => {
          sessionStorage.setItem("partners", JSON.stringify(result.articles));
          setPartners(result.articles);
        })
        .catch(console.log);
    } else {
      setPartners(data);
    }
  }, [lang]);

  //Receive submit on search.
  const handleSearchSubmit = (filters) => {
    let query = "";
    Object.keys(filters).map((idx) => {
      let param = "";
      if (filters[idx]) {
        //Translate
        switch (idx) {
          case "q":
            param = t("vendors.query_what");
            break;
          case "d":
            param = t("vendors.query_where");
            break;
          default:
            param = "";
        }
        if (param) {
          query += !query
            ? `?${idx}=${filters[idx]}`
            : `&${idx}=${filters[idx]}`;
        }
      }
      return query;
    });

    if (query) {
      navigate(`/${lang}/${t("url.vendors")}/${t("url.search")}${query}`);
    }
  };

  

  return (
    <Layout withFade={true} noFooter={true}>
      <SEO lang={lang} title={t("vendors.title")} />
      <main
        className="home__page"
        role="main"
        itemScope=""
        itemProp="mainContentOfPage"
        itemType="http://schema.org/Blog"
      >
        <section className="partners__search__box">
          <div className="container d-flex align-items-center">
            <div className="col-lg-9 partners__search__form">
              <h1>{t("vendors.title")}</h1>
              <h2>{t("vendors.expand_intro")}</h2>
              <div>
                <a href="/" className="form__partner__trigger">
                  <img src={ico_search_partners} alt={t("vendors.looking")} />
                  <span>{t("vendors.looking")}</span>
                </a>

                <SearchVendorForm
                  className="d-flex flex-column flex-sm-column flex-md-row flex-lg-row align-items-center justify-content-center"
                  onSubmit={handleSearchSubmit}
                />
              </div>
            </div>
          </div>
        </section>

        {/* <VendorCategoryCloud data={categories} />
        <VendorLinks data={vendors} />
        <VendorDistributors data={distributors} />
        <VendorPartners data={partners} /> */}
      </main>
      <MobileMenu selected="vendors" withFade={true} />
    </Layout>
  );
};

function mapState(state) {
  const { config } = state.app;
  if (config) {
    return { categories: config.categories };
  }

  return { categories: null };
}

const connectedVendorsPage = connect(mapState)(VendorsPage);
export { connectedVendorsPage as VendorsPage };
