import React, { useState } from "react";
import Author from "../contents/author";

export const Follower = ({ data }) => {
  const [info, setInfo] = useState(null);
  const { user, id } = data;

  const handleOnData = (data) => {
    setInfo(data);
  };

  return (
    <div className=" follow__item d-flex">
      <div className="follow__picture">
        <Author
          id={id}
          name={user}
          info={false}
          onData={handleOnData}
          label={false}
        />
      </div>
      {info && (
        <div className="follow__detail">
          <span className="follow__name">{info.display_name}</span>
          
          <div className="detail__and__btn">
            <span className="user">{info.field__professions}</span>
          </div>
        
        </div>
      )}
    </div>
  );
};
