import { modalConstants } from "../_constants";

export function modals(state = {}, action) {
  switch (action.type) {
    case modalConstants.SHOW_LOGIN:
      return {
        login: true,
      };
    case modalConstants.HIDE_LOGIN:
      return {
        login: false,
      };
    case modalConstants.SHOW_REGISTER:
      return {
        register: true,
        step: action.step,
      };
    case modalConstants.HIDE_REGISTER:
      return {
        register: false,
      };
    case modalConstants.SHOW_SEARCH:
      return {
        search: true,
        query: action.query,
      };
    case modalConstants.HIDE_SEARCH:
      return {
        search: false,
      };
    case modalConstants.SHOW_FORGOT_PASSWORD:
      return {
        problem: "",
        forgot: true,
        step: action.step,
        email: action.email,
      };
    case modalConstants.HIDE_FORGOT_PASSWORD:
      return {
        forgot: false,
      };
    case modalConstants.SHOW_RECOVERY_PASSWORD:
      return {
        problem: "",
        recovery: true,
        step: action.step,
        token: action.token,
      };
    case modalConstants.HIDE_RECOVERY_PASSWORD:
      return {
        recovery: false,
      };
    case modalConstants.SHOW_NEWSLETTER:
      return {
        newsletter: true,
      };
    case modalConstants.HIDE_NEWSLETTER:
      return {
        newsletter: false,
      };
    case modalConstants.SHOW_ADDARTICLE:
      return {
        article: true,
        content: action.content,
      };
    case modalConstants.HIDE_ADDARTICLE:
      return {
        article: false,
      };
    case modalConstants.SHOW_CREATECONTENT:
      return {
        create: true,
      };
    case modalConstants.HIDE_CREATECONTENT:
      return {
        create: false,
      };
    default:
      return state;
  }
}
