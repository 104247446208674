import React from "react";
import SEO from "../_components/common/seo";
import Layout from "../_components/common/layout";
import { useTranslation } from "react-i18next";
import contact_us from "../_images/contac-info-detail.png";
import { RegisterContact } from "../_components/forms/register-contact";
import icoWhatsapp from "../_images/ico-whatsapp-white.svg"

export default function ContactPage() {
  const { t, i18n } = useTranslation("common");
  const lang = i18n.language;

  return (
    <Layout>
      <SEO lang={lang} title={t("pages.title")} />
      <main
        className="home__page"
        role="main"
        itemScope=""
        itemProp="mainContentOfPage"
        itemType="http://schema.org/Blog"
      >
        <section className="contact">
          <div className="container">
            <div className="d-flex contact__container flex-wrap">
              <div className="col-12 col-sm-12 col-md-5 col-lg-5 contact__info__box">
                <h2>{t("contact.title")}</h2>
                <span>
                  <strong>{t("contact.call")}</strong>
                </span>
                <span>{t("contact.phone")}</span>
                <span>
                  <img src={icoWhatsapp} alt="Whatsapp" />
                  {t("contact.number")}
                </span>
                <hr />
                <span>
                  <strong>{t("contact.write")}</strong>
                </span>
                <span>{t("contact.email")}</span>
                <hr />
                <span>
                  <strong>{t("contact.find")}</strong>
                </span>
                <span>
                {t("contact.address")}
                  <br />
                  {t("contact.city")}
                </span>
                <img
                  className="contact__deco"
                  src={contact_us}
                  alt="Contact with us"
                />
              </div>
              <div id="contact__form" className="col-12 col-sm-12 col-md-7 col-lg-7 contact__form">
                <h1>
                  <span className="title__highlight">{t("contact.title1")}</span>{t("contact.title2")}
                </h1>
                <span className="copy__contact">
                {t("contact.copy_contact")}
                </span>

                <RegisterContact />
                
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
}
