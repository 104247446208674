import { appConstants } from "../_constants";
import { appService } from "../_services";
import he from "he";

export const appActions = {
  init,
  ads,
};

/**
 *
 * @param {*} lang
 */
function init(lang) {
  return (dispatch) => {
    dispatch(request());

    appService
      .initConfig(lang)
      .then((data) => data.json())
      .then(
        (categories) => {
          let cats = {};

          //Store categories by language.
          for (const category of categories) {
            let lang = String(category.link).substr(5, 2);
            if (!cats[lang]) {
              cats[lang] = [];
            }
            category.category = he.decode(category.category);
            cats[lang].push(category);
          }
          const config = {
            categories: cats,
          };
          dispatch(success(config));
        },
        (error) => {
          dispatch(failure(error.toString()));
        }
      );
  };

  function request(config) {
    return { type: appConstants.INIT_REQUEST };
  }
  function success(config) {
    return { type: appConstants.INIT_SUCCESS, config };
  }
  function failure(error) {
    return { type: appConstants.INIT_FAILURE, error };
  }
}

/**
 *
 * @param {*} lang
 */
function ads(lang) {
  return (dispatch) => {
    dispatch(request());

    appService
      .getBanners(lang)
      .then((data) => data.json())
      .then(
        (banners) => {
          dispatch(success(banners));
        },
        (error) => {
          dispatch(failure(error.toString()));
        }
      );
  };

  function request() {
    return { type: appConstants.BANNERS_REQUEST };
  }
  function success(banners) {
    return { type: appConstants.BANNERS_SUCCESS, banners };
  }
  function failure(error) {
    return { type: appConstants.BANNERS_FAILURE, error };
  }
}
