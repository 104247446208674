import React from "react";
// import { navigate } from "@reach/router";
import { useTranslation } from "react-i18next";

export default function LangSelector({ className }) {
  const { i18n } = useTranslation("common");
  const lang = i18n.language;

  function handleChange(e) {
    const lang = e.target.value;

    window.location.href = `/${lang}`;
  }

  return (
    <select className={className} defaultValue={lang} onChange={handleChange}>
      <option value="en">English</option>
      <option value="es">Español</option>
    </select>
  );
}
