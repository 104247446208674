import React, { useEffect, useState } from "react";
import { Link } from "@reach/router";
import ArticleFrenetic from "../ui/article-frenetic";
import { useTranslation } from "react-i18next";
import { articleService } from "../../_services";

export default function FreneticArticles() {
  //Language hooks
  const { t, i18n } = useTranslation("common");
  const lang = i18n.language;

  //State
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    /** Call Services  */
    articleService
      .getArticleFrenetics(lang)
      .then((res) => res.json())
      .then((response) => {
        setArticles(response);
      })
      .catch(console.log);
  }, [lang, setArticles]);

  if (!articles) {
    return "";
  }

  return (
    <div className="content__frenetic">
      <h3 className="title__home">
        <span className="title__highlight">{t("articles.content_from")}</span>
        {t("articles.frenetics")}
      </h3>

      {articles.splice(0, 6).map((article, id) => (
        <ArticleFrenetic key={id} article={article} />
      ))}

      <Link
        to={`/${lang}/${t("url.community")}`}
        className="btn-secondary btn-lg btn"
      >
        {t("articles.explore_more")}
      </Link>
    </div>
  );
}
