import React from "react";
import AuthorWall from "../contents/author-wall";
import he from "he";
import { PostReactions } from "./post-reactions";
import { PostActions } from "./post-actions";

export const PostQuote = ({ post, onDelete, user }) => {
  return (
    <article itemScope="" itemType="http://schema.org/Article">
      <div className="articles__activearea">
        {post.user === user && <PostActions post={post} onDelete={onDelete} />}
        <AuthorWall id={post.uid} name={post.display_name} date={post.date} />
        <p className="post__text big__status">{he.decode(post.title)}</p>
      </div>

      <PostReactions
        type="field_like_post"
        post={post}
        actions={true}
        like={false}
      />
    </article>
  );
};
