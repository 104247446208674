import React, { Suspense } from "react";
import { Router, Redirect, Location } from "@reach/router";
import { connect } from "react-redux";

import { appActions, userActions } from "../_actions";
import { HomePage } from "../HomePage";
import { NotFoundPage } from "../NotFoundPage";
import {
  ArticlePage,
  ContentsPage,
  CategoryPage,
  RelatedPage,
  PoliticsPage,
} from "../ArticlePage";
import { EventPage, EventsPage, EventsSearch } from "../EventPage";
import {
  GroupsPage,
  DiscoverGroupPage,
  GroupDetailPage,
  NewGroupPage,
} from "../GroupsPage";
import { CommunityPage } from "../CommunityPage";
import {
  VendorsPage,
  SearchVendorPage,
  VendorDetailPage,
  VendorBrandPage,
} from "../VendorPage";
import { ContactPage } from "../ContactPage";
import { AboutPage, NosotrosPagina } from "../AboutPage";
import { GeneralPage } from "../GeneralPage";
import { ProfilePage } from "../UserPage";
import { AuthPage } from "../HomePage";
import CookieConsent, { Cookies } from "react-cookie-consent";

import {
  Login,
  Register,
  Search,
  Forgot,
  Newsletter,
  AddContent,
  Reset,
  ShareContent,
} from "../_components/modals";

import { MeetupsPage, NewMeetupPage } from "../MeetupsPage";

import "bootstrap/dist/css/bootstrap.min.css";
import "../_styles/style.css";
import ProfileTrends from "../UserPage/ProfileTrends";
import ProfileMyActivity from "../UserPage/ProfileMyActivity";
import ProfileNoContent from "../UserPage/ProfileNoContent";
import ProfileContent from "../UserPage/ProfileContent";
import { ProfileAccount } from "../UserPage/ProfileAccount";
import { ProfileFollowersAll } from "../UserPage/ProfileFollowersAll";
import { ProfileFollowingAll } from "../UserPage/ProfileFollowingAll";
import PrfileArticle from "../UserPage/ProfileArticle";
import { ProfileNotifications } from "../UserPage/ProfileNotifications";
import { PublicProfile } from "../UserPage/PublicProfile";
import { DetailPageItem } from "../DetailsPage/DetailPageItem";
import { PremiumPage } from "../PremiumPage";
import DetailPageUser from "../DetailPageUser/DetailPageUser";
import { PolicyPage, PoliticaPagina } from "../PolicyPage";
import { TerminosPagina, TermPage } from "../TermPage";
import { DescargoPagina, DisclaimerPage } from "../DisclaimerPage";
import { PremiumSuccess } from "../_components/modals/PremiumSuccess";
import { CookiePage, CookiePagina } from "../CookiePage";
import { RefundPage, ReembolsoPage } from "../RefundPage";
import { AcceptablePage, AceptablePage } from "../AcceptablePage";
import { GuestPage, InvitadosPage } from "../GuestPage";
import { CoursePage, InternalCoursePage } from "../CoursePage";
import ChatPage from "../ChatPage/ChatPage";


class OnRouteChangeWorker extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.props.action();
    }
  }

  render() {
    return null;
  }
}

const OnRouteChange = ({ action }) => (
  <Location>
    {({ location }) => (
      <OnRouteChangeWorker location={location} action={action} />
    )}
  </Location>
);

let textLayer =
  "In this portal we use our own and third-party 'cookies' to manage the portal, compile statistical information, optimize the functionality of the site and display advertising related to your preferences through navigation analysis. You can learn how to disable them or get more information \n";
let linkLayer = "here";
let urlLayer = "/en/cookies-policy";

class App extends React.Component {
  constructor(props) {
    super(props);
    const { language: lang, me } = this.props;

    //Load catagories at state.
    this.props.init(lang);
    this.props.initAds(lang);
    //Load followers if needed.

    if (me) {
      if (me.custom_language !== lang) {
        this.props.loadSummary(lang, me.id).then((resp) => {
          me.field__professions = resp.field__professions;
          me.field_country = resp.field_country;
        });
      }
      this.props.loadFollowers(me.id);
      this.props.loadFavorites(me.id);
    }
  }

  render() {
    if (this.props.language === "es") {
      textLayer =
        "En este portal utilizamos 'cookies' propias y de terceros para gestionar el portal, elaborar información estadística, optimizar la funcionalidad del sitio y mostrar publicidad relacionada con sus preferencias a través del análisis de la navegación. Puede conocer cómo deshabilitarlas u obtener más información \n";
      linkLayer = "aquí";
      urlLayer = "/es/cookies-policy";
    }

    return (
      <>
        <Suspense fallback="loading">
          {this.props.me ? (
            <Router>
              <Redirect
                from="/"
                to={`/${this.props.language}`}
                noThrow={true}
              />
              <HomePage path="/es" />
              <HomePage path="/en" />
              <ContentsPage path="/en/articles" me={this.props.me} />
              <ContentsPage path="/es/articulos" me={this.props.me} />
              <RelatedPage path="/en/articles/search" />
              <RelatedPage path="/es/articulos/buscar" />
              <ArticlePage path="/en/articles/:slug" />
              <ArticlePage path="/es/articulos/:slug" />
              <CategoryPage path="/en/categories/:slug" />
              <CategoryPage path="/es/categorias/:slug" />
              <PoliticsPage path="/en/politics/:slug" />
              <PoliticsPage path="/es/politica/:slug" />
              <PublicProfile path="/en/profile/:slug" />
              <PublicProfile path="/es/perfil/:slug" />
              {/* <DetailPageUser path="/en/profile/:slug" />
              <DetailPageUser path="/es/perfil/:slug" /> */}
              <EventsPage path="/en/events" />
              <EventsPage path="/es/eventos" />
              <EventsSearch path="/en/events/search" />
              <EventsSearch path="/es/eventos/buscar" />
              <EventPage path="/en/events/:slug" />
              <EventPage path="/es/eventos/:slug" />
              <ContactPage path="/es/contacto" />
              <ContactPage path="/en/contact" />
              <NosotrosPagina path="/es/nosotros" />
              <AboutPage path="/en/about-us" />

              <CommunityPage path="/en/community" />
              <CommunityPage path="/es/comunidad" />
              <VendorsPage path="/en/vendors" />
              <VendorsPage path="/es/proveedores" />
              <PremiumPage path="/en/premium" />
              <PremiumPage path="/es/premium" />
              <SearchVendorPage path="/en/vendors/search" />
              <SearchVendorPage path="/es/proveedores/buscar" />
              <VendorDetailPage path="/en/vendors/:slug" />
              <VendorDetailPage path="/es/proveedores/:slug" />
              <VendorBrandPage path="/en/brands/:slug" />
              <VendorBrandPage path="/es/marcas/:slug" />
              <MeetupsPage path="/en/meetups" />
              <MeetupsPage path="/es/meetups" />
              <NewMeetupPage path="/en/meetups/new" />
              <NewMeetupPage path="/es/meetups/nuevo" />
              <GroupsPage path="/en/groups" />
              <GroupsPage path="/es/grupos" />
              <DiscoverGroupPage path="/en/groups/discover" />
              <DiscoverGroupPage path="/es/grupos/descubrir" />
              <NewGroupPage path="/en/groups/new" />
              <NewGroupPage path="/es/grupos/nuevo" />
              <GroupDetailPage path="/en/groups/:slug" />
              <GroupDetailPage path="/es/grupos/:slug" />
              <ProfilePage path="/es/perfil" />
              <ProfilePage path="/en/profile" />
              <ProfileTrends path="/es/perfil/visitas" />
              <ProfileTrends path="/en/profile/visits" />
              <ProfileMyActivity path="/es/perfil/actividad" />
              <ProfileMyActivity path="/en/profile/activity" />
              <ProfileNoContent path="/es/perfil/no-contenido" />
              <ProfileNoContent path="/en/profile/no-content" />
              <PrfileArticle path="/es/perfil/articulos" />
              <PrfileArticle path="/en/profile/articles" />
              <ProfileContent path="/es/perfil/contenido" />
              <ProfileContent path="/en/profile/content" />
              <ProfileAccount path="/es/perfil/cuenta" />
              <ProfileAccount path="/en/profile/account" />
              <ProfileFollowersAll path="/es/perfil/seguidores" />
              <ProfileFollowersAll path="/en/profile/followers" />
              <ProfileFollowingAll path="/es/perfil/siguiendo" />
              <ProfileFollowingAll path="/en/profile/following" />
              <ProfileNotifications path="/es/perfil/notificaciones" />
              <ProfileNotifications path="/en/profile/notifications" />

              <DetailPageItem path="/es/:id" />
              <DetailPageItem path="/en/:id" />

              <PoliticaPagina path="/es/politica-de-privacidad" />
              <PolicyPage path="/en/privacy-policy" />

              <TerminosPagina path="/es/terminos-y-condiciones" />
              <TermPage path="/en/terms-and-conditions" />

              <DescargoPagina path="/es/descargo" />
              <DisclaimerPage path="/en/disclaimer" />

              <CookiePagina path="/es/politica-de-cookies" />
              <CookiePage path="/en/cookie-policy" />

              <ReembolsoPage path="/es/politica-de-reembolso" />
              <RefundPage path="/en/refund-policy" />

              <AceptablePage path="/es/politica-de-uso-aceptable" />
              <AcceptablePage path="/en/acceptable-use-policy" />

              <InvitadosPage path="/es/acuerdo-de-publicacion-de-invitados" />
              <GuestPage path="/en/guest-post-agreement" />

              <PremiumSuccess path="/es/usuario-premium" />
              <PremiumSuccess path="/en/user-premium" />

              <CoursePage path="/es/curso/:id" />
              <CoursePage path="/en/course/:id" />

              <InternalCoursePage path="/es/curso-interno/:id" />
              <InternalCoursePage path="/en/course-internal/:id" />

              <GeneralPage path="/es/:slug" />
              <GeneralPage path="/en/:slug" />

              <ChatPage path="/es/chat" />
              <ChatPage path="/en/chat" />

              <NotFoundPage default />
            </Router>
          ) : (
            <Router>
              <Redirect
                from="/"
                to={`/${this.props.language}`}
                noThrow={true}
              />
              <HomePage path="/es" />
              <HomePage path="/en" />
              <HomePage path="/es/recuperar-clave/:token" />
              <HomePage path="/en/reset-password/:token" />
              {/* <ContentsPage path="/en/articles" />
              <ContentsPage path="/es/articulos" /> */}
              <RelatedPage path="/en/articles/search" />
              <RelatedPage path="/es/articulos/buscar" />
              <ArticlePage path="/en/articles/:slug" />
              <ArticlePage path="/es/articulos/:slug" />
              <CategoryPage path="/en/categories/:slug" />
              <CategoryPage path="/es/categorias/:slug" />
              <PoliticsPage path="/en/politics/:slug" />
              <PoliticsPage path="/es/politica/:slug" />
              <PublicProfile path="/en/profileUser/:slug" />
              <PublicProfile path="/es/perfilUsuario/:slug" />
              <DetailPageUser path="/en/profile/:slug" />
              <DetailPageUser path="/es/perfil/:slug" />
              <EventsPage path="/en/events" />
              <EventsPage path="/es/eventos" />
              <EventsSearch path="/en/events/search" />
              <EventsSearch path="/es/eventos/buscar" />
              <EventPage path="/en/events/:slug" />
              <EventPage path="/es/eventos/:slug" />
              <ContactPage path="/es/contacto" />
              <ContactPage path="/en/contact" />
              <NosotrosPagina path="/es/nosotros" />
              <AboutPage path="/en/about-us" />

              <AuthPage path="/en/community" />
              <AuthPage path="/es/comunidad" />
              <AuthPage path="/en/articles" />
              <AuthPage path="/es/articulos" />
              <AuthPage path="/en/vendors" />
              <AuthPage path="/es/proveedores" />
              <AuthPage path="/es/premium" />
              <AuthPage path="/en/premium" />
              <AuthPage path="/en/meetups" />
              <AuthPage path="/es/meetups" />
              <AuthPage path="/en/meetups/new" />
              <AuthPage path="/es/meetups/nuevo" />
              <AuthPage path="/en/groups" />
              <AuthPage path="/es/grupos" />
              <AuthPage path="/en/groups/discover" />
              <AuthPage path="/es/grupos/descubrir" />
              <AuthPage path="/en/groups/new" />
              <AuthPage path="/es/grupos/nuevo" />
              <AuthPage path="/en/groups/:slug" />
              <AuthPage path="/es/grupos/:slug" />
              <AuthPage path="/es/perfil" />
              <AuthPage path="/en/profile" />
              <AuthPage path="/es/perfil/visitas" />
              <AuthPage path="/en/profile/visits" />
              <AuthPage path="/es/perfil/actividad" />
              <AuthPage path="/en/profile/activity" />
              <AuthPage path="/es/perfil/no-contenido" />
              <AuthPage path="/en/profile/no-content" />
              <AuthPage path="/es/perfil/articulo" />
              <AuthPage path="/en/profile/article" />
              <AuthPage path="/es/perfil/contenido" />
              <AuthPage path="/en/profile/content" />
              <AuthPage path="/es/perfil/cuenta" />
              <AuthPage path="/en/profile/account" />
              <AuthPage path="/es/perfil/notificaciones" />
              <AuthPage path="/en/profile/notifications" />
              <AuthPage path="/en/profile/following" />
              <AuthPage path="/es/perfil/siguiendo" />
              <AuthPage path="/en/profile/followers" />
              <AuthPage path="/es/perfil/seguidores" />

              <DetailPageItem path="/es/:id" />
              <DetailPageItem path="/en/:id" />

              <PoliticaPagina path="/es/politica-de-privacidad" />
              <PolicyPage path="/en/privacy-policy" />

              <TerminosPagina path="/es/terminos-y-condiciones" />
              <TermPage path="/en/terms-and-conditions" />

              <DescargoPagina path="/es/descargo" />
              <DisclaimerPage path="/en/disclaimer" />

              <CookiePagina path="/es/politica-de-cookies" />
              <CookiePage path="/en/cookie-policy" />

              <ReembolsoPage path="/es/politica-de-reembolso" />
              <RefundPage path="/en/refund-policy" />

              <AceptablePage path="/es/politica-de-uso-aceptable" />
              <AcceptablePage path="/en/acceptable-use-policy" />

              <InvitadosPage path="/es/acuerdo-de-publicacion-de-invitados" />
              <GuestPage path="/en/guest-post-agreement" />

              <PremiumSuccess path="/es/usuario-premium" />
              <PremiumSuccess path="/en/user-premium" />

              <CoursePage path="/es/curso/:id" />
              <CoursePage path="/en/course/:id" />

              <InternalCoursePage path="/es/curso-interno/:id" />
              <InternalCoursePage path="/en/course-internal/:id" />

              <GeneralPage path="/es/:slug" />
              <GeneralPage path="/en/:slug" />

              <ChatPage path="/es/chat" />
              <ChatPage path="/en/chat" />

              <NotFoundPage default />
            </Router>
          )}
        </Suspense>

        {/* Create Modals */}
        <>
          <Login />
          <Register />
          <Forgot />
          <Reset />
          <Search />
          <Newsletter />
          <AddContent />
          <ShareContent />
        </>

        {/* Reset scroll */}
        <OnRouteChange
          action={() => {
            window.scrollTo(0, 0);
          }}
        />
        {/* Cookies */}
        <CookieConsent
          disableStyles={true}
          location="bottom"
          buttonText={this.props.language === "es" ? "ACEPTO" : "I AGREE"}
          cookieName="cookiePolicies"
          containerClasses="cookies-layer"
          contentClasses="cookies-layer__content"
          buttonClasses="cookies-layer__button"
          expires={150}
          onAccept={() => {
            Cookies.set("cookiePolicies", "ok");
          }}
        >
          {textLayer}
          <a href={urlLayer}>{linkLayer}</a>
        </CookieConsent>
      </>
    );
  }
}

function mapState(state) {
  const { user, me } = state.authentication;
  return { user, me };
}

const actionCreators = {
  init: appActions.init,
  initAds: appActions.ads,
  loadFollowers: userActions.loadFollowers,
  loadFavorites: userActions.loadFavorites,
  loadSummary: userActions.loadSummary,
};

const connectedApp = connect(mapState, actionCreators)(App);
export { connectedApp as App };
