import React from "react";
import Modal from "react-bootstrap/Modal";
import { RegisterForm1 } from "../forms";
import { modalActions } from "../../_actions";
import { modalConstants } from "../../_constants";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

import ico_video from "../../_images/ico-video.svg";
import ico_publish from "../../_images/ico-publish.svg";
import ico_access from "../../_images/ico-access.svg";
import ico_community from "../../_images/ico-community.svg";
import ico_hands from "../../_images/ico-hands.svg";

class Register extends React.Component {
  constructor(props) {
    super(props);

    this.handleClose = this.handleClose.bind(this);
  }

  /* Close window */
  handleClose(e) {
    if (e) {
      e.preventDefault();
    }
    this.props.hideRegister();
  }

  /* Visualization */
  render() {
    //state, visibility and user gotten from Redux state.
    const { t, step, register: visibility } = this.props;

    return (
      <Modal
        className="fullsize"
        show={visibility}
        onHide={this.handleClose}
        animation={false}
      >
        {step !== modalConstants.REGISTER_STEP2 ? (
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="row account_box">
                <div className="col-md-5 images__registry"></div>

                <div className="col-md-7 registry__content">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={this.handleClose}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                  <RegisterForm1 />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div
            className="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div className="modal-content welcome">
              <div className="welcome__content">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={this.handleClose}
                >
                  <span aria-hidden="true"> </span>
                </button>
                <img
                  className="welcome__ico"
                  src={ico_hands}
                  alt={t("register.welcome")}
                />
                {/* <h1 className="welcome__title">
                  <span className="title__highlight welcome">
                    {`${t("register.awesome")} ${me.shortName}!`}
                  </span>
                  <br />
                  {t("register.welcome")}
                </h1> */}
                <span className="you__can">{t("register.you_can")}</span>
                <div className="highlights__box row">
                  <div className="highlight__01">
                    <div className="highlight__ico">
                      <img src={ico_publish} alt="Publish" />
                    </div>
                    <div className="highlight__txt">
                      {t("register.advice1")}
                    </div>
                  </div>
                  <div className="highlight__02">
                    <div className="highlight__ico">
                      <img src={ico_video} alt="Highlight" />
                    </div>
                    <div className="highlight__txt">
                      {t("register.advice2")}
                    </div>
                  </div>
                </div>
                <div className="highlights__box row">
                  <div className="highlight__01">
                    <div className="highlight__ico">
                      <img src={ico_access} alt="Access" />
                    </div>
                    <div className="highlight__txt">
                      {t("register.advice3")}
                    </div>
                  </div>
                  <div className="highlight__02">
                    <div className="highlight__ico">
                      <img src={ico_community} alt="Community" />
                    </div>
                    <div className="highlight__txt">
                      {t("register.advice4")}
                    </div>
                  </div>
                </div>
                <button
                  className="btn btn-lg btn-primary float-left"
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={this.handleClose}
                >
                  {t("buttons.start_now")}
                </button>
              </div>
            </div>
          </div>
        )}
      </Modal>
    );
  }
}

function mapState(state) {
  const { register, step } = state.modals;
  const { me } = state.authentication;
  return { register, step, me };
}

const actionCreators = {
  hideRegister: modalActions.hideRegister,
};

const connectedRegister = connect(
  mapState,
  actionCreators
)(withTranslation("common")(Register));
export { connectedRegister as Register };
