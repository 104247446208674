import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Select from 'react-select';
import { articleService } from "../../_services";


export const Autocompletes = ({ handler, value }) => {
  //Language hooks
  const { i18n, t } = useTranslation("common");
  const lang = i18n.language;
  const [members, setMembers] = useState([]);
  const options = [];
  useEffect(() => {
    /** Call Services  */
    articleService
      .getUser(lang)
      .then((res) => res.json())
      .then((response) => {
        if (response) {
          setMembers(response.articles)
        }
      })
      .catch(console.log);
  }, [lang, setMembers]);

  members.map( item => {
    options.push({
      value: item.id,
      uid: item.uid,
      label: t(item.name),
    });
  });

  return (
      <Select
        options={options}
        onChange={handler}
        value={value}
        name="person"
        defaultValue={value}
      />
  );
};
