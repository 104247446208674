import React, { useState, useEffect } from "react";
import SEO from "../_components/common/seo";
import { connect } from "react-redux";
import Layout from "../_components/common/layout";
import { useTranslation } from "react-i18next";
import {HorizontalAd} from "../_components/ads/horizontal-ad";
import he from "he";
import LatestArticles from "../_components/lists/latest-articles";
import { CategoryArticles } from "../_components/lists/category-articles";

const CategoryPage = ({ slug, config }) => {
  const { t, i18n } = useTranslation("common");
  const lang = i18n.language;
  const [category, setCategory] = useState(null);

  useEffect(() => {
    //Assign category by slug.
    if (config) {
      for (let i = 0; config.categories[lang].length; i++) {
        let item = config.categories[lang][i];
        if (item.link.indexOf(`/${slug}`) >= 0) {
          setCategory(item);
          break;
        }
      }
    }
  }, [config, slug, lang]);

  if (!category) {
    return (
      <Layout>
        <SEO lang={lang} title={t("category.title")} />
        <main>Loading...</main>
      </Layout>
    );
  }

  return (
    <Layout>
      <SEO
        lang={lang}
        title={`${t("category.title")} ${he.decode(category.category)}`}
      />
      <main
        role="main"
        itemScope=""
        itemProp="mainContentOfPage"
        itemType="http://schema.org/Blog"
      >
        {/* Category featured */}
        <CategoryArticles
          title={he.decode(category.category)}
          category={encodeURIComponent(he.decode(category.category))}
        />

        {/* Ad */}
        <HorizontalAd id="Main Banner" />

        {/* Beginning lastest videos section */}
        <LatestArticles
          category={encodeURIComponent(he.decode(category.category))}
          className="category"
        />
      </main>
    </Layout>
  );
};

function mapState(state) {
  const { config } = state.app;
  return { config };
}

const connectedCategoryPage = connect(mapState)(CategoryPage);
export { connectedCategoryPage as CategoryPage };
