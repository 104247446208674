import React from "react";
import { Link } from "@reach/router";
import SEO from "../_components/common/seo";
import Layout from "../_components/common/layout";
import { useTranslation } from "react-i18next";
import ico_notfound from "../_images/ico-404.svg";

const NotFoundPage = () => {
  const { t, i18n } = useTranslation("common");
  const lang = i18n.language;

  //Default page
  return (
    <Layout>
      <SEO lang={lang} title={t("pages.not_found")} />
      <main
        role="main"
        className="no__top"
        itemScope=""
        itemProp="mainContentOfPage"
        itemType="http://schema.org/Blog"
      >
        <section className="notifications error">
          <div className="container">
            <div className="profile__header d-flex flex-column">
              <img src={ico_notfound} alt="Nothing here" />
              <h1 className="error404">404 Error</h1>
              <span>We can’t find the page what are ypu looking for</span>
              <Link to="/" className="btn-primary">
                Back to home
              </Link>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default NotFoundPage;
