import React, { useState, useEffect } from "react";
import { taxonomys } from "../../_services/taxonomys.service";
import { useTranslation } from "react-i18next";


export const PhraseVideo = () => {
  //Language hooks
  const { i18n } = useTranslation("common");
  const lang = i18n.language;
  const [phrases, setPhrases] = useState([]);

  useEffect(() => {
    /** Call Services  */
    if (lang === 'en') {
      taxonomys
      .getPhrases(lang)
      .then((res) => res.json())
      .then((response) => {
        if (response) {
          setPhrases(response[0].field_video);
        }
      })
      .catch(console.log);
    }
    if (lang === 'es') {
      taxonomys
      .getPhrases(lang)
      .then((res) => res.json())
      .then((response) => {
        if (response) {
          setPhrases(response[1].field_video);
        }
      })
      .catch(console.log);
    }
  }, [lang, setPhrases]);

  return (
    <p>{ phrases }</p>
  );
};

export const PhraseArticle = () => {
  //Language hooks
  const { i18n } = useTranslation("common");
  const lang = i18n.language;
  const [phrases, setPhrases] = useState([]);

  useEffect(() => {
    /** Call Services  */
   
      if (lang === 'en') {
        taxonomys
        .getPhrases(lang)
        .then((res) => res.json())
        .then((response) => {
          if (response) {
            setPhrases(response[0].field_article);
          }
        })
        .catch(console.log);
      }
      if (lang === 'es') {
        taxonomys
        .getPhrases(lang)
        .then((res) => res.json())
        .then((response) => {
          if (response) {
            setPhrases(response[1].field_article);
          }
        })
        .catch(console.log);
      }

  }, [lang, setPhrases]);

  return (
    <p>{ phrases }</p>
  );
};

export const PhraseGroup = () => {
  //Language hooks
  const { i18n } = useTranslation("common");
  const lang = i18n.language;
  const [phrases, setPhrases] = useState([]);

  useEffect(() => {
    /** Call Services  */
   
      if (lang === 'en') {
        taxonomys
        .getPhrases(lang)
        .then((res) => res.json())
        .then((response) => {
          if (response) {
            setPhrases(response[0].field_grupos);
            
          }
        })
        .catch(console.log);
      }
      if (lang === 'es') {
        taxonomys
        .getPhrases(lang)
        .then((res) => res.json())
        .then((response) => {
          if (response) {
            setPhrases(response[1].field_grupos);
          }
        })
        .catch(console.log);
      }

  }, [lang, setPhrases]);

  return (
    <p>{ phrases }</p>
  );
};