import { userConstants, modalConstants } from "../_constants";
import { appService, userService } from "../_services";
import { modalActions } from "../_actions";

export const userActions = {
  login,
  logout,
  contact,
  new_group,
  register,
  register_2,
  register_3,
  forgotPassword,
  resetPassword,
  loadFollowers,
  loadFavorites,
  loadSummary,
};

/**
 * Login
 */
function login(lang, username, password, redirect) {
  return (dispatch) => {
    dispatch(request({ username }));

    userService.login(lang, username, password, redirect).then(
      ({ user, me }) => {
        //Success
        dispatch(success(user, me));
        dispatch(userActions.loadFollowers(me.id));
        dispatch(userActions.loadFavorites(me.id));
        dispatch(modalActions.hideLogin());
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }
  function success(user, me) {
    return { type: userConstants.LOGIN_SUCCESS, user, me };
  }
  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}

/**
 * contact
 */
function contact(lang, result) {
  return (dispatch) => {
    dispatch(request(result));
    const name = result.name;
    const email = result.email;
    const phone = result.phone;
    const message = result.message;
    const subject = result.subject;

    appService
      .postContact(lang, { name, email, phone, message, subject })
      .then((res) => res.json())
      .then(() => {
        window.location.reload();
      })
      .catch(console.log);
  };

  function request(result) {
    return { type: userConstants.CONTACT_REQUEST, result };
  }
}

/**
 * Grupos
 */
function new_group(lang, result) {
  return (dispatch) => {
    dispatch(request(result));
    const image_id = result.image_id;
    // const image_alt = result.image_alt
    const name = result.name;
    const description = result.description;
    const rules = result.rules;
    const person = result.person;
    const stateGroup = result.stateGroup;
    const member_id = result.member_id;
    // const label_member = result.label_member

    appService
      .postGroup(lang, {
        image_id,
        name,
        description,
        rules,
        person,
        stateGroup,
      })
      .then((res) => res.json())
      .then((response) => {
        if (response) {
          const group_id = response.id[0].value;
          appService
            .postMembers({ group_id, member_id })
        }
      })
      .catch(console.log);
  };

  function request(result) {
    return { type: userConstants.GROUP_REQUEST, result };
  }
}

/**
 * Register
 */
function register(lang, user) {
  return (dispatch) => {
    dispatch(request(user));

    userService.register(lang, user).then(
      ({ user, me }) => {
        dispatch(success(user, me));
        dispatch(modalActions.showRegister(modalConstants.REGISTER_STEP2));
      },
      (error) => {
        error = String(error.toLocaleLowerCase().match(/[a-z]+/g)).replace(
          /,/g,
          "_"
        );
        dispatch(failure(error.toString()));
      }
    );
  };

  function request(user) {
    return { type: userConstants.REGISTER_REQUEST, user };
  }
  function success(user, me) {
    return { type: userConstants.REGISTER_SUCCESS, user, me };
  }
  function failure(error) {
    return { type: userConstants.REGISTER_FAILURE, error };
  }
}

/**
 * Register Setp 2
 */
function register_2(lang, user, info) {
  return (dispatch) => {
    dispatch(request());

    userService.registerStep2(lang, user, info).then(
      ({ me }) => {
        dispatch(success(me));
        dispatch(modalActions.showRegister(modalConstants.REGISTER_STEP3));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.REGISTER_2_REQUEST };
  }
  function success(me) {
    return { type: userConstants.REGISTER_2_SUCCESS, me };
  }
  function failure(error) {
    error = String(error.toLocaleLowerCase().match(/[a-z]+/g)).replace(
      /,/g,
      "_"
    );
    return { type: userConstants.REGISTER_2_FAILURE, error };
  }
}

/**
 * Register Setp 3
 */
function register_3(lang, user, info) {
  return (dispatch) => {
    dispatch(request());

    userService.registerStep3(lang, user, info).then(
      ({ me }) => {
        dispatch(success(me));
        dispatch(modalActions.showRegister(modalConstants.REGISTER_STEP4));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.REGISTER_3_REQUEST };
  }
  function success(me) {
    return { type: userConstants.REGISTER_3_SUCCESS, me };
  }
  function failure(error) {
    error = String(error.toLocaleLowerCase().match(/[a-z]+/g)).replace(
      /,/g,
      "_"
    );
    return { type: userConstants.REGISTER_3_FAILURE, error };
  }
}

/**
 * Logout
 */
function logout() {
  userService.logout();
  return { type: userConstants.LOGOUT };
}

/**
 * Logout
 */
function loadSummary(lang, uid) {
  return (dispatch) => {
    dispatch(request());
    return userService.summary(lang, uid).then(
      (response) =>
        response.text().then((text) => {
          const data = text && JSON.parse(text);
          const me = data[0];
          me.shortName = me.display_name.substr(
            0,
            me.display_name.indexOf(" ")
          );
          localStorage.setItem("me", JSON.stringify(me));
          dispatch(success(me));
          return me;
        }),
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: "" };
  }
  function success(me) {
    return { type: me };
  }
  function failure(error) {
    return { type: "" };
  }
}

/**
 * Forgot Password
 */
function forgotPassword(lang, email) {
  return (dispatch) => {
    dispatch(request());

    userService.forgotPassword(lang, email).then(
      () => {
        dispatch(success(email));
        dispatch(
          modalActions.showForgotPassword(modalConstants.FORGOT_STEP2, email)
        );
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.FORGOT_PASSWORD_REQUEST };
  }
  function success(me) {
    return { type: userConstants.FORGOT_PASSWORD_SUCCESS, email };
  }
  function failure(error) {
    error = String(error.toLocaleLowerCase().match(/[a-z]+/g)).replace(
      /,/g,
      "_"
    );
    return { type: userConstants.FORGOT_PASSWORD_FAILURE, error };
  }
}

/**
 * Reset Password
 */
function resetPassword(lang, token, password) {
  return (dispatch) => {
    dispatch(request());

    userService.resetPassword(lang, token, password).then(
      () => {
        dispatch(success());
        dispatch(modalActions.showRecoveryPassword(modalConstants.RESET_STEP2));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.RESET_PASSWORD_REQUEST };
  }
  function success(me) {
    return { type: userConstants.RESET_PASSWORD_SUCCESS };
  }
  function failure(error) {
    error = String(error.toLocaleLowerCase().match(/[a-z]+/g)).replace(
      /,/g,
      "_"
    );
    return { type: userConstants.RESET_PASSWORD_FAILURE, error };
  }
}

/**
 * Followers
 */
function loadFollowers(uid) {
  return (dispatch) => {
    dispatch(request());
    userService.followers(uid).then(
      (followers) => {
        userService.following(uid).then(
          (following) => {
            dispatch(success(following, followers));
          },
          (error) => {
            dispatch(failure(error.toString()));
          }
        );
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.FOLLOWERS_REQUEST };
  }
  function success(following, followers) {
    return { type: userConstants.FOLLOWERS_SUCCESS, following, followers };
  }
  function failure(error) {
    return { type: userConstants.FOLLOWERS_FAILURE, error };
  }
}

/**
 * Likes/Favorites
 */
function loadFavorites(uid) {
  return (dispatch) => {
    dispatch(request());
    userService.favorites(uid).then(
      (favorites) => {
        dispatch(success(favorites));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.FAVORITES_REQUEST };
  }
  function success(favorites) {
    return { type: userConstants.FAVORITES_SUCCESS, favorites };
  }
  function failure(error) {
    return { type: userConstants.FAVORITES_FAILURE, error };
  }
}
