import React, { useState, useEffect } from "react";
import Fade from "@material-ui/core/Fade";
import { connect } from "react-redux";
import { MainMenu } from "../navigation/main-menu";
import { LoggedProfile } from "../users/logged-profile";
import { modalActions } from "../../_actions";

import image_search from "../../_images/ico-search.svg";
import { Link } from "@reach/router";

const Header = ({ search, withFade }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [visibility, setVisibility] = useState(true);

  useEffect(() => {
    if (withFade) {
      //Listen scroll when mouting.
      window.addEventListener("scroll", handleScroll);

      return () => {
        //Remove listener when unmount.
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, [withFade]);

  /* Listen scroll */
  const handleScroll = (e) => {
    if (window.scrollY > 0) {
      setVisibility(false);
    } else if (window.scrollY <= 0) {
      setVisibility(true);
    }
  };

  /* Listen navigation open */
  const openNav = (e) => {
    e.preventDefault();
    setShowMenu(true);
  };

  /* Listen navigation close */
  const closeNav = (e) => {
    e.preventDefault();
    setShowMenu(false);
  };

  return (
    <Fade in={visibility}>
      <header role="banner" itemScope="" itemType="https://schema.org/WPHeader">
        <div className="navbar navbar-light bg-light shadow-sm">
          <div className="container d-flex justify-content-between">
            <MainMenu isOpen={showMenu} handleClose={closeNav} />

            <div className="header__secondary d-flex col-7 col-lg-2 justify-content-end">
              <Link to={"/"}>
              <button
                className="search__btn"
                data-toggle="modal"
                data-target="#search"
                onClick={search}
              >
                <img src={image_search} alt="Search" />
              </button>
              </Link>
              <LoggedProfile />
              <span
                style={{ fontSize: "30px", cursor: "pointer" }}
                onClick={(e) => openNav(e)}
                className="menu__toggle mobile"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="27"
                  height="18"
                  viewBox="0 0 27 18"
                  role="button"
                >
                  <g transform="matrix(-1 0 0 1 27 0)" fill="#021431;">
                    <path d="M0 8L27 8 27 10 0 10 0 8Z"></path>
                    <path d="M0 0L27 0 27 2 0 2 0 0Z"></path>
                    <path d="M0 16L15 16 15 18 0 18 0 16Z"></path>
                  </g>
                </svg>
              </span>
            </div>
          </div>
        </div>
      </header>
    </Fade>
  );
};

const actionCreators = {
  search: modalActions.showSearch,
};

const connectedHeader = connect(null, actionCreators)(Header);
export { connectedHeader as Header };
