import React, { useEffect, useState } from "react";

import ico_pri from "../../_images/pri.png";
import ico_pub from "../../_images/pub.png";

import { Link } from "@reach/router";
import { useTranslation } from "react-i18next";

import { appService } from "../../_services";

export const MembersCard = ({ id, field_logo, type, label }) => {
  const { t, i18n } = useTranslation("common");
  const lang = i18n.language;

  const [articles, setArticles] = useState([]);
  const [membersgroup, setMembersgroup] = useState([]);

  useEffect(() => {
    appService
      .getArticles(lang, id)
      .then((res) => res.json())
      .then((response) => {
        if (response) {
          setArticles(response);
        }
      })
      .catch(console.log);

    appService
      .getMembersGroup(lang, id)
      .then((res) => res.json())
      .then((response) => {
        if (response) {
          setMembersgroup(response);
        }
      })
      .catch(console.log);
  }, []);

  return (
    <div>
      <div className="group__card col-12">
        <div className="g__card">
          <figure className="article__image__content col-12">
            <Link
              to={`/${lang}/${t("groups.ttl_black")}/${id}`}
              className="container-img"
            >
              <img src={field_logo} alt="Remote Workers" />
              {/* <img src="/images/temp/group-img-big1.jpg" alt="Remote Workers"/> */}
              <div className="type-group">
                {type === "Group Public" ? (
                  <img
                    src={ico_pub}
                    alt="Group public"
                    className="icon-group"
                  />
                ) : (
                  <img
                    src={ico_pri}
                    alt="Group private"
                    className="icon-group"
                  />
                )}
                <p>{type}</p>
              </div>
            </Link>
          </figure>
          <div className="group__info">
            <h2>{label}</h2>
            <div className="detail_info__group d-flex flex-wrap">
              <div className="group__activity col-6 col-sm-6 col-md-12 col-lg-6">
                <p className="groups__members">
                  {membersgroup.results} - {t("groups.members")}{" "}
                </p>
                <p className="groups__post">
                  {articles.results} {t("groups.posts")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
