import React from "react";
import SEO from "../_components/common/seo";
import Layout from "../_components/common/layout";
import { useTranslation } from "react-i18next";
import CommunityMenu from "../_components/navigation/community-menu";
import GroupsMenu from "../_components/navigation/groups-menu";
import Credits from "../_components/common/credits";
import ico_back from "../_images/ico-back.svg";
import { GroupForm } from "../_components/forms/group-form";

const NewGroupPage = () => {
  const { t, i18n } = useTranslation("common");
  const lang = i18n.language;

  return (
    <Layout withFade={true} noFooter={true}>
      <SEO lang={lang} title={t("groups.title")} />
      <main
        className="groups"
        itemScope=""
        itemProp="mainContentOfPage"
        itemType="http://schema.org/Blog"
      >
        <section className="profile__page feed group">
          <div className="container d-flex flex-wrap">
            <div className="profile__menu col-lg-2 col-md-3">
              <CommunityMenu selected="groups" />
            </div>
            <div className="profile__body col-lg-10 col-md-9">
              <div className="groups__options">
                <div className="tabs__container groups__tabs group__menu__lv2 sticky">
                  <div className="mobile__bar d-flex align-items-center">
                    <a href="/" className="back__btn">
                      <img src={ico_back} alt="Back" />
                    </a>
                    <span className="title__profile">Groups</span>
                  </div>
                  <GroupsMenu selected="tab3" />
                </div>

                <div className="create__group__content">
                  <h3 className="new__content group">
                    <span>{t("contents.short_create")}</span>
                    {t("contents.short_group")}
                  </h3>
                  {/* <PhraseGroup /> */}

                  <div className="creation__group__form">
                    <GroupForm />
                  </div>
                </div>
              </div>
              <Credits />
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default NewGroupPage;
