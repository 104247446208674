import { combineReducers } from "redux";
import { authentication } from "./authentication.reducer";
import { users } from "./users.reducer";
import { modals } from "./modals.reducer";
import { app } from "./app.reducer";

const rootReducer = combineReducers({
  authentication,
  users,
  modals,
  app,
});

export default rootReducer;
