import React from "react";
import SEO from "../_components/common/seo";
import Layout from "../_components/common/layout";
import { useTranslation } from "react-i18next";
import { ProfileMenu } from "../_components/navigation/profile-menu";
import Followers from "../_components/ui/followers";
import Credits from "../_components/common/credits";
import ContentEmpty from "../_components/contents/content-empty";

const ProfileNoContent = () => {
  const { t, i18n } = useTranslation("common");
  const lang = i18n.language;

  return (
    <Layout>
      <SEO lang={lang} title={t("profile.title")} />
      <main
        role="main"
        itemScope=""
        itemProp="mainContentOfPage"
        itemType="http://schema.org/Blog"
      ></main>
      <section className="profile__page empty">
        <div className="container d-flex">
          <ProfileMenu></ProfileMenu>
          <div className="profile__body no__content col-lg-7 col-md-9">
            <ContentEmpty></ContentEmpty>
            <Credits></Credits>
          </div>
          <div className="user__followers col-3 d-none d-md-none d-lg-block">
            <Followers title={t("profile.following")}></Followers>
            <Followers title={t("profile.followers")}></Followers>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ProfileNoContent;
