import React, { useEffect, useState } from "react";
import { Link } from "@reach/router";
import Carousel from "react-multi-carousel";
import EventFeatured from "../ui/event-featured";
import { useTranslation } from "react-i18next";
import { eventService } from "../../_services/event.service";
import * as moment from "moment";
import { path } from "../../_helpers";
import he from "he";
import "react-multi-carousel/lib/styles.css";

export default function FeaturedEvents({ onClick }) {
  //Language hooks
  const { t, i18n } = useTranslation("common");
  const lang = i18n.language;

  const [data, setData] = useState([]);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  useEffect(() => {
    /** Call Services  */
    eventService
      .getEventsFeatured(lang)
      .then((res) => res.json())
      .then((response) => {
        // response = ["1", "2", "3", "4", "5", "6", "7", "8", "9"];
        setData(response);
      })
      .catch((error) => console.log(error));
  }, [lang, setData]);

  const CustomDot = ({ index, onClick, active }) => {
    return (
      <li className={active ? "active" : "inactive"} onClick={() => onClick()}>
        {index + 1}
      </li>
    );
  };

  const CustomLeftArrow = ({ onClick }) => {
    return (
      <button
        className="a react-multiple-carousel__arrow react-multiple-carousel__arrow--left"
        onClick={() => onClick()}
      />
    );
  };

  const CustomRightArrow = ({ onClick }) => {
    return (
      <button
        className="b react-multiple-carousel__arrow"
        onClick={() => onClick()}
      />
    );
  };

  return (
    <div className="container">
      <div className="featured__heading d-flex justify-content-between">
        <h3 className="title__home bold">
          <span className="title__highlight">{t("events.featured_blue")}</span>
          {t("events.featured_black")}
        </h3>
        <div className="navigation__featured d-flex align-items-center">
          <Link to={`/${lang}/${t("url.events")}`}>
            <span className="explore__featured">{t("events.allContent")}</span>
          </Link>
          <div className="buttons__featured d-flex align-items-center">
            <div className="nav__counter">
              <span className={data.length < 3 ? "remove-padding" : ""}>
                {data.length < 3 ? data.length : ""}
              </span>{" "}
              / {data.length}
            </div>
          </div>
        </div>
      </div>
      <div className="featured__carousel">
        <Carousel
          responsive={responsive}
          showDots={true}
          infinite={true}
          customDot={<CustomDot />}
          customLeftArrow={<CustomLeftArrow />}
          customRightArrow={<CustomRightArrow />}
        >
          {data.map((dataArticle) => (
            <div
              className="col-12 col-sm-12 small__column__articles"
              key={dataArticle.nid}
            >
              {/* <div>{dataArticle}</div> */}
              <EventFeatured
                slug={dataArticle.field_path}
                img={path(dataArticle.field_image)}
                category={dataArticle.field_principal_category}
                title={he.decode(dataArticle.field_name_evente)}
                location={dataArticle.field_location}
                startDate={moment(dataArticle.field_start_date).format(
                  "MMM DD, YYYY"
                )}
              />
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
}
