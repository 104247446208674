import React from "react";
import SEO from "../_components/common/seo";
import Layout from "../_components/common/layout";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { ProfileFollowers } from "../_components/users/profile-followers";
import { ProfileMenu } from "../_components/navigation/profile-menu";
import { MobileMenu } from "../_components/navigation/mobile-menu";
import Followers from "../_components/contents/followers";

const ProfileFollowingAll = ({ following }) => {
  const {t, i18n} = useTranslation("common");
  const lang = i18n.language;

  return (
    <Layout withFade={true} noFooter={true}>
      <SEO lang={lang} title={t("profile.title")}/>
      <main
        role="main"
        itemScope=""
        itemProp="mainContentOfPage"
        itemType="http://schema.org/Blog"
      ></main>
      <section className="profile__page feed">
        <div className="container d-flex flex-wrap">
          <ProfileMenu selected=""/>
          <div className="profile__body col-lg-8 col-md-9">
            <Followers
              type="following"
              users={following}/>
          </div>
          <div className="user__followers col-lg-2 col-3 d-none d-md-none d-lg-block">
            <ProfileFollowers selected="following"/>
          </div>
        </div>
      </section>
      <MobileMenu selected="profile" withFade={true}/>
    </Layout>
  );
};

function mapState(state) {
  const { following } = state.users;
  return { following };
}

const connectedProfileFollowingAll = connect(mapState)(ProfileFollowingAll);
export {connectedProfileFollowingAll as ProfileFollowingAll};

