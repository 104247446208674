import React from "react";
import logo from "../../_images/techfrenetic-logo.svg";
import LangSelector from "../navigation/lang-selector";
import { FooterMenu } from "../navigation/footer-menu";
import { SocialsMenu } from "../navigation/socials-menu";
import { LegalMenu } from "../navigation/legal-menu";
import Copyright from "./copyright";

export default function Footer({ visibility }) {
  return (
    !visibility && (
      <footer
        role="contentinfo"
        itemScope=""
        itemType="http://schema.org/WPFooter"
      >
        <div className="container justify-content-between">
          {/* Beginning of footer left box */}
          <div className="left__footer row">
            <div className="footer__logo">
              <img itemProp="logo" src={logo} alt="Here is Techfrenetic!" />
            </div>
            <div className="footer__menus__container">
              <div className="quick__footer__links row">
                <FooterMenu />
                <LangSelector className="languaje__desktop" />
              </div>
              <div className="legal__footer__links row">
                <LegalMenu />
              </div>
            </div>
          </div>

          {/* Beginning of footer right box */}
          <div className="right__footer">
            <SocialsMenu />
            <div className="copyright">
              <Copyright />
            </div>
          </div>

          {/* Beginning of footer copyrights for mobile */}
          <div className="copyright__mobile">
            <LangSelector className="language__mobile" />
            <Copyright />
          </div>
        </div>
      </footer>
    )
  );
}
