import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

export const SearchInput = ({
  query,
  handleClear,
  handleChange,
  handleSubmit,
}) => {
  //Language hooks
  const { t } = useTranslation("common");
  const [typed, setTyped] = useState("");

  useEffect(() => {
    if (typeof query === "string") {
      setTyped(query);
    }
  }, [query, setTyped]);

  const handleDelete = () => {
    setTyped("");
    document.getElementById("query").focus();
    handleClear();
  };

  return (
    <div className="search__form__container">
      <input
        id="query"
        name="query"
        value={typed || ""}
        placeholder={t("search.typeto")}
        type="search"
        onChange={handleChange}
      />
      <button
        type="button"
        className="close clear"
        data-dismiss="modal"
        aria-label="Close"
        onClick={handleDelete}
      >
        <span aria-hidden="true">×</span>
      </button>
      <button type="button" onClick={handleSubmit} className="btn btn-primary">
        {t("search.button")}
      </button>
    </div>
  );
};
