import React from "react";
import Modal from "react-bootstrap/Modal";
import { connect } from "react-redux";
import { modalActions } from "../../_actions";
import { useTranslation } from "react-i18next";

import post_video from "../../_images/post-video.svg";
import post_text from "../../_images/post-text.svg";
import { PhraseVideo } from "../ui/phrases";
import { PhraseArticle } from "../ui/phrases";

const ShareContent = ({
  create: visibility,
  hideCreateContent,
  showAddArticle,
}) => {
  //Language hooks
  const { t } = useTranslation("common");

  const handleClickVideo = (e) => {
    e.preventDefault();
    hideCreateContent();
    showAddArticle("video");
  };

  const handleClickArticle = (e) => {
    e.preventDefault();
    hideCreateContent();
    showAddArticle("article");
  };

  return (
    <Modal show={visibility} onHide={hideCreateContent} centered>
      <div
        id="_1"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="modalLabel_1"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered shared"
          role="document"
        >
          <div className="modal-content auto share__options">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={hideCreateContent}
            >
              <span aria-hidden="true">×</span>
            </button>
            <div className="mobile__bar d-flex align-items-center">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={hideCreateContent}
              >
                <span aria-hidden="true">×</span>
              </button>
              <span className="title__profile">
                {t("contents.create-content")}
              </span>
            </div>

            <div className="share__options">
              <div className="share__card">
                <a href="/" onClick={handleClickVideo}>
                  <h2 className="highlighted">
                    <img src={post_video} alt="Share a video" />
                    <span>{t("profile.share-modal")}</span>
                    {t("profile.share-video")}
                  </h2>
                  <PhraseVideo />
                </a>
              </div>

              <div className="share__card">
                <a href="/" onClick={handleClickArticle}>
                  <h2 className="highlighted">
                    <img src={post_text} alt="Share a video" />
                    <span>{t("profile.share-modal")}</span>
                    {t("profile.share-article")}
                  </h2>
                  <PhraseArticle />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

function mapState(state) {
  const { create } = state.modals;
  return { create };
}

const actionCreators = {
  showAddArticle: modalActions.showAddArticle,
  hideCreateContent: modalActions.hideCreateContent,
};

const connectedShareContent = connect(mapState, actionCreators)(ShareContent);
export { connectedShareContent as ShareContent };
