import React, { useState } from "react";
import SEO from "../_components/common/seo";
import Layout from "../_components/common/layout";
import { EventSearch } from "../_components/ui/event-search";
import { useTranslation } from "react-i18next";
import { SearchFilters } from "../_components/search/search-filters";
// import { eventService } from "../_services/event.service";

// const EventsSearch = ({ slug }) => {
const EventsSearch = () => {
  const { i18n } = useTranslation("common");
  const lang = i18n.language;
  const [info] = useState({});

  /*
    useEffect(() => {
        eventService
            .getInfoEvent(lang, slug)
            .then((res) => res.json())
            .then((response) => {
                console.log(response);
                setInfo(response[0]);
            })
            .catch((error) => console.log(error));
    }, [lang, slug, setInfo]);
    */

  const handleCategoriesChange = (values) => {
    // this.setState({ categories: values });
  };

  return (
    <Layout>
      <SEO lang={lang} title={info.title} />
      <main
        className="contents__inner"
        role="main"
        itemScope=""
        itemProp="mainContentOfPage"
        itemType="http://schema.org/Blog"
      >
        <section className="search__results__box">
          <div className="container">
            <h1 className="search__results">cloud: 6 events</h1>
            <div className="details__filtering d-flex flex-column flex-sm-row flex-md-row align-items-center justify-content-start flex-nowrap">
              <SearchFilters setFilters={handleCategoriesChange} />
            </div>
            <div className="search__results__body d-flex flex-wrap">
              <div className="search__results__body__l col-12 col-sm-12 col-md-8 col-lg-8">
                <EventSearch />
                <EventSearch />
                <EventSearch />
                <EventSearch />

                <button className="load__more">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                  >
                    <path d="M8.3 14.4C4.9 14.4 2.2 11.6 2.2 8.2 2.2 4.8 4.9 2.1 8.3 2.1 10 2.1 11.6 2.8 12.6 4L13.1 4.5 12.6 5 12 5.6 15.5 5.6 15.5 1.9 14.5 2.9 14 2.4C12.5 0.9 10.5 0 8.2 0 5.9 0 3.9 0.9 2.4 2.4 0.9 3.9 0 6 0 8.2 0 10.5 0.9 12.5 2.4 14 3.9 15.5 5.9 16.4 8.2 16.4 10.2 16.4 12.1 15.7 13.5 14.5L12 13C11 13.9 9.7 14.4 8.3 14.4L8.3 14.4Z"></path>
                  </svg>
                  View more
                </button>
              </div>
              <div className="article__right__ads col-12 col-sm-12 col-md-4 col-lg-4">
                <div className="vertical__ads">
                  <img
                    src="/images/temp/article-ads-01.jpg"
                    alt="Article Ada"
                  />
                </div>
                <div className="vertical__ads">
                  <img
                    src="/images/temp/article-ads-02.jpg"
                    alt="Article Ada"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default EventsSearch;
