import React from "react";
import { useTranslation } from "react-i18next";

export default function EventBannerDetail({
  img,
  location,
  startDate,
  endDate,
  email,
  buttonBuy,
}) {
  const { t } = useTranslation("common");

  return (
    <div className="container d-flex flex-column flex-sm-column flex-md-row flex-lg-row">
      <div
        style={{
          backgroundImage: `url("${img}")`,
        }}
        className="featured__image col-12 col-sm-12 col-md-8 col-lg-8"
      ></div>
      <div className="description__featured col-12 col-sm-12 col-md-4 col-lg-4">
        <span className="main__title__event">{t("events.event_details")}</span>
        <div className="event__details">
          <div className="location__event">
            <span className="event__location">{location}</span>
          </div>
          <div className="event__start">
            <span className="event__date">
              <strong>Start date</strong>
              <br /> {startDate}
            </span>
          </div>
          <div className="event__end">
            <span className="event__date">
              <strong>End date</strong>
              <br />
              {endDate}
            </span>
          </div>
          <div className="event__contact">
            <span className="event__mail">{email}</span>
          </div>
          <div className="events__tickets">
            {/* {ReactHtmlParser(buttonBuy)} */}
            <a href="https://www.allticketscol.com/" target="_blank">
              {t("events.buy_tickets")}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
