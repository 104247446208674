import React from "react";
import Modal from "react-bootstrap/Modal";
import { modalActions } from "../../_actions";
import { modalConstants } from "../../_constants";
import { ResetPassword } from "../forms/reset-password";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

class Reset extends React.Component {
  /* Visualization */
  render() {
    //state, visibility and user gotten from Redux state.
    const {
      t,
      step,
      token,
      recovery: visibility,
      hideRecoveryPassword,
      showLogin,
    } = this.props;

    return (
      <Modal
        className="fullsize"
        show={visibility}
        onHide={hideRecoveryPassword}
        animation={false}
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="row recovery__box">
              <div className="col-md-5 images__login"></div>

              {step === modalConstants.RESET_STEP1 && (
                <div className="col-md-7 recovery__process">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={hideRecoveryPassword}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                  <h1>
                    <span className="title__highlight">
                      {t("register.choose_blue")}
                    </span>
                    <br />
                    {t("register.choose_black")}
                  </h1>

                  <ResetPassword token={token} />
                </div>
              )}
              {step === modalConstants.RESET_STEP2 && (
                <div className="col-md-7 recovery__process ready">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={hideRecoveryPassword}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                  <h1>
                    <span className="title__highlight no__background">
                      Ready!
                    </span>
                  </h1>
                  <span className="copy__ready">You can continue learning</span>
                  <button
                    className="btn btn-lg btn-primary"
                    type="submit"
                    onClick={showLogin}
                  >
                    Login
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

function mapState(state) {
  const { recovery, step, token } = state.modals;
  return { recovery, step, token };
}

const actionCreators = {
  hideRecoveryPassword: modalActions.hideRecoveryPassword,
  showLogin: modalActions.showLogin,
};

const connectedReset = connect(
  mapState,
  actionCreators
)(withTranslation("common")(Reset));
export { connectedReset as Reset };
