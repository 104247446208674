import { authHeader } from "../_helpers";
import { appConstants } from "../_constants";

export const seedServices = {
  getProfile,
  getUserbelong,
  getInternalGroup,
  getInfoGroup
};

/**
 *
 * @param {*} lang
 * @param {*} username
 */
async function getProfile(userId) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
  };
  return await fetch(
    `${appConstants.API}/users/get-data-profile?uid=${userId}`,
    requestOptions
  )
    .then((res) => res.json())
    .then((response) => {
      if (response) {
        return response;
      }
    })
    .catch(console.log);
}

// grupos

function getUserbelong(lang, usr) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), 
      "Content-Type": "application/json",
    },
  };

  return fetch(`${appConstants.API}/${lang}/v1/group/${usr}/userbelong/json`, requestOptions);
}

function getInternalGroup(lang, nid) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), 
    },
  };
  return fetch(`${appConstants.API}/${lang}/v1/group-id/${nid}`, requestOptions);
}

function getInfoGroup(lang, idg) {
  const requestOptions = {
    method: "GET",
    headers: {...authHeader(),}
  };
  return fetch(`${appConstants.API}/${lang}/v1/group-usersnumber/${idg}`, requestOptions)
}
