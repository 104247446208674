import React from "react";
import { Link } from "@reach/router";
import { useTranslation } from "react-i18next";
import { PublishDate } from "./publish-date";
import { FavoriteButton } from "./favorite-button";
import { CategoryLink } from "./category-link";
import { path } from "../../_helpers";
import he from "he";

export default function ArticleSearch({ article, onClick }) {
  //Language hooks
  const { i18n, t } = useTranslation("common");
  const lang = i18n.language;


  return (
    <article
      itemScope=""
      itemType="http://schema.org/Article"
      className="col-12 col-sm-12 col-md-6 col-lg-6"
      onClick={onClick}
    >
      <div className="article__cat d-flex">
        <p className="article__cat__tag" rel="category">
          <CategoryLink name={article.category} />
        </p>
        <span className="article__cat__separator">•</span>
        <PublishDate className="article__hour__tag" date={article.date} />
      </div>
      <div className="d-flex">
        <div className="col-8 row__article__left">
          <div className="article__information">
            <h2 itemProp="headline">
              <Link to={`/${lang}/${article.id}`}>{he.decode(article.title)}</Link>
            </h2>
          </div>
        </div>
        <div className="col-4 row__article__right">
          <figure className="article__image__content">
            <Link to={`/${lang}/${article.id}`}>
              <img
                itemProp="image"
                src={path(article.thumbnail)}
                alt={he.decode(article.title)}
              />
            </Link>
          </figure>
        </div>
      </div>
      <div className="autor__container d-flex">
        <p className="article__autor">
          <Link to={`/${lang}/${t("home.profile")}/${String(article.user).toLocaleLowerCase()}`}>
            <span itemProp="name">{article.display_name}</span>
          </Link>
        </p>
        <FavoriteButton id={article.id} />
      </div>
    </article>
  );
}
