import { authHeader } from "../_helpers";
import { appConstants } from "../_constants";

export const commentService = {
  addComment,
  getComments,
  addReply,
  getReplys,
};

function addComment(lang, cid, comment) {
  //Setup object according drupal's format.
  const payload = {
    entity_id: [{ target_id: cid }],
    entity_type: [{ value: "node" }],
    comment_type: [{ target_id: "comment" }],
    field_name: [{ value: "comment" }],
    subject: [{ value: `Comment ${cid}` }],
    comment_body: [{ value: comment, format: "basic_html" }],
  };

  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(payload),
  };
  return fetch(
    `${appConstants.API}/${lang}/comment?_format=json`,
    requestOptions
  ).then(response => {
    return sendNotification(response, cid, lang);
  });
}

function addReply(lang, cid, comment, pid) {
  //Setup object according drupal's format.
  const payload = {
    entity_id: [{ target_id: cid }],
    entity_type: [{ value: "node" }],
    comment_type: [{ target_id: "comment" }],
    field_name: [{ value: "comment" }],
    subject: [{ value: `Comment ${cid}` }],
    comment_body: [{ value: comment, format: "basic_html" }],
    pid: [{ target_id: pid }],
  };

  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(payload),
  };
  return fetch(
    `${appConstants.API}/${lang}/comment?_format=json`,
    requestOptions
  ).then(response => {
    return sendNotification(response, cid, lang);
  });
}

function getComments(lang, cid, page) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(
    `${appConstants.API}/${lang}/v1/comments?_format=json&article=${cid}&page=${page}`,
    requestOptions
  ).then(handleResponse);
}



function getReplys(lang, cid, parent) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(
    `${appConstants.API}/${lang}/v1/replys?_format=json&article=${cid}&pid=${parent}`,
    requestOptions
  ).then(handleResponse);
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        window.location.reload(true);
      }
      const error = (data && data.message) || response.statusText;

      //Filter Drupal errors.
      let result = String(
        error
          .toString()
          .toLocaleLowerCase()
          .match(/[a-z]+/g)
      ).replace(/,/g, "_");

      if (result.substr("unprocessable_entity_validation_failed") >= 0) {
        result = "unprocessable_entity_validation_failed";
        return Promise.reject(result);
      }

      return Promise.reject(result);
    }
    return data;
  });
}

function sendNotification(response, id, lang) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        window.location.reload(true);
      }
      const error = (data && data.message) || response.statusText;

      //Filter Drupal errors.
      let result = String(
        error
          .toString()
          .toLocaleLowerCase()
          .match(/[a-z]+/g)
      ).replace(/,/g, "_");

      if (result.substr("unprocessable_entity_validation_failed") >= 0) {
        result = "unprocessable_entity_validation_failed";
        return Promise.reject(result);
      }

      return Promise.reject(result);
    }
    const cid = data.cid[0].value;
    const payload = {
      _links: {
        type: {
          href: `http://${appConstants.LOCATION}/rest/type/notification/notification`,
        },
      },
      name: [{ "value": cid}],
      field_content: [{"target_id": id}],
      field_comment: [{"target_id": cid}],
      field_type: [{"value": "comment"}],
    };
  
    const requestOptions = {
      method: "POST",
      headers: { ...authHeader(), "Content-Type": "application/hal+json" },
      body: JSON.stringify(payload),
      credentials: 'same-origin'
    };
    fetch(
      `${appConstants.API}/${lang}/entity/notification?_format=hal_json`,
      requestOptions
    );
    return data;
  });
}
