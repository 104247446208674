import React from "react";
import { useTranslation } from "react-i18next";
import no_published from "../../_images/no-published.png";
import ico_create from "../../_images/ico-create.svg";

export default function ContentEmpty() {
  const { t } = useTranslation("common");
  return (
    <div className="profile__header no__published">
      <span className="no__published d-block">
        {t("contents.content-empty")}
      </span>
      <img src={no_published} alt="You have no published articles yet" />
      <div className="add__container no__published">
        <a href="/" className="add__content">
          <div className="button__create">
            <img src={ico_create} alt="Create content" />
          </div>
          <span>{t("contents.create-content")}</span>
        </a>
      </div>
    </div>
  );
}
