import React from "react";
import "../../css/contentsPage.css";

export const Courses = ({ i }) => {
  return (
    <div className="courses_content">
      <img src={i.field_imagen_courses} alt="course_image" />
      <div className="courses_info">
        <h4>{i.title}</h4>
        <p>{i.uid}</p>
      </div>
    </div>
  );
};
