import React from "react";

export default function SharerMobile() {
  return (
    <div className="share__mobile">
      <div className="d-flex justify-content-center">
        <a href="/">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="34"
            height="34"
            viewBox="0 0 34 34"
          >
            <path
              className="circle_share"
              d="M16.5 31.7C24.9 31.7 31.7 24.9 31.7 16.5 31.7 8.1 24.9 1.3 16.5 1.3 8.1 1.3 1.3 8.1 1.3 16.5 1.3 24.9 8.1 31.7 16.5 31.7Z"
              fill="#021431"
            ></path>
            <path
              className="ico_share"
              d="M19.9 12.3L13.8 12.3C13.1 12.3 13.1 12.3 13.1 13L13.1 22.5 16.8 19.3 20.6 22.5 20.6 13C20.6 12.3 20.5 12.3 19.9 12.3Z"
              fill="#FFF"
            ></path>
          </svg>
        </a>
        <a href="/">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="31"
            height="31"
            viewBox="0 0 31 31"
          >
            <path d="M18.9 17.8C18.7 18.2 18 18.6 17.6 18.7 17.3 18.7 16.9 18.8 16.4 18.6 16.2 18.5 15.8 18.4 15.4 18.2 13.5 17.4 12.3 15.5 12.2 15.4 12.1 15.3 11.4 14.4 11.4 13.4 11.4 12.5 11.9 12.1 12.1 11.9 12.2 11.7 12.4 11.6 12.6 11.6 12.7 11.6 12.8 11.6 12.9 11.6 13 11.7 13.2 11.6 13.3 12 13.5 12.3 13.9 13.3 13.9 13.4 14 13.5 14 13.6 13.9 13.7 13.9 13.8 13.8 13.9 13.7 14 13.6 14.1 13.5 14.3 13.5 14.3 13.4 14.4 13.3 14.5 13.4 14.7 13.5 14.9 13.9 15.5 14.4 16 15.1 16.7 15.7 16.9 15.9 17 16.1 17.1 16.2 17 16.3 16.9 16.5 16.8 16.8 16.4 16.9 16.2 17.1 16 17.2 16 17.4 16.1 17.5 16.1 18.5 16.6 18.6 16.7 18.8 16.8 19 16.8 19 16.9 19.1 17 19.1 17.4 18.9 17.8M15.2 8.9C11.8 8.9 9 11.7 9 15.1 9 16.3 9.3 17.4 9.9 18.4L10.1 18.7 9.4 21 11.8 20.4 12 20.5C13 21.1 14.1 21.4 15.2 21.4L15.2 21.4C18.7 21.4 21.5 18.6 21.5 15.1 21.5 13.5 20.8 11.9 19.6 10.7 18.5 9.5 16.9 8.9 15.2 8.9"></path>
            <path
              d="M0 15.2C0 6.8 6.8 0 15.2 0 23.6 0 30.4 6.8 30.4 15.2 30.4 23.6 23.6 30.4 15.2 30.4 6.8 30.4 0 23.6 0 15.2ZM15.2 22.6L15.2 22.6C19.4 22.6 22.7 19.3 22.7 15.1 22.7 13.1 22 11.2 20.5 9.8 19.1 8.4 17.2 7.6 15.2 7.6 11.1 7.6 7.7 11 7.7 15.1 7.7 16.4 8 17.7 8.7 18.9L7.6 22.8 11.6 21.7C12.7 22.3 14 22.6 15.2 22.6Z"
              fill="#021431"
            ></path>
          </svg>
        </a>
        <a href="/">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="34"
            height="34"
            viewBox="0 0 34 34"
          >
            <path
              d="M16.5 31.7C24.9 31.7 31.7 24.9 31.7 16.5 31.7 8.1 24.9 1.3 16.5 1.3 8.1 1.3 1.3 8.1 1.3 16.5 1.3 24.9 8.1 31.7 16.5 31.7Z"
              fill="#021431"
            ></path>
            <path
              d="M22.3 22.3L22.3 22.3 22.3 22.3 22.3 18C22.3 15.9 21.8 14.3 19.4 14.3 18.2 14.3 17.4 14.9 17.1 15.6L17.1 15.6 17.1 14.5 14.7 14.5 14.7 22.3 17.1 22.3 17.1 18.4C17.1 17.4 17.3 16.4 18.6 16.4 19.8 16.4 19.9 17.6 19.9 18.5L19.9 22.3 22.3 22.3Z"
              fill="#ffffff"
            ></path>
            <path
              d="M10.8 14.5L13.2 14.5 13.2 22.3 10.8 22.3 10.8 14.5Z"
              fill="#ffffff"
            ></path>
            <path
              d="M12 10.6C11.2 10.6 10.6 11.2 10.6 12 10.6 12.8 11.2 13.4 12 13.4 12.8 13.4 13.4 12.8 13.4 12 13.4 11.2 12.8 10.6 12 10.6Z"
              fill="#ffffff"
            ></path>
          </svg>
        </a>
        <a href="/">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="34"
            height="34"
            viewBox="0 0 34 34"
          >
            <path
              d="M16.5 31.7C24.9 31.7 31.7 24.9 31.7 16.5 31.7 8.1 24.9 1.3 16.5 1.3 8.1 1.3 1.3 8.1 1.3 16.5 1.3 24.9 8.1 31.7 16.5 31.7Z"
              fill="#021431"
            ></path>
            <path
              d="M17 14.8L17 13.4C17 13 17.4 12.7 17.8 12.7L18.5 12.7 18.5 10.9 17 10.9C15.8 10.9 14.8 11.9 14.8 13L14.8 14.8 13.3 14.8 13.3 16.5 14.8 16.5 14.8 22.1 17 22.1 17 16.5 18.5 16.5 19.3 14.8 17 14.8Z"
              fill="#FFF"
            ></path>
          </svg>
        </a>
        <a href="/">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="34"
            height="34"
            viewBox="0 0 34 34"
          >
            <path
              d="M16.5 31.7C24.9 31.7 31.7 24.9 31.7 16.5 31.7 8.1 24.9 1.3 16.5 1.3 8.1 1.3 1.3 8.1 1.3 16.5 1.3 24.9 8.1 31.7 16.5 31.7Z"
              fill="#021431"
            ></path>
            <path
              d="M23.3 13.6C22.8 13.9 22.3 14 21.7 14.1 22.3 13.7 22.7 13.2 22.9 12.5 22.4 12.8 21.8 13.1 21.1 13.2 20.6 12.7 19.8 12.3 19 12.3 17.4 12.3 16.1 13.6 16.1 15.1 16.1 15.3 16.2 15.5 16.2 15.7 13.8 15.6 11.7 14.5 10.3 12.8 10.1 13.2 9.9 13.7 9.9 14.2 9.9 15.2 10.4 16 11.2 16.5 10.7 16.5 10.3 16.4 9.9 16.2 9.9 16.2 9.9 16.2 9.9 16.2 9.9 17.6 10.9 18.7 12.2 19 12 19 11.7 19.1 11.4 19.1 11.3 19.1 11.1 19 10.9 19 11.3 20.1 12.3 20.9 13.6 20.9 12.6 21.7 11.4 22.1 10 22.1 9.8 22.1 9.6 22.1 9.3 22.1 10.6 22.9 12.1 23.3 13.7 23.3 19 23.3 21.9 19.1 21.9 15.4 21.9 15.3 21.9 15.2 21.9 15.1 22.5 14.7 22.9 14.2 23.3 13.6"
              fill="#FFF"
            ></path>
          </svg>
        </a>
        <div className="others__links dropdown">
          <a href="/" className="others__shares">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="34"
              height="34"
              viewBox="0 0 34 34"
            >
              <g opacity="0.3">
                <path
                  d="M16.5 31.7C24.9 31.7 31.7 24.9 31.7 16.5 31.7 8.1 24.9 1.3 16.5 1.3 8.1 1.3 1.3 8.1 1.3 16.5 1.3 24.9 8.1 31.7 16.5 31.7Z"
                  fill="#B6D0ED"
                ></path>
              </g>
              <path
                d="M10.9 18.9C11.8 18.9 12.5 18.2 12.5 17.3 12.5 16.4 11.8 15.7 10.9 15.7 10 15.7 9.3 16.4 9.3 17.3 9.3 18.2 10 18.9 10.9 18.9Z"
                fill="#021431"
              ></path>
              <path
                d="M16.7 18.9C17.6 18.9 18.3 18.2 18.3 17.3 18.3 16.4 17.6 15.7 16.7 15.7 15.8 15.7 15.1 16.4 15.1 17.3 15.1 18.2 15.8 18.9 16.7 18.9Z"
                fill="#021431"
              ></path>
              <path
                d="M22.4 18.9C23.3 18.9 24 18.2 24 17.3 24 16.4 23.3 15.7 22.4 15.7 21.6 15.7 20.8 16.4 20.8 17.3 20.8 18.2 21.6 18.9 22.4 18.9Z"
                fill="#021431"
              ></path>
            </svg>
          </a>
        </div>
      </div>
    </div>
  );
}
