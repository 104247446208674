import React from "react";
import ico_back from "../../_images/ico-back.svg";
import { navigate } from "@reach/router";

export const Back = () => {
  const onHandleClick = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  return (
    <a href="/" className="back__btn" onClick={onHandleClick}>
      <img src={ico_back} alt="Back" />
    </a>
  );
};
