import React from "react";
import Layout from "../_components/common/layout";
import { useTranslation } from "react-i18next";
import SEO from "../_components/common/seo";
import "../css/terms.css";

const CookiePagina = () => {
  const { t, i18n } = useTranslation("common");
  const lang = i18n.language;

  return (
    <Layout>
      <SEO lang={lang} title={t("url.cookies")} />
      <main
        role="main"
        className="no__top"
        itemScope=""
        itemProp="mainContentOfPage"
        itemType="http://schema.org/Blog"
      >
        <div className="container d-flex  term_style">
          <div className="d-block">
            <p>
              Esta política de cookies (“Política”) describe qué son las cookies
              y cómo y están siendo utilizados por el sitio web techfrenetic.com
              ("Sitio web" o "Servicio") y cualquiera de sus productos y
              servicios relacionados (colectivamente, "Servicios"). Esta
              Política es legalmente vinculante acuerdo entre usted ("Usuario",
              "usted" o "su") y TechFrenetic (“TechFrenetic”, “nosotros”, “nos”
              o “nuestro”). Si está entrando en este acuerdo en nombre de una
              empresa u otra entidad legal, usted declara que tiene la autoridad
              para obligar a dicha entidad a este acuerdo, en cuyo caso los
              términos "Usuario", "usted" o "su" referirse a tal entidad. Si no
              tiene tal autoridad, o si no está de acuerdo con los términos de
              este acuerdo, no debe aceptar este acuerdo y no puede acceder y
              utilizar el sitio web y Servicios. Debe leer esta Política para
              que pueda comprender los tipos de cookies que usamos, la
              información que recopilamos usando cookies y cómo se utiliza esa
              información. También describe las opciones disponible para usted
              con respecto a aceptar o rechazar el uso de cookies.
            </p>
            <br />
            <h2>¿Qué son las cookies?</h2>
            <br />
            <p>
              Las cookies son pequeños fragmentos de datos almacenados en
              archivos de texto que son guardado en su computadora u otros
              dispositivos cuando se cargan los sitios web en un navegador. Se
              utilizan mucho para recordarte a ti y a tus preferencias, ya sea
              para una sola visita (a través de una "sesión cookie") o para
              múltiples visitas repetidas (usando una "Cookie persistente").
            </p>
            <p>
              Las cookies de sesión son cookies temporales que se utilizan
              durante la curso de su visita al Sitio Web, y caducan cuando Ud.
              cierre el navegador web.
            </p>
            <p>
              Las cookies persistentes se utilizan para recordar sus
              preferencias dentro de nuestro sitio web y permanecer en su
              computadora de escritorio o dispositivo móvil incluso después de
              cierra su navegador o reinicia su computadora. Aseguran un
              experiencia consistente y eficiente para usted mientras visita el
              Sitio Web y Servicios.
            </p>
            <p>
              Las cookies pueden ser establecidas por el sitio web ("cookies de
              origen"), o por terceros, como aquellos que sirven contenido o
              proporcionan servicios de publicidad o análisis en el sitio web
              ("terceros cookies"). Estos terceros pueden reconocerlo cuando
              visita nuestro sitio web y también cuando visita ciertos otros
              sitios web. Tú Puede obtener más información sobre las cookies y
              cómo funcionan aquí.
            </p>
            <br />
            <h2>¿Qué tipo de cookies utilizamos?</h2>
            <br />
            <ul>
              <li>
                <h4>Cookies necesarias</h4>
                <p>
                  Las cookies necesarias nos permiten ofrecerle lo mejor posible
                  experiencia al acceder y navegar por nuestro Sitio Web y
                  usando sus características. Por ejemplo, estas cookies nos
                  permiten reconozca que ha creado una cuenta y ha iniciado
                  sesión en esa cuenta para acceder al contenido.
                </p>
              </li>
              <br />
              <li>
                <h4>Cookies de funcionalidad</h4>
                <p>
                  Las cookies de funcionalidad nos permiten operar el sitio web
                  y los servicios de acuerdo con las elecciones que hagas. Por
                  ejemplo, haremos reconozca su nombre de usuario y recuerde
                  cómo personalizó el Sitio web y Servicios durante futuras
                  visitas.
                </p>
              </li>
              <br />
              <li>
                <h4>Cookies analíticas</h4>
                <p>
                  Estas cookies nos permiten a nosotros y a servicios de
                  terceros recopilar datos agregados con fines estadísticos
                  sobre cómo nuestros visitantes utilizar el sitio web. Estas
                  cookies no contienen datos personales información como nombres
                  y direcciones de correo electrónico y se utilizan para
                  ayúdenos a mejorar su experiencia de usuario del sitio web.
                </p>
              </li>
              <br />
              <li>
                <h4>Cookies publicitarias</h4>
                <p>
                  Las cookies publicitarias nos permiten a nosotros y a terceros
                  brindar servicios relevantes anuncios para usted de manera más
                  efectiva y ayudarnos a recopilar datos de auditoría,
                  investigación e informes de rendimiento para anunciantes
                  También nos permiten comprender y mejorar la entrega de
                  anuncios a usted y saber cuándo ciertos anuncios han sido
                  mostrado a usted.
                </p>
                <p>
                  Su navegador web puede solicitar anuncios directamente desde
                  ad servidores de red, estas redes pueden ver, editar o
                  configurar sus propias cookies, como si hubiera solicitado una
                  página web de su sitio web.
                </p>
                <p>
                  Aunque no utilizamos cookies para crear un perfil de su
                  comportamiento de navegación en sitios web de terceros, usamos
                  datos agregados datos de terceros para mostrarle información
                  relevante basada en intereses publicidad. No proporcionamos
                  ninguna información personal que recopilamos a los
                  anunciantes.
                </p>
              </li>
              <br />
              <li>
                <h4> Cookies de redes sociales</h4>
                <p>
                  Cookies de terceros de sitios de redes sociales (como
                  Facebook, Twitter, etc.) nos permiten rastrear a los usuarios
                  de redes sociales cuando visitar o usar el sitio web y los
                  servicios, o compartir contenido, por utilizando un mecanismo
                  de etiquetado proporcionado por esas redes sociales.
                </p>
                <p>
                  Estas cookies también se utilizan para el seguimiento de
                  eventos y el remarketing. propósitos Todos los datos
                  recopilados con estas etiquetas se utilizarán en de acuerdo
                  con nuestras políticas de privacidad y las de las redes
                  sociales. Nosotros no recopilará ni compartirá ninguna
                  identificación personal información del usuario.
                </p>
              </li>
            </ul>
            <br />
            <h2>¿Utilizamos balizas web o píxeles de seguimiento?</h2>
            <br />
            <p>
              Nuestros correos electrónicos pueden contener una "baliza web" (o
              "píxel de seguimiento") para díganos si nuestros correos
              electrónicos están abiertos y verifique cualquier clic a través de
              enlaces o anuncios dentro del correo electrónico.
            </p>
            <p>
              Podemos usar esta información para propósitos que incluyen
              determinar cuáles de nuestros correos electrónicos son más
              interesantes para los usuarios y para consultar si los usuarios
              que no abren nuestros correos electrónicos desean continuar
              recibiéndolos.
            </p>
            <p>
              El píxel se eliminará cuando elimine el correo electrónico. Si no
              lo hace desea que el píxel se descargue en su dispositivo, debe
              leer el correo electrónico en vista de texto sin formato o con las
              imágenes deshabilitadas.
            </p>
            <br />
            <h2>¿Cuáles son sus opciones de cookies?</h2>
            <br />
            <p>
              Si no le gusta la idea de las cookies o ciertos tipos de cookies,
              puede cambiar la configuración de su navegador para eliminar las
              cookies que tienen ya se ha configurado y no aceptar nuevas
              cookies. Visitar internetcookies.com para obtener más información
              sobre cómo hacerlo.
            </p>
            <h2>Cambios y enmiendas</h2>
            <p>
              Nos reservamos el derecho de modificar esta Política o sus
              términos relacionados con el sitio web y los servicios en
              cualquier momento a nuestra discreción. Cuando nosotros hacer,
              revisaremos la fecha actualizada en la parte inferior de esta
              página, publicar una notificación en la página principal del sitio
              web. también podemos notificarle de otras maneras a nuestra
              discreción, como a través de la información de contacto que ha
              proporcionado.
            </p>
            <p>
              Una versión actualizada de esta Política entrará en vigencia de
              inmediato. tras la publicación de la Política revisada, a menos
              que se especifique lo contrario. Su uso continuo del sitio web y
              los servicios después de la vigencia fecha de la Política revisada
              (o cualquier otro acto especificado en ese tiempo) constituirá su
              consentimiento a dichos cambios.
            </p>
            <br />
            <h2>Aceptación de esta política</h2>
            <br />
            <p>
              Usted reconoce que ha leído esta Política y acepta todas sus
              términos y condiciones. Al acceder y utilizar el Sitio Web y
              Servicios usted acepta estar sujeto a esta Política. si no estas
              de acuerdo cumplir con los términos de esta Política, no está
              autorizado a acceder o utilizar el Sitio Web y los Servicios.
            </p>
            <br />
            <h2>Contáctanos</h2>
            <br />
            <p>
              Si tiene alguna pregunta, inquietud o queja con respecto a este
              Política o el uso de cookies, le animamos a ponerse en contacto
              con nosotros utilizando los detalles a continuación:
            </p>
            <p>memo@techfrenetic.com</p>
            <span>
              Este documento fue actualizado por última vez el 17 de agosto de
              2021
            </span>
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default CookiePagina;
