import { userConstants } from "../_constants";

let user = JSON.parse(localStorage.getItem("user"));
let me = JSON.parse(localStorage.getItem("me"));
const initialState = user ? { loggedIn: true, user, me } : {};

export function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.REGISTER_REQUEST:
    case userConstants.LOGIN_REQUEST:
      return {
        loggingIn: true,
        user: action.user,
      };
    case userConstants.REGISTER_SUCCESS:
    case userConstants.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        loggingIn: false,
        user: action.user,
        me: action.me,
      };
    case userConstants.REGISTER_2_SUCCESS:
    case userConstants.REGISTER_3_SUCCESS:
      return {
        ...state,
        me: action.me,
      };
    case userConstants.REGISTER_FAILURE:
    case userConstants.LOGIN_FAILURE:
      return {
        loggingIn: false,
        problem: action.error,
      };
    case userConstants.LOGOUT:
      return {};
    default:
      return state;
  }
}
