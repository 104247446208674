import React from "react";
import { Link } from "@reach/router";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { modalActions } from "../../_actions";

const FooterMenu = ({ showNewsletter }) => {
  //Language hooks
  const { i18n, t } = useTranslation("common");
  const lang = i18n.language;

  return (
    <ul className="navbar-nav flex-row">
      <li itemProp="url">
        <Link to={`/${lang}/${t("url.contact")}`} itemProp="name">
          {t("menus.contact")}
        </Link>
      </li>
      <li itemProp="url">
        <Link to={`/${lang}/${t("url.about")}`} itemProp="name">
          {t("menus.about")}
        </Link>
      </li>
      <li itemProp="url">
        <Link to={`/${lang}/${t("url.disclaimer")}`} itemProp="name">
          {t("menus.disclaimer")}
        </Link>
      </li>
      {/* <li itemProp="url">
        <Link to="/sitemap.xml" itemProp="name">
          {t("menus.sitemap")}
        </Link>
      </li> */}
      {/* <li itemProp="url">
        <a href="/" itemProp="name" onClick={handleClickNewsletter}>
          {t("menus.newsletter")}
        </a>
      </li> */}
      <li itemProp="url">
        <Link to={`/${lang}/${t("url.sale")}`} itemProp="name">
          {t("menus.conditions")}
        </Link>
      </li>
    </ul>
  );
};

const actionCreators = {
  showNewsletter: modalActions.showNewsletter,
};

const connectedFooterMenu = connect(null, actionCreators)(FooterMenu);
export { connectedFooterMenu as FooterMenu };
