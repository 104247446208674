import React, { useEffect, useState } from "react";
import { Link } from "@reach/router";
import SEO from "../_components/common/seo";
import Layout from "../_components/common/layout";
import { useTranslation } from "react-i18next";
import { MobileMenu } from "../_components/navigation/mobile-menu";
import CommunityMenu from "../_components/navigation/community-menu";
import MeetupCard from "../_components/ui/meetup-card";
import { meetupService } from "../_services";
import { LoadMore } from "../_components/contents/load-more";
import { communityConstants } from "../_constants";
import { Spinner } from "../_components/ui/spinner";

const MeetupsPage = ({ meetup = "" }) => {
  const { t, i18n } = useTranslation("common");
  const lang = i18n.language;

  const [listMeetups, setListMeetups] = useState([]);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  // const [hiddenButton, setHiddenButton] = useState(true);

  useEffect(() => {
    resultMeetups(lang, meetup, page);
  }, [lang, meetup, page]);

  const resultMeetups = (lang, meetup, pageUrl) => {
    setLoading(true);
    meetupService
      .getListMeetups(lang, pageUrl, meetup)
      .then((res) => res.json())
      .then((response) => {
        //if (response) {
        setTotal(response.results);
        setListMeetups(listMeetups.concat(response.articles));
        //}
        setLoading(false);
      })
      .catch(console.log);
  };

  const handleMore = () => {
    const pageMore = page + 1;
    resultMeetups(lang, meetup, pageMore);
    setPage(pageMore);
  };

  // if (!listMeetups.length) {
  //   return "";
  // }

  const onDeleteMeetups = (mid) => {
    setListMeetups(listMeetups.filter((item) => item.id !== mid));
  };

  return (
    <Layout withFade={true} noFooter={true}>
      <SEO lang={lang} title={t("meetups.title")} />
      <main
        role="main"
        itemScope=""
        itemProp="mainContentOfPage"
        itemType="http://schema.org/Blog"
      >
        <section className="profile__page">
          <div className="container d-flex">
            <div className="profile__menu col-lg-2 col-md-3">
              <CommunityMenu selected="meetups" />
            </div>
            <div className="profile__body col-lg-10 col-md-9">
              <div className="profile__header meetups__listing">
                <h1 className="highlighted text-center">
                  <span>{t("meetups.ttl_blue")}</span>
                  {t("meetups.ttl_black")}
                </h1>
                <p className="meetups__txt text-center">{t("meetups.intro")}</p>
                <Link
                  to={`/${lang}/${t("url.meetups")}/${t("url.meetups_new")}`}
                  className="btn btn-primary btn-lg"
                >
                  {t("meetups.cta_new")}
                </Link>
                <div className="clearfix"></div>
                <div className="meets__counter">
                  <span>({total}) Meetups</span>
                </div>

                {listMeetups.map((lists, id) => (
                  <MeetupCard
                    key={id}
                    date={lists.when}
                    url={lists.url}
                    author={lists.author}
                    displayName={lists.display_name}
                    title={lists.title}
                    place={lists.where}
                    uid={lists.uid_1}
                    onDelete={onDeleteMeetups}
                    mid={lists.id}
                  />
                ))}

                {
                  /* Load More button */
                  !loading ? (
                    <div className="meetup_action">
                      <LoadMore
                        page={page}
                        totalItems={total}
                        itemsByPage={communityConstants.MEETUPS_BY_PAGE}
                        onClick={handleMore}
                      />
                    </div>
                  ) : (
                    <Spinner />
                  )
                }
              </div>
            </div>
          </div>
        </section>
      </main>
      <MobileMenu selected="community" withFade={true} />
    </Layout>
  );
};

export default MeetupsPage;
