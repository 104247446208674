import React from "react";
import { Link } from "@reach/router";
import { useTranslation } from "react-i18next";
import { PublishDate } from "../ui/publish-date";
import { CategoryLink } from "../ui/category-link";
import { PostReactions } from "./post-reactions";
import { PostActions } from "./post-actions";
import { Stream } from "@cloudflare/stream-react";
import he from "he";

export const PostOwned = ({ post, type, onDelete}) => {
  //Language hooks
  const { i18n, t } = useTranslation("common");
  const lang = i18n.language;
 
 
  return (
    <article
      itemScope=""
      itemType="http://schema.org/Article"
      className="feed__byme"
    >
      {type !== "activity" && <PostActions post={post} onDelete={onDelete} />}
      {post.field_has_video === 'True' ? (
        <Stream
          controls
          src={post.field_cloudflare_id}
        />
      ) : (
        <figure className="article__image__content">
        <Link className="image__btn" to={`/${lang}/${post.id}`} state={{ post: { post } }}>
          <img
            itemProp="image"
            src={post.image}
            alt={he.decode(post.title)}
          />
        </Link>
      </figure>
      )}
      <div className="article__information">
        <div className="article__cat d-flex">
          <p className="article__cat__tag" rel="category">
            <CategoryLink name={post.category} />
          </p>
          <span className="article__cat__separator">•</span>
          <PublishDate className="article__hour__tag" date={post.date} />
        </div>
        <h2 itemProp="headline">
          <Link to={`/${lang}/${post.id}`} state={{post: {post}}}>{he.decode(post.title)}</Link>
        </h2>
        <p className="feed__source">
          <Link to={`/${lang}/${t("home.profile")}/${String(post.id).toLocaleLowerCase()}`} >
            By {post.display_name}
          </Link>
        </p>
      </div>

      <PostReactions
        type="field_like_dislike"
        post={post}
        qualification={true}
        actions={true}
        
      />
    </article>
  );
};
