import React from "react";

export const Twitter = ({ link }) => {
  return (
    <a
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      className="social__btn twitter"
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
        <circle className="st0" cx="18" cy="18" r="17.5" />
        <path
          className="st1"
          d="M25.8 13.3c-0.6 0.2-1.2 0.4-1.8 0.5 0.7-0.4 1.2-1 1.4-1.7 -0.6 0.4-1.3 0.6-2 0.8 -0.6-0.6-1.4-1-2.3-1 -1.8 0-3.2 1.4-3.2 3.1 0 0.2 0 0.5 0.1 0.7 -2.7-0.1-5-1.4-6.6-3.3 -0.3 0.5-0.4 1-0.4 1.6 0 1.1 0.6 2.1 1.4 2.6 -0.5 0-1-0.2-1.4-0.4 0 0 0 0 0 0 0 1.5 1.1 2.8 2.6 3.1 -0.3 0.1-0.5 0.1-0.8 0.1 -0.2 0-0.4 0-0.6-0.1 0.4 1.2 1.6 2.2 3 2.2 -1.1 0.8-2.5 1.3-4 1.3 -0.3 0-0.5 0-0.8 0 1.4 0.9 3.1 1.4 4.9 1.4 5.9 0 9.1-4.8 9.1-8.9 0-0.1 0-0.3 0-0.4C24.8 14.4 25.3 13.9 25.8 13.3"
        />
      </svg>
    </a>
  );
};
