import React, { useState } from "react";
import { useLocation } from "@reach/router";
import { useTranslation } from "react-i18next";
import ico_search from "../../_images/ico-search-partner.svg";

export const useQuery = (queryParam) => {
  const search = new URLSearchParams(useLocation().search);
  return search.get(queryParam);
};

export const SearchVendorForm = ({ className, onSubmit }) => {
  const { t } = useTranslation("common");
  const [filters, setFilters] = useState({
    q: useQuery("q") || "",
    d: useQuery("d") || "",
  });

  const handleSearch = (e) => {
    e.preventDefault();
    onSubmit(filters);
  };

  const changeWhat = (e) => {
    let data = { ...filters };
    data.q = e.target.value;
    setFilters(data);
  };

  const changeWhere = (e) => {
    let data = { ...filters };
    data.d = e.target.value;
    setFilters(data);
  };

  return (
    <form className={className} onSubmit={handleSearch}>
      <div className="ps-camp d-flex flex-column">
        <label>{t("vendors.looking")}</label>
        <input
          type="text"
          placeholder={t("vendors.looking_desc")}
          value={filters.q}
          onChange={changeWhat}
        />
      </div>
      <div className="ps-camp d-flex flex-column">
        <label htmlFor="where">{t("vendors.where")}</label>
        <input
          type="text"
          placeholder={t("vendors.where_desc")}
          value={filters.d}
          onChange={changeWhere}
        />
      </div>
      <a
        className="search__p d-flex align-items-center justify-content-center"
        href="/"
        onClick={handleSearch}
      >
        <img src={ico_search} alt="Search Partner" />
        {t("buttons.search")}
      </a>
      <button
        type="submit"
        style={{ width: 0, padding: 0, height: 0, opacity: 0 }}
      ></button>
    </form>
  );
};
