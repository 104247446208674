import React from "react";
import SEO from "../_components/common/seo";
import Layout from "../_components/common/layout";
import { useTranslation } from "react-i18next";
import { MobileMenu } from "../_components/navigation/mobile-menu";
import { CommunityPosts } from "../_components/lists/community-posts";
import CommunityMenu from "../_components/navigation/community-menu";
import GroupsMayLike from "../_components/lists/groups-maylike";
import CommunityAd from "../_components/ads/community-ad";
import Credits from "../_components/common/credits";

import { CometChat } from "@cometchat-pro/chat";

const appID = process.env.REACT_APP_COMETCHAT_APP_ID;
const region = process.env.REACT_APP_COMETCHAT_REGION;
const appSetting = new CometChat.AppSettingsBuilder()
  .subscribePresenceForAllUsers()
  .setRegion(region)
  .build();
CometChat.init(appID, appSetting);

const CommunityPage = () => {
  const { t, i18n } = useTranslation("common");
  const lang = i18n.language;

  return (
    <Layout withFade={true} noFooter={true}>
      <SEO lang={lang} title={t("community.title")} />
      <main
        role="main"
        itemScope=""
        itemProp="mainContentOfPage"
        itemType="http://schema.org/Blog"
      >
        <section className="profile__page feed">
          <div className="container d-flex flex-wrap">
            {/* Menu */}
            <div className="profile__menu sticky col-lg-2 col-md-3">
              <div className="tabs__container">
                <CommunityMenu selected="feed" />
              </div>
            </div>

            {/* Community Feed */}
            <div className="profile__body col-lg-6 col-md-9">
              <CommunityPosts section="wall" />
            </div>

            {/* Sidebar right */}
            <div className="community__right__box col-lg-4 col-md-9">
              <div className="groups__maylike">
                <GroupsMayLike />
              </div>

              <CommunityAd />

              <Credits />
            </div>
          </div>
        </section>
      </main>
      <MobileMenu selected="community" withFade={true} />
    </Layout>
  );
};

export default CommunityPage;
