import React, { useEffect, useState } from "react";
import ArticleMost from "../ui/article-most";
import { useTranslation } from "react-i18next";
import { articleService } from "../../_services";

export default function PopularArticles() {
  //Language hooks
  const { t, i18n } = useTranslation("common");
  const lang = i18n.language;

  //State
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    /** Call Services  */
    articleService
      .getArticlePopular(lang)
      .then((res) => res.json())
      .then((response) => {
        setArticles(response);
      })
      .catch(console.log);
  }, [lang, setArticles]);

  if (!articles) {
    return "";
  }

  return (
    <div className="most__popular__container">
      <h3 className="title__home">
        <span className="title__highlight">{t("articles.most")}</span>
        {t("articles.popular")}
      </h3>
      <ol>
        {articles.splice(0, 5).map((article, id) => (
          <li key={id}>
            <ArticleMost key={id} article={article} />
          </li>
        ))}
      </ol>
    </div>
  );
}
