import React from "react";
import { CategorySelect } from "../ui/category-select";
import { useTranslation } from "react-i18next";

export const EventsForm = () => {
  const { t } = useTranslation("common");

  return (
    <form className="d-flex flex-sm-column flex-md-row flex-lg-row">
      <div className="form-label-group">
        <CategorySelect />
      </div>
      <div className="form-label-group">
        <input className="form-control" id="location" name="location" />
      </div>
      <button className="btn btn-lg btn-primary btn-block" type="submit">
        {t("buttons.search")}
      </button>
    </form>
  );
};
