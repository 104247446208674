import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import Followers from "../../_components/ui/followers";

const ProfileFollowers = ({ following, followers , selected}) => {
  const ico_heart = <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.9062 1.44255C15.6484 -0.726502 12.1602 -0.400698 10 2.11201C7.83984 -0.400698 4.35156 -0.730966 2.09375 1.44255C-0.843745 4.26767 -0.414058 8.87356 1.67969 11.3149L8.53125 19.2904C8.92187 19.7456 9.44531 20 10 20C10.5586 20 11.0781 19.7501 11.4687 19.2948L18.3203 11.3193C20.4101 8.87803 20.8476 4.27214 17.9062 1.44255ZM16.9844 9.81081L10.1328 17.7863C10.0391 17.8934 9.96094 17.8934 9.86719 17.7863L3.01563 9.81081C1.58985 8.15055 1.30078 5.00854 3.30078 3.08496C4.82031 1.62554 7.16406 1.84423 8.63281 3.55358L10 5.1469L11.3672 3.55358C12.8437 1.8353 15.1875 1.62554 16.6992 3.0805C18.6953 5.00408 18.3984 8.16393 16.9844 9.81081Z" fill="white"/>
  </svg>;

  const ico_like = <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.4279 11.1988C19.7933 10.6187 20 10 20 9.25195C20 7.53262 18.4493 5.90898 16.4242 5.90898H14.9042C15.1092 5.40859 15.2729 4.81016 15.2729 4.09102C15.2729 1.2475 13.7025 0 11.3033 0C8.73637 0 8.88279 3.70832 8.31333 4.24219C7.36554 5.13074 6.24604 6.83777 5.44833 7.5H1.33333C0.596958 7.5 0 8.05965 0 8.75V18.125C0 18.8154 0.596958 19.375 1.33333 19.375H4C4.62054 19.375 5.142 18.9776 5.29075 18.4395C7.14529 18.4786 8.41825 19.9996 12.6992 19.9996C13 19.9996 13.3333 20 13.625 20C16.8382 20 18.2911 18.46 18.3308 16.2762C18.8858 15.5564 19.1766 14.5917 19.0533 13.6594C19.4639 12.9386 19.6227 12.0835 19.4279 11.1988ZM16.855 13.3016C17.3783 14.127 16.9075 15.2316 16.2742 15.5504C16.595 17.4559 15.5405 18.1246 14.0608 18.1246H12.485C9.50004 18.1246 7.56713 16.6473 5.33333 16.6473V9.375H5.78833C6.97 9.375 8.62083 6.60586 9.7275 5.56797C10.9092 4.46016 10.5154 2.61367 11.3033 1.875C13.2729 1.875 13.2729 3.16328 13.2729 4.09102C13.2729 5.62109 12.0913 6.30664 12.0913 7.78398H16.4242C17.3038 7.78398 17.9963 8.52266 18 9.26133C18.0037 9.99961 17.4658 10.7383 17.0721 10.7383C17.6341 11.3068 17.7542 12.5053 16.855 13.3016ZM3.66667 16.875C3.66667 17.3928 3.21896 17.8125 2.66667 17.8125C2.11438 17.8125 1.66667 17.3928 1.66667 16.875C1.66667 16.3572 2.11438 15.9375 2.66667 15.9375C3.21896 15.9375 3.66667 16.3572 3.66667 16.875Z" fill="#4B4B4B"/>
  </svg>;

  const { t } = useTranslation("common");

  return (
    <>
      <ul className="profile__tabs nav draggable" role="tablist">
      {following && (
        <Followers
          title={t("profile.following")}
          users={following}
          type="following"
          icon={ico_heart}
          redirect={true}
          selected={selected}
        />
      )}

      {followers && (
        <Followers
          title={t("profile.followers")}
          users={followers}
          type="followers"
          icon={ico_like}
          redirect={true}
          selected={selected}
        />
      )}
      </ul>
    </>
  );
};

function mapState(state) {
  const { following, followers } = state.users;
  return { following, followers };
}

const connectedProfileFollowers = connect(mapState)(ProfileFollowers);
export { connectedProfileFollowers as ProfileFollowers };
