import React from "react";
import FormValidator from "../../_helpers/form-validator";
import { navigate } from "@reach/router";
import { withTranslation } from "react-i18next";
import { Spinner } from "../ui/spinner";
import { userService } from "../../_services";

import { Cellphone } from "../ui/cellphone";
import { Country } from "../ui/countries";

class AccountForm extends FormValidator {
  constructor(props) {
    super(props);
    const { user } = props;
    this.state = {
      elements: {
        country: {
          id: user.field_country_1 ?? 0,
          value: user.field_country ?? null,
          error: false,
          message: "",
        },
        phone: {
          value: user.cellphone ?? null,
          error: false,
          message: "",
        },
        date: {
          value: user.field_birthdate ?? null,
          error: false,
          message: "",
        },
        name: {
          value: user.name ?? null,
          error: false,
          message: "",
        },
        custom_user_email: {
          value: user.custom_user_email ?? null,
          error: false,
          message: "",
        },

        position: {
          value: user.field_position ?? null,
          error: false,
          messsage: "",
        },
      },
      problem: "",
      result: "",
      loading: false,
    };

    this.submitHandler = this.submitHandler.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.changeCountry = this.changeCountry.bind(this);
    this.changePhone = this.changePhone.bind(this);
    // this.changeSelectHandlerCon = this.changeSelectHandlerCon.bind(this);
  }

  /* Form submit */
  submitHandler(e) {
    e.preventDefault();

    const isValid = this.validate(this.state.elements, [
      {
        field: "country",
        type: "required",
        message: "errors.country_required",
      },
      {
        field: "phone",
        type: "required",
        message: "errors.phone_required",
      },
      {
        field: "date",
        type: "required",
        message: "errors.date_required",
      },
      {
        field: "name",
        type: "required",
        message: "errors.name_required",
      },
      {
        field: "custom_user_email",
        type: "required",
        message: "errors.email_required",
      },
      // {
      //   field: "position",
      //   type: "required",
      //   message: "errors.position_required",
      // },
    ]);

    // if (isValid) {
    const { country, phone, date, name, custom_user_email, position } =
      this.state.elements;

    const lang = this.props.i18n.language;
    this.setState({ loading: true });

    userService
      .updateProfile(lang, this.props.user, {
        field_birthdate: {
          value: date.value,
        },
        field_country: [
          {
            target_id: country.id,
          },
        ],
        field_cellphone: {
          value: phone.value,
        },
        field_name: {
          value: name.value,
        },
        // custom_user_email: {
        //   value: custom_user_email.value,
        // },
        field_position: {
          value: position.value,
        },
      })
      .then((response) => {
        this.setState({ result: "success", loading: false });
      })
      .catch((err) => {
        this.setState({ result: "error", problem: err, loading: false });
      });
  }
  // }

  /* Controlled input */
  changeHandler(e) {
    const input = this.state.elements[e.target.name];
    input.value = e.target.value;
    input.error = false;

    this.setState({ elements: this.state.elements });
  }
  /* Controlled input */
  changeCountry(e) {
    const input = this.state.elements["country"];
    input.id = e.value;
    input.value = e.label;
    this.setState({ elements: this.state.elements });
  }

  // changeSelectHandlerCon(e) {
  //   const input = this.state.elements["country"];
  //   input.id = e.value;
  //   input.label = e.label;
  //   input.error = false;
  //   this.setState({ elements: this.state.elements });
  // }

  changePhone(e) {
    e.preventDefault();
  }

  render() {
    const { i18n, t } = this.props;
    const lang = i18n.language;
    const { phone, date, name, custom_user_email, position, country } =
      this.state.elements;
    const { result, problem } = this.state;

    return (
      <form className="form-signin" onSubmit={this.submitHandler}>
        {
          /* Error */
          result === "error" && (
            <div className={`form-label-group`}>
              <div className="alert alert-danger text-center">
                {t(`errors.${problem}`)}
              </div>
            </div>
          )
        }

        {
          /* Success */
          result === "success" && (
            <div className={`form-label-group`}>
              <div className="alert alert-success text-center">
                {t(`success.profile_updated`)}
              </div>
            </div>
          )
        }
        <div className={`form__nofloating half-l ${name.error ? "error" : ""}`}>
          <label htmlFor="name">
            {t("forms.name")}{" "}
            <input
              type="text"
              id="name"
              className="form-control"
              required=""
              autoFocus=""
              name="name"
              onChange={this.changeHandler}
              value={name.value}
              disabled
            />
            {name.error && (
              <small id="passlHelp" className="form-text text-muted">
                {t(name.message)}
              </small>
            )}
          </label>
        </div>

        <div
          className={`form__nofloating half-r ${
            custom_user_email.error ? "error" : ""
          }`}
        >
          <label htmlFor="custom_user_email">
            {t("forms.email")}
            <input
              type="email"
              id="email"
              className="form-control"
              name="custom_user_email"
              onChange={this.changeHandler}
              value={custom_user_email.value}
              disabled
            />
            {custom_user_email.error && (
              <small id="passlHelp" className="form-text text-muted">
                {t(custom_user_email.message)}
              </small>
            )}
          </label>
        </div>

        <Cellphone phone={phone} changeHandler={this.handleChange} />

        <div className={`form__nofloating half-r ${date.error ? "error" : ""}`}>
          <label htmlFor="date">
            {t("forms.birthdate")}
            <input
              type="date"
              id="birthdate"
              className="form-control"
              name="date"
              onChange={this.changeHandler}
              value={date.value}
            />
            {date.error && (
              <small id="passlHelp" className="form-text text-muted">
                {t(date.message)}
              </small>
            )}
          </label>
        </div>

        <div
          className={`form__nofloating half-r ${position.error ? "error" : ""}`}
        >
          <label htmlFor="position">
            {t("forms.position")}
            <input
              type="text"
              id="position"
              className="form-control"
              name="position"
              onChange={this.changeHandler}
              value={position.value}
            />
            {position.error && (
              <small id="passlHelp" className="form-text text-muted">
                {t(position.message)}
              </small>
            )}
          </label>
        </div>

        <div
          className={`form__nofloating half-l ${country.error ? "error" : ""}`}
        >
          <Country
            value={country.value}
            id={country.id}
            handler={this.changeCountry}
          />
        </div>

        <div className="clearfix"></div>

        <div className="buttons">
          {this.state.loading ? (
            <Spinner />
          ) : (
            <>
              <button className="btn btn-primary" type="submit">
                {t("forms.save")}
              </button>
              <button
                className="btn btn-secondary"
                type="button"
                onClick={() => navigate(`/${lang}/${t("url.profile")}`)}
              >
                {t("forms.cancel")}
              </button>
            </>
          )}
        </div>
      </form>
    );
  }
}

const connectedAccountForm = withTranslation("common")(AccountForm);
export { connectedAccountForm as AccountForm };
