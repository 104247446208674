import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import ico_back from "../../_images/ico-back.svg";
import ico_delete from "../../_images/ico-delete.svg";
import ico_add from "../../_images/ico-add.svg";
import { appService, userService } from "../../_services";
import { AddInterest } from "./add-edit-interest";

export const EditInterests = ({
  interests,
  show,
  onHide,
  interests_id,
  me,
}) => {
  const [interest, setInterest] = useState([]);
  const [showAddEditCertifications, setShowAddEditCertifications] =
    useState(false);

  const { i18n, t } = useTranslation("common");
  const lang = i18n.language;

  useEffect(() => {
    appService
      .getInterest(lang)
      .then((res) => res.json())
      .then((response) => {
        if (response) {
          const items = response.map(function (e) {
            let catg = e;
            return catg;
          });
          setInterest(items);
        }
      })
      .catch(console.log);
  }, [lang, setInterest]);

  const indexDelete = [];

  const deleteInterest = (e) => {
    e.preventDefault();
    if (!!e.target.parentElement.parentElement) {
      e.target.parentElement.parentElement.outerHTML = "";
    }
    if (!!e.target.parentElement.previousSibling) {
      const tar = e.target.parentElement.previousSibling.value;
      const idDelete = interest.find((item) => tar.includes(item.category));
      if (!!idDelete) {
        let idD = idDelete.id;
        indexDelete.push(idD);
        localStorage.setItem("index_delete", indexDelete);
      }
    }
  };

  const handleCloseAddEditCertifications = () =>
    setShowAddEditCertifications(false);
  const handleShowAddEditCertifications = (e) => {
    e.preventDefault();
    setShowAddEditCertifications(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let mod = document.querySelector("#modal-add-interest .close");
    if (!!mod) {
      mod.click();
    }

    const add_element = localStorage.getItem("index_select") ?? "0";
    const delete_element = Array.from(
      localStorage.getItem("index_delete") ?? false
    );

    if (interests_id[0] === "") {
      interests_id = [];
    }

    const add = [...interests_id, add_element];

    const targets1 = add.filter((e, i) => add.indexOf(e) === i);
    let targets = targets1.map(function (e) {
      return e.trim();
    });

    if (delete_element) {
      const arraydifference = targets.filter(
        (el) => delete_element.indexOf(el) === -1
      );
      const arrayfilter = arraydifference.filter(
        (e, i) => arraydifference.indexOf(e) === i
      );
      targets = arrayfilter;
      localStorage.removeItem("index_delete");
      localStorage.removeItem("index_select");
    }

    if (!!targets) {
      const field_interests = targets.map(function (item) {
        var obj = {};
        obj.target_id = item;
        return obj;
      });
      userService
        .updateProfile(lang, me, {
          field_interests,
        })
        .then()
        .catch(console.log);

      document.querySelector("#btn-send").classList.add("lds-dual-ring");
      setTimeout(() => {
        document.querySelector("#modal-topics .close").click();
        window.location.reload();
      }, 2000);
    }
  };

  return (
    <Modal show={show} onHide={onHide} centered id="modal-topics">
      <div
        id="_1"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="modalLabel_1"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered edit__profile"
          role="document"
        >
          <div className="modal-content auto">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={onHide}
            >
              <span aria-hidden="true">×</span>
            </button>

            <div className="mobile__bar d-flex align-items-center">
              <a href="/" className="back__btn">
                <img src={ico_back} alt="Back" />
              </a>
              <span className="title__profile">{t("user.profile_button")}</span>
            </div>

            <div className="profile__camps">
              <form onSubmit={handleSubmit}>
                <h3>
                  <span>{t("profile.edit_about-blue")}</span>
                  {t("profile.edit_interests")}
                </h3>
                <div className="certifications">
                  <span className="certification__copy">
                    {t("profile.edit_interest")}
                  </span>

                  {interests !== "" &&
                    interests.map((item, id) => (
                      <div
                        className="certification__box d-flex align-items-center"
                        key={item}
                      >
                        <input
                          type="text"
                          defaultValue={item}
                          id="value-certification"
                          disabled
                        />
                        <a href="/" onClick={(e) => deleteInterest(e, item)}>
                          <img src={ico_delete} alt="Delete Certification" />
                        </a>
                      </div>
                    ))}

                  <a
                    href="/s"
                    className="add__certification"
                    onClick={handleShowAddEditCertifications}
                  >
                    <img src={ico_add} alt="Ad a certification" />
                    {t("profile.edit_add")} {t("profile.edit_interests")}
                  </a>
                  <AddInterest
                    show={showAddEditCertifications}
                    onHide={handleCloseAddEditCertifications}
                  />
                </div>
                <button className="btn-primary" type="submit" id="btn-send">
                  {t("profile.save")}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
