import React, { useState } from "react";
import { Link } from "@reach/router";
import { useTranslation } from "react-i18next";
import { userService } from "../../_services";

export default function UserSearch({ article, onClick }) {
  //Language hooks
  const { i18n, t } = useTranslation("common");
  const lang = i18n.language;
  const [user, setUser] = useState({});

  const searchUser = () => {
    userService
      .summary(lang, article.uid)
      .then((res) => res.json())
      .then((response) => {
        const user = response[0];
        if (user) {
          setUser(user);
        }
      })
      .catch(console.log);
  };


  return (
    <article
      itemScope=""
      itemType="http://schema.org/Article"
      className="col-12 col-sm-12 col-md-3 col-lg-3"
      onClick={onClick}
    >
      <div>
        <div className="row__article__left">
          <div className="article__information">
            <h2 itemProp="headline">{article.field_name}</h2>
          </div>
        </div>
        <div className="row__article__right">
          <figure className="article__image__content">
            <Link
              to={`/${lang}/${t("home.profile")}/${article.uid}`}
              state={{ userA: { user } }}
             
            >
              <img
                itemProp="image"
                src="/images/no-avatar.png"
                alt={article.field_name}
                onClick={searchUser(article.uid)}
              />
            </Link>
          </figure>
        </div>
      </div>
      <div className="autor__container d-flex">
        <p className="article__autor">
          <span itemProp="name">{article.name}</span>
        </p>
      </div>
    </article>
  );
}
