import React from "react";
import SEO from "../_components/common/seo";
import Layout from "../_components/common/layout";
import { useTranslation } from "react-i18next";

const GeneralPage = ({ slug }) => {
  const { t, i18n } = useTranslation("common");
  const lang = i18n.language;

  

  //Default page
  return (
    <Layout>
      <SEO lang={lang} title={t("pages.title")} />
      <main
        className="home__page"
        role="main"
        itemScope=""
        itemProp="mainContentOfPage"
        itemType="http://schema.org/Blog"
      >
        <h1>{t("pages.title")}</h1>
      </main>
    </Layout>
  );
};

export default GeneralPage;
