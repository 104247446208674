import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "@reach/router";
import { useTranslation } from "react-i18next";

import { appService } from "../../_services";

export default function GroupCard({ image, name, id, type }) {
  const { t, i18n } = useTranslation("common");
  const lang = i18n.language;
  const link = `/${lang}/${t("url.groups")}/${id}`;

  const navigate = useNavigate();
  const [numberMembers, setNumberMembers] = useState([]);
  const [articles, setArticles] = useState([]);

  const [submitJoin] = useState(false);



  useEffect(() => {
    /** Call Services  */
    appService
      .getMembersGroup(lang, id)
      .then((res) => res.json())
      .then((response) => {
        if (response) {
          setNumberMembers(response);
        }
      })
      .catch(console.log);

    appService
      .getArticles(lang, id)
      .then((res) => res.json())
      .then((response) => {
        if (response) {
          setArticles(response);
        }
      })
      .catch(console.log);
  }, [lang, setNumberMembers, id]);

  return (
    <div>
      <div className="group__card col-12">
        <div className="g__card">
          <figure className="article__image__content col-12">
            <Link to={link}>
              <img itemProp="image" src={image} alt={name} />
            </Link>
          </figure>
          <div className="group__info">
            <h2>{name}</h2>
            <div className="detail_info__group d-flex flex-wrap">
              <div className="group__activity col-6 col-sm-6 col-md-12 col-lg-6">
                <p className="groups__members">
                  {numberMembers.results} {t("groups.members")}{" "}
                </p>
                <p className="groups__post">
                  {articles.results} {t("groups.posts")}
                </p>
              </div>
              <div className="group__like__cta col-6 col-sm-6 col-md-12 col-lg-6">
                {/* <Link to={link} className="join__group">
                  {t("groups.btn_join")}
                </Link> */}

                {type === "Group Public" && (
                  <button
                    className="button-blue general-button"
                    onClick={() => navigate(link)}
                    style={{ color: "white" }}
                  >
                    {t("groups.visit_me")}
                  </button>
                )}
                {type === "Group private" &&  (
                  <button
                    style={{ color: "white" }}
                    className={`button-blue general-button  ${
                      submitJoin && "button-gray"
                    }`}
                    onClick={() => navigate(link)}
                  >
                    {/* {submitJoin
                      ? `${t("groups.pending")}`
                      : `${t("groups.request")}`} */}
                      {t("groups.visit_me")}
                  </button>
                )}
              
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
