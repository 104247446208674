import React from "react";
import SEO from "../_components/common/seo";
import Layout from "../_components/common/layout";
import { useTranslation } from "react-i18next";
import ico_back from "../_images/ico-back.svg";
import Credits from "../_components/common/credits";
import { MobileMenu } from "../_components/navigation/mobile-menu";
import { connect } from "react-redux";
import { Notification } from "../_components/contents/notification";


const Comments = ({ user, me }) => {
   const { t, i18n } = useTranslation("common");
   const lang = i18n.language;
   
   
   return (
    <Layout withFade={true} noFooter={true}>
      <SEO lang={lang} title={t("profile.title")} />
      <main
        role="main"
        itemScope=""
        itemProp="mainContentOfPage"
        itemType="http://schema.org/Blog"
      ></main>
      <div className="mobile__bar sticky d-flex align-items-center">
        <a href="/" className="back__btn">
          <img src={ico_back} alt="Back" />
        </a>
        <span className="title__profile">{t("profile.notifications")} </span>
      </div>

      <section className="notifications">
        <div className="container">
          <div className="breadcrumbs">
            <a href="/">{t("profile.tech_vendors")} </a>
            <span className="b__separator">/</span>
            <span className="b__current">{t("profile.notifications")}</span>
          </div>
          <div className="profile__header d-flex flex-column flex-sm-column flex-md-row flex-lg-row">
            <div className="notifications">
              <div className="notifications__header d-flex justify-content-between">
                <h2 className="title__home">
                  <span className="title__highlight">
                    {t("profile.notifications")}
                  </span>
                </h2>
              </div>
              <div className="notifications__container">
                <Notification 
                  lang={lang}
                  uid={me.id}
                />
              </div>
            </div>
          </div>
          <Credits />
        </div>
      </section>
      <MobileMenu selected="profile" withFade={true} />
    </Layout>
  );
}

function mapState(state) {
  const { user, me } = state.authentication;
  return { user, me };
}


const connectedComments = connect(mapState)(Comments);
export { connectedComments as ProfileNotifications };
