import { authHeader } from "../_helpers";
import { appConstants } from "../_constants";

export const eventService = {
  getEventsUpcommig,
  getEventsFeatured,
  getEventsRecent,
  getInfoEvent,
};

function getEventsUpcommig(lang) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${appConstants.API}/${lang}/v1/events-upcomming`,
    requestOptions
  );
}

function getEventsFeatured(lang) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${appConstants.API}/${lang}/v1/events-featured`,
    requestOptions
  );
}

function getEventsRecent(lang) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(`${appConstants.API}/${lang}/v1/events-recent`, requestOptions);
}

function getInfoEvent(lang, slug) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${appConstants.API}/${lang}/v1/events?alias=/${slug}`,
    requestOptions
  );
}
