import React, { useState, useEffect } from "react";
import { Link } from "@reach/router";
import { useTranslation } from "react-i18next";
import { userService } from "../../_services";
import { path } from "../../_helpers";
import { SimpleDate } from "../ui/simple-date";
import { FollowButton } from "../buttons/follow-button";

export default function Author({ id, name, info, label, onData }) {
  //Language hooks
  const { i18n, t } = useTranslation("common");
  const lang = i18n.language;
  const [user, setUser] = useState({});
  const [showInfo, setSiddenInfo] = useState(true);
  const [showLabel, setLabelVisibility] = useState(true);


  useEffect(() => {
    /** Call Services  */
    if (id) {
      userService
        .summary(lang, id)
        .then((res) => res.json())
        .then((response) => {
          const user = response[0];
          if (user) {
            name = user.display_name;
            setUser(user);
            if (onData) {
              onData(user);
            }
          }
        })
        .catch(console.log);
    }

    if (info === false) {
      setSiddenInfo(info);
    }

    if (label === false) {
      setLabelVisibility(label);
    }
  }, [lang, id, name, info, label, setUser, setSiddenInfo, setLabelVisibility]);

  return (
    <>
      <div className="profile__btn__content dropdown">
        <Link
          to={`/${lang}/${t("home.profile")}/${String(
            id
          ).toLocaleLowerCase()}`}
          className="profile__btn user"
          state={{ userA: { user } }}
        >
          {user.use_avatar === "True" ? (
            <img
              src={`/images/${user.avatar}.svg`}
              alt={name}
              onClick={() => {
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              }}
            />
          ) : (
            <img
              src={path(user.picture)}
              alt={name}
              onClick={() => {
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              }}
            />
          )}
        </Link>
        {showInfo ? (
          <ul className="profile__autor lista__user dropdown-list">
            <div className="user__info__container">
              <div className="user__image">
                {user.use_avatar === "True" ? (
                  <img
                    src={`/images/${user.avatar}.svg`}
                    alt={t("user.your_profile")}
                  />
                ) : (
                  <img src={path(user.picture)} alt={t("user.your_profile")} />
                )}
              </div>

              <div className="user__details">
                <span className="user__name">{name}</span>
                <span className="user__profile">@{id}</span>
              </div>
            </div>
            <li>
              <span>{user.bio}</span>
            </li>
            <li>
              <span className="title__autor__detail">
                {user.type === "person"
                  ? t("user.job_person")
                  : t("user.job_company")}
                :
              </span>
              <span className="text__autor__detail">{user.role}</span>
            </li>
            <li>
              <span className="title__autor__detail">
                {t("user.location")}:
              </span>
              <span className="text__autor__detail">{user.location}</span>
            </li>
            <li>
              <span className="title__autor__detail">{t("user.joined")}:</span>
              <span className="text__autor__detail">
                <SimpleDate date={user.date} />
              </span>
            </li>
            <li>
              <FollowButton
                uid={user.id}
                nick={user.name}
                className="btn btn-primary sign__header"
              />
            </li>
          </ul>
        ) : null}
      </div>

      {showLabel && (
        <p className="article__autor">
          <Link
            to={`/${lang}/${t("home.profile")}/${String(
              id
            ).toLocaleLowerCase()}`}
          >
            <span itemProp="name">{name}</span>
          </Link>
        </p>
      )}
    </>
  );
}
