import React from "react";
import { Link } from "@reach/router";
import { useTranslation } from "react-i18next";

export default function Breadcrumbs({ eventTitle }) {
  const { i18n, t } = useTranslation("common");
  const lang = i18n.language;

  return (
    <div className="breadcrumbs d-none d-sm-none d-md-block d-lg-block align-items-center">
      <Link to={`/${lang}/${t("url.events")}`} className="b__main">
        {t("events.title")}
      </Link>
      <span className="b__separator">/</span>
      <span className="b__main">{eventTitle}</span>
    </div>
  );
}
