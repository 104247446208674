import { appConstants } from "../_constants";

export function url(link, path = "") {
  // Striped characters from Drupal URL.
  const CHARS = 4; // => '/api'
  let new_link = String(link).substr(CHARS);

  if (path) {
    const LANG = 3; // => '/en'
    const lang = new_link.substr(0, LANG);
    new_link = `${lang}/${path}/${new_link.substr(LANG + 1)}`;
  }

  /* new_link = new_link.replace(
    "front-mit-drush.eastus.cloudapp.azure.com",
    window.location.hostname
  ); */

  return new_link;
}

export function path(link) {
  let url = `${appConstants.PATH}${link}`;
  /* url = url.replace(
    "front-mit-drush.eastus.cloudapp.azure.com",
    window.location.hostname
  ); */
  return url;
}
