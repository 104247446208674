import React from "react";
import { Link } from "@reach/router";
import { useTranslation } from "react-i18next";
import { CategoryLink } from "./category-link";
import { url } from "../../_helpers";
import he from "he";

export default function ArticleMost({ article }) {
  const { t, i18n } = useTranslation("common");
  const lang = i18n.language;

  const link = url(article.slug);

  return (
    <div className="most__item">
      <article itemScope="" itemType="http://schema.org/Article">
        <div className="article__cat d-flex">
          <p className="article__cat__tag" rel="category">
            <CategoryLink name={article.category} />
          </p>
        </div>
        <div className="article__information">
          <h2 itemProp="headline">
            <Link to={link}>{he.decode(article.title)}</Link>
          </h2>
        </div>
        <div className="autor__container d-flex">
          <p className="article__autor">
            <Link to={`/${lang}/${t("home.profile")}/${String(article.user).toLocaleLowerCase()}`}>
              <span itemProp="name">
                {t("articles.by")} {article.display_name}
              </span>
            </Link>
          </p>
        </div>
      </article>
    </div>
  );
}
