import React, { useEffect, useState } from "react";
import SEO from "../_components/common/seo";
import Layout from "../_components/common/layout";
import { useTranslation } from "react-i18next";
import UpcomingEvents from "../_components/lists/upcoming-events";
import Breadcrumbs from "../_components/common/breadcrumbs";
import EventBannerDetail from "../_components/ui/event-banner-detail";
import EventDetail from "../_components/contents/event-detail";
import { eventService } from "../_services/event.service";
import * as moment from "moment";
import he from "he";
import { path } from "../_helpers";

const EventPage = ({ slug }) => {
  const { i18n, t } = useTranslation("common");
  const lang = i18n.language;

  const [info, setInfo] = useState({
    title: t("contents.loading"),
    field_name_evente: t("contents.loading"),
  });

  useEffect(() => {
    /** Call Services  */
    eventService
      .getInfoEvent(lang, slug)
      .then((res) => res.json())
      .then((response) => {
        setInfo(response[0]);
      })
      .catch((error) => console.log(error));
  }, [lang, slug, setInfo]);

  return (
    <Layout>
      <SEO lang={lang} title={info.field_name_evente} />
      <main
        className="contents__inner"
        role="main"
        itemScope=""
        itemProp="mainContentOfPage"
        itemType="http://schema.org/Blog"
      >
        <article itemScope="" itemType="http://schema.org/Article">
          {/* Beginning of top trending section */}
          <section className="main__content event__content">
            <div className="event__headline">
              <div className="category__event">
                <a href="/">Cybersecurity</a>
              </div>
              <Breadcrumbs
                key={info.field_name_evente}
                eventTitle={he.decode(info.field_name_evente)}
              />

              <h1 itemProp="headline">
               {he.decode(info.field_name_evente)}
              </h1>

              {/* Event info */}
              <EventBannerDetail
                img={path(info.field_image)}
                location={info.field_location}
                startDate={moment(info.field_start_date).format("MMM DD, YYYY")}
                endDate={moment(info.field_end_date).format("MMM DD, YYYY")}
                email={info.field_contact}
                buttonBuy={info.field_tickets}
              />
            </div>

            {/* Event detail */}
            <div className="container event__contain d-flex flex-column-reverse flex-sm-column-reverse flex-md-row flex-lg-row">
              <EventDetail
                text={info.body}
                categories={info.field_secondary_categories}
                buttonDownload={info.field_diary}
              />

              {/* Recomendations */}
              <div className="article__right__ads col-md-4 col-lg-4">
                <div className="event__recomendations">
                  <span className="recomentations__title">
                    {t("events.recommendation")}
                  </span>
                  <span>{info.field_our_recommendation}</span>
                </div>
              </div>
            </div>
          </section>
        </article>

        {/* Upcomming events */}
        <section className="upcoming_events event__detail">
          <UpcomingEvents />
        </section>
      </main>
    </Layout>
  );
};

export default EventPage;
