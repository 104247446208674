import React from "react";
import Modal from "react-bootstrap/Modal";
import { connect } from "react-redux";
import { modalActions } from "../../_actions";
import { useTranslation } from "react-i18next";
import { ArticleForm } from "../forms/article-form";

const AddContent = ({ article: visibility, content: type, hideAddArticle }) => {
  //Language hooks
  const { t } = useTranslation("common");

  return (
    <Modal
      show={visibility}
      onHide={hideAddArticle}
      dialogClassName="modal-community"
      centered
    >
      <div
        id="_1"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="modalLabel_1"
        aria-hidden="true"
      >
        <div className="modal-dialog add__content__box" role="document">
          <div className="modal-content">
            <h3 className="new__content">
              {type === "video" ? (
                <>
                  <span>{t("community.video_blue")}</span>
                  {t("community.video")}
                </>
              ) : (
                <>
                  <span>{t("community.article_blue")}</span>
                  {t("community.article")}
                </>
              )}

              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={hideAddArticle}
                id="close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </h3>

            {/* Form */}
            <ArticleForm type={type} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

function mapState(state) {
  const { article, content } = state.modals;
  return { article, content };
}

const actionCreators = {
  hideAddArticle: modalActions.hideAddArticle,
};

const connectedAddContent = connect(mapState, actionCreators)(AddContent);
export { connectedAddContent as AddContent };
