import React, {useState} from "react";
import { useTranslation } from "react-i18next";

import ico_edit from "../../_images/ico-edit.svg";
import { EditCellphone } from "../modals/edit-cellphone";


export const Cellphone = ({ phone, changeHandler }) => {
  const { t } = useTranslation("common");
  const [showMobile, setShowMobile] = useState(false);

  const handleShowMobile = (e) => {
    e.preventDefault();
    setShowMobile(true);
  };

  const handleCloseMobile = () => {
    setShowMobile(false);
    window.location.reload();
  };

  return (
    <>
      <div className={`form__nofloating half-l ${phone.error ? "error" : ""}`}>
        <label htmlFor="mobile">
          {t("forms.phone")}{" "}
          <div className="d-flex">
            <input
              type="text"
              id="mobile"
              className="form-control"
              required=""
              autoFocus=""
              name="phone"
              onChange={changeHandler}
              value={phone.value}
            />
            <a href="/" className="edit" onClick={handleShowMobile}>
              <img src={ico_edit} alt="Edit profile" />
            </a>
          </div>
          {phone.error && (
            <small id="passlHelp" className="form-text text-muted">
              {t(phone.message)}
            </small>
          )}
        </label>
      </div>

      <EditCellphone show={showMobile} onHide={handleCloseMobile} phone={phone}/>
    </>
  );
};
