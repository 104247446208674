import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Layout from "../_components/common/layout";
import SEO from "../_components/common/seo";
import PublicInformation from "../_components/ui/public-information";
// import ProfileArticle from "../_components/contents/profile-article";
import { DetailProfile } from "../_components/contents/detail-profile";
import { DetailContent } from "../_components/contents/detail-content";
import { DetailArticle } from "../_components/contents/detail-article";
import { FollowersAll } from "../_components/modals/followers-all";
import { userService } from "../_services";
import { Spinner } from "../_components/ui/spinner";
import { Back } from "../_components/buttons/back";
import { connect } from "react-redux";
import { useParams } from "@reach/router";


const PublicProfile = ({ me }) => {
 
  const { t, i18n } = useTranslation("common");
  const lang = i18n.language;
  const [user, setUser] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tab, setTab] = useState("profile");
  const [followers, setFollowers] = useState([]);
  const [following, setFollowing] = useState([]);
  const [showFollowers, setShowFollowers] = useState(false);
  const [followersModal, setFollowersModal] = useState("following");

  const id = useParams();


  //On intialize
  useEffect(() => {
    setLoading(true);
    userService
      .summary(lang, id.slug)
      .then((res) => res.json())
      .then((response) => {
        const user = response[0];
        if (user) {
          setLoading(false);
          setUser(user);
          fetchFollowers(user.id);
          fetchFollowing(user.id);
        }
      })
      .catch(console.log);
  }, [lang, me.id, setUser]);

  
  const fetchFollowers = (userId) => {
    userService
      .followers(userId)
      .then((followers) => {
        setLoading(false);
        setFollowers(followers);
      })
      .catch(console.log);
  };

  const fetchFollowing = (userId) => {
    userService
      .following(userId)
      .then((followiners) => {
        setLoading(false);
        setFollowing(followiners);
      })
      .catch(console.log);
  };

  //On Tab click
  const handleClickTab = (e, tab) => {
    e.preventDefault();
    setTab(tab);
  };

  //Open modal
  const handleShowAll = (type) => {
    setFollowersModal(type);
    setShowFollowers(true);
  };

  //Close modal
  const handleCloseFollowers = () => {
    setShowFollowers(false);
  };


  return (
    <Layout>
      <SEO lang={lang} title={t("profile.title")} />
      <main
        role="main"
        itemScope=""
        itemProp="mainContentOfPage"
        itemType="http://schema.org/Blog"
      ></main>

      {loading ? (
        <Spinner />
      ) : (
        <>
          <div className="mobile__bar sticky d-flex align-items-center">
            <Back />
            <span className="title__profile">{user.display_name}</span>
          </div>
          <section className="public__profile">
            <div className="container">
              <PublicInformation
                user={user}
                following={following.length}
                followers={followers.length}
                onShowAll={handleShowAll}
                
              />
            </div>
          </section>

          <section className="profile__page">
            <div className="container d-flex justify-content-center">
              <div className="profile__body col-sm-12 col-lg-8">
                <div className="profile__dashboard">
                  <div className="tabs__container">
                    <ul
                      className="nav subnav nav-tabs draggable draggable-center"
                      role="tablist"
                    >
                      <li
                        role="presentation"
                        className={tab === "profile" ? "active" : ""}
                      >
                        <a
                          href={`./#${t("url.detail_profile")}`}
                          aria-controls="home"
                          role="tab"
                          data-toggle="tab"
                          onClick={(e) => handleClickTab(e, "profile")}
                        >
                          <span>{t("menus.profile")}</span>
                        </a>
                      </li>
                      <li
                        role="presentation"
                        className={tab === "content" ? "active" : ""}
                      >
                        <a
                          href={`./#${t("url.public_content")}`}
                          aria-controls="home"
                          role="tab"
                          data-toggle="tab"
                          onClick={(e) => handleClickTab(e, "content")}
                        >
                          <span>{t("menus.public_content")}</span>
                        </a>
                      </li>
                     
                    </ul>
                  </div>
                  {tab === "profile" && <DetailProfile user={user} />}
                  {tab === "content" && (
                    <DetailContent
                      user={user.name}
                      className="details__profile"
                    />
                  )}
                  {tab === "articles" && <DetailArticle />}
                  {/* <ProfileArticle></ProfileArticle> */}
                </div>
              </div>
              {/* <div className="public__f col-4 d-none d-md-none d-lg-block">
                <ul className="profile__tabs nav draggable" role="tablist">
                  <Followers
                    title={t("profile.following")}
                    users={following}
                    type="following"
                    icon={ico_heart}
                    redirect={false}
                    onShowAll={handleShowAll}
                  />
                  <Followers
                    title={t("profile.followers")}
                    users={followers}
                    type="followers"
                    icon={ico_like}
                    redirect={false}
                    onShowAll={handleShowAll}
                  />
                </ul>
              </div> */}
            </div>
          </section>

          {/* Modal followers/following */}
          <FollowersAll
            show={showFollowers}
            followers={followers}
            following={following}
            selected={followersModal}
            onHide={handleCloseFollowers}
          />
        </>
      )}
    </Layout>
  );
};

function mapState(state) {
  const { me } = state.authentication;
  return { me };
}

const connectedPublicProfile = connect(mapState, null)(PublicProfile);
export { connectedPublicProfile as PublicProfile };
