import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "@reach/router";
import { userService } from "../../_services";

export const ProfileGroupMembers = ({ item }) => {
  const { i18n, t } = useTranslation("common");
  const lang = i18n.language;
  const [user, setUser] = useState({});


  useEffect(() => {
    /** Call Services  */
    if (item.uid) {
      userService
        .summary(lang, item.uid)
        .then((res) => res.json())
        .then((response) => {
          if (response[0]) {
            setUser(response[0]);
          }
        })
        .catch(console.log);
    }
  }, [lang, setUser]);

  return (
    <>
      {item.user_picture ? (
        <Link
          to={`/${lang}/${t("home.profile")}/${item.uid}`}
          className="profile__btn user"
          state={{userA: {user} }}
        >
          <img src={item.user_picture} />
        </Link>
      ) : (
        <Link
          to={`/${lang}/${t("home.profile")}/${item.uid}`}
          className="profile__btn user"
          state={{userA: {user} }}
        >
          <img
            src={`/images/${item.field_user_avatar}.svg`}
            alt="image-profile"
          />
        </Link>
      )}
    </>
  );
};
