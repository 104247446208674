import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { modalActions } from "../../_actions";

const FavoriteButton = ({ id, user, favorites, showLogin, className = "" }) => {
  const [uuid] = useState(0);
  const [queue, setQueue] = useState(false);
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    if (user && id && favorites) {
      if (user.current_user) {
        /*
        const uuid = `${user.current_user.name}-${id}`;
        setUuid(uuid);

        if (queue) {
          setQueue(false);
          /* eslint-disable * /
          selectItem();
        } else {
          const item = localStorage.getItem(uuid);
          setSelected(item);
        }
        */

        const fav = favorites.filter(
          //Identified as Article content type
          (item) => item.id === id && item.type === "field_like_dislike"
        );
        setSelected(fav != null);
      }
    } else if (!user) {
      //Clear on session close.
      setSelected(false);
    }
  }, [id, user, favorites, queue]);

  //Select
  const selectItem = () => {
    setSelected(true);
    localStorage.setItem(uuid, true);
  };

  //Unselect
  const unselectItem = () => {
    setSelected(false);
    localStorage.removeItem(uuid);
  };

  // Handle click.
  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (user) {
      if (selected) {
        unselectItem();
      } else {
        selectItem();
      }
    } else {
      setQueue(true);
      showLogin();
    }
  };

  return (
    <a
      href="./"
      onClick={handleClick}
      className={`${className} ${selected ? "selected" : ""}`}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="17"
        viewBox="0 0 145 206"
      >
        <path
          className="mark__path"
          d="M0 0L145 0 145 205.4 0 205.4 0 0Z"
          fill="none"
        ></path>
        <path
          className="mark__border"
          d="M137.8 201.8L72.5 148.6 6 200.6 3.6 201.8 2.4 201.8C1.2 200.6 0 199.4 0 198.2L0 7.2C0 4.8 2.4 3.6 4.8 3.6L140.2 3.6C142.6 3.6 143.8 4.8 143.8 7.2L143.8 199.4C143.8 201.8 142.6 203 140.2 203L137.8 201.8ZM137.7 10.9L8.5 10.9 8.5 190.9 70.1 141.4 72.5 140.2 76.1 140.2 137.7 189.7 137.7 10.9Z"
        ></path>
        <path
          className="mark__body"
          d="M8.5 190.6L70.6 140.8 73 140.8 75.4 140.8 77.9 138.4 75.4 140.8 137.5 190.6 137.5 10.9 8.5 10.9 8.5 190.6Z"
          fill="#FFF"
        ></path>
      </svg>
    </a>
  );
};

function mapState(state) {
  const { user } = state.authentication;
  const { favorites } = state.users;
  return { user, favorites };
}

const actionCreators = {
  showLogin: modalActions.showLogin,
};

const connectedFavoriteButton = connect(
  mapState,
  actionCreators
)(FavoriteButton);
export { connectedFavoriteButton as FavoriteButton };
