import React from "react";
import { useTranslation } from "react-i18next";
import ico_others_options from "../../_images/ico-others-options.svg";
import ico_delete from "../../_images/ico-delete-mycontent.svg";
import ico_player from "../../_images/ico-player.svg";
import ico_interesting from "../../_images/ico-interesting-counter.svg";
import ico_bigstar from "../../_images/ico-bigstar.svg";

export default function SaveArticles() {
  const { t } = useTranslation("common");
  return (
    <div className="profile__dashboard my__content">
      <h2 className="dashboard__title">
        <span className="my__content__title">{t("menus.saved_articles")}</span>
        <span className="title__counter">20 {t("profile.articles")}</span>
      </h2>
      <div className="my__articles__content">
        <article>
          <div className="article__interactions d-flex justify-content-end">
            <div className="others__links dropdown">
              <a href="/" className="others__shares">
                <img src={ico_others_options} alt="Options" />
              </a>
              <ul className="share__options dropdown-list" id="shared__list8">
                <div className="share__links">
                  <a href="/" className="delete__content">
                    <img src={ico_delete} alt="Delete content" />
                    <span>Delete</span>
                  </a>
                </div>
              </ul>
            </div>
          </div>
          <div className="d-flex">
            <div className="article__information col-7">
              <div className="article__cat d-flex">
                <p className="article__cat__tag" rel="category">
                  {" "}
                  <a href="/">{t("profile.applications")}</a>
                </p>
                <span className="article__cat__separator">•</span>
                <time className="article__hour__tag" itemProp="datePublished">
                  8 {t("profile.hours")}
                </time>
              </div>
              <h2 itemProp="headline">
                <a href="/">
                  AI development is easier than you think. See Why.
                </a>
              </h2>
              <div className="autor__container d-flex">
                <p className="article__autor">
                  <a href="/">
                    {" "}
                    <span itemProp="name">Guillermo Heins</span>
                  </a>
                </p>
              </div>
            </div>
            <figure className="article__image__content col-5">
              {" "}
              <span className="player__counter">7:02</span>
              <a className="player__btn" href="/">
                <img itemProp="image" src={ico_player} alt="Play the video!" />
              </a>
              <a href="/">
                <img
                  itemProp="image"
                  src="/images/temp/last-video-02.jpg"
                  alt="AI development is easier than you think. 
                                    See Why."
                />
              </a>
            </figure>
          </div>
          <div className="article__feedback d-flex justify-content-between">
            <div className="reactions__counter d-flex justify-content-between">
              <div className="interesting__counter align-items-center">
                <a href="/" className="d-flex align-items-center">
                  <img src={ico_interesting} alt="Mark as cool" />
                  <span>4</span>
                </a>
              </div>
              <span className="article__cat__separator">•</span>
              <div className="comment__counter align-items-center">
                <a href="/" className="d-flex align-items-center">
                  <span>1 {t("contents.comments-content")}</span>
                </a>
              </div>
              <span className="article__cat__separator">•</span>
              <div className="comment__counter align-items-center">
                <a href="/" className="d-flex align-items-center">
                  <span>4 {t("contents.shares")}</span>
                </a>
              </div>
            </div>
            <div className="max__rating d-flex myarticle__rating">
              <div className="big__rate">
                <img src={ico_bigstar} alt="Rate this" />
              </div>
              <div className="rate__numbers">
                <span>3,5 / 5</span>
              </div>
            </div>
          </div>
        </article>
        <button className="load__more">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="17"
            viewBox="0 0 16 17"
          >
            <path d="M8.3 14.4C4.9 14.4 2.2 11.6 2.2 8.2 2.2 4.8 4.9 2.1 8.3 2.1 10 2.1 11.6 2.8 12.6 4L13.1 4.5 12.6 5 12 5.6 15.5 5.6 15.5 1.9 14.5 2.9 14 2.4C12.5 0.9 10.5 0 8.2 0 5.9 0 3.9 0.9 2.4 2.4 0.9 3.9 0 6 0 8.2 0 10.5 0.9 12.5 2.4 14 3.9 15.5 5.9 16.4 8.2 16.4 10.2 16.4 12.1 15.7 13.5 14.5L12 13C11 13.9 9.7 14.4 8.3 14.4L8.3 14.4Z" />
          </svg>
          {t("events.view_more")}
        </button>
      </div>
    </div>
  );
}
