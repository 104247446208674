import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import ico_back from "../../_images/ico-back.svg";
import ico_add from "../../_images/ico-add.svg";
import { CertificationForm } from "./add-edit-certification";
import { EditCertificationsModal } from "./edit-certifications-modal";

export const EditCertifications = ({ show, onHide, me }) => {
  // const certification = me.custom_user_certification ?? [];
  let [certification, setCertification] = useState(
    me.custom_user_certification
  );
  const [showAddEditCertifications, setShowAddEditCertifications] =
    useState(false);
  //Language hooks
  const { t } = useTranslation("common");

  function NumberList() {
    const listItems = certification.map(({ id, name, url_image }) => {
      return (
        <div className="certification__box d-flex certification-main" key={id}>
          <div className="certification__box card-imgtext">
            <input type="text" value={name} disabled />
            <div className="animate__fadeIn">
              <img src={url_image} alt={name} />
            </div>
          </div>
          <EditCertificationsModal
            id={id}
            name={name}
            url_image={url_image}
            setCertification={setCertification}
          />
        </div>
      );
    });
    return <div>{listItems}</div>;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    onHide();
  };

  const handleCloseAddEditCertifications = () =>
    setShowAddEditCertifications(false);
  const handleShowAddEditCertifications = (e) => {
    setShowAddEditCertifications(true);
    e.preventDefault();
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <div
        id="_1"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="modalLabel_1"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered edit__profile"
          role="document"
        >
          <div className="modal-content auto">
            <button
              type="button"
              className="close"
              id="close_certifications"
              data-dismiss="modal"
              aria-label="Close"
              onClick={onHide}
            >
              <span aria-hidden="true">×</span>
            </button>
            <div className="mobile__bar d-flex align-items-center">
              <a href="/" className="back__btn">
                <img src={ico_back} alt="Back" />
              </a>
              <span className="title__profile">{t("user.profile_button")}</span>
            </div>
            <div className="profile__camps">
              <form onSubmit={handleSubmit}>
                <h3>
                  <span>{t("profile.edit_about-blue")}</span>
                  {t("profile.edit_certifications")}
                </h3>
                <div className="certifications">
                  <span className="certification__copy">
                    {t("profile.edit_name")}
                  </span>

                  <NumberList />

                  <a
                    href="/s"
                    className="add__certification"
                    onClick={handleShowAddEditCertifications}
                  >
                    <img src={ico_add} alt="Ad a certification" />
                    {t("profile.edit_add")} {t("profile.edit_certifications")}
                  </a>
                  <CertificationForm
                    show={showAddEditCertifications}
                    onHide={handleCloseAddEditCertifications}
                    certification={setCertification}
                    data={{
                      id: 0,
                      url_image: "",
                      name: "",
                    }}
                  />
                </div>
                <button className="btn-primary" type="submit">
                  {t("profile.save")}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
