import React, { useState, useEffect } from "react";
import { articleService } from "../../_services";
import { useTranslation } from "react-i18next";
import { ArticleFeatured } from "../ui/article-featured";

export const TopArticles = ({ notId, category }) => {
  //Language hooks
  const { i18n, t } = useTranslation("common");
  const lang = i18n.language;
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    /** Call Services  */
    articleService
      .getArticleFeatured(lang)
      .then((res) => res.json())
      .then((response) => {
        setArticles(response.filter((item) => item.id !== notId));
      })
      .catch(console.log);
  }, [lang, category, notId, setArticles]);

  if (!articles.length) {
    return "";
  }

  return (
    <section className="read__also">
      <div className="container">
        <h3 className="category__title">{t("articles.top_trending")}</h3>
        <div className="recommended__articles d-flex flex-wrap">
          {articles.slice(0, 4).map((article, id) => (
            <ArticleFeatured article={article} key={id} />
          ))}
        </div>
      </div>
    </section>
  );
};
