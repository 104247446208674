import React from "react";

const validEmailRegex = RegExp(
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
);
const validAlphaNumRegex = RegExp(
  /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))/
);
const validUrlRegex = RegExp(
  "^(https?:\\/\\/)?" + // protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
    "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
    "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
    "(\\#[-a-z\\d_]*)?$",
  "i"
);
const validLowerCapsRegex = RegExp(/^(?=.*[a-z])(?=.*[A-Z])/);
const validNumbersRegex = RegExp(/\d/);

class FormValidator extends React.Component {
  constructor() {
    super();

    this.state = {
      loading: false,
      errorMessage: null,
      successMessage: null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.validate = this.validate.bind(this);
  }

  /**
   * Receives a change event and store its name into 'state'.
   * @param {object} e Event object.
   */
  handleChange(e) {
    if (e.target.type === "checkbox") {
      this.setState({
        [e.target.name]: e.target.checked,
        errorMessage: null,
        successMessage: null,
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
        errorMessage: null,
        successMessage: null,
      });
    }
  }

  /**
   * Perform validations over the 'state' of a component.
   * @param {array} validations All validations to perform.
   */
  validate(states, validations) {
    let has_errors = false;

    //Reset all fields
    Object.keys(states).forEach((i) => {
      let item = states[i];
      item.error = false;
      item.message = "";
    });

    //Check all rules.
    validations.forEach((validation) => {
      const item = states[validation.field];
      const type = validation.type;
      //Validates Email
      if (type === "required" && !item.error) {
        if (!item.value.toString().trim()) {
          item.message = validation.message;
          item.error = true;
        }
      }

      else if(type === "phone" && item.value === undefined){
        item.message = validation.message;
        item.error = true;
      }
      //Validates Email
      else if (type === "email" && !item.error) {
        if (!validEmailRegex.test(item.value)) {
          item.message = validation.message;
          item.error = true;
        }
      }
      //Validates Min Length
      else if (type.startsWith("min:") && !item.error && type !== "company") {
        const chars = type.replace("min:", "");
        if (!(item.value.length >= Number(chars))) {
          item.message = validation.message;
          item.error = true;
        }
      }
      //Validate Alphanum Chars
      else if (type === "alphanum" && !item.error) {
        if (!validAlphaNumRegex.test(item.value)) {
          item.message = validation.message;
          item.error = true;
        }
      }
      //Validate 1 Cap and 1 Lower at least
      else if (type === "lowercaps" && !item.error) {
        if (!validLowerCapsRegex.test(item.value)) {
          item.message = validation.message;
          item.error = true;
        }
      }
      //Validate 1 Digit at least
      else if (type === "hasnum" && !item.error) {
        if (!validNumbersRegex.test(item.value)) {
          item.message = validation.message;
          item.error = true;
        }
      }
      //Validates Equals
      else if (type.startsWith("equals:") && !item.error) {
        const state_ref = type.replace("equals:", "");
        const reference = states[state_ref];
        if (reference.value !== item.value) {
          item.message = validation.message;
          item.error = true;
        }
      }
      //Validates Url
      else if (type === "url" && !item.error && item.value) {
        if (!validUrlRegex.test(item.value)) {
          item.message = validation.message;
          item.error = true;
        }
      }
      
      //Validates Future date
      else if (type === "future" && !item.error && item.value) {
        var today = new Date();
        today.setHours(0, 0, 0, 0);
        var varDate = new Date(item.value);

        if (varDate < today) {
          item.message = validation.message;
          item.error = true;
        }
      }


      //Controls any error.
      has_errors = has_errors || item.error;
    });

    this.setState({ elements: states });
    return !has_errors;
  }
}

export default FormValidator;
