import React from "react";
import SEO from "../_components/common/seo";
import Layout from "../_components/common/layout";
import { useTranslation } from "react-i18next";


import "../css/terms.css"

const PoliticaPagina = () => {
  const { t, i18n } = useTranslation("common");
  const lang = i18n.language;

  //Default page
  return (
    <Layout>
      <SEO lang={lang} title={t("url.privacy")} />
      <main
        role="main"
        class="no__top"
        itemscope=""
        itemprop="mainContentOfPage"
        itemtype="http://schema.org/Blog"
      >
        <div
          className="container d-flex  term_style"
         
        >
          <div className="d-block">
            <h2>POLÍTICA DE PRIVACIDAD Y TRATAMIENTO DE DATOS PERSONALES </h2>
            <br />
            <h3>TECHFRENETIC S.A.S.</h3>
            <br />
            <br />
            <p>
              <b>TECHFRENETIC S.A.S</b>, sociedad identificada con NIT.
              901.393.213-6, (en adelante, <b>“Techfrenetic”</b> o la{" "}
              <b>“Compañía”</b>), es una entidad respetuosa de los datos
              personales e información que le suministra sus actuales y
              potenciales trabajadores, clientes, contratistas, aliados
              comerciales, proveedores, distribuidores, accionistas, clientes y
              posibles interesados en los servicios y/o productos de la
              Compañía; por tal razón, procede a emitir la presente Política de
              Privacidad y Tratamiento de Datos Personales (en adelante, la
              <b> “Política”</b>) donde se establecen las finalidades, alcance,
              medidas y procedimientos de bases de datos, así como los
              mecanismos con que los Titulares de la información cuentan para
              conocer, actualizar, rectificar o suprimir los datos
              suministrados, o revocar la autorización que se otorga con la
              aceptación de la presente Política.
            </p>
            <p>
              La adquisición de los productos y servicios ofrecidos por la
              Compañía (en adelante, los <b>“Productos y Servicios”</b>), la
              celebración de contratos de trabajo o de prestación de servicios,
              y/o la aceptación expresa e inequívoca de la presente Política,
              implica la aceptación de los Titulares de la información respecto
              de la Política y su autorización para los usos y otros
              tratamientos que aquí se describen.
            </p>
            <br />
            <br />
            <h3>
              CAPÍTULO I DEFINICIONES, ALCANCE, PRINCIPIOS Y GENERALIDADES DE LA
              POLÍTICA
            </h3>
            <br />
            <ul>
              <li>
                <b>1.0 Definiciones</b>. Para el mejor entendimiento y
                cumplimiento de la presente Política se deben tener en cuenta
                las siguientes definiciones, las cuales tendrán el significado
                que a continuación se detalla, sea que su uso se presente de
                manera singular o plural a lo largo de la Política.{" "}
              </li>
              <br />
              <ul>
                <li>
                  <b>“Aviso de privacidad”:</b> comunicación verbal o escrita
                  para darle a conocer a los titulares de la información, la
                  existencia y las formas de acceder a las políticas de
                  tratamiento de la información y el objetivo de su recolección
                  y uso.
                </li>
                <br />
                <li>
                  <b>“Autorización”:</b> consentimiento previo, expreso e
                  informado del Titular para llevar a cabo el tratamiento de
                  datos personales.
                </li>
                <br />
                <li>
                  <b>“Base de Datos”:</b> conjunto organizado de datos
                  personales que son objeto de Tratamiento.
                </li>
                <br />
                <li>
                  <b>“Cookies”:</b> archivo de texto que se descarga al disco
                  duro de la computadora o se guarda en la memoria del navegador
                  web cuando visitan los sitios web de <b>Techfrenetic</b> (en
                  adelante, los <b>“Sitios”</b>).
                </li>
                <br />
                <li>
                  <b>“Consulta”:</b> solicitud del Titular del Dato Personal, de
                  las personas autorizadas por éste, o las autorizadas por ley,
                  para conocer la información que reposa sobre él en las Bases
                  de Datos de la Compañía.
                </li>
                <br />
                <li>
                  <b>“Datos Anonimizados”:</b> datos que no identifican de
                  manera personal al Titular y que pueden utilizarse por la
                  Compañía para la administración y el análisis estadístico, que
                  incluye análisis de tendencias, productos y servicios
                  personalizados, evaluación de riesgos y análisis de costos y
                  cargos relacionados con nuestros productos, servicios y
                  soluciones.
                </li>
                <br />
                <li>
                  <b>“Dato personal”:</b> cualquier información vinculada o que
                  pueda asociarse a una o varias personas naturales determinadas
                  o determinables.
                </li>
                <br />
                <li>
                  <b>“Dato personal privado”:</b> dato que por su naturaleza
                  íntima o reservada sólo es relevante para el Titular.
                </li>
                <br />
                <li>
                  <b>“Dato personal semiprivado”:</b> dato que no tiene
                  naturaleza íntima, reservada, ni pública y cuyo conocimiento o
                  divulgación puede interesar no sólo a su Titular sino a cierto
                  sector o grupo de personas, o a la sociedad en general. (e.g.,
                  datos financieros, crediticios, de la actividad comercial o de
                  servicios, de seguridad social, etc.)
                </li>
                <br />
                <li>
                  <b>“Dato público”:</b> es el dato calificado como tal según
                  los mandatos de la ley o de la Constitución Política y todos
                  aquellos que no sean semiprivado, privado o sensible de
                  conformidad con la Ley 1266 de 2008. Son públicos, entre
                  otros, los datos relativos al estado civil de las personas, a
                  su profesión u oficio, a su calidad de comerciante o de
                  servidor público y aquellos que puedan obtenerse sin reserva
                  alguna.
                </li>
                <br />
                <li>
                  <b>“Datos sensibles”:</b> datos que afectan la intimidad del
                  Titular o cuyo uso indebido puede generar su discriminación,
                  (e.g., datos que revelen el origen racial o étnico, la
                  orientación política, las convicciones religiosas o
                  filosóficas, la pertenencia a sindicatos, organizaciones
                  sociales, de derechos humanos o que promueva intereses de
                  cualquier partido político o que garanticen los derechos y
                  garantías de partidos políticos de oposición, así como los
                  datos relativos a la salud, a la vida sexual y los datos
                  biométricos, como la huella dactilar, entre otros).
                </li>
                <br />
                <li>
                  <b>“Encargado del Tratamiento:</b> persona natural o jurídica,
                  pública o privada, que realiza el tratamiento de datos
                  personales, a partir de una delegación que le hace el
                  Responsable del Tratamiento, recibiendo instrucciones acerca
                  de la forma en la que deberán ser administrados los datos.
                </li>
                <br />
                <li>
                  <b>“Plataforma”:</b> se refiere a la plataforma web de
                  Techfrenetic.
                </li>
                <br />
                <li>
                  <b>“Reclamo”</b>: solicitud del Titular o las personas
                  autorizadas por éste o por la ley para corregir, actualizar o
                  suprimir sus Datos Personales o para advertir que existe un
                  presunto incumplimiento del régimen de protección de datos.
                </li>
                <br />
                <li>
                  <b>“Responsable del Tratamiento”:</b> persona natural o
                  jurídica, pública o privada, que por sí misma o en asocio con
                  otros, decida sobre la base de datos y/o el Tratamiento de los
                  datos.
                </li>
                <br />
                <li>
                  <b>“Requisito de procedibilidad”:</b> paso previo que debe
                  surtir el Titular antes de interponer una queja ante la
                  Superintendencia de Industria y Comercio. Este consiste en una
                  reclamación directa al Encargado o Responsable de sus Datos
                  Personales.
                </li>
                <br />
                <li>
                  <b>“Titular”:</b> persona natural cuyos datos personales sean
                  objeto de Tratamiento.
                </li>
                <br />
                <li>
                  <b>“Tratamiento”:</b> cualquier operación o conjunto de
                  operaciones sobre Datos Personales como, la recolección, el
                  almacenamiento, el uso, la circulación, transferencia,
                  transmisión, actualización o supresión de los Datos
                  Personales, entre otros. El Tratamiento puede ser nacional
                  (dentro de Colombia) o internacional (fuera de Colombia).
                </li>
                <br />
                <li>
                  <b>“Transmisión”:</b> tratamiento de datos personales que
                  implica la comunicación de los mismos dentro o fuera del
                  territorio de la República de Colombia cuando tenga por objeto
                  la realización de un Tratamiento por el Encargado por cuenta
                  del Responsable.
                </li>
                <br />
                <li>
                  <b>“Transferencia”:</b> tratamiento de Datos Personales que
                  tiene lugar cuando el Responsable y/o Encargado del
                  Tratamiento de Datos Personales, envía los Datos Personales a
                  un receptor, que a su vez es Responsable del Tratamiento y se
                  encuentra dentro o fuera del país.
                </li>
                <br />
              </ul>
              <li>
                <b>2.0 Alcance.</b> Esta Política aplica para toda la
                información personal registrada en las bases de datos de
                Techfrenetic, quien actúa en calidad de Responsable del
                Tratamiento de los Datos Personales para estos efectos.
              </li>
              <br />
              <li>
                <b>3.0 Principios del Tratamiento de Datos Personales.</b> El
                tratamiento de Datos Personales de esta Política estará
                orientado por los principios dispuestos en el artículo 4 de la
                Ley 1581 de 2012 y las normas que la actualicen o modifiquen.
                Todas las actividades relacionadas con el tratamiento de datos
                personales que se encuentran en la base de datos de La Compañía
                deben cumplir con los principios reconocidos por la ley y la
                jurisprudencia de la Corte Constitucional colombiana, los cuales
                se resumen a continuación:
              </li>
              <br />
              <ul>
                <li>
                  <b>Principio de finalidad:</b> el Tratamiento debe obedecer a
                  una finalidad legítima de acuerdo con la Constitución y la
                  Ley, la cual debe ser informada al Titular.
                </li>
                <br />
                <li>
                  <b>Principio de Necesidad y Proporcionalidad:</b> los datos
                  personales registrados en la Base de Datos deben ser los
                  estrictamente necesarios para cumplir las finalidades del
                  Tratamiento. En tal sentido, deben ser adecuados, pertinentes
                  y acordes con las finalidades para los cuales fueron
                  recolectados.
                </li>
                <br />
                <li>
                  <b>Principio de Temporalidad:</b>el período de conservación de
                  los datos personales en nuestra base de datos debe ser el
                  necesario para alcanzar la finalidad para la cual los hemos
                  recolectado.
                </li>
                <br />
                <li>
                  <b>Principio de libertad:</b> el Tratamiento sólo puede
                  ejercerse con el consentimiento, previo, expreso e informado
                  del Titular de los datos. Por ello, los datos personales no
                  podrán ser obtenidos o divulgados sin previa autorización, o
                  en ausencia de mandato legal o judicial.
                </li>
                <br />
                <li>
                  <b>Principio de veracidad:</b> la información sujeta a
                  Tratamiento debe ser cierta, completa, exacta, actualizada,
                  comprobable y comprensible. No está permitido el Tratamiento
                  de datos parciales, incompletos, fraccionados o que induzcan a
                  error.
                </li>
                <br />
                <li>
                  <b>Principio de transparencia:</b> en el Tratamiento se debe
                  garantizar el derecho del Titular a obtener de La Compañía, en
                  cualquier momento y sin restricciones, información acerca de
                  la existencia de datos que le conciernan.
                </li>
                <br />
                <li>
                  <b>Principio de acceso y circulación restringida:</b> los
                  datos personales, salvo la información pública, sólo puede
                  estar en Internet cuando el acceso sea técnicamente
                  controlable para otorgar un conocimiento restringido
                  únicamente a los Titulares de los datos personales o a
                  terceros autorizados.
                </li>
                <br />
                <li>
                  <b>Principio de seguridad:</b> El responsable y encargado del
                  tratamiento de los datos personales deberán cumplir con todas
                  las medidas técnicas, humanas y administrativas necesarias
                  para otorgar seguridad a los registros evitando su
                  adulteración, pérdida, consulta, uso o acceso no autorizado o
                  fraudulento.
                </li>
                <br />
                <li>
                  <b>Principio de confidencialidad: </b>todas las personas que
                  intervengan en el Tratamiento de datos personales que no
                  tengan la naturaleza de públicos, están obligadas a garantizar
                  la reserva de la información, inclusive después de finalizada
                  su relación con alguna de las labores que comprende el
                  Tratamiento.
                </li>
                <br />
                <li>
                  <b>No discriminación:</b> queda prohibido realizar cualquier
                  acto de discriminación por las informaciones recaudadas en las
                  bases de datos o archivos.
                </li>
                <br />
                <li>
                  <b>Reparación:</b> es obligación indemnizar los perjuicios
                  causados por las posibles fallas en el tratamiento de datos
                  personales.
                </li>
                <br />
                <li>
                  <b>Principio de limitación en la recolección:</b> sólo deben
                  recolectarse los Datos Personales que sean estrictamente
                  necesarios para el cumplimiento de las finalidades del
                  Tratamiento, de tal forma que se encuentra prohibido el
                  registro y divulgación de datos que no guarden estrecha
                  relación con el objetivo del Tratamiento. En consecuencia,
                  debe hacerse todo lo razonablemente posible para limitar el
                  procesamiento de datos personales al mínimo necesario. Es
                  decir, los datos deberán ser: (i) adecuados, (ii) pertinentes
                  y (iii) acordes con las finalidades para las cuales fueron
                  previstos.
                </li>
                <br />
              </ul>
              <li>
                <b>4.0 Vigencia de la Política.</b> Esta política se encuentra
                vigente desde diciembre de 2021.
              </li>
              <br />
              <li>
                <b>5.0 Legislación vigente:</b> La legislación nacional vigente
                en materia de Protección de Datos Personales es la Ley 1581 de
                2012, la Ley 1266 de 2008, el Decreto 1377 de 2013, Decreto 1074
                de 2015 y las normas que las reglamenten, reemplacen o
                modifiquen.
              </li>
              <br />
              <li>
                <b>
                  6.0 Aplicación de garantías y derechos de la regulación
                  establecida en el reglamento (UE) 2016/679:
                </b>{" "}
                En el evento en que
                <b> Techfrenetic</b>, tenga operaciones y actos que sean objeto
                y competencia de la regulación de la Unión Europea en materia de
                Protección de Datos Personales, en su calidad de Responsable,
                garantizará el ejercicio de las prerrogativas, garantías y
                derechos conferidos en el Reglamento (UE) 2016/679. Para los
                efectos, el Titular deberá manifestar en su reclamo o petición
                las razones y hechos que fundamentan la aplicación de tal
                régimen. Igualmente, previo al uso de la Plataforma, el Titular
                deberá informar tal situación a <b>Techfrenetic</b>, a través de
                los canales habilitados para los efectos. En cualquier caso, en
                aplicación del régimen mencionado, <b>Techfrenetic</b>{" "}
                garantizará el ejercicio de los derechos de restricción de
                procesamiento, objeción del procesamiento y de portabilidad de
                datos personales, cuando sean solicitados por parte de los
                Titulares y en los eventos de su procedencia de acuerdo con la
                regulación.
              </li>
            </ul>
            <br />
            <br />
            <h3>
              CAPÍTULO II DATOS PERSONALES SUMINISTRADOS Y FORMA DE OBTENCIÓN
            </h3>
            <br />
            <ul>
              <li>
                <b>7.0 Recolección de Datos.</b> En general Techfrenetic
                recolecta los datos personales de los Titulares en los
                siguientes casos:{" "}
              </li>
              <br />
              <ul>
                <li>
                  Cuando los Titulares visitan algunos de nuestros Sitios web y
                  utilizan nuestras aplicaciones que se encuentran en la nube.
                </li>
                <br />
                <li>
                  Cuando los Titulares brindan datos personales de forma
                  voluntaria, por ejemplo, al adherirse a los correos
                  electrónicos de la Compañía, registrar su cuenta en uno de los
                  Sitios de Techfrenetic, completar un formulario web para
                  obtener información comercial, o registrarse para uno de los
                  eventos de Techfrenetic.
                </li>
                <br />
                <li>
                  Cuando los Titulares participan en Webinars, seminarios,
                  ferias comerciales y en otros de los eventos organizados por
                  Techfrenetic.
                </li>
                <br />
                <li>
                  Cuando Techfrenetic toma los datos de los Titulares desde
                  fuentes de internet de carácter público, incluyendo pero sin
                  limitarse a, redes sociales como LinkedIn, Facebook, entre
                  otras, así como de sitios web corporativos donde estén
                  colocados sus datos.
                </li>
                <br />
                <li>
                  Cuando los Titulares usan los servicios de Techfrenetic.
                </li>
                <br />
                <li>
                  Cuando Techfrenetic requiere aproximarse a los Titulares para
                  brindar o solicitar nuevos servicios.
                </li>
                <br />
                <li>
                  Cuando Techfrenetic contrata servicios de terceros que emplean
                  datos Cookies orientadas, con el fin de publicitar anuncios
                  según características que tengamos en común con el Titular,
                  como la industria en la que se desempeña.
                </li>
                <br />
                <li>
                  Cuando Techfrenetic le informa con antelación sobre la
                  recolección y el uso previsto para sus datos, mediante esta
                  Política o en el momento de la recolección.
                </li>
              </ul>
              <br />
              <p>
                La Compañía podrá pedir expresamente datos personales a sus
                Titulares o recolectarlos a partir de su comportamiento, salvo
                las excepciones relacionadas con datos sensibles o datos de
                niños, niñas y adolescentes.
              </p>
              <br />
              <li>
                <b>8.0 Datos Personales recolectados.</b> Los datos
                recolectados, incluyen, pero no se limitan a, los siguientes:
                nombre y apellidos, edad, nacionalidad, estado civil, número de
                identificación, libreta militar, tarjeta profesional, firma y su
                caligrafía, huela dactilar, fecha y lugar de nacimiento, estado
                civil, dirección de correspondencia, teléfono de contacto,
                correo electrónico, historial laboral, clínico o de salud,
                académico y patrimonial, referencias, antecedentes comerciales o
                información biográfica, financiera, judicial, disciplinaria y
                familiar, y la relacionada con otras compañías o con entidades
                públicas, fotografías recientes, imágenes en cámaras de
                vigilancia; historia clínica ocupacional, sexo, lugar de trabajo
                cargo o profesión del cónyuge o compañero permanente, de
                empleados y contratistas y de sus parientes hasta el cuarto
                grado de consanguinidad, segundo de afinidad y/o primero civil,
                huellas dactilares, hábitos de consumo, datos de medios
                transaccionales, historial de navegación en sitio web, permisos
                de funcionamiento, autorizaciones para desarrollar actividades
                relacionados con los productos o servicios ofrecidos por
                Techfrenetic, historial de casos abiertos con servicio al
                cliente, contenido favorito, intención de asistencia a eventos,
                antecedentes comerciales, judiciales, relaciones comerciales y
                familiares, e información relacionada con otras compañías o con
                entidades públicas, necesidades e intereses, calificaciones,
                estudios de proveedores, permisos y autorizaciones requeridos
                para proveer servicios o vender productos, contenido creado para
                su publicación, razón o denominación social de la empresa,
                identificación tributaria, descripción, fotografías y videos de
                la empresa, descripción, fotografías y videos del portafolio de
                servicios y productos, historial de pedidos recibidos,
                calificación de satisfacción recibida, indicadores de desempeño
                operativo, comentarios de clientes recibidos, inversión
                promocional, incentivos a la compra causados, premios
                entregados, intención de asistencia a eventos, historial de
                preguntas a expertos en el sitio web y contenido creado para su
                publicación en el sitio web y cualquier otro dato que fuere
                necesario para lograr las finalidades descritas.
              </li>
              <br />
              <li>
                <b>9.0 Datos de carácter sensible y su tratamiento.</b> De
                acuerdo con la Ley 1581 de 2012, son considerados datos de
                carácter sensible los siguientes: el origen racial o étnico,
                orientación política, convicciones religiosas o filosóficas,
                pertenencia a sindicatos, organizaciones sociales, datos
                relacionados con el estado de salud, la vida sexual y los datos
                biométricos, o cualquier otro que pueda afectar la intimidad del
                Titular o cuyo uso indebido pueda generar discriminación.
                Teniendo en cuenta las características de la Compañía, las
                actividades que desarrolla, y las finalidades descritas en la
                presente Política, la Compañía requiere realizar el tratamiento
                de algunos datos sensibles, en la forma y condiciones que se
                indican a lo largo del documento.
              </li>
              <br />
              <p>
                A pesar de lo anterior, Techfrenetic solo recopilará y tratará
                Datos Sensibles en los casos permitidos por la ley. Para tales
                eventos, se informa a los Titulares que no están obligados a
                suministrar los referidos datos o a autorizar su tratamiento.
                Una vez suministrados dichos datos y otorgado el correspondiente
                consentimiento, los datos serán recopilados y tratados
                únicamente para las finalidades descritas en la presente
                Política de Tratamiento, y tal Tratamiento solo será realizado
                mediante autorización cualificada.
              </p>
              <p>
                Los Titulares que pretendan realizar peticiones a través de la
                Plataforma de Techfrenetic, donde sea necesario el suministro de
                información sensible, deberán advertirlo dentro del aplicativo,
                informando tal situación dentro del formulario correspondiente,
                para lo cual, teniendo en cuenta la naturaleza de las peticiones
                y del servicio ofrecido de la Plataforma, deberán otorgar su
                autorización de Tratamiento de Datos Personales Sensibles, para
                los efectos de usar la Plataforma de Techfrenetic y dar trámite
                al proceso de la petición. De no otorgar la referida
                autorización, deberán abstenerse de realizar tales peticiones a
                través de la Plataforma y/o de usarlo para tales efectos.
              </p>
              <p>
                El cualquier caso, el Titular también deberá implementar la
                diligencia necesaria para mantener la reserva y seguridad de su
                información personal sensible.
              </p>
              <li>
                <b>10.0 Datos personales de menores de edad.</b> Techfrenetic
                podrá usar, almacenar y realizar tratamiento de Datos Personales
                de menores de edad que sean usuarios de la Plataforma, y cuyo
                tratamiento haya sido autorizado por sus tutores. La finalidad
                de dicho tratamiento será únicamente la de cumplir con el objeto
                social de La Compañía y prestar los servicios de la Plataforma.
                Para tales efectos, La Compañía tendrá en cuenta el respeto y
                prevalencia de los derechos de los menores, su interés superior
                y sus derechos fundamentales.
              </li>
              <p>
                Techfrenetic podrá tratar Datos Personales de menores de edad
                para las finalidades descritas en la presente Política, cuando
                el tratamiento haya sido autorizado por personas que estén
                facultadas para representar los niños, niñas o adolescentes.
              </p>

              <li>
                <b>11.0 Datos de imagen.</b> Con el hecho de participar en
                cualquier videoconferencia patrocinada por Techfrenetic, los
                Titulares aceptan y autorizan que sus nombres e imágenes
                aparezcan en los programas, publicaciones y demás medios
                publicitarios y en general en todo material de divulgación con
                fines promocionales o comerciales que La Compañía deseen hacer
                durante el término de 50 años, sin que ello implique la
                obligación de remunerarlos o compensarlos. Asimismo, renuncian a
                cualquier reclamo por derechos de imagen.
              </li>
            </ul>
            <br />
            <br />
            <h3>
              CAPÍTULO III FINALIDAD DEL TRATAMIENTO DE LOS DATOS PERSONALES
            </h3>
            <br />
            <p>
              Techfrenetic realizará el Tratamiento de los Datos Personales de
              acuerdo con las condiciones establecidas por el Titular, la ley o
              las entidades públicas para el cumplimiento de las actividades
              propias de su objeto social. En general, Techfrenetic recolectará,
              almacenará, organizará, circulará, usará, perfilará, transmitirá y
              transferirá los Datos Personales de acuerdo a las finalidades que
              se establecen a continuación:
            </p>
            <ul>
              <li>
                <b>
                  12.0 Finalidad del Tratamiento de la Base de Datos de
                  empleados, contratistas y aspirantes a empleados, ex empleados
                  y personal de la Compañía.
                </b>
              </li>
              <p>
                La finalidad de la presente Política en relación con las Bases
                de Datos que administra respecto de empleados, contratistas y
                aspirantes a empleados, ex empleados y personal de Techfrenetic
                es:
              </p>
              <ul>
                <li>
                  Conservar y administrar la información de la relación laboral,
                  civil o comercial de los Titulares.
                </li>
                <br />
                <li>
                  Dar cumplimiento a los deberes legales, contables, comerciales
                  y regulatorios que le aplican.{" "}
                </li>
                <br />
                <li>
                  Controlar y preservar la seguridad de las personas, bienes e
                  información de la Compañía.
                </li>
                <br />
                <li>
                  Cumplir el objeto de la relación laboral, comercial o civil
                  que se hubiere adquirido con los Titulares.
                </li>
                <br />
                <li>
                  Proteger la salud de los empleados y contratistas de la
                  Compañía.
                </li>
                <br />
                <li>
                  Verificar conflictos de intereses en nuevos empleados o
                  contratistas de Techfrenetic, así como inhabilidades o
                  incompatibilidades de los mismos.
                </li>
                <br />
                <li>Cumplir con el objeto social de Techfrenetic</li>
                <br />
                <li>
                  Prevenir y constatar la comisión de delitos o conductas
                  delictivas por parte de los empleados, contratistas,
                  aspirantes y personal en general, para lo cual se podrán
                  consultar distintas bases de datos, listas restrictivas y
                  fuentes, tales como, bases de datos de la Policía Nacional,
                  Contraloría, Interpol, FBI, SDNT, así como las redes sociales
                  correspondientes, en la forma en la que se encuentren
                  dispuestas.
                </li>
                <br />
                <li>
                  Mantener comunicación directa con los Titulares para temas
                  relacionados con su situación laboral, civil o comercial y
                  para efectos contractuales, informativos y comerciales.
                </li>
                <br />
                <li>
                  Seleccionar personal, administrar contrataciones, manejar las
                  relaciones laborales, otorgar beneficios a sus empleados por
                  sí mismo o a través de terceros, así como permitir el acceso
                  de los empleados a los recursos informáticos de la Compañía.
                </li>
                <br />
                <li>
                  Llevar un registro de las sanciones disciplinarias impuestas a
                  contratistas y empleados de la Compañía.
                </li>
                <br />
                <li>
                  La realización de análisis estadísticos, comerciales,
                  financieros, sociales, administrativos o técnicos.
                </li>
                <br />
                <li>
                  Comprobación y verificación de la identidad y antecedentes
                  penales, disciplinarios, financieros y crediticios de los
                  Titulares.
                </li>
                <br />
                <li>
                  Transmitir, transferir y suministrar la información y datos
                  personales de los Titulares a aquellos terceros encargados de
                  administrar el sistema de seguridad social en Colombia, así
                  como a compañías aseguradoras.
                </li>
                <br />
                <li>
                  Transmitir, transferir y suministrar la información y datos
                  personales de los Titulares a terceros, en aquellos casos en
                  que se presente sustitución patronal o en aquellos casos en
                  que la Compañía ceda su posición contractual, o cuando sea
                  parte de un proceso de adquisición o integración empresarial.
                </li>
                <br />
                <li>
                  Transmitir, transferir y suministrar la información y los
                  datos personales de los Titulares a compañías operadoras de
                  libranza, con el fin de que estas consulten la información
                  pertinente a efectos del otorgamiento de créditos de libranza.
                </li>
                <br />
                <li>
                  Transmitir, transferir y suministrar la información y los
                  datos personales de los Titulares a la casa matriz o demás
                  empresas del grupo empresarial de Techfrenetic, con el fin de
                  que estas almacenen, consulten o actúen como Encargados o
                  Responsables de los datos personales según el caso.
                </li>
                <br />
                <li>
                  Para la evaluación y desarrollo de procesos de selección y su
                  histórico.
                </li>
                <br />
                <li>
                  Para dar atención de Consultas, peticiones, solicitudes,
                  acciones y Reclamos, hechas por el Titular de la información o
                  por sus representantes o derecho habientes, o por entidades
                  del sistema general de seguridad social integral a los que el
                  titular esté o hubiere estado vinculado.
                </li>
                <br />
                <li>
                  Para participar en procesos de contratación pública o privada,
                  y atender las condiciones o exigencias de dichos concursos,
                  licitaciones, manifestaciones de interés públicas o privadas,
                  precalificaciones públicas o privadas, presentación de
                  propuestas, y en general, a la inscripción o participación en
                  cualquier proceso de selección de contratación público o
                  privado.
                </li>
                <br />
                <li>
                  La socialización de políticas, proyectos, programas y cambios
                  organizacionales.
                </li>
              </ul>
              <br />
              <li>
                <b>
                  13.0 Finalidades del Tratamiento en relación con la base de
                  datos de actuales o potenciales proveedores y aliados
                  comerciales.
                </b>
              </li>
              <p>
                La finalidad de la presente Política en relación con las Bases
                de Datos que administra respecto de proveedores y aliados
                comerciales de Techfrenetic es:
              </p>
              <ul>
                <li>
                  El cumplimiento del objeto social de la Compañía y del grupo
                  empresarial del que forma parte.
                </li>
                <br />
                <li>
                  La realización de análisis estadísticos, comerciales,
                  estratégicos, financieros, sociales y técnicos.
                </li>
                <br />
                <li>
                  El desarrollo, ejecución y cumplimiento de la relación
                  contractual que el Titular tenga con la Compañía.
                </li>
                <br />
                <li>
                  Cumplir con la propuesta de valor y el nivel de servicio
                  ofrecido a cada segmento de clientes y proveedores.
                </li>
                <br />
                <li>
                  El cumplimiento de deberes legales, contables, comerciales y
                  regulatorios.
                </li>
                <br />
                <li>
                  La comunicación con los Titulares para efectos contractuales,
                  informativos y comerciales.
                </li>
                <br />
                <li>
                  El control y la preservación de la seguridad de las personas,
                  bienes e información de la Compañía, para lo cual se podrán
                  consultar distintas bases de datos y fuentes, tales como,
                  bases de datos de la Policía Nacional, Contraloría, Interpol,
                  FBI, SDNT list (o “Lista Clinton”), listas restrictivas,
                  centrales de riesgo crediticio, así como las redes sociales
                  del Titular, en la forma en la que se encuentren dispuestas.
                </li>
                <br />
                <li>
                  Comprobación y verificación de la identidad e información en
                  general, y antecedentes penales, disciplinarios, financieros y
                  crediticios de los Titulares.
                </li>
                <br />
                <li>
                  Transmitir, Transferir y suministrar la información y datos
                  personales de los Titulares a las sociedades subsidiarias,
                  filiales o afiliadas a la Compañía, aliados comerciales o a
                  otras sociedades o personas nacionales y/o internacionales que
                  la Compañía encargue para realizar el Tratamiento de la
                  información y cumplir con las finalidades descritas en la
                  presente Política y el objeto de la relación comercial o civil
                  con los Titulares, o para que dichos terceros asuman la
                  posición de Responsables.
                </li>
                <br />
                <li>
                  Transmitir, Transferir y suministrar, a título gratuito u
                  oneroso, la información y datos personales de los Titulares a
                  aliados comerciales nacionales y/o internacionales para que
                  estos contacten a los Titulares para ofrecerles sus productos,
                  información o servicios que a juicio de Compañía puedan ser de
                  interés del Titular.
                </li>
                <br />
                <li>
                  Transmitir, Transferir y suministrar la información y datos
                  personales de los Titulares a terceros nacionales y/o
                  internacionales, en aquellos casos en que la Compañía
                  participe en procesos de fusión, integración, escisión,
                  liquidación, adquisición y/o enajenación de activos.
                </li>
                <br />
                <li>
                  Realizar actividades de mercadeo, como lo son estudios de
                  mercado, y realizar actos de promoción de productos y
                  servicios, entre otros conceptos similares.
                </li>
                <br />
                <li>
                  Definir perfiles de consumo de sus clientes, con el fin de
                  realizar análisis estadísticos o mejorar los procesos de
                  mercadeo y venta de los servicios y/o productos de
                  Techfrenetic.
                </li>
                <br />
                <li>
                  La socialización de políticas, proyectos, programas y cambios
                  organizacionales.
                </li>
                <br />
                <li>
                  La difusión de los casos o asuntos atendidos por Techfrenetic
                  que hayan sido exitosos y representativos.
                </li>
                <br />
                <li>
                  Transmitir, transferir y suministrar la información y datos
                  personales de los Titulares a terceros nacionales o
                  internacionales, en aquellos casos en que Techfrenetic tenga
                  el interés de vender o comercializar en cualquier forma los
                  datos como un activo o bien de naturaleza comercial.
                </li>
                <br />
                <li>
                  Para realizar reportes ante las bases de datos de operadores
                  de información crediticios y de naturaleza comercial.
                </li>
                <br />
                <li>
                  Para gestionar el pago de sumas remuneradas, comisiones,
                  descuentos y retenciones de naturaleza tributaria o
                  parafiscal.
                </li>
                <br />
                <li>
                  Transmitir, transferir y suministrar, a título gratuito u
                  oneroso, la información y datos personales de los Titulares a
                  entidades gubernamentales nacionales y/o internacionales para
                  los proyectos que gestionen tales entidades.
                </li>
                <br />
                <li>
                  Realizar invitaciones a eventos, mejorar servicios, y todas
                  aquellas actividades asociadas a la relación comercial o
                  vínculo existente con Techfrenetic o aquel que llegare a
                  tener.
                </li>
                <br />
                <li>
                  Para la evaluación y desarrollo de procesos de selección de
                  proveedores y su histórico.
                </li>
              </ul>
              <br />
              <li>
                <b>
                  14.0 Finalidades del Tratamiento en relación con la base de
                  datos de futuros, eventuales, actuales y antiguos accionistas:{" "}
                </b>
              </li>
              <ul>
                <li>
                  El desarrollo, ejecución y cumplimiento de la relación
                  contractual que el titular tenga con Techfrenetic,
                </li>
                <br />
                <li>
                  El cumplimiento de deberes legales, contables, comerciales y
                  regulatorios, y de requerimientos y solicitudes de información
                  de autoridades públicas.
                </li>
                <br />
                <li>
                  La realización de análisis estadísticos, comerciales,
                  estratégicos, financieros, sociales y técnicos.
                </li>
                <br />
                <li>
                  La comunicación con los titulares para efectos contractuales,
                  informativos y comerciales.
                </li>
                <br />
                <li>
                  El cumplimiento de deberes legales, contables, comerciales y
                  regulatorios.
                </li>
                <br />
                <li>
                  Transmitir, transferir y suministrar la información y datos
                  personales de los Titulares a las sociedades subsidiarias,
                  filiales o afiliadas a Techfrenetic aliados comerciales o a
                  otras sociedades o personas nacionales e internacionales que
                  Techfrenetic encargue para realizar el tratamiento de la
                  información y cumplir con las finalidades descritas en la
                  presente Política y el objeto de la relación comercial o civil
                  con los Titulares, o para que dichos terceros asuman la
                  posición de Responsables.
                </li>
                <br />
                <li>
                  Con el fin de preservar la seguridad de Techfrenetic, analizar
                  y verificar la información de los accionistas de Techfrenetic.
                </li>
                <br />
                <li>
                  Transmitir, transferir y suministrar la información y datos
                  personales de los Titulares a terceros nacionales y/o
                  internacionales, en aquellos casos en que Techfrenetic
                  participe en procesos de fusión, integración, escisión,
                  liquidación y/o enajenación de activos.
                </li>
                <br />
                <li>
                  Para verificar antecedentes laborales, profesionales,
                  comerciales y cualquier referencia que concierna a su
                  idoneidad profesional y comercial.
                </li>
                <br />
                <li>
                  Comprobación y verificación de la identidad y antecedentes
                  penales, disciplinarios financieros y crediticios de los
                  titulares.
                </li>
                <br />
                <li>
                  El control y la preservación de la seguridad de las personas,
                  bienes e información de la Compañía, para lo cual se podrán
                  consultar distintas bases de datos y fuentes, tales como,
                  bases de datos de la Policía Nacional, Contraloría, Interpol,
                  FBI, SDNT list (o “Lista Clinton”), listas restrictivas,
                  centrales de riesgo crediticio, así como las redes sociales
                  del Titular, en la forma en la que se encuentren dispuestas.
                </li>
                <br />
                <li>
                  Para el ejercicio de desarrollo de los derechos del accionista
                  y de la operación de la asamblea general de accionistas.
                </li>
                <br />
                <li>
                  Proteger la salud de los accionistas de Techfrenetic y de
                  quienes concurran a sus instalaciones.
                </li>
              </ul>
              <br />
              <li>
                <b>
                  15.0 Finalidades del Tratamiento en relación con la base de
                  datos de clientes.{" "}
                </b>
              </li>
              <ul>
                <li>
                  La comunicación con los Titulares para efectos contractuales,
                  informativos o comerciales.
                </li>
                <br />
                <li>
                  El cumplimiento de deberes legales, contables, comerciales y
                  regulatorios.
                </li>
                <br />
                <li>
                  El control y la preservación de la seguridad de las personas,
                  bienes e información de Techfrenetic.
                </li>
                <br />
                <li>
                  La socialización de políticas, proyectos, programas y cambios
                  organizacionales.
                </li>
                <br />
                <li>
                  La difusión de los casos o asuntos atendidos por Techfrenetic
                  que hayan sido exitosos y representativos.
                </li>
                <br />
                <li>
                  La realización de análisis estadísticos, comerciales,
                  estratégicos, financieros, sociales, técnicos y de
                  calificación de riesgo
                </li>
                <br />
                <li>
                  El cumplimiento del objeto social de Techfrenetic y el
                  cumplimiento del objeto contractual o civil con los Titulares.
                </li>
                <br />
                <li>
                  Transmitir, transferir y suministrar la información y datos
                  personales de los Titulares a las sociedades subsidiarias,
                  filiales o afiliadas a Techfrenetic, aliados comerciales o a
                  otras sociedades o personas nacionales o internacionales que
                  Techfrenetic encargue para realizar el tratamiento de la
                  información y cumplir con las finalidades descritas en la
                  presente Política y el objeto de la relación comercial o civil
                  con los Titulares, o para que dichos terceros asuman la
                  posición de Responsables.
                </li>
                <br />
                <li>
                  Transmitir, transferir y suministrar, a título gratuito u
                  oneroso, la información y datos personales de los Titulares a
                  aliados comerciales nacionales o internacionales para que
                  estos contacten a los Titulares para ofrecerles sus productos,
                  información o servicios que a juicio de Techfrenetic puedan
                  ser de interés del Titular.
                </li>
                <br />
                <li>
                  Transmitir, transferir y suministrar la información y datos
                  personales de los Titulares a terceros nacionales o
                  internacionales, en aquellos casos en que Techfrenetic
                  participe en procesos de fusión, integración, escisión,
                  liquidación y/o enajenación de activos.
                </li>
                <br />
                <li>
                  Transmitir, transferir y suministrar la información y datos
                  personales de los Titulares a terceros nacionales o
                  internacionales, en aquellos casos en que Techfrenetic tenga
                  el interés de vender o comercializar en cualquier forma los
                  datos como un activo o bien de naturaleza comercial.
                </li>
                <br />
                <li>
                  Prevenir y contrarrestar un eventual riesgo reputacional que
                  pudiere afectar a Techfrenetic para lo cual se podrán
                  consultar distintas bases de datos y fuentes, tales como,
                  bases de datos de la Policía Nacional, Contraloría, Interpol,
                  FBI, SDNT list (o “Lista Clinton”), SARLAFT, así como las
                  redes sociales y correspondientes, en la forma en la que se
                  encuentren dispuestas.
                </li>
                <br />
                <li>
                  Para verificar antecedentes laborales, profesionales,
                  comerciales y cualquier referencia que concierna a su
                  idoneidad profesional y comercial y/o comportamiento como
                  consumidor.
                </li>
                <br />
                <li>
                  Para realizar estudios y estrategias de mercadeo y publicidad.
                </li>
                <br />
                <li>
                  Transmitir, transferir y suministrar, a título gratuito u
                  oneroso, la información y datos personales de los Titulares a
                  entidades gubernamentales nacionales y/o internacionales para
                  los proyectos que gestionen tales entidades.
                </li>
                <br />
                <li>
                  Para realizar labores de mercadeo y remitir publicidad
                  Techfrenetic y/o de Terceros aliados comerciales.
                </li>
                <br />
                <li>Para optimizar las funcionalidades de la Plataforma.</li>
                <br />
                <li>
                  Para fines de seguridad, mejoramiento del servicio, la
                  experiencia en los servicios, los Datos Personales podrán ser
                  utilizados, entre otros, como prueba en cualquier tipo de
                  proceso.
                </li>
              </ul>
              <br />
              <br />
              <h3>
                CAPÍTULO IV AUTORIZACIÓN PARA RECOLECCIÓN Y TRATAMIENTO DE DATOS
                PERSONALES Y OTRA INFORMACIÓN
              </h3>
              <br />
              <li>
                <b>
                  16.0 Manifestaciones de los Titulares de los Datos Personales.
                </b>
                Al momento de suministrar voluntariamente sus datos y/o conceder
                la autorización expresa verbal o por escrito, el Titular:{" "}
              </li>
              <br />
              <ul>
                <li>
                  {" "}
                  Mediante el suministro voluntario de alguno de los Datos
                  Personales en la forma señalada en la Política, la
                  Autorización expresa verbal o por escrito, el registro o
                  creación de un perfil o cuenta en la Plataforma, o la
                  autenticación o ingreso a la Plataforma por medio de la
                  autenticación con la cuenta del titular suscrita a un tercero,
                  incluyendo, pero sin limitarse a Microsoft, Google o Facebook,
                  el titular autoriza expresa e inequívocamente a Techfrenetic
                  para recolectar Datos Personales y cualquier otra información
                  que suministre, así como para realizar el Tratamiento sobre
                  sus Datos Personales, de conformidad con esta Política y la
                  ley.
                </li>
                <br />
                <li>
                  Autoriza a la Compañía para solicitarle, cuando lo requieran
                  las leyes aplicables, que brinde su consentimiento expreso
                  para las actividades de marketing directo, electrónico y en
                  línea, y para el uso de las Cookies. De ser necesario, la
                  Compañía solicitará que se brinde su consentimiento expreso
                  para recolectar y utilizar datos personales como se indica en
                  esta Política. Dicho consentimiento se solicitará en el
                  formulario web o el formulario en copia impresa que se usa
                  para recolectar datos personales o para confirmar las
                  inscripciones o preferencias (por ejemplo, para actividades
                  fuera de línea, como las ferias comerciales, seminarios y
                  otros eventos).
                </li>
                <br />
                <li>
                  {" "}
                  Autoriza a que la Compañía en algunos casos le solicite que
                  confirme su consentimiento mediante un vínculo en un correo
                  electrónico de seguimiento, con el fin de contar con una doble
                  autorización para llevar a cabo la recolección y Tratamiento
                  de los datos.
                </li>
                <br />
                <li>
                  Fue informado y entiende que los datos sensibles son aquellos
                  que afectan la intimidad del Titular o cuyo uso indebido puede
                  generar discriminación. Así mismo, que ellos pueden
                  identificarse como los de origen racial o étnico, orientación
                  política, convicciones religiosas o filosóficas, pertenencia a
                  sindicatos, organizaciones sociales, datos relacionados con el
                  estado de salud, la vida sexual y los datos biométricos.
                </li>
                <br />
                <li>
                  Fue informado de que, en caso de recolección de información
                  sensible, tiene derecho a no contestar las preguntas que le
                  sean formuladas y a no entregar los datos solicitados.
                </li>
                <br />
                <li>
                  Fue informado acerca de las finalidades para las cuales se
                  utilizarán los datos sensibles recolectados, los cuales se
                  encuentran descritos en esta Política.
                </li>
                <br />
                <li>
                  Fue informado y comprende las medidas de seguridad que
                  Techfrenetic implementa para brindar protección a los datos
                  personales que recolecta y, por tanto, acepta las mismas.
                </li>
                <br />
                <li>
                  Se les informó acerca de sus derechos en relación con sus
                  Datos Personales y mecanismos para ejercerlos.
                </li>
              </ul>
              <br />
              <li>
                <b>17.0 Término de la Autorización:</b> El término de la
                Autorización para el Tratamiento de los Datos Personales será
                desde que se otorga la Autorización, hasta el día en que
                Techfrenetic se disuelva y se liquide o hasta que se termine la
                finalidad para la cual fueron recolectados los Datos Personales.
              </li>
              <br />
              <li>
                <b>
                  18.0 Autorización para el Tratamiento de Datos Sensibles o de
                  menores de edad:
                </b>{" "}
                Cuando se trate de la recolección de datos sensibles o de
                menores de edad se deben cumplir los siguientes requisitos:
              </li>
              <ul>
                <li>La autorización debe ser explícita;</li>
                <br />
                <li>
                  Se debe informar al Titular que no está obligado a autorizar
                  el Tratamiento de dicha información;
                </li>
                <br />
                <li>
                  Se debe informar de forma explícita y previa al Titular cuáles
                  de los datos que serán objeto de Tratamiento son sensibles y
                  la finalidad del mismo;
                </li>
                <br />
                <li>
                  Se requiere autorización por parte del representante o tutor
                  para el Tratamiento de datos de niños, niñas y adolescentes.
                </li>
              </ul>
              <br />
              <li>
                <b>19.0 Autorización para nuevos usos o finalidades:</b>{" "}
                Techfrenetic podrá solicitar Autorización de los Titulares para
                nuevos usos de sus Datos Personales o información, para lo cual
                publicará los cambios en la presente Política de Tratamiento en
                su página web, Plataforma o en cualquier medio que estime
                conveniente según el caso.
              </li>
              <br />
              <br />
              <h3>
                CAPÍTULO V TRATAMIENTO DE LOS DATOS PERSONALES ALMACENADOS EN
                LAS BASES DE DATOS DE TECHFRENETIC
              </h3>
              <br />
              <li>
                <b>20.0 Usos bajo la Política.</b> La Compañía solo tratará los
                datos personales y otra información de los Titulares para las
                finalidades descritas y los usos autorizados en esta Política o
                en las leyes vigentes. En adición a lo mencionado en otras
                secciones, el Titular expresamente autoriza a la Compañía para
                el Tratamiento de sus datos personales y otra información para
                los siguientes propósitos y en las siguientes circunstancias:{" "}
              </li>
              <ul>
                <li>
                  Establecer comunicación entre la Compañía y los Titulares para
                  cualquier propósito relacionado con las finalidades que se
                  establecen en la presente Política, ya sea mediante llamadas,
                  mensajes de texto, correos electrónicos y/o físicos.{" "}
                </li>
                <br />
                <li>
                  Auditar, estudiar y analizar la información de las bases de
                  datos para diseñar y ejecutar estrategias administrativas,
                  laborales, de seguridad y financieras relacionadas con el
                  personal de la Compañía.{" "}
                </li>
                <br />
                <li>
                  Suministrar la información y datos personales de los Titulares
                  a las sociedades subsidiarias, filiales o afiliadas a la
                  Compañía, aliados comerciales o a otras sociedades o personas
                  que la Compañía encargue para realizar el tratamiento de la
                  información y cumplir con las finalidades descritas en la
                  presente Política y el objeto de la relación laboral,
                  comercial o civil con los Titulares.
                </li>
                <br />
                <li>
                  Con el fin de preservar la seguridad de la Compañía, analizar
                  y verificar la información de los empleados y colaboradores de
                  la Compañía y de aquellos que participen en procesos de
                  selección.{" "}
                </li>
                <br />
                <li>
                  Transferir, transmitir y suministrar, a título gratuito u
                  oneroso, la información y datos personales de los Titulares a
                  aliados comerciales nacionales y/o extranjeros para que estos
                  contacten a los Titulares para ofrecerles sus productos,
                  información o servicios que a juicio de la Compañía puedan ser
                  de interés del Titular.{" "}
                </li>
                <br />
                <li>
                  Transferir, transmitir y suministrar la información y datos
                  personales de los Titulares a terceros, en aquellos casos en
                  que la Compañía participe en procesos de fusión, integración,
                  escisión, adquisición y/o liquidación.
                </li>
                <br />
                <li>
                  Verificar conflictos de intereses o posibles irregularidades
                  con los nuevos contratistas, aliados, proveedores, clientes
                  y/o empleados de la Compañía.
                </li>
                <br />
                <li>
                  Realizar calificación de riesgo financiero, jurídico,
                  comercial y de seguridad.
                </li>
                <br />
                <li>
                  Consultar, almacenar y usar la información financiera obtenida
                  que terceros administradores de bases de datos, previa
                  autorización del Titular para dicha consulta.
                </li>
                <br />
                <li>
                  Combinar los datos personales con la información que se
                  obtenga de otros aliados o compañías o enviarla a los mismos
                  para implementar estrategias comerciales conjuntas.
                </li>
                <br />
                <li>
                  Cuando la información deba ser revelada para cumplir con
                  leyes, regulaciones o procesos legales, para detener o
                  prevenir fraudes, ataques a la seguridad de la Compañía o de
                  otros, así como para prevenir problemas técnicos o proteger
                  los derechos de otros.{" "}
                </li>
                <br />
                <li>
                  Auditar, estudiar y analizar la información de las bases de
                  datos para diseñar estrategias comerciales y aumentar y/o
                  mejorar los productos y/o servicios que ofrece la Compañía.
                </li>
                <br />
                <li>
                  Auditar, estudiar, analizar y utilizar la información de las
                  bases de datos para diseñar, implementar y desarrollar,
                  programas, proyectos y eventos.
                </li>
                <br />
                <li>
                  Auditar, estudiar, analizar y utilizar la información de la
                  base de datos para la socialización de políticas, proyectos,
                  programas, resultados y cambios organizacionales.
                </li>
                <br />
                <li>
                  Transmitir, transferir y suministrar la información y datos
                  personales de los Titulares a aliados estratégicos nacionales
                  y/o extranjeros para que estos contacten a los Titulares para
                  ofrecerles bienes y servicios de su interés, recibir ofertas
                  de los Titulares, invitar a la participación en programas,
                  proyectos, eventos, socializar políticas, proyectos,
                  programas, resultados y cambios organizacionales.
                </li>
                <br />
                <li>
                  Vender o ceder los datos a terceros nacionales y/o
                  extranjeros, previo cumplimiento de la regulación.{" "}
                </li>
                <br />
                <li>
                  Realizar actividades de mercadeo de los servicios y productos
                  ofrecidos, en el marco de actividades de marketing directo. La
                  Compañía garantiza que el marketing directo o el estudio de
                  mercado que reciba o acerca del cual se lo contacte por correo
                  electrónico, brindarán medios sencillos para que no reciba más
                  esa comunicación por correo electrónico. Por ejemplo, en el
                  correo electrónico la Compañía le brindará un vínculo para
                  "cancelar la suscripción" o un correo electrónico para enviar
                  una solicitud de exclusión. En estos casos, no necesariamente
                  se removerán sus datos personales de las bases de datos, sino
                  que se tendrá presente y se respetará el cambio en las
                  preferencias de marketing directo.
                </li>
                <br />
                <li>
                  Informar sobre el alcance y características de los servicios y
                  productos de la Compañía, soluciones, Webinars, seminarios,
                  ferias comerciales y otros eventos nuevos y existentes, además
                  de promociones y ofertas.
                </li>
                <br />
                <li>
                  Administrar nuestros Sitios y las cuentas de los Titulares,
                  analizar tendencias y mejorar la funcionalidad de nuestro
                  Sitio.
                </li>
                <br />
                <li>
                  Personalizar nuestros Sitios y algunos sitios web de terceros
                  según las preferencias que el usuario seleccionó y
                  personalizar y mejorar su experiencia en línea, que puede
                  incluir anuncios orientados sobre nuestros productos,
                  servicios y soluciones para que reciba información relevante.
                  Para mostrar los anuncios digitales orientados en sitios web
                  de terceros, la Compañía utiliza redes publicitarias.
                </li>
                <br />
                <li>
                  Considerar una solicitud de trabajo enviada por el Titular.
                </li>
                <br />
                <li>
                  Según se considere necesario, para que la Compañía proteja sus
                  derechos legales y su propiedad, así como para proteger a
                  otros usuarios de la Plataforma o terceros, o evitar una
                  lesión personal o pérdida.
                </li>
                <br />
                <li>
                  Generar clientes potenciales, identificar el interés
                  particular que un cliente o potencial cliente tenga en
                  nuestros productos, soluciones y servicios.
                </li>
                <br />
                <li>
                  Invitar a los Titulares a realizar comentarios o participar en
                  encuestas de clientes, comprender mejor la naturaleza y
                  calidad de la provisión de los servicios de la Compañía, así
                  como para mejorar y desarrollar productos, servicios y
                  soluciones.
                </li>
                <br />
                <li>
                  Compartir la información con proveedores de servicios y
                  subcontratistas seleccionados que brindan servicios a la
                  Compañía o los prestan en su nombre, como sitios de
                  alojamiento web, envío de información, realización de
                  encuestas, prestación de servicios de tecnología, análisis de
                  datos, organización de eventos y otros servicios
                  profesionales. Los proveedores de servicios solo reciben los
                  datos personales que necesitan para brindar sus servicios a la
                  Compañía o en su nombre.
                </li>
                <br />
                <li>
                  Compartir la información con proveedores de servicios y
                  subcontratistas seleccionados que brindan servicios a la
                  Compañía o en su nombre, como servicios de marketing y
                  publicidad, que incluyen el telemarketing, a quienes se les
                  brinda información, como por ejemplo, información recolectada
                  sobre los usuarios mediante las Cookies para generar y brindar
                  publicidad de productos, servicios y soluciones más relevantes
                  y útiles.
                </li>
                <br />
                <li>
                  Compartir la información con autoridades gubernamentales o
                  entidades reguladoras según lo requiera la ley aplicable o de
                  acuerdo con una orden administrativa, de un tribunal o
                  similar.
                </li>
                <br />
                <li>
                  Cualquier otro uso que se enmarque en las finalidades ya
                  expuestas y que se relacione con el objeto social de la
                  Compañía y su actividad.
                </li>
              </ul>
              <br />
              <li>
                <b>
                  21.0 Abstención de Tratamiento de Datos referidos a
                  antecedentes penales.
                </b>{" "}
                Techfrenetic se abstendrá de realizar el tratamiento de Datos
                Personales que concierna a los antecedentes penales de las
                personas. No obstante, podrá verificar las bases datos públicas,
                donde consten este tipo información, con el único y exclusivo
                fin de garantizar legalidad de sus operaciones y la seguridad de
                la compañía y sus empleados.
              </li>
              <br />
              <li>
                <b>
                  22.0 Medidas de seguridad para la protección de los datos
                  personales y otra información.
                </b>{" "}
                Las medidas de seguridad con las que cuenta la Compañía buscan
                proteger los datos de los Titulares en aras de impedir su
                adulteración, pérdida, usos y accesos no autorizados. Para ello,
                la Compañía de forma diligente implementa medidas de protección
                humanas, administrativas y técnicas que razonablemente están a
                su alcance. El Titular acepta expresamente esta forma de
                protección y declara que la considera conveniente y suficiente
                para todos los propósitos.
              </li>
              <br />
              <li>
                <b>
                  23.0 Política para la eliminación y/o f de Datos Personales.
                </b>
                Techfrenetic en virtud de los principios de finalidad,
                necesidad, proporcionalidad, y temporalidad, realizará el
                Tratamiento de Datos Personales de los Titulares. Una vez,
                cumplido con la finalidad de dicho Tratamiento y viéndolo
                necesario, procederá a la supresión o eliminación de los datos
                de los Titulares, conforme a los siguientes parámetros:
              </li>
              <br />
              <ul>
                <li>
                  i. Techfrenetic evaluará semestralmente la información de los
                  Titulares, y de acuerdo con sus características y su estado,
                  procederá a elegir aquellos Datos que considere a su
                  discreción que deben ser suprimidos o eliminados. Para los
                  efectos, tendrá en cuenta si existen derechos en cabeza de los
                  Titulares que impidan la supresión de los Datos o si existen
                  deberes legales, contractuales o constitucionales que impidan
                  la eliminación.{" "}
                </li>
                <br />
                <li>
                  ii. Techfrenetic informará previamente a los Titulares la
                  decisión y el fundamento de la supresión de sus Datos
                  Personales quienes, mediante los canales habilitados, podrán
                  ejercer su derecho a interponer Consultas, peticiones o
                  Reclamos respecto de dicha decisión dentro de los quince (15)
                  días hábiles siguientes. Las Consultas, peticiones o Reclamos
                  seguirán los procedimientos establecidos en esta Política. En
                  el evento que no haya Reclamo o petición alguna en relación
                  con la decisión se procederá a la supresión.{" "}
                </li>
                <br />
                <li>
                  iii. Techfrenetic elevará las actas y registros que
                  correspondan respecto de los procesos de supresión y
                  eliminación de los Datos Personales de los Titulares, para los
                  efectos de trazabilidad del procedimiento.
                </li>
              </ul>
              <br />
              <li>
                <b>24.0 Aviso de Privacidad.</b> Mediante el Aviso de Privacidad
                se informa al Titular, la información relativa a la existencia
                de las políticas y procedimientos de Tratamiento de Datos
                Personales contenida en esta Política, así como las
                características del Tratamiento que se le dará a los Datos
                Personales, y contendrá, cuando menos, la siguiente información:
                (i) identidad, domicilio y datos de contacto del Responsable del
                Tratamiento; (ii) tipo de Tratamiento al cual serán sometidos
                los Datos Personales y su finalidad; y (iii) los mecanismos
                generales dispuestos por el Responsable para que el Titular
                conozca la Política de Tratamiento y los mecanismos para la
                consulta de los Datos Personales del Titular.
              </li>
              <br />
              <p>
                Techfrenetic conservará copia del modelo de Aviso de Privacidad
                que se transmite a los Titulares mientras se lleve a cabo el
                Tratamiento de los Datos Personales y perduren las obligaciones
                que de éste deriven. Para el almacenamiento del modelo,
                Techfrenetic o quien éste designe podrá emplear medios
                informáticos, electrónicos o cualquier otra tecnología. El Aviso
                de Privacidad de Tratamiento de los Datos Personales está para
                la disposición y consulta a través de la Página Web de La
                Plataforma.
              </p>
              <br />
              <li>
                <b>25.0 Uso de Cookies.</b> El Titular acepta que Techfrenetic
                puede hacer uso de cookies y otras tecnologías similares, tanto
                en la Plataforma, sus contenidos, servicios, como en los correos
                electrónicos enviados a los Titulares, con la finalidad de,
                entre otras, propósitos de autenticación, registrar las
                actividades en la Plataforma, mejorar la funcionalidad de la
                misma, optimizar los productos o servicios ofrecidos, analizar
                tendencias del mercado, analizar la información demográfica de
                quienes visitan la Plataforma y hacen uso de los servicios que
                la mismo ofrece, evaluar la eficacia de la publicidad del mismo,
                comportamiento de consumo de los usuarios de la Plataforma y
                resultado de las actividades allí ejecutadas, determinar quién
                ha abierto el correo electrónico enviado y el formato en que lo
                hace. Estas herramientas permiten la obtención de, entre otras,
                información referente al tipo de navegador y el sistema
                operativo que se utiliza, la dirección IP, el tiempo que se
                permanece en la Plataforma y el número de visitas realizadas a
                la Plataforma, el uso de la misma, entre otros.{" "}
              </li>
              <br />
              <p>
                Los Titulares puede configurar su navegador para deshabilitar y
                eliminar las cookies, en cuyo caso, a pesar de que se podrá
                continuar visitando la Plataforma, el acceso a determinadas
                características de la Plataforma se pueden restringirse.{" "}
              </p>
              <br />
              <li>
                <b>26.0 Tratamiento de Datos Personales automatizado.</b>{" "}
                Techfrenetic podrá realizar el Tratamiento de Datos Personales
                de forma automatizada, por lo que, podría llegar a utilizar
                aplicativos de inteligencia artificial, con el fin de lograr la
                eficiencia en sus procesos.{" "}
              </li>
              <br />
              <p>
                No obstante, los Datos Personales que son sometidos a un proceso
                de automatización, no se utilizan para la toma decisiones
                individuales sobre los derechos de las personas, y no son el
                único referente para decisiones que rigen el Tratamiento de
                Datos Personales de la compañía.
              </p>
              <p>
                {" "}
                En cualquier caso, en el evento en que se realicen estudios de
                Perfilamiento que eventualmente puedan llevar a afectar a los
                Titulares, Techfrenetic informará a los Titulares y garantizará
                su derecho a la objeción frente al Tratamiento en mención y sus
                resultados.
              </p>
              <br />
              <br />
              <h3>
                CAPÍTULO VI DERECHOS DE LOS TITULARES, PROCEDIMIENTO PARA
                EJERCERLOS E INSTANCIAS DE ATENCIÓN A LOS TITULARES
              </h3>
              <br />
              <li>
                <b>27.0 Derecho de los Titulares en general.</b> Los Titulares
                tienen el derecho de conocer, actualizar, rectificar su
                información, y/o revocar la autorización para su tratamiento. En
                particular, son derechos de los Titulares según se establece en
                el artículo 8 de la Ley 1581 de 2012, los siguientes:
              </li>
              <ul>
                <li>
                  Conocer, actualizar y rectificar sus datos personales. Para el
                  efecto es necesario establecer previamente la identificación
                  de la persona para evitar que terceros no autorizados accedan
                  a los datos del Titular.
                </li>
                <br />
                <li>
                  Solicitar prueba de la autorización otorgada, salvo que se
                  trate de uno de los casos en los que no es necesaria la
                  autorización, de conformidad con lo previsto en el artículo 10
                  de la ley 1581 de 2012.
                </li>
                <br />
                <li>
                  Ser informado, previa solicitud, respecto del uso que les ha
                  dado a sus datos personales.
                </li>
                <br />
                <li>
                  Presentar ante la Superintendencia de Industria y Comercio
                  quejas por infracciones a lo dispuesto en la ley y demás
                  normas que la modifiquen, adicionen o complementen.
                </li>
                <br />
                <li>
                  Revocar la autorización y/o solicitar la supresión del dato.
                </li>
                <br />
                <li>
                  Acceder en forma gratuita a sus datos personales que hayan
                  sido objeto de Tratamiento.
                </li>
                <br />
                <li>
                  Mover, copiar, o transferir datos personales de un controlador
                  a otro, de una forma segura, en un formato legible y
                  comúnmente usado (derecho de portabilidad).
                </li>
                <br />
                <li>
                  Abstenerse de responder las preguntas sobre datos sensibles o
                  sobre datos de las niñas, niños y adolescentes. En este caso,
                  se comunica a los Titulares, o sus respectivos representantes
                  legales, que no están obligados a proveer la autorización para
                  el tratamiento de datos sensibles o datos de niños, niñas y
                  adolescentes.
                </li>
              </ul>
              <br />
              <p>
                La solicitud de supresión de la información y la revocatoria de
                la Autorización no procederá cuando el Titular tenga un deber
                legal o contractual de permanecer en la Base de Datos del
                Responsable.
              </p>
              <br />
              <li>
                <b>28.0 Procedimiento para ejercer sus derechos.</b> En caso de
                que el Titular desee ejercer sus derechos, deberá enviar un
                correo electrónico a santiago@techfrenetic.com, el cual será
                atendido por el área de protección de datos de la Compañía.
                Dicho correo deberá estar dirigido a Techfrenetic S.A.S. y se
                deberá especificar: (i) nombre e identificación del titular o la
                persona legitimada; (ii) descripción precisa y completa de los
                hechos que dan lugar al reclamo; (iii) pretensiones, (iv)
                dirección física o electrónica para remitir la respuesta; y (v)
                documentos y demás pruebas pertinentes que quiera hacer valer.
              </li>
              <br />
              <p>
                El procedimiento que se seguirá para dicha comunicación, será el
                que se indica a continuación:
              </p>
              <ul>
                <li>
                  Cuando el Titular de los datos o sus causahabientes deseen
                  consultar la información que reposa en la base de datos, la
                  Compañía responderá la solicitud en plazo de máximo diez (10)
                  días. En cumplimiento de lo dispuesto en la Ley 1581 de 2012,
                  cuando no fuere posible atender la consulta dentro de dicho
                  término, se informará a los Titulares, se le expresará los
                  motivos de la demora y se le señalará la fecha en que se
                  atenderá su consulta, la cual no podrá superar los cinco (5)
                  días hábiles siguientes al vencimiento del primer término.
                </li>
                <br />
                <li>
                  Cuando el Titular o sus causahabientes consideren que la
                  información contenida en las bases de datos debe ser objeto de
                  corrección, actualización o supresión, o cuando adviertan el
                  presunto incumplimiento de cualquiera de los deberes
                  contenidos en la Ley 1581 de 2012, podrán presentar un reclamo
                  ante la Compañía, el cual será tramitado bajo las siguientes
                  reglas:{" "}
                </li>
                <br />
                <ul>
                  <li>
                    El reclamo se formulará mediante solicitud dirigida a la
                    Compañía con la identificación de los Titulares, la
                    descripción de los hechos que dan lugar al reclamo, la
                    dirección, y se anexarán los documentos que se quieran hacer
                    valer. Si el reclamo resulta incompleto, la Compañía podrá
                    requerir al interesado dentro de los cinco (5) días
                    siguientes a la recepción del reclamo para que subsane las
                    fallas. Transcurridos dos (2) meses desde la fecha del
                    requerimiento, sin que el solicitante presente la
                    información requerida, se entenderá que ha desistido del
                    reclamo.
                  </li>
                  <br />
                  <li>
                    En caso de que la Compañía no sea competente para resolver
                    el reclamo, dará traslado a quien corresponda en un término
                    máximo de dos (2) días hábiles e informará de la situación
                    al Titular, con lo cual quedará relevada de cualquier
                    reclamación o responsabilidad por el uso, rectificación o
                    supresión de los datos.
                  </li>
                  <br />
                  <li>
                    Una vez recibido el reclamo completo, se incluirá en la base
                    de datos una leyenda que diga "reclamo en trámite" y el
                    motivo del mismo, en un término no mayor a dos (2) días
                    hábiles. Dicha leyenda deberá mantenerse hasta que el
                    reclamo sea decidido.
                  </li>
                  <br />
                  <li>
                    El término máximo para atender el reclamo será de quince
                    (15) días hábiles contados a partir del día siguiente a la
                    fecha de su recibo. Cuando no fuere posible atender el
                    reclamo dentro de dicho término, se informará al Titular los
                    motivos de la demora y la fecha en que se atenderá su
                    reclamo, la cual en ningún caso podrá superar los ocho (8)
                    días hábiles siguientes al vencimiento del primer término.
                    El retiro o supresión no procederá cuando exista un deber
                    contractual de permanecer en la base de datos de
                    Techfrenetic.
                  </li>
                </ul>
              </ul>
              <br />
              <li>
                <b>
                  29.0 Quejas ante la Superintendencia de Industria y Comercio:
                </b>{" "}
                El Titular, sus causahabientes o apoderados, deberán agotar el
                trámite de consulta ante Techfrenetic o quien éste designe, con
                anterioridad a la presentación de cualquier queja ante la
                Superintendencia de Industria y Comercio como Requisito de
                Procedibilidad.
              </li>
              <br />
              <li>
                <b>
                  30.0 Deberes de Techfrenetic en su calidad de Responsable del
                  Tratamiento de Datos Personales.
                </b>{" "}
                Techfrenetic está obligada a cumplir los deberes impuestos por
                la ley. Por ende, debe obrar de tal forma que cumpla con los
                siguientes deberes:
              </li>
              <ul>
                <li>
                  Garantizar al Titular, en todo tiempo, el pleno y efectivo
                  ejercicio de los derechos mencionados en esta Política.
                </li>
                <br />
                <li>
                  Observar los principios establecidos en esta política en el
                  Tratamiento de Datos Personales.
                </li>
                <br />
                <li>
                  Conservar la información bajo las condiciones de seguridad
                  necesarias para impedir su adulteración, pérdida, consulta,
                  uso o acceso no autorizado o fraudulento.
                </li>
                <br />
                <li>Actualizar la información cuando sea necesario.</li>
                <br />
                <li>
                  Rectificar los Datos Personales cuando ello sea procedente.
                </li>
                <br />
                <li>
                  Suministrar al Encargado del Tratamiento únicamente los Datos
                  Personales cuyo Tratamiento esté previamente autorizado.
                </li>
                <br />
                <li>
                  Garantizar que la información que se suministre al Encargado
                  del Tratamiento sea veraz, completa, exacta, actualizada,
                  comprobable y comprensible.
                </li>
                <br />
                <li>
                  Comunicar de forma oportuna al Encargado del Tratamiento,
                  todas las novedades respecto de los datos que previamente le
                  haya suministrado y adoptar las demás medidas necesarias para
                  que la información suministrada a este se mantenga
                  actualizada.
                </li>
                <br />
                <li>
                  Informar de manera oportuna al Encargado del Tratamiento las
                  rectificaciones realizadas sobre los Datos Personales para que
                  éste proceda a realizar los ajustes pertinentes.
                </li>
                <br />
                <li>
                  Exigir al Encargado del Tratamiento en todo momento, el
                  respeto a las condiciones de seguridad y privacidad de la
                  información del Titular.f
                </li>
                <br />
                <li>
                  Informar al Encargado del Tratamiento cuando determinada
                  información se encuentra en discusión por parte del Titular,
                  una vez se haya presentado la reclamación y no haya finalizado
                  el trámite respectivo.
                </li>
                <br />
                <li>
                  Informar a la Superintendencia de Industria y Comercio cuando
                  se presenten violaciones a los códigos de seguridad y existan
                  riesgos en la administración de la información de los
                  Titulares.
                </li>
                <br />
                <li>
                  Cumplir las instrucciones y requerimientos que imparta la
                  Superintendencia de Industria y Comercio.
                </li>
              </ul>
              <br />
              <br />
              <h3>CAPÍTULO VII MISCÉLANEOS</h3>
              <br />
              <li>
                <b>31.0 Contacto.</b> Cualquier duda o información adicional
                será recibida y tramitada mediante su envío a las direcciones de
                contacto establecidas en la presente Política.
              </li>
              <br />
              <li>
                <b>
                  32.0 Cambios en la política de tratamiento y protección de
                  datos personales.
                </b>{" "}
                Cualquier cambio sustancial en la Política, será comunicado
                oportunamente a los Titulares mediante la publicación en los
                portales web de la Compañía.
              </li>
              <br />
              <li>
                <b>
                  33.0 Confidencialidad, derechos fundamentales y anonimización
                  de los Datos Personales.
                </b>{" "}
                Techfrenetic es respetuoso de los derechos fundamentales de a la
                intimidad, a la libertad de expresión y al buen nombre y honra
                de los Titulares de sus Bases de Datos, razón por la cual, para
                los efectos de garantizar tales derechos, tomará las medidas que
                considere pertinentes en materia de confidencialidad, tales como
                las siguientes:{" "}
              </li>
              <br />
              <ul>
                <li>
                  i. Se abstendrá de divulgar parcial o totalmente la
                  información confidencial recibida a cualquier persona natural
                  o jurídica, entidades gubernamentales o privadas, que no haya
                  sido autorizada previamente.{" "}
                </li>
                <br />
                <li>
                  ii. Se abstendrá de utilizar, explotar, emplear, publicar o
                  divulgar la información confidencial en una forma diferente a
                  la autorizada en la presente Política.{" "}
                </li>
                <br />
                <li>
                  iii. Instruirá a todas aquellas personas que eventualmente
                  tengan acceso a la información confidencial sobre la forma de
                  manipulación, utilización, manejo de medidas de seguridad y
                  demás, para que dicha información permanezca con el debido
                  manejo y protegida.
                </li>
                <br />
                <li>
                  iv. El uso de la información confidencial se realizar
                  observando los principios de la ética y buenas prácticas en
                  materia de protección de Datos Personales. Para ello,
                  Techfrenetic manifiesta expresamente que emplea todos los
                  medios a su alcance para impedir esa utilización irregular o
                  no autorizada de la información confidencial.
                </li>
                <br />
                <li>
                  v. Techfrenetic realizará la anonimización de los datos
                  personales de los Titulares, cuando considere que pueden
                  afectar su derecho a la intimidad, buen nombre y honra. En
                  todo caso, Techfrenetic, a petición los Titulares y
                  presentándose las razones para ello, realizará la
                  correspondiente anonimización de cualquier Dato Personal.
                </li>
                <br />
                <li>
                  vi. Techfrenetic no censurara la información personal de los
                  Titulares, siempre y cuando, este dentro del marco de las
                  buenas costumbres, la ley y la constitución, garantizando el
                  derecho fundamental de los Titulares a su libertad de
                  expresión.
                </li>
              </ul>
              <br />
              <li>
                <b>
                  34.0 Condiciones a las cuales se someten los datos sensibles.
                </b>{" "}
                La Compañía aplicará las medidas necesarias para realizar el
                tratamiento de datos sensibles de los titulares conforme a lo
                estipulado en la Ley 1581 de 2012, razón por la cual se
                abstendrá de realizar el tratamiento de datos personales que no
                este autorizado de manera expresa por parte del Titular.
                Igualmente, implementara las medidas de seguridad y de
                confidencialidad necesarias para evitar cualquier incidente y/o
                violación a dicha información. El Tratamiento será excepcional y
                de acuerdo con el tratamiento y las finalidades estipuladas en
                la autorización cualificada y de conformidad con el principio de
                Responsabilidad Demostrada. En cualquier caso, el titular deberá
                implementar la diligencia necesaria para mantener la reserva y
                seguridad de su información personal sensible.
              </li>
              <br />
              <li>
                <b>35.0 Políticas de veracidad y calidad de la información.</b>{" "}
                En desarrollo del principio de veracidad y calidad de la
                información, La Compañía tomará las medidas necesarias para que
                la información que conste en sus bases de datos, sea cierta,
                completa, exacta, actualizada, comprobable y comprensible. Por
                tal razón, solicita a los Titulares la información que sea
                necesaria e integral para las finalidades del tratamiento
                respectivo. Igualmente, solicitara los soportes necesarios, para
                que la información de sus bases de datos sea comprobable y
                cierta. Para los efectos los efectos anteriores, La Compañía
                implementara las siguientes medidas.
              </li>
              <br />
              <ul>
                <li>
                  i. Tomará todas las medidas para que, en la recolección de la
                  información de los titulares, se obtenga información completa,
                  exacta, actualizada, comprobable y comprensible. En tal
                  sentido, implementara medidas de debida diligencia en los
                  medios de recolección de datos para tales fines.
                </li>
                <br />
                <li>
                  ii. Ofrecerá formularios de actualización semestralmente a los
                  titulares, para que actualicen sus datos personales.
                </li>
                <br />
                <li>
                  iii. Se abstendrá de realizar el tratamiento de datos
                  personales de los titulares, con información parcial,
                  incompleta, fraccionada o confusa.
                </li>
                <br />
                <li>
                  iv. Verificará la identidad de los titulares y de la
                  información que le sea suministrada, solicitando los
                  documentos de acreditación que sean necesarios en cada caso
                  particular, pudiendo solicitar entre otros, los siguientes:
                  Cédula de ciudadanía, Rut, antecedentes judiciales y de
                  policía, historial crediticio, certificaciones de entidades
                  privadas y/o públicas.
                </li>
                <br />
                <li>
                  v. La Compañía realizara auditorias semestrales sobre la
                  veracidad y calidad de su información, de encontrar
                  irregularidad y/o falencias en la misma, requerirá a los
                  titulares que suministren la información y soportes que sean
                  necesarios.
                </li>
              </ul>
              <br />
              <li>
                <b>36.0 Integralidad.</b> Se entienden incorporadas a esta Política
                todas aquellas políticas corporativas de la Compañía que se
                relacionen con la misma.{" "}
              </li>
            </ul>
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default PoliticaPagina;
