import React from "react";
import { useTranslation } from "react-i18next";
import ico_create from "../../_images/ico-create.svg";
import { Link } from "@reach/router";
import { connect } from "react-redux";
import { modalActions } from "../../_actions";

const ProfileMenu = ({ selected, showCreateContent }) => {
  const { i18n, t } = useTranslation("common");
  const lang = i18n.language;

  const handleShowShare = (e) => {
    e.preventDefault();
    showCreateContent();
  };

  return (
    <div className="profile__menu sticky col-lg-2 col-md-3">
      <div className="tabs__container">
        <ul className="profile__tabs nav draggable" role="tablist">
          <li
            role="presentation"
            className={`${selected === "profile" ? "active" : ""}`}
          >
            <Link
              to={`/${lang}/${t("url.my_profile")}`}
              aria-controls="profile"
              role="tab"
              data-toggle="tab"
            >
              <span>{t("menus.my_profile")}</span>
            </Link>
          </li>
          <li
            role="presentation"
            className={`${selected === "content" ? "active" : ""}`}
          >
            <Link
              to={`/${lang}/${t("url.my_content")}`}
              aria-controls="content"
              role="tab"
              data-toggle="tab"
            >
              <span>{t("menus.my_content")}</span>
            </Link>
          </li>
          <li
            role="presentation"
            className={`${selected === "activity" ? "active" : ""}`}
          >
            <Link
              to={`/${lang}/${t("url.my_activity")}`}
              aria-controls="activity"
              role="tab"
              data-toggle="tab"
            >
              <span>{t("menus.my_activity")}</span>
            </Link>
          </li>
          <li
            role="presentation"
            className={`${selected === "article" ? "active" : ""}`}
          >
            <Link
              to={`/${lang}/${t("url.saved_articles")}`}
              aria-controls="profile"
              role="tab"
              data-toggle="tab"
            >
              <span>{t("menus.saved_articles")}</span>
            </Link>
          </li>
          <li
            role="presentation"
            className={`${selected === "account" ? "active" : ""}`}
          >
            <Link
              to={`/${lang}/${t("url.my_account")}`}
              aria-controls="profile"
              role="tab"
              data-toggle="tab"
            >
              <span>{t("menus.my_account")}</span>
            </Link>
          </li>
        </ul>
      </div>
      <div className="add__container">
        <a
          className="add__content"
          href="/"
          itemProp="name"
          onClick={handleShowShare}
        >
          <div className="button__create">
            <img src={ico_create} alt="Create content" />
          </div>
          <span>{t("menus.create_content")}</span>
        </a>
      </div>
    </div>
  );
};

function mapState(state) {
  const { create } = state.modals;
  return { create };
}

const actionCreators = {
  showCreateContent: modalActions.showCreateContent,
};

const connectedProfileMenu = connect(mapState, actionCreators)(ProfileMenu);
export { connectedProfileMenu as ProfileMenu };
