import React, { useState, useEffect } from "react";
import { Link } from "@reach/router";
import { useTranslation } from "react-i18next";
import { userService } from "../../_services";
import { path } from "../../_helpers";
import { FollowButton } from "../buttons/follow-button";


export default function AuthorFollowing({ id, name, info, label, onData }) {
  //Language hooks
  const { i18n, t } = useTranslation("common");
  const lang = i18n.language;
  const [user, setUser] = useState({});
  const [setSiddenInfo] = useState(true);
  const [setLabelVisibility] = useState(true);

  useEffect(() => {
    /** Call Services  */
    if (id) {
      userService
        .summary(lang, id)
        .then((res) => res.json())
        .then((response) => {
          const user = response[0];
          if (user) {
            name = user.display_name;
            setUser(user);
            if (onData) {
              onData(user);
            }
          }
        })
        .catch(console.log);
    }

    if (info === false) {
      setSiddenInfo(info);
    }

    if (label === false) {
      setLabelVisibility(label);
    }
  }, [lang, id, name, info, label, setUser, setSiddenInfo, setLabelVisibility]);

  
  return (
    <>
      <div className="profile__btn__content content-general__follower">
        <div className="user__info__container">
          <Link
            to={`/${lang}/${t("home.profile")}/${String(
              id
            ).toLocaleLowerCase()}`}
            className="profile__btn user"
          >
            {user.use_avatar === "True" ? (
              <img src={`/images/${user.avatar}.svg`} alt={name} />
            ) : (
              <img src={path(user.picture)} alt={name} />
            )}
          </Link>
          <div className="user__details">
            <div className="name__follow">
              <span className="user__name">{name}</span>
              <div className="follow__btn__content">
                <span>&bull;</span>
                <FollowButton
                  uid={user.id}
                  nick={user.name}
                  className="btn btn-primary sign__header"
                />
              </div>
            </div>
            <span className="user__profile">@{id}</span>
          </div>
        </div>
      </div>
    </>
  );
}
