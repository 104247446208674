import React from "react";

export const Facebook = ({ link }) => {
  return (
    <a
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      className="social__btn facebook"
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
        <circle className="st0" cx="18" cy="18" r="17.5" />
        <path
          className="st1"
          d="M18.9 15.7v-1.8c0-0.5 0.4-0.9 0.9-0.9h0.9v-2.3h-1.8c-1.5 0-2.7 1.2-2.7 2.7v2.3h-1.8V18h1.8v7.2h2.7V18h1.8l0.9-2.3H18.9z"
        />
      </svg>
    </a>
  );
};
