import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import common_es from "./es/common.json";
import common_en from "./en/common.json";

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    detection: {
      // order and from where user language should be detected
      order: [
        "path",
        "querystring",
        "cookie",
        "localStorage",
        "sessionStorage",
      ],

      // keys or params to lookup language from
      lookupQuerystring: "lng",
      lookupCookie: "i18next",
      lookupLocalStorage: "i18nextLng",
      lookupFromPathIndex: 0,
      lookupFromSubdomainIndex: 0,

      // cache user language on
      caches: ["localStorage", "cookie"],
      excludeCacheFor: ["cimode"], // languages to not persist (cookie, localStorage)
    },
    interpolation: { escapeValue: false }, // React already does escaping
    fallbackLng: "en", // use en if detected lng is not available
    resources: {
      en: {
        common: common_en, // 'common' is our custom namespace
      },
      es: {
        common: common_es,
      },
    },
  });

export default i18n;
