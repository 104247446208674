import React from "react";
import FormValidator from "../../_helpers/form-validator";
import { Spinner } from "../ui/spinner";
import { withTranslation } from "react-i18next";
import { appConstants } from "../../_constants";
import { Alert } from "react-bootstrap";
// or
import check from "../../_images/Vector.svg";

class PasswordForm extends FormValidator {
  constructor(props) {
    super(props);

    this.state = {
      elements: {
        temporarypassword: {
          value: "",
          error: false,
          message: "",
          visible: false,
        },
        currentpassword: {
          value: "",
          error: false,
          message: "",
          visible: false,
        },
        password: {
          value: "",
          error: false,
          message: "",
          visible: false,
          criterias: {
            minlen: "",
            lowcaps: "",
            numbers: "",
          },
        },
        repassword: {
          value: "",
          error: false,
          message: "",
          visible: false,
        },
      },
      showing: false,
      buttonPass: true,
      alertChange: false,
      message: "",
    };

    this.submitHandler = this.submitHandler.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.getPassword = this.getPassword.bind(this);
    this.changePassword = this.changePassword.bind(this);
    this.viewPassword = this.viewPassword.bind(this);
    this.viewPassword2 = this.viewPassword2.bind(this);
    this.viewPassword3 = this.viewPassword3.bind(this);

    // My changes
    this.temporalPass = this.temporalPass.bind(this);
  }

  submitHandler = async (e) => {
    // e.preventDefault();

    const isValid = this.validate(this.state.elements, [
      {
        field: "temporarypassword",
        type: "required",
        message: "errors.password_required",
      },
      {
        field: "password",
        type: "required",
        message: "errors.password_required",
      },
      {
        field: "password",
        type: "min:8",
        message: "errors.password_minlength",
      },
      {
        field: "password",
        type: "hasnum",
        message: "errors.password_hasnum",
      },
      {
        field: "password",
        type: "lowercaps",
        message: "errors.password_lowercaps",
      },
    ]);

    if (isValid) {
      // const { temporarypassword, password } = this.state.elements;
      // const lang = this.props.i18n.language;

      const that = this;

      let token = this.props.token;
      // let tempass = this.state.elements.temporarypassword.value;

      let user = {
        name: this.props.name,
        temp_pass: this.state.elements.temporarypassword.value,
        new_pass: this.state.elements.password.value,
      };

      await fetch(`${appConstants.API}/user/lost-password-reset?_format=json`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token,
        },
        body: JSON.stringify(user),
      })
        .then(async (res) => {
          const text = res.status;

          if (text === 500) {
            that.setState({
              showing: false,
              buttonPass: true,
            });
          }
        })
        .catch((err) => console.log(err));
    }
  };

  changePassword(e) {
    const value = e.target.value;
    const input = this.state.elements.password;
    let { criterias } = input;

    criterias.minlen = value.length >= 8;
    criterias.lowcaps = /^(?=.*[a-z])(?=.*[A-Z])/.test(value);
    criterias.numbers = /\d/.test(value);

    this.changeHandler(e);
  }

  handleChangeType(e) {
    const input = this.state.elements.type;
    input.value = e.target.value;

    this.setState({ elements: this.state.elements });
  }

  changeHandler(e) {
    const input = this.state.elements[e.target.name];
    input.value = e.target.value;
    input.error = false;

    this.setState({ elements: this.state.elements });
  }

  getPassword(e) {
    const input = this.state.elements.temporarypassword;
    const value = e.target.value;
    input.value = value;
    input.error = false;

    this.setState({ elements: this.state.elements });
  }

  viewPassword = (e) => {
    e.preventDefault();
    this.state.elements.temporarypassword.visible =
      !this.state.elements.temporarypassword.visible;
    this.setState({ elements: this.state.elements });
  };

  viewPassword2 = (e) => {
    e.preventDefault();
    this.state.elements.password.visible =
      !this.state.elements.password.visible;
    this.setState({ elements: this.state.elements });
  };

  viewPassword3 = (e) => {
    e.preventDefault();
    this.state.elements.repassword.visible =
      !this.state.elements.repassword.visible;
    this.setState({ elements: this.state.elements });
  };

  temporalPass = async () => {
    const that = this;
    let token = this.props.token;

    await fetch(`${appConstants.API}/user/lost-password?_format=json`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({ mail: this.props.email }),
    })
      .then(async function (response) {
        return response.text().then(function (text) {
          const msg = JSON.parse(text);
          const main = msg.message;
          that.setState({ message: main, alertChange: true });
        });
      })
      .catch((err) => console.log(err));
  };

  render() {
    const { t } = this.props;

    const { temporarypassword, password } = this.state.elements;

    const { showing, buttonPass, alertChange } = this.state;

    let pass_minlen = "";
    let pass_lowcaps = "";
    let pass_numbers = "";

    if (password.criterias.minlen === true) {
      pass_minlen = "validate";
    } else if (password.criterias.minlen === false) {
      pass_minlen = "error";
    }

    if (password.criterias.lowcaps === true) {
      pass_lowcaps = "validate";
    } else if (password.criterias.lowcaps === false) {
      pass_lowcaps = "error";
    }

    if (password.criterias.numbers === true) {
      pass_numbers = "validate";
    } else if (password.criterias.numbers === false) {
      pass_numbers = "error";
    }

    return (
      <div className="password__form">
        <h4 className="account__title">{t("forms.password")}</h4>
        <div className="last__p__change">
          <span>Last changed: February 27, 2020</span>{" "}
          {buttonPass ? (
            <button
              className="btn btn-secondary"
              onClick={() => {
                this.setState({ showing: !showing, buttonPass: !buttonPass });
                this.temporalPass();
              }}
            >
              {t("forms.change-password")}
            </button>
          ) : null}
        </div>
        {showing ? (
          <div className="profile__form profile__form--password">
            {!alertChange ? (
              <Spinner />
            ) : (
              <Alert>
                <img src={check} alt="Icon" />
                <p>{this.state.message}</p>
              </Alert>
            )}

            <form className="form-signin" onSubmit={this.submitHandler}>
              {
                /* Error */
                this.props.problem && (
                  <div className={`form-label-group`}>
                    <div className="alert alert-danger text-center">
                      {t(`errors.${this.props.problem}`)}
                    </div>
                  </div>
                )
              }

              {/* Temporary Password */}
              <div
                className={`form-label-group new__password confirmation1 ${
                  temporarypassword.error ? "error" : ""
                }`}
              >
                <label htmlFor="temporarypassword">
                  {" "}
                  {t("forms.temporary-password")}
                </label>
                <input
                  type={temporarypassword.visible ? "text" : "password"}
                  id="temporarypassword"
                  name="temporarypassword"
                  className="form-control"
                  placeholder={t("register.form1_temporarypassword")}
                  required=""
                  onChange={this.getPassword}
                  value={temporarypassword.value}
                  autoComplete="off"
                />
                <div className="password__toggle">
                  <a
                    id="temporarypassword__temporary"
                    href="/"
                    className="enable"
                    onClick={this.viewPassword}
                    tabIndex="-1"
                  >
                    {""}
                  </a>
                </div>
                {temporarypassword.error && (
                  <small id="passlHelp" className="form-text text-muted">
                    {t(temporarypassword.message)}
                  </small>
                )}
              </div>

              {/* New Password */}
              <div
                className={`form-label-group new__password confirmation1 ${
                  password.error ? "error" : ""
                }`}
              >
                <label htmlFor="inputPassword">
                  {" "}
                  {t("forms.new-password")}
                </label>
                <input
                  type={password.visible ? "text" : "password"}
                  id="password"
                  name="password"
                  className="form-control"
                  placeholder={t("register.form1_password")}
                  required=""
                  onChange={this.changePassword}
                  value={password.value}
                  maxLength="30"
                  autoComplete="off"
                />
                <div className="password__toggle">
                  <a
                    id="password__current"
                    href="/"
                    className="enable"
                    onClick={this.viewPassword2}
                    tabIndex="-1"
                  >
                    {""}
                  </a>
                </div>
                {password.error && (
                  <small id="passlHelp" className="form-text text-muted">
                    {t(password.message)}
                  </small>
                )}
              </div>

              {/* Rules for Password */}
              <div className="password__validator">
                <span className="paas__instructions">
                  {t("register.pwd_intro")}:
                </span>
                <div className="password__conditions">
                  <div className={`condition__pass ${pass_minlen}`}>
                    <i className="far fa-check-circle"></i>
                    <span>{t("register.pwd_minchars")}</span>
                  </div>
                  <div className={`condition__pass ${pass_lowcaps}`}>
                    <i className="far fa-check-circle"></i>
                    <span>{t("register.pwd_lowper")}</span>
                  </div>
                  <div className={`condition__pass ${pass_numbers}`}>
                    <i className="far fa-check-circle"></i>
                    <span>{t("register.pwd_number")}</span>
                  </div>
                  <div className="clearfix"></div>
                </div>
              </div>

              {/* Submit */}
              {this.props.loggingIn ? (
                <Spinner />
              ) : (
                <div className="buttons">
                  <button className="btn btn-primary" type="submit">
                    {t("forms.save")}
                  </button>
                  <button
                    className="btn btn-secondary"
                    type="button"
                    onClick={() =>
                      this.setState({
                        showing: !showing,
                        buttonPass: !buttonPass,
                      })
                    }
                  >
                    {t("forms.cancel")}
                  </button>
                </div>
              )}
            </form>
          </div>
        ) : null}
      </div>
    );
  }
}

export default withTranslation("common")(PasswordForm);
