import React from "react";
import { Link } from "@reach/router";
import { useTranslation } from "react-i18next";
import arrow from "../../_images/cards-arrow.svg";

export default function EventFeatured({
  slug,
  img,
  title,
  location,
  startDate,
}) {
  const { t, i18n } = useTranslation("common");
  const lang = i18n.language;
  const formatSlug = slug.substring(8);
  const link = `/${lang}/${t("url.events")}/${formatSlug}`;

  return (
    <article itemScope="" itemType="http://schema.org/Article">
      <figure className="article__image__content">
        <Link to={link}>
          <img itemProp="image" src={img} alt={title} />
        </Link>
      </figure>
      <div className="article__information">
        <h2 itemProp="headline">
          <Link to={link}>{title}</Link>
        </h2>
        <div className="date__place">
          <span className="city">{location}</span>
          <div className="d-flex justify-content-between details__date">
            <div className="date">{startDate}</div>
            <div className="boton__date__cta">
              <Link className="card__url" to={link}>
                {t("events.view_more")}
                <span>
                  <img src={arrow} alt={t("events.go_to")} />
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
}
