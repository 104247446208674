import React from "react";
import Modal from "react-bootstrap/Modal";
import { path } from "../../_helpers";


export const ContactVendor = ({ vendor, visible, onHide }) => {
  //Language hooks

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <Modal show={visible} onHide={onHide} centered>
      <div
        id="_1"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="modalLabel_1"
        aria-hidden="true"
      >
        <div className="modal-dialog vendor__contact__modal" role="document">
          <div className="modal-content auto">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={onHide}
            >
              <span aria-hidden="true">×</span>
            </button>
            <div className="vendor__contact">
              <div className="partner__img d-flex align-items-center">
                <a href="http://">
                  <img src={path(vendor.logo)} alt="Cisco" />
                </a>
                <h2>
                  {vendor.name}
                  <span>{vendor.contact}</span>
                </h2>
              </div>
              <p className="main__message">
                Or complete this form to contact the appropiate sales person
                within this company.
              </p>
              <form className="form-signin" onSubmit={handleSubmit}>
                <div className="form-label-group half-l">
                  <input
                    type="text"
                    id="inputName"
                    className="form-control"
                    placeholder="Your name"
                    required=""
                    autoFocus=""
                  />
                  <label htmlFor="inputName">What´s your name?</label>
                </div>

                <div className="form-label-group half-r">
                  <input
                    type="text"
                    id="inputPhone"
                    className="form-control"
                    placeholder="Your phone"
                    required=""
                    autoFocus=""
                  />
                  <label htmlFor="inputPhone">What´s your phone number?</label>
                </div>

                <div className="form-label-group half-l">
                  <input
                    type="text"
                    id="inputCompeny"
                    className="form-control"
                    placeholder="Your company"
                    required=""
                    autoFocus=""
                  />
                  <label htmlFor="inputCompeny">Your company name</label>
                </div>

                <div className="form-label-group half-r">
                  <input
                    type="email"
                    id="inputEmail"
                    className="form-control"
                    placeholder="Your email"
                    required=""
                    autoFocus=""
                  />
                  <label htmlFor="inputEmail">Your company email</label>
                </div>

                <div className="form-label-group no__floating">
                  <select className="form-control" id="where">
                    <option>Your country</option>
                    <option>Madrid Madrid, Spain</option>
                    <option>Madras Hulu Medan, Indonesia</option>
                    <option>Cautro Vientos Airport Madrid, Spain</option>
                    <option>Madrod Morgan Hill, United States of…</option>
                  </select>
                </div>

                <div className="form-label-group no__floating">
                  <label htmlFor="inputDescription" className="no__floating">
                    Your Request
                  </label>
                  <textarea
                    id="inputDescription"
                    className="form-control"
                    placeholder="Write the details of your requirement"
                    required=""
                    autoFocus=""
                  ></textarea>
                </div>

                <a
                  href="http://"
                  className="btn btn-lg btn-primary btn-block"
                  type="submit"
                >
                  Send
                </a>
                <a href="http://" onClick={onHide}>
                  Cancel
                </a>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
