import React, { useEffect, useState } from "react";

import SEO from "../_components/common/seo";
import Layout from "../_components/common/layout";
import { useTranslation } from "react-i18next";
import FeaturedEvents from "../_components/lists/featured-events";
import UpcomingEvents from "../_components/lists/upcoming-events";
import RecentEvents from "../_components/lists/recent-events";
import EventBanner from "../_components/ui/event-banner";
import { EventsForm } from "../_components/forms/events-form";

import { eventService } from "../_services/event.service";

import * as moment from "moment";
import { path } from "../_helpers";

const EventsPage = () => {
  const [data, setData] = useState([]);
  const { i18n, t } = useTranslation("common");
  const lang = i18n.language;

  useEffect(() => {
    /** Call Services  */
    eventService
      .getEventsFeatured(lang)
      .then((res) => res.json())
      .then((response) => {
        const articleImportant = [response.shift()];
        setData(articleImportant);
      })
      .catch((error) => console.log(error));
  }, [lang, setData]);

  

  return (
    <Layout>
      <SEO lang={lang} title={t("events.title")} />
      <main
        role="main"
        itemScope=""
        itemProp="mainContentOfPage"
        itemType="http://schema.org/Blog"
      >
        {/* Beginning of top trending section */}
        {data.map((dataInportant) => (
          <EventBanner
            slug={dataInportant.field_path}
            key={dataInportant.field_name_evente + "banner"}
            img={path(dataInportant.field_image)}
            title={dataInportant.field_name_evente}
            location={dataInportant.field_location}
            startDate={moment(dataInportant.field_start_date).format(
              "MMM DD, YYYY"
            )}
          />
        ))}

        {/* Beginning feature section home */}
        <section className="featured__events no-bg">
          <FeaturedEvents />
        </section>

        {/* Search events */}
        <section className="event__search">
          <div className="container d-flex flex-sm-column flex-md-row flex-lg-row justify-content-center align-items-center">
            <span className="search__title">{t("events.search_label")}:</span>
            <EventsForm />
          </div>
        </section>

        {/* Upcomming events section */}
        <section className="featured__events no-bg">
          <UpcomingEvents />
        </section>

        {/* Past events section */}
        <section className="featured__events recent_events">
          <div className="container">
            <RecentEvents />
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default EventsPage;
