import React, { useState, useEffect } from "react";
import { Link } from "@reach/router";
import { useTranslation } from "react-i18next";
import { articleService } from "../../_services";
import { path } from "../../_helpers";
import he from "he";

import ico_player from "../../_images/ico-player.svg";
import { Level } from "../contents/level";

export const ArticleNext = ({ slug }) => {
  //Language hooks
  const { i18n, t } = useTranslation("common");
  const lang = i18n.language;

  const [loading, setLoading] = useState(true);
  const [article, setArticle] = useState({
    title: t("contents.loading"),
  });

  useEffect(() => {
    /** Call Services  */
    articleService
      .getInfoArticle(lang, slug)
      .then((res) => res.json())
      .then((response) => {
        if (response[0]) {
          setLoading(false);
          setArticle(response[0]);
        }
      })
      .catch(console.log);
  }, [lang, slug, setArticle]);

  return loading ? (
    ""
  ) : (
    <div className={`next__video__box ${article.level ? "" : "no_level"}`}>
      <article
        itemScope=""
        itemType="http://schema.org/Article"
        className="d-flex flex-column flex-sm-row flex-md-row flex-lg-row col-12 secondary__lvideo"
      >
        <figure className="article__image__content col-12 col-sm-3 col-md-3 col-lg-3">
          {article.level && (
            <div className="next__level__txt">
              {t("articles.next_level")}:
              <span>{t(`articles.level_${article.level}`)}</span>
            </div>
          )}

          <div className="next__video__box_player">
            <span className="player__counter">{article.duration}</span>
            <Link
              className="player__btn"
              to={`/${lang}/${t("url.contents")}/${slug}`}
            >
              <img
                itemProp="image"
                src={ico_player}
                alt={t("articles.play_video")}
              />
            </Link>
            <Link to={`/${lang}/${t("url.contents")}/${slug}`}>
              <img
                itemProp="image"
                src={path(article.image)}
                alt={article.title}
              />
            </Link>
          </div>
        </figure>
        <div className="article__information col-12 col-sm-9 col-md-9 col-lg-9">
          <div className="autor__container">
            <p className="article__autor">
              <a href="/">
                <span itemProp="name">{article.display_name}</span>
              </a>
            </p>
          </div>
          <h2 itemProp="headline">
            <Link to={`/${lang}/${t("url.contents")}/${slug}`}>
              {he.decode(article.title)}
            </Link>
          </h2>
          <p itemProp="text">{article.summary}</p>

          {article.level && <Level grade={article.level} />}
        </div>
      </article>
    </div>
  );
};
