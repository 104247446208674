import React, { useState, useEffect } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { FavoriteButton } from "../ui/favorite-button";

export const Sharer = ({ id, className, shareUrl }) => {
  shareUrl = window.location.href;
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCopied("");
    }, 3000);
    return () => clearTimeout(timer);
  }, [copied]);

  return (
    <div className={className}>
      <CopyToClipboard text={shareUrl} onCopy={() => setCopied(true)}>
        <FavoriteButton id={id} className={"icon favorite"} />
      </CopyToClipboard>

      <div className="messager-copy">
        {copied ? <span>Copiado</span> : null}
      </div>

      <WhatsappShareButton url={shareUrl}>
        <a className="icon fab fa-whatsapp" href="./">
          {""}
        </a>
      </WhatsappShareButton>

      <LinkedinShareButton url={shareUrl}>
        <a className="icon fab fa-linkedin-in" href="./">
          {""}
        </a>
      </LinkedinShareButton>

      <FacebookShareButton url={shareUrl}>
        <a className="icon fab fa-facebook-f" href="./">
          {""}
        </a>
      </FacebookShareButton>

      <TwitterShareButton url={shareUrl}>
        <a className="icon fab fa-twitter" href="./">
          {""}
        </a>
      </TwitterShareButton>

      <CopyToClipboard text={shareUrl} onCopy={() => setCopied(true)}>
        <a className="icon copy fas fa-link" aria-hidden="true" href="./">
          {" "}
        </a>
      </CopyToClipboard>

      <div className="messager-copy">
        {copied ? <span>Copiado</span> : null}
      </div>

      <div className="others__links dropdown">
        <a className="others__shares fas fa-ellipsis-h" href="./">
          {""}
        </a>
        <ul className="share__options dropdown-list" id="shared__list">
          <div className="header__share__box">
            <span className="share__title">Share</span>
            <button
              type="button"
              className="close"
              id="shared__close"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="share__links">
            <WhatsappShareButton url={shareUrl} className="icon-links">
              <a href="./">
                <i className="fab fa-whatsapp"></i>Share on Whatsapp
              </a>
            </WhatsappShareButton>

            <LinkedinShareButton url={shareUrl} className="icon-links">
              <a href="./">
                <i className="fab fa-linkedin-in"></i>Share on Linkedin
              </a>
            </LinkedinShareButton>

            <FacebookShareButton url={shareUrl} className="icon-links">
              <a href="./">
                <i className="fab fa-facebook-f"></i>Share on Facebook
              </a>
            </FacebookShareButton>

            <TwitterShareButton url={shareUrl} className="icon-links">
              <a href="./">
                <i className="fab fa-twitter"></i>Share on Twitter
              </a>
            </TwitterShareButton>

            <CopyToClipboard
              text={shareUrl}
              className="icon-links"
              onCopy={() => setCopied(true)}
            >
              <a href="./">
                <i className="fas fa-link"></i>Copy link
              </a>
            </CopyToClipboard>

            <EmailShareButton url={shareUrl} className="icon-links">
              <a href="./">
                <i className="far fa-envelope"></i>Email
              </a>
            </EmailShareButton>
          </div>
        </ul>
      </div>
    </div>
  );
};
