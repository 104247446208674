import React from "react";
import Modal from "react-bootstrap/Modal";
import { modalActions } from "../../_actions";
import { modalConstants } from "../../_constants";
import { ForgotPassword } from "../forms/forgot-password";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";


import{ PasswordFormForgot} from "../forms/password-form-forgot";

class Forgot extends React.Component {
  constructor(props) {
    super(props);

    this.handleBack = this.handleBack.bind(this);
  }

  /* Close window */
  handleBack(e) {
    if (e) {
      e.preventDefault();
    }
    this.props.showLogin();
  }

  /* Visualization */
  render() {
    //state, visibility and user gotten from Redux state.
    const {
      t,
      step,
      forgot: visibility,
      hideForgotPassword,
    } = this.props;

    return (
      <Modal
        className="fullsize"
        show={visibility}
        onHide={hideForgotPassword}
        animation={false}
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="row recovery__box">
              <div className="col-md-5 images__login"></div>

              {step === modalConstants.FORGOT_STEP1 && (
                <div className="col-md-7 recovery__process">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={hideForgotPassword}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                  <a href="/" className="back__btn" onClick={this.handleBack}>
                    <svg
                      width="17px"
                      height="15px"
                      viewBox="0 0 17 15"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="right-arrow" transform="matrix(-1 0 0 1 17 0)">
                        <path
                          d="M16.5683 6.46528L10.3214 0.218368C10.097 -0.0436645 9.70267 -0.0742108 9.44064 0.150243C9.1786 0.374643 9.14806 0.769007 9.37251 1.03104C9.39345 1.05547 9.41621 1.07828 9.44064 1.09916L14.6193 6.28409L0.624669 6.28409C0.279695 6.28409 4.63626e-13 6.56379 4.63626e-13 6.90882C4.63626e-13 7.25384 0.279695 7.53349 0.624669 7.53349L14.6193 7.53349L9.44064 12.7121C9.1786 12.9365 9.14806 13.3309 9.37251 13.5929C9.59697 13.855 9.99128 13.8855 10.2533 13.6611C10.2777 13.6401 10.3006 13.6174 10.3214 13.5929L16.5683 7.34602C16.8105 7.10246 16.8105 6.70895 16.5683 6.46528Z"
                          transform="translate(0 0.01982867)"
                          id="Path"
                          fill="#000000"
                          stroke="none"
                        />
                      </g>
                    </svg>
                    Back
                  </a>
                  <h1>
                    <span className="title__highlight">
                      {t("register.reset_blue")}
                    </span>
                    <br />
                    {t("register.reset_black")}
                  </h1>
                  <span>
                    Please enter your email address. We will send you an email
                    to reset your password.
                  </span>

                  <ForgotPassword  />
                </div>
              )}
              {step === modalConstants.FORGOT_STEP2 && (
                <div className="col-md-7 recovery__process sended">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={hideForgotPassword}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                  {/* 
                  
                    <h1>
                    <span className="title__highlight">Check</span>
                    <br />
                    your email
                  </h1>
                  <div className="details__reco d-flex">
                    <div className="image__reco">
                      <img src={ico_sent} alt="Check our email" />
                    </div>
                    <div className="details__reco__txt">
                      <span>We have sent you a recovery link to</span>
                      <span>
                        <strong>{email}</strong>
                      </span>
                    </div>
                  </div>
                  <div className="back__login">
                    <a
                      className="secondary__hyper underline"
                      href="/"
                      onClick={this.handleBack}
                    >
                      Back to login
                    </a>
                  </div> */}
                     <PasswordFormForgot />
                  
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

function mapState(state) {
  const { forgot, step } = state.modals;
  return { forgot, step };
}

const actionCreators = {
  hideForgotPassword: modalActions.hideForgotPassword,
  showLogin: modalActions.showLogin,
};

const connectedForgot = connect(
  mapState,
  actionCreators
)(withTranslation("common")(Forgot));
export { connectedForgot as Forgot };
