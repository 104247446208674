import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";

const ModalSuccess = ({ success }) => {
  const [show] = useState(success);
  const { t, i18n } = useTranslation("common");
  const lang = i18n.language;


  return (
    <Modal
      show={show}
      backdrop="static"
      keyboard={false}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <Modal.Header style={{ border: "none" }}></Modal.Header>
      <Modal.Body>
        <div className="tittle__premium">
          <h1 className="highlighted text-center">
            {t("premium.successTitle")}
          </h1>
        </div>
        <p>{t("premium.successText")}</p>
        <Button
          variant="primary"
          href={`/${lang}/${t("url.contents")}`}
          className="modal__button"
        >
          {t("premium.btnText")}
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default ModalSuccess;
