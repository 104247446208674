import React from "react";
import FormValidator from "../../_helpers/form-validator";
import { userActions } from "../../_actions";
import { modalActions } from "../../_actions";
import { Spinner } from "../ui/spinner";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

class ResetPassword extends FormValidator {
  constructor(props) {
    super(props);

    this.state = {
      elements: {
        password: {
          value: "",
          error: false,
          message: "",
          visible: false,
          criterias: {
            minlen: "",
            lowcaps: "",
            numbers: "",
          },
        },
        repassword: {
          value: "",
          error: false,
          message: "",
          visible: false,
        },
      },
    };

    this.submitHandler = this.submitHandler.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.changePassword = this.changePassword.bind(this);
    this.viewPassword = this.viewPassword.bind(this);
    this.viewPassword2 = this.viewPassword2.bind(this);
  }

  /* Form submit */
  submitHandler(e) {
    e.preventDefault();

    const isValid = this.validate(this.state.elements, [
      {
        field: "password",
        type: "required",
        message: "errors.password_required",
      },
      {
        field: "password",
        type: "min:8",
        message: "errors.password_minlength",
      },
      {
        field: "password",
        type: "hasnum",
        message: "errors.password_hasnum",
      },
      {
        field: "password",
        type: "lowercaps",
        message: "errors.password_lowercaps",
      },
      {
        field: "repassword",
        type: "required",
        message: "errors.repassword_required",
      },
      {
        field: "repassword",
        type: "equals:password",
        message: "errors.repassword_different",
      },
    ]);

    if (isValid) {
      const { password } = this.state.elements;
      const lang = this.props.i18n.language;
      this.props.reset(lang, this.props.token, password.value);
    }
  }

  /* Controlled input */
  changeHandler(e) {
    const input = this.state.elements[e.target.name];
    input.value = e.target.value;
    input.error = false;

    this.setState({ elements: this.state.elements });
  }

  changePassword(e) {
    const value = e.target.value;
    const input = this.state.elements.password;
    let { criterias } = input;

    criterias.minlen = value.length >= 8;
    criterias.lowcaps = /^(?=.*[a-z])(?=.*[A-Z])/.test(value);
    criterias.numbers = /\d/.test(value);

    this.changeHandler(e);
  }

  handleChangeType(e) {
    const input = this.state.elements.type;
    input.value = e.target.value;

    this.setState({ elements: this.state.elements });
  }

  viewPassword = (e) => {
    e.preventDefault();
    this.state.elements.password.visible = !this.state.elements.password
      .visible;
    this.setState({ elements: this.state.elements });
  };

  viewPassword2 = (e) => {
    e.preventDefault();
    this.state.elements.repassword.visible = !this.state.elements.repassword
      .visible;
    this.setState({ elements: this.state.elements });
  };

  render() {
    const { t } = this.props;
    const { password, repassword } = this.state.elements;

    let pass_minlen = "";
    let pass_lowcaps = "";
    let pass_numbers = "";

    if (password.criterias.minlen === true) {
      pass_minlen = "validate";
    } else if (password.criterias.minlen === false) {
      pass_minlen = "error";
    }

    if (password.criterias.lowcaps === true) {
      pass_lowcaps = "validate";
    } else if (password.criterias.lowcaps === false) {
      pass_lowcaps = "error";
    }

    if (password.criterias.numbers === true) {
      pass_numbers = "validate";
    } else if (password.criterias.numbers === false) {
      pass_numbers = "error";
    }

    return (
      <form onSubmit={this.submitHandler}>
        {
          /* Error */
          this.props.problem && (
            <div className={`form-label-group`}>
              <div className="alert alert-danger text-center">
                {t(`errors.${this.props.problem}`)}
              </div>
            </div>
          )
        }

        {/* New Password */}
        <div
          className={`form-label-group new__password ${
            password.error ? "error" : ""
          }`}
        >
          <input
            type={password.visible ? "text" : "password"}
            id="password"
            name="password"
            className="form-control"
            placeholder={t("register.form1_password")}
            required=""
            onChange={this.changePassword}
            value={password.value}
            maxLength="30"
            autoComplete="off"
            data-toggle="collapse"
            data-target="#collapseValidator"
            aria-controls="collapseValidator"
          />
          <label htmlFor="password">Create password</label>
          <div className="password__toggle">
            <a
              id="password__toggle"
              href="/"
              className="enable"
              onClick={this.viewPassword}
              tabIndex="-1"
            >
              {""}
            </a>
          </div>

          {password.error && (
            <small id="passlHelp" className="form-text text-muted">
              {t(password.message)}
            </small>
          )}
        </div>

        <div className="password__validator" id="collapseValidator">
          <span className="paas__instructions">Your password must have:</span>
          <div className="password__conditions">
            <div className={`condition__pass ${pass_minlen}`}>
              <i className="far fa-check-circle"></i>
              <span>{t("register.pwd_minchars")}</span>
            </div>
            <div className={`condition__pass ${pass_lowcaps}`}>
              <i className="far fa-check-circle"></i>
              <span>{t("register.pwd_lowper")}</span>
            </div>
            <div className={`condition__pass ${pass_numbers}`}>
              <i className="far fa-check-circle"></i>
              <span>{t("register.pwd_number")}</span>
            </div>
            <div className="clearfix"></div>
          </div>
        </div>

        {/* Repassword */}
        <div
          className={`form-label-group new__password mb-5 ${
            repassword.error ? "error" : ""
          }`}
        >
          <input
            type={repassword.visible ? "text" : "password"}
            id="repassword"
            name="repassword"
            className="form-control"
            placeholder={t("register.form1_repassword")}
            required=""
            onChange={this.changeHandler}
            value={repassword.value}
            maxLength="30"
            autoComplete="off"
          />
          <label htmlFor="inputPassword">Confirm your password</label>
          <div className="password__toggle">
            <a
              id="repassword__toggle"
              href="/"
              className="enable"
              onClick={this.viewPassword2}
            >
              {""}
            </a>
          </div>

          {repassword.error && (
            <small id="passlHelp" className="form-text text-muted">
              {t(repassword.message)}
            </small>
          )}
        </div>

        {/* Submit */}
        {this.props.loading ? (
          <Spinner />
        ) : (
          <>
            <button className="btn btn-lg btn-primary" type="submit">
              Send
            </button>
          </>
        )}
      </form>
    );
  }
}

function mapState(state) {
  const { loading, problem } = state.users;
  return { loading, problem };
}

const actionCreators = {
  showLogin: modalActions.showLogin,
  reset: userActions.resetPassword,
};

const connectedResetPassword = connect(
  mapState,
  actionCreators
)(withTranslation("common")(ResetPassword));
export { connectedResetPassword as ResetPassword };
