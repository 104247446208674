import React from "react";
import FormValidator from "../../_helpers/form-validator";
import { userActions } from "../../_actions";
import { Spinner } from "../ui/spinner";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

class LoginForm extends FormValidator {
  constructor(props) {
    super(props);

    this.state = {
      elements: {
        email: {
          value: "",
          error: false,
          message: "",
        },
        password: {
          value: "",
          error: false,
          message: "",
          visible: false,
        },
      },
    };

    this.submitHandler = this.submitHandler.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.viewPassword = this.viewPassword.bind(this);
  }

  /* Form submit */
  submitHandler(e) {
    e.preventDefault();

    const isValid = this.validate(this.state.elements, [
      {
        field: "email",
        type: "required",
        message: "errors.email_required",
      },
      {
        field: "email",
        type: "email",
        message: "errors.email_format",
      },
      {
        field: "password",
        type: "required",
        message: "errors.password_required",
      },
    ]);

    if (isValid) {
      const lang = this.props.i18n.language;
      const { email, password } = this.state.elements;
      const url = `/${lang}/${this.props.t("url.community")}`;

      this.props.login(lang, email.value, password.value, url);
    }
  }

  /* Controlled input */
  changeHandler(e) {
    const input = this.state.elements[e.target.name];
    input.value = e.target.value;
    input.error = false;

    this.setState({ elements: this.state.elements });
  }

  /* Show password */
  viewPassword = (e) => {
    e.preventDefault();
    this.state.elements.password.visible =
      !this.state.elements.password.visible;
    this.setState({ elements: this.state.elements });
  };

  /* View */
  render() {
    const { t } = this.props;
    const { email, password } = this.state.elements;

    return (
      <form className="form-signin" onSubmit={this.submitHandler}>
        {
          /* Error */
          this.props.problem && (
            <div className={`form-label-group`}>
              <div className="alert alert-danger text-center">
                {t(
                  `errors.${String(
                    this.props.problem.toLocaleLowerCase().match(/[a-z]+/g)
                  ).replace(/,/g, "_")}`
                )}
              </div>
            </div>
          )
        }

        {/* Email Input */}
        <div
          className={`form-label-group email__content ${
            email.error ? "error" : ""
          }`}
        >
          <input
            type="text"
            id="email"
            name="email"
            className="form-control"
            placeholder={t("login.form_email")}
            required=""
            autoFocus=""
            onChange={this.changeHandler}
            value={email.value}
            maxLength="80"
          />
          <label htmlFor="inputEmail">{t("login.label_email")}</label>
          {email.error && (
            <small id="passlHelp" className="form-text text-muted">
              {t(email.message)}
            </small>
          )}
        </div>

        {/* Password Input */}
        <div
          className={`form-label-group password__content ${
            password.error ? "error" : ""
          }`}
        >
          <input
            type={password.visible ? "text" : "password"}
            id="password"
            name="password"
            className="form-control"
            placeholder={t("login.form_pass")}
            required=""
            onChange={this.changeHandler}
            value={password.value}
            maxLength="30"
            autoComplete="off"
          />
          <div className="password__toggle">
            <a
              id="password__toggle"
              href="/"
              className="enable"
              onClick={this.viewPassword}
            >
              {""}
            </a>
          </div>
          <label htmlFor="inputPassword">{t("login.label_pass")}</label>
          {password.error && (
            <small id="passlHelp" className="form-text text-muted">
              {t(password.message)}
            </small>
          )}
        </div>

        <div className="checkbox mb-3">
          <label>
            <input type="checkbox" value="remember-me" />
            {t("login.keep_me")}
          </label>
        </div>

        <div className="forgot__password">
          <a
            className="secondary__hyper"
            href="./"
            onClick={this.props.showForgot}
          >
            {t("login.forgot")}
          </a>
        </div>
        {this.props.loggingIn ? (
          <Spinner />
        ) : (
          <button className="btn btn-lg btn-primary btn-block" type="submit">
            {t("buttons.login")}
          </button>
        )}
      </form>
    );
  }
}

function mapState(state) {
  const { loggingIn, problem } = state.authentication;
  return { loggingIn, problem };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedLoginForm = connect(
  mapState,
  actionCreators
)(withTranslation("common")(LoginForm));
export { connectedLoginForm as LoginForm };
