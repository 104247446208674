import React from "react";

export const Linkedin = ({ link }) => {
  return (
    <a
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      className="social__btn linkedin"
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
        <circle className="st0" cx="18" cy="18" r="17.5" />
        <path
          className="st1"
          d="M14 11.5c-0.9 0-1.5 0.6-1.5 1.5 0 0.8 0.6 1.5 1.5 1.5l0 0c0.9 0 1.5-0.7 1.5-1.5C15.5 12.1 14.9 11.5 14 11.5z"
        />
        <rect x="12.5" y="15.5" className="st1" width="3" height="9" />
        <path
          className="st1"
          d="M22.1 15.5c-1.6 0-2.7 1.5-2.7 1.5v-1.3h-2.9v8.8h2.9v-4.9c0-0.3 0-0.5 0.1-0.7 0.2-0.5 0.7-1.1 1.5-1.1 1.1 0 1.5 0.8 1.5 2v4.7h2.9v-5C25.5 16.8 24 15.5 22.1 15.5z"
        />
      </svg>
    </a>
  );
};
