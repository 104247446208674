import React from "react";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import { ProfileForm } from "../forms/profile-form";
import ico_back from "../../_images/ico-back.svg";

export const EditProfile = ({ show, onHide }) => {
  //Language hooks
  const { t } = useTranslation("common");

  const onClickBack = (e) => {
    e.preventDefault();
    onHide();
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <div
        id="_1"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="modalLabel_1"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered edit__profile"
          role="document"
        >
          <div className="modal-content auto">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={onHide}
            >
              <span aria-hidden="true">×</span>
            </button>
            <div className="mobile__bar d-flex align-items-center">
              <a href="/" className="back__btn" onClick={onClickBack}>
                <img src={ico_back} alt="Back" />
              </a>
              <span className="title__profile">{t("user.profile_button")}</span>
            </div>
            <div className="profile__camps">
              <ProfileForm onSuccess={onHide} />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
