import React from "react";
import { useTranslation } from "react-i18next";

export const LoadMore = ({ page, totalItems, itemsByPage, onClick }) => {
  const pages = Math.ceil(totalItems / itemsByPage);

  const { t } = useTranslation("common");

  if (page >= pages - 1) {
    return "";
  }

  return (
    <button className="load__more" onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="17"
        viewBox="0 0 16 17"
      >
        <path d="M8.3 14.4C4.9 14.4 2.2 11.6 2.2 8.2 2.2 4.8 4.9 2.1 8.3 2.1 10 2.1 11.6 2.8 12.6 4L13.1 4.5 12.6 5 12 5.6 15.5 5.6 15.5 1.9 14.5 2.9 14 2.4C12.5 0.9 10.5 0 8.2 0 5.9 0 3.9 0.9 2.4 2.4 0.9 3.9 0 6 0 8.2 0 10.5 0.9 12.5 2.4 14 3.9 15.5 5.9 16.4 8.2 16.4 10.2 16.4 12.1 15.7 13.5 14.5L12 13C11 13.9 9.7 14.4 8.3 14.4L8.3 14.4Z"></path>
      </svg>
      {t("events.view_more")}
    </button>
  );
};
