import React from "react";
import { useTranslation } from "react-i18next";
import * as moment from "moment";
import "moment/locale/es";

export const SimpleDate = ({ className, date }) => {
  //Language hooks
  const { i18n } = useTranslation("common");
  const lang = i18n.language;

  moment.locale(lang);
  let published = moment(date).format("MMM DD, YYYY");

  return date ? (
    <time className={className} dateTime={date}>
      {published}
    </time>
  ) : (
    ""
  );
};
