import { authHeader } from "../_helpers";
import { appConstants } from "../_constants";

export const meetupService = {
  getListMeetups,
  postMeetups,
  deleteMeetups,
};

function getListMeetups(lang, page) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${appConstants.API}/${lang}/v1/meetups?page=${page}`,
    requestOptions
  );
}

function postMeetups(lang, description, uri, date, location) {
  //Setup object according drupal's format.
  const payload = {
    type: [{ target_id: "meetup", target_type: "node_type" }],
    title: [{ value: description }],
    langcode: [{ value: lang }],
    field_event_url: [{ uri: uri }],
    field_when: [{ value: date }],
    field_where: [{ value: location }],
  };

  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(payload),
  };
  return fetch(`${appConstants.API}/node?_format=json`, requestOptions).then(
    handleResponse
  );
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        window.location.reload(true);
      }
      const error = (data && data.message) || response.statusText;

      //Filter Drupal errors.
      let result = String(
        error
          .toString()
          .toLocaleLowerCase()
          .match(/[a-z]+/g)
      ).replace(/,/g, "_");

      return Promise.reject(result);
    }
    return data;
  });
}


function deleteMeetups(mid) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      ...authHeader(),
      "Content-Type": "application/hal+json",
    },
  };

  //Delete meetups
  return fetch(
    `${appConstants.API}/node/${mid}?_format=json`,
    requestOptions
  ).then(handleResponse).then(() => window.location.reload());
}