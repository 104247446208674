import React from "react";


export const Avatar = ({ user, alt }) => {
  const { use_avatar, picture, avatar } = user;
  return (
    <>
      {use_avatar === "True" ? (
        avatar ? (
          <img src={`/images/${avatar}.svg`} alt={alt} />
        ) : (
          <img src="/images/no-avatar.png" alt={alt} />
        )
      ) : picture ? (
        <img src={picture} alt={alt} />
      ) : (
        <img src="/images/no-avatar.png" alt={alt} />
      )}
    </>
  );
};
