import React, { useState, useEffect } from "react";
import { Link } from "@reach/router";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { EditAbout } from "../modals/edit-about";
import { EditCertifications } from "../modals/edit-certifications";
import { EditInterests } from "../modals/edit-interests";
import ico_add from "../../_images/ico-add.svg";
import ico_edit from "../../_images/ico-edit.svg";
import { CategoryLink } from "../ui/category-link";
import he from "he";
import { userService } from "../../_services";

const ProfileDashboard = ({ me }) => {
  const { i18n, t } = useTranslation("common");
  const lang = i18n.language;
  const [summary, setSummary] = useState([]);

  useEffect(() => {
    userService
      .summary(lang, me.id)
      .then((res) => res.json())
      .then((response) => {
        if (response) {
          setSummary(response[0]);
        }
      })
      .catch(console.log);
  }, [lang, setSummary]);

  const certifications = me.custom_user_certification;
  const interests = String(summary.field_interests_1).split(",");
  const interests_id = String(summary.interests).split(",");
  const about = summary.full_bio;

  const [showFullBio, setShowFullView] = useState(false);
  const [showEditAbout, setShowEditAbout] = useState(false);
  const [showEditCertifications, setShowEditCertifications] = useState(false);
  const [showEditInterests, setShowEditInterests] = useState(false);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);

  useEffect(() => {
    userService.myContents(me.name, page).then((response) => {
  
      console.log(response);
      setTotal(response.results);
    });
  }, [page, setTotal]);



  const handleCloseEditAbout = () => setShowEditAbout(false);
  const handleShowEditAbout = (e) => {
    e.preventDefault();
    setShowEditAbout(true);
  };

  const handleCloseEditCertifications = () => setShowEditCertifications(false);
  const handleShowEditCertifications = (e) => {
    e.preventDefault();
    setShowEditCertifications(true);
  };

  const handleCloseEditInterests = () => setShowEditInterests(false);
  const handleShowEditInterests = (e) => {
    e.preventDefault();
    setShowEditInterests(true);
  };

  const toggleBio = (e) => {
    e.preventDefault();
    setShowFullView(!showFullBio);
  };

  return (
    <div className="profile__dashboard">
      <div className="profile__body__heading d-flex justify-content-between align-items-center">
        <h2 className="dashboard__title">{t("profile.dashboard")}</h2>
        <span className="profile__onlyfy">{t("profile.view-dashboard")}</span>
      </div>
      <div className="articles d-flex justify-content-center">
        <div className="articles__counter articles flex-fill col-4">
          <Link to={`/${lang}/${t("url.my_content")}`}>
            <span className="big__counter">{total}</span>
            <span>{t("profile.articles")}</span>
          </Link>
        </div>
        {/* <div className="articles__counter viwers flex-fill col-4">
          <Link to={`/${lang}/${t("url.my_visits")}`}>
            <span className="big__counter">{stats.views}</span>
            <span>{t("profile.trends-view")}</span>
          </Link>
        </div> */}
        <div className="articles__counter posted flex-fill col-4">
          <Link to={`/${lang}/${t("url.my_content")}`}>
            <span className="big__counter">{total}</span>
            <span>{t("profile.post")}</span>
          </Link>
        </div>
      </div>
      <div className="details__profile">
        <div className="about">
          <div className="detail_header d-flex justify-content-between">
            <h3>{t("profile.about")}</h3>
            <a href="./" className="edit" onClick={handleShowEditAbout}>
              <img src={ico_edit} alt="Edit a/out" />
            </a>
          </div>
          {showFullBio ? (
            <p>{he.decode(me.full_bio)}</p>
          ) : (
            <p>{he.decode(me.bio)}</p>
          )}

          <a href="./" className="more" onClick={toggleBio}>
            {showFullBio ? t("events.view_less") : t("events.view_more")}
          </a>
          <a href="./" className="more" style={{ display: "none" }}>
            <img src={ico_add} alt="Add info" />
            {t("profile.add")}
          </a>
        </div>
        <div className="certifications">
          <div className="detail_header d-flex justify-content-between">
            <h3>{t("profile.certifications")}</h3>
            <a
              href="./"
              className="edit"
              onClick={handleShowEditCertifications}
            >
              <img src={ico_edit} alt="Edit certifications" />
            </a>
          </div>

          {certifications.map(({ name, id }) => (
            <div className="certification_detail" key={id}>
              <span>{name}</span>
            </div>
          ))}
        </div>
        <div className="interest">
          <div className="detail_header d-flex justify-content-between">
            <h3>{t("profile.interest")}</h3>

            <a href="./" className="edit" onClick={handleShowEditInterests}>
              <img src={ico_edit} alt="Edit interest" />
            </a>
          </div>

          <CategoryLink className="quick__cats__btn topics" arr={interests} />
        </div>
      </div>
      <EditAbout
        show={showEditAbout}
        onHide={handleCloseEditAbout}
        bio={about}
        me={me}
      />
      <EditCertifications
        show={showEditCertifications}
        onHide={handleCloseEditCertifications}
        me={me}
        lang={lang}
      />
      <EditInterests
        show={showEditInterests}
        onHide={handleCloseEditInterests}
        interests={interests}
        interests_id={interests_id}
        me={me}
      />
    </div>
  );
};

function mapState(state) {
  const { me } = state.authentication;
  return { me };
}

const connectedProfileDashboard = connect(mapState, null)(ProfileDashboard);
export { connectedProfileDashboard as ProfileDashboard };
