import { authHeader } from "../_helpers";
import { appConstants } from "../_constants";

export const taxonomys = {
  getProfessions,
  getCountries,
  getPhrases,
  getSubject,
  getGender,
  getPhone
};

/**
 *
 * @param {*} lang
 */
 


function getProfessions(lang) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${appConstants.API}/${lang}/v1/professions`,
    requestOptions
  );
}

function getCountries(lang) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
      `${appConstants.API}/${lang}/v1/countrys`,
    requestOptions
  );
}

function getPhrases(lang) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${appConstants.API}/${lang}/v1/Description_article_and_video`,
    requestOptions
  );
}

function getSubject(lang) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), 
      "Content-Type": "application/json",
    },
  };

  return fetch(
    `${appConstants.API}/${lang}/v1/subject`,
    requestOptions
  );
}

function getGender(lang) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), 
      "Content-Type": "application/json",
    },
  };

  return fetch(
    `${appConstants.API}/${lang}/v1/gender`,
    requestOptions
  );
}

function getPhone(lang) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), 
      "Content-Type": "application/json",
    },
  };

  return fetch(
    `${appConstants.API}/${lang}/v1/phone`,
    requestOptions
  );
}
