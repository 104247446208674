import React from "react";
import FormValidator from "../../_helpers/form-validator";
import { withTranslation } from "react-i18next";
import ico_add from "../../_images/ico-add.svg";
import { connect } from "react-redux";
import { userActions } from "../../_actions";
import { saveFile } from "../../_services";
import { Autocompletes } from "../ui/autocomplete";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

class GroupForm extends FormValidator {
  constructor(props) {
    super(props);
    this.state = {
      elements: {
        image: {
          base64: ico_add,
          name: "",
          type: "",
        },
        name: {
          value: "",
          error: false,
          message: "",
        },
        description: {
          value: "",
          error: false,
          message: "",
        },
        rules: {
          value: "",
          error: false,
          message: "",
        },
        person: {
          value: "",
          error: false,
          message: "",
          uid: "",
          members: [],
          members_id: [],
        },
        stateGroup: {
          value: "Private",
          error: false,
          message: "",
        },
      },
      loading: false,
      problem: null,
      showModal: false,
    };

    this.submitHandler = this.submitHandler.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.changeHandlerMembers = this.changeHandlerMembers.bind(this);
  }

  submitHandler(e) {
    e.preventDefault();

    // const { language: lang } = this.props.i18n;
    const isValid = this.validate(this.state.elements, [
      {
        field: "name",
        type: "required",
        message: "errors.group_name",
      },
      {
        field: "description",
        type: "required",
        message: "errors.group_description",
      },
      {
        field: "rules",
        type: "required",
        message: "errors.where_required",
      },
      {
        field: "person",
        type: "required",
        message: "errors.group_person",
      },
      {
        field: "stateGroup",
        type: "required",
        message: "errors.group_status",
      },
    ]);
    // this.setState({ error: "" });

    if (isValid) {
      const { image, name, description, rules, person, stateGroup } =
        this.state.elements;
      // this.setState({ loading: true });

      const lang = this.props.i18n.language;
      if (image.name) {
        saveFile(image.base64, image.name, image.type, name.value).then(
          (fid) => {
            this.props.new_group(lang, {
              image_id: fid,
              // image_alt: image.name,
              name: name.value,
              description: description.value,
              rules: rules.value,
              person: person.value,
              stateGroup:
                stateGroup.value === "Public"
                  ? [{ target_id: "group" }]
                  : [
                      {
                        target_id: "group_private",
                        target_type: "group_type",
                        target_uuid: "5e0979de-a025-4739-ab14-7f7cf04e0707",
                      },
                    ],
              member_id: person.members_id,
              label_member: person.members,
              group_roles: [
                {
                  target_id: "group-admin",
                },
              ],
            })
          }
        );
        setTimeout(() => {
          this.setState({ showModal: true });
          // window.location.reload();
        }, 5000);
      }
      document.querySelector(".create__group__content").classList.add("opacy");
      document.querySelector("#buttonGroup").classList.add("lds-dual-ring");
    }
  }

  changeHandler(e) {
    const input = this.state.elements[e.target.name];
    input.value = e.target.value;
    input.error = false;

    this.setState({ elements: this.state.elements });
  }

  changeHandlerMembers(e) {
    const input = this.state.elements.person;

    input.value = e.label;
    input.uid = e.uid;
    input.error = false;

    const members_i = [...input.members, input.value];
    input.members = members_i.filter((e, i) => members_i.indexOf(e) === i);

    const members_id_i = [...input.members_id, input.uid];
    input.members_id = members_id_i.filter(
      (e, i) => members_id_i.indexOf(e) === i
    );

    this.setState({ elements: this.state.elements });
  }

  onFileChange(e) {
    let files = e.target.files;
    let fileReader = new FileReader();
    fileReader.readAsDataURL(files[0]);
    fileReader.onload = (event) => {
      const img = this.state.elements["image"];
      img.base64 = event.target.result;
      img.name = files[0].name;
      img.type = files[0].type;
      this.setState({ elements: this.state.elements });
    };
  }

  render() {
    const imgRequired = this.state.elements.image.base64 ? false : true;
    const { t, i18n } = this.props;
    const lang = i18n.language;
    const { image, name, description, rules, person, stateGroup } =
      this.state.elements;
    const { showModal } = this.state;

    return (
      <>
        <form onSubmit={this.submitHandler}>
          {
            /* Error */
            this.props.problem && (
              <div className={`form-label-group`}>
                <div className="alert alert-danger text-center">
                  {/* {t("errors.group_api")} */}
                  {t(`errors.${this.props.problem}`)}
                </div>
              </div>
            )
          }
          {
            <div className="create__image__txt">
              <div
                className={`profile__image__content ${
                  image.error ? "error" : ""
                }`}
              >
                <div className="submit__image">
                  <div className="image__profile"></div>
                  <img src={ico_add} alt="Add your profile" id="ico-add" />
                  <input
                    type="file"
                    id="profile_file"
                    className="profile_file form-control"
                    name="image"
                    onChange={this.onFileChange}
                    required={imgRequired}
                    style={{ opacity: "0" }}
                  />
                  <div className="img-group">
                    <img
                      src={this.state.elements.image.base64}
                      className="img-file"
                      alt="group"
                    />
                  </div>
                </div>
              </div>
            </div>
          }
          {
            <div className={`form-label-group ${name.error ? "error" : ""}`}>
              <input
                type="text"
                id="GroupName"
                className="form-control"
                placeholder={t("contents.group_name")}
                required=""
                autoFocus=""
                name="name"
                value={name.value}
                onChange={this.changeHandler}
              />
              <label htmlFor="inputEmail">{t("contents.group_name")}</label>
              {name.error && (
                <small className="form-text text-muted">
                  {t(name.message)}
                </small>
              )}
            </div>
          }

          {
            <div
              className={`form-label-group ${description.error ? "error" : ""}`}
            >
              <input
                type="text"
                id="GroupDescription"
                className="form-control"
                placeholder={t("contents.group_description")}
                name="description"
                required=""
                autoFocus=""
                value={description.value}
                onChange={this.changeHandler}
              />
              <label htmlFor="inputEmail">
                {t("contents.group_description")}
              </label>
              {description.error && (
                <small className="form-text text-muted">
                  {t(description.message)}
                </small>
              )}
            </div>
          }

          {
            <div className={`form-label-group ${rules.error ? "error" : ""}`}>
              <input
                type="text"
                id="GroupRules"
                className="form-control"
                placeholder={t("contents.group_rules")}
                required=""
                name="rules"
                autoFocus=""
                value={rules.value}
                onChange={this.changeHandler}
              />
              <label htmlFor="inputEmail">{t("contents.group_rules")}</label>
              {rules.error && (
                <small className="form-text text-muted">
                  {t(rules.message)}
                </small>
              )}
            </div>
          }

          {
            <div
              className={`form-label-group nofloating ${
                person.error ? "error" : ""
              }`}
            >
              <label htmlFor="inputEmail">{t("contents.group_person")}</label>
              <Autocompletes
                handler={this.changeHandlerMembers}
                value={person.value}
              />
              {person.error && (
                <small className="form-text text-muted">
                  {t(person.message)}
                </small>
              )}
              <ul className="list-members">
                {person.members.map((item, id) => (
                  <li key={id}>{item},</li>
                ))}
              </ul>
            </div>
          }

          {
            <div
              className={`form-label-group labeled ${
                stateGroup.error ? "error" : ""
              }`}
            >
              <label>{t("contents.group_group")}</label>
              <select
                className="form-control"
                name="stateGroup"
                id="visibility"
                value={stateGroup.value}
                onChange={this.changeHandler}
              >
                <option>{t("contents.group_group_private")}</option>
                <option>{t("contents.group_group_public")}</option>
              </select>
              {stateGroup.error && (
                <small className="form-text text-muted">
                  {t(stateGroup.message)}
                </small>
              )}
            </div>
          }

          {
            <div className="buttons text-center" id="buttonGroup">
              <button className="btn btn-primary">
                {t("contents.group_create")}
              </button>
            </div>
          }
        </form>
        {showModal && (
          <Modal
            backdrop="static"
            show={showModal}
            keyboard={false}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Modal.Header style={{ border: "none" }}></Modal.Header>
            <Modal.Body>
              <div className="tittle__premium">
                <h1 className="highlighted text-center">{t("groups.create")}</h1>
              </div>
              <p>{t("groups.modal_text")}</p>
              <Button
                variant="primary"
                href={`/${lang}/${t("url.groups")}`}
                className="modal__button"
              >
                {t("groups.btn_back")}
              </Button>
            </Modal.Body>
          </Modal>
        )}
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn, problem } = state.authentication;
  return { loggingIn, problem };
}

const actionCreators = {
  new_group: userActions.new_group,
};

const connectedGroupForm = connect(
  mapState,
  actionCreators
)(withTranslation("common")(GroupForm));
export { connectedGroupForm as GroupForm };
