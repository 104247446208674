import React from "react";
import { Link } from "@reach/router";
import { useTranslation } from "react-i18next";
import ico_player from "../../_images/ico-player-home.svg";
import { FavoriteButton } from "./favorite-button";
import { CategoryLink } from "./category-link";
import { PublishDate } from "./publish-date";
import { url, path } from "../../_helpers";
import he from "he";

export default function ArticleCategoryBig({ article }) {
  const { t, i18n } = useTranslation("common");
  const lang = i18n.language;
  const link = url(article.slug);

  return (
    <article itemScope="" itemType="http://schema.org/Article">
      <div className="article__cat d-flex desktop__cat">
        <p className="article__cat__tag" rel="category">
          <CategoryLink name={article.category} />
        </p>
        <span className="article__cat__separator">•</span>
        <PublishDate className="article__hour__tag" date={article.date} />
      </div>
      <figure className="article__image__content">
        {article.is_premium === "True" && (
          <a href="/" className="premium__label">
            {t("articles.premium")}
          </a>
        )}

        {article.is_video === "True" && (
          <Link className="player__btn home" to={link}>
            <img
              src={ico_player}
              itemProp="image"
              alt={t("articles.play_video")}
            />
            <span>{t("articles.watch_video")}</span>
          </Link>
        )}
        <Link to={link} className="image__btn">
          <img
            itemProp="image"
            src={path(article.thumbnail)}
            alt={he.decode(article.title)}
          />
        </Link>
      </figure>
      <div className="article__cat d-flex mobile__cat">
        <p className="article__cat__tag" rel="category">
          <CategoryLink name={article.category} />
        </p>
        <span className="article__cat__separator">•</span>
        <PublishDate className="article__hour__tag" date={article.date} />
      </div>
      <div className="article__information">
        <h2 itemProp="headline">
          <Link to={link}>{he.decode(article.title)}</Link>
        </h2>
      </div>
      <div className="autor__container d-flex">
        <p className="article__autor">
          <Link to={`/${lang}/${t("home.profile")}/${String(article.user).toLocaleLowerCase()}`}>
            <span itemProp="name">{article.display_name}</span>
          </Link>
        </p>

        <FavoriteButton id={article.id} />
      </div>
    </article>
  );
}
