export const appConstants = {
  INIT_REQUEST: "APP_INIT_REQUEST",
  INIT_SUCCESS: "APP_INIT_SUCCESS",
  INIT_FAILURE: "APP_INIT_FAILURE",

  BANNERS_REQUEST: "APP_BANNERS_REQUEST",
  BANNERS_SUCCESS: "APP_BANNERS_SUCCESS",
  BANNERS_FAILURE: "APP_BANNERS_FAILURE",

  // API: "/api",
  // PATH: "/",
  // API: `${window.location.protocol}//${window.location.hostname}/api`,
  // PATH: `${window.location.protocol}//${window.location.hostname}`,
  // LOCATION: `${window.location.hostname}/api`,

  // API: "http://dev.techfrenetic.com/api",
  // PATH: "http://dev.techfrenetic.com",

  // API: "http://front-mit-drush.eastus.cloudapp.azure.com/api",
  // PATH: "http://front-mit-drush.eastus.cloudapp.azure.com",
  // LOCATION: "front-mit-drush.eastus.cloudapp.azure.com/api",

  API: "https://dev-techfrenetic.us.seedcloud.co/api",
  PATH: "https://dev-techfrenetic.us.seedcloud.co/",
  LOCATION: "dev-techfrenetic.us.seedcloud.co/api",
  CHR: 8, // Strip => /api/es/
};
