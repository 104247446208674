import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { appService } from "../../_services";
 
 
export const Interest = () => {
  //Language hooks
  const { i18n, t } = useTranslation("common");
  const lang = i18n.language;
  const [interest, setInterest] = useState([]);
  const [Index, setIndex] = useState(0);

  useEffect(() => {
    appService
  .getInterest(lang)
  .then((res) => res.json())
  .then((response) => {
    if (response) {
      const items =  response.map(function (e) {
        let element = e;
        return element;
      });
      setInterest(items);
    }
  })
  .catch(console.log);
  }, [lang, setInterest]);

  const handleInputChange = (e) => {
    setIndex(e.target.selectedIndex);
  }
  localStorage.setItem("index_select", Index);

  return (
      
      <>
    <div className="certification__box d-flex align-items-center">
      <select 
        className = "form-control"
        id= "addInput"
        name= "interest"
        onChange={ handleInputChange }
        >
        <option value="">{t("Select")}</option>
        {interest.map((item) => (
          <option key={item.category} code={item.id}>
            {t(item.category)}
          </option>
        ))}
      </select>
    </div>
    </>
  );
};
