import { userConstants } from "../_constants";

export function users(state = {}, action) {
  switch (action.type) {
    case userConstants.FORGOT_PASSWORD_REQUEST:
      return {
        loading: true,
      };
    case userConstants.FORGOT_PASSWORD_SUCCESS:
      return {
        loading: false,
        me: action.me,
      };
    case userConstants.FORGOT_PASSWORD_FAILURE:
      return {
        loading: false,
        problem: action.error,
      };
    case userConstants.RESET_PASSWORD_REQUEST:
      return {
        loading: true,
      };
    case userConstants.RESET_PASSWORD_SUCCESS:
      return {
        loading: false,
        me: action.me,
      };
    case userConstants.RESET_PASSWORD_FAILURE:
      return {
        loading: false,
        problem: action.error,
      };
    case userConstants.REGISTER_2_REQUEST:
    case userConstants.REGISTER_3_REQUEST:
      return {
        loading: true,
      };
    case userConstants.REGISTER_2_SUCCESS:
    case userConstants.REGISTER_3_SUCCESS:
      return {
        loading: false,
        me: action.me,
      };
    case userConstants.REGISTER_2_FAILURE:
    case userConstants.REGISTER_3_FAILURE:
      return {
        loading: false,
        problem: action.error,
      };
    case userConstants.FOLLOWERS_REQUEST:
      return {
        charging: true,
      };
    case userConstants.FOLLOWERS_SUCCESS:
      return {
        followers: action.followers,
        following: action.following,
      };
    case userConstants.FOLLOWERS_FAILURE:
      return {
        charging: false,
        problem: action.error,
      };
    case userConstants.FAVORITES_REQUEST:
      return {
        charging: true,
      };
    case userConstants.FAVORITES_SUCCESS:
      return {
        favorites: action.favorites,
      };
    case userConstants.FAVORITES_FAILURE:
      return {
        charging: false,
        problem: action.error,
      };
    default:
      return state;
  }
}
