import React from "react";

export default function TagsCategories({ url, tags }) {
  const items = tags.split(",");

  return (
    <>
      {items.map((item, id) => (
        <a href="#" key={id} className="quick__cats__btn topics">
          {item}
        </a>
      ))}
    </>
  );
}
