import React from "react";
import { connect } from "react-redux";
import { modalActions } from "../../_actions";
import { useTranslation } from "react-i18next";
import ico_create from "../../_images/ico-floating-create.svg";
import ico_create_active from "../../_images/ico-floating-create-active.svg";

const CreateOption = ({ selected, showCreateContent }) => {
  const { t } = useTranslation("common");

  const handleClick = (e) => {
    e.preventDefault();
    showCreateContent();
  };

  return (
    <a href="/" onClick={handleClick}>
      <img
        src={selected === "create" ? ico_create_active : ico_create}
        alt={t("menus.content")}
      />
      <span>{t("menus.short_content")}</span>
    </a>
  );
};

const actionCreators = {
  showCreateContent: modalActions.showCreateContent,
};

const connectedCreateOption = connect(null, actionCreators)(CreateOption);
export { connectedCreateOption as CreateOption };
