import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { modalActions } from "../../_actions";
import { NewsletterForm } from "../forms";
import logo from "../../_images/techfrenetic-logo.svg";
import { appService } from "../../_services";

const Newsletter = ({ hideNewsletter, newsletter, config }) => {
  //Language hooks
  const { i18n, t } = useTranslation("common");
  const lang = i18n.language;

  const visibility = newsletter;
  let emailNew = "";
  const [step, setStep] = useState(1);
  const [categories, setCategories] = useState({});
  //Store categories.
  const [listCategories, setlistCategories] = useState([]);

  const handleClose = () => {
    setStep(1);
    hideNewsletter();
  };

  const getEmail = (email) => {
    emailNew = email.value;
  };

  const onSubmitHandler = (e) => {
    const sendId = [];
    e.preventDefault();
    setStep(2);

    listCategories.forEach((element) => {
      sendId.push(parseInt(element));
    });

    appService
      .postNewsletter(lang, emailNew, sendId)
      .then((res) => res.json())
      .then((response) => {
      })
      .catch(console.log);
    setlistCategories([]);
  };

  const handleChangeCheck = (e) => {
    let ctgSelect = [...listCategories];
    if (e.target.checked) {
      ctgSelect.push(e.target.value);
    } else {
      ctgSelect = ctgSelect.filter((item) => item !== e.target.value);
    }
    setlistCategories(ctgSelect);
  };

  useEffect(() => {
    //Assign categories.
    if (config) {
      const lmt1 = Math.ceil(config.categories[lang].length / 2);
      const lmt2 = config.categories[lang].length - lmt1;

      setCategories([
        config.categories[lang],
        config.categories[lang].slice(0, lmt1),
        config.categories[lang].slice(lmt2 + 1),
      ]);
    }
  }, [lang, config, setCategories]);

  return (
    <Modal show={visibility} onHide={hideNewsletter} animation={false}>
      {
        /* Form */
        step === 1 && (
          <div
            className="modal-dialog modal-dialog-centered newsletter__overlay"
            role="document"
          >
            <div className="modal-content">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={handleClose}
              >
                <span aria-hidden="true">×</span>
              </button>
              <img src={logo} alt="TechFrenetic" />
              <h2>
                <span className="blue__tittle">
                  {t("newsletter.ttl_suscribe")}
                </span>
              </h2>
              <span className="newsletter__sub">
                {t("newsletter.claim_intro")}
              </span>
              <span className="topics">{t("newsletter.choose_topics")}</span>

              {categories.length && (
                <NewsletterForm
                  emailNew={getEmail}
                  categories={categories}
                  handleChangeCheck={handleChangeCheck}
                  checkedCtg={listCategories}
                  onSuccess={onSubmitHandler}
                />
              )}
            </div>
          </div>
        )
      }

      {
        /* Confirmation */
        step === 2 && (
          <div
            className="modal-dialog modal-dialog-centered newsletter__overlay complete"
            role="document"
          >
            <div className="modal-content auto">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={handleClose}
              >
                <span aria-hidden="true">×</span>
              </button>
              <img src={logo} alt="TechFrenetic" />
              <span className="newsletter__complete">
                {t("newsletter.completed")}
              </span>
              <button
                className="btn btn-lg btn-primary"
                type="submit"
                onClick={handleClose}
              >
                {t("newsletter.ok")}
              </button>
            </div>
          </div>
        )
      }
    </Modal>
  );
};

function mapState(state) {
  const { newsletter } = state.modals;
  const { config } = state.app;
  return { newsletter, config };
}

const actionCreators = {
  hideNewsletter: modalActions.hideNewsletter,
};

const connectedNewsletter = connect(mapState, actionCreators)(Newsletter);
export { connectedNewsletter as Newsletter };
