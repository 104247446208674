import React, { useState, useEffect } from "react";
import { Link } from "@reach/router";
import Carousel from "react-bootstrap/Carousel";
import { useTranslation } from "react-i18next";
import { appService } from "../../_services";
import { url, path } from "../../_helpers";

import arrow_left from "../../_images/nav-arrow-left.svg";
import arrow_right from "../../_images/nav-arrow-right.svg";

export default function HomeCarrousel() {
  //Language hooks
  const { t, i18n } = useTranslation("common");
  const lang = i18n.language;

  const [index, setIndex] = useState(0);
  const [slides, setSlides] = useState(null);

  useEffect(() => {
    /** Call Services  */
    appService
      .getHero(lang)
      .then((res) => res.json())
      .then((response) => {
        setSlides(response);
      })
      .catch(console.log);
  }, [lang, setSlides]);

  const handlePrevious = (e) => {
    e.preventDefault();
    if (index > 0) {
      setIndex(index - 1);
    } else {
      setIndex(slides.length - 1);
    }
  };

  const handleNext = (e) => {
    e.preventDefault();
    if (index < slides.length - 1) {
      setIndex(index + 1);
    } else {
      setIndex(0);
    }
  };

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  //Loading
  if (!slides) {
    return null;
  }

  //Render
  return (
    <section className="home__slider__box">
      <Carousel
        activeIndex={index}
        onSelect={handleSelect}
        controls={false}
        indicators={false}
        slide={true}
      >
        {slides.map((slide, id) => (
          <Carousel.Item key={id}>
            <div className="container d-flex align-items-center">
              <div className="col-lg-6 col-md-12 home__slider__txt">
                <h1
                  dangerouslySetInnerHTML={{
                    __html: slide.claim,
                  }}
                ></h1>
                {slide.is_external === "False" ? (
                  <Link
                    to={url(slide.link)}
                    className="btn-secondary white btn-lg"
                  >
                    {slide.cta}
                  </Link>
                ) : (
                  <a
                    href={slide.link}
                    className="btn-secondary white btn-lg"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {slide.cta}
                  </a>
                )}
              </div>
              <div className="col-md-6 d-none d-lg-block">
                <img
                  itemProp="image"
                  src={path(slide.image)}
                  alt={slide.title}
                />
              </div>
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
      <div className="nav__slider align-items-center">
        <a className="left__nav__slider" href="./" onClick={handlePrevious}>
          <img src={arrow_left} alt={t("buttons.to_left")} />
        </a>
        <div className="nav__counter">
          <span>0{index + 1}</span> / 0{slides.length}
        </div>
        <a className="right__nav__slider" href="./" onClick={handleNext}>
          <img src={arrow_right} alt={t("buttons.to_right")} />
        </a>
      </div>
    </section>
  );
}
