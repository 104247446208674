import React, { useEffect } from "react";
import SEO from "../_components/common/seo";
import Layout from "../_components/common/layout";
import { useTranslation } from "react-i18next";
import { Stream } from "@cloudflare/stream-react";

import ico_back from "../_images/ico-back.svg";
import "../_styles/style.css";

const InternalCoursePage = (info) => {
  const { t, i18n } = useTranslation("common");
  const lang = i18n.language;

  const { location } = info;

  const handleBack = () => {
    window.history.back();
  };

  if (location.state === null) {
    window.location.href = `/${lang}`;
  }

  return (
    <Layout withFade={true} noFooter={true}>
      <SEO lang={lang} title={t("menus.community")} />

      <main
        role="main"
        className="no__top"
        itemScope=""
        itemProp="mainContentOfPage"
        itemType="http://schema.org/Blog"
      >
        <section>
          <div className="container">
            <div className="detail__header d-flex flex-column">
              <div className="detail__header__container">
                <div className="detail__container">
                  <button className="button-back" onClick={() => handleBack()}>
                    <img src={ico_back} alt="Back" />
                    {t("buttons.back")}
                  </button>
                  <h2>{location.state.info.i.title}</h2>
                </div>
              </div>

              <div className="detail__header__container__a">
                <Stream
                  controls
                  src={location.state.info.i.field_cloudflare_id}
                  section="wall"
                />
              </div>
              <div className="detail__header__container__alt">
                <hr />
                <label>{location.state.info.i.description}</label>
              </div>
              {/* <div className="detail__header__container__alt_block">
                <p>{resultStr}</p>
              </div>
              <div className="detail__header__container__alt_block">
                {arrayTags.map((tag, i) => (
                  <p className="detail__tags" key={i}>
                    {tag}
                  </p>
                ))}
              </div> */}
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default InternalCoursePage;
