import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { articleService } from "../../_services";

export default function Survey({ id }) {
  const [data, setData] = useState([]);
  const [dataResult, setDataResult] = useState([]);
  const [list, setList] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [setChecked] = useState(0);

  // const itemsResults = [];
  const { t, i18n } = useTranslation("common");
  const lang = i18n.language;

  const onClick = () => setShowResults(true);

  const handleOnClick = (index) => {
    setChecked(index);
  };

  useEffect(() => {
    const items = [];
    if (id) {
      articleService
        .getSurvey(lang)
        .then((res) => res.json())
        .then((response) => {
          response[0].questions.forEach((element) => {
            items.push(element);
          });
          setData(response[0]);
          setList(items);
        })
        .catch((error) => console.log(error));
    }
  }, [setData, setList, id, lang]);

  useEffect(() => {
    if (id) {
      articleService
        .getSurveyResult(lang)
        .then((res) => res.json())
        .then((response) => {
          setDataResult(response[0]);
        })
        .catch((error) => console.log(error));
    }
  }, [setDataResult, id, lang]);

  //Check survey exist
  if (!data.length) {
    return "";
  }

  return (
    <div>
      <div className="survey__questions">
        <h4 className="survey__title">{data.title}</h4>
        {showResults ? null : (
          <div className="survey__box">
            {list.map((item, index) => (
              <div className="form-check" key={"form-check" + index}>
                <label
                  className="form-check-label bullet"
                  htmlFor={"option" + index}
                >
                  {item}
                  <input
                    className="form-check-input"
                    type="radio"
                    name="survey-demo"
                    id={"option" + index}
                    value={"option" + index}
                    onClick={() => handleOnClick(index)}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
            ))}
          </div>
        )}

        {showResults ? (
          <div className="survey__results">
            <div className="survey__box results">
              <span className="results__title"> {t("survey.results")} </span>
              {dataResult.questions.map((item) => (
                <div className="result__survey">
                  <span>{Object.keys(item)}</span>
                  <div className="progress justify-content-between align-items-center">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: "62%" }}
                      aria-valuenow="62"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                    <span>{Object.values(item)}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : null}
      </div>

      {showResults ? null : (
        <button className="vote__survey btn-primary" onClick={onClick}>
          {t("survey.vote")}
        </button>
      )}
    </div>
  );
}
