import React from "react";
import FormValidator from "../../_helpers/form-validator";
import { userActions } from "../../_actions";
import { Spinner } from "../ui/spinner";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

import { Profession } from "../ui/profession";
import { Country } from "../ui/countries";
import { Gender } from "../ui/gender";

import "../../css/register-form1.css";

import avatar_01 from "../../_images/avatar-01.svg";
import avatar_02 from "../../_images/avatar-02.svg";
import avatar_03 from "../../_images/avatar-03.svg";
import avatar_04 from "../../_images/avatar-04.svg";
import avatar_05 from "../../_images/avatar-05.svg";
import avatar_06 from "../../_images/avatar-06.svg";
import avatar_07 from "../../_images/avatar-07.svg";
import avatar_08 from "../../_images/avatar-08.svg";
import avatar_09 from "../../_images/avatar-09.svg";
import avatar_10 from "../../_images/avatar-10.svg";
import avatar_11 from "../../_images/avatar-11.svg";
import avatar_12 from "../../_images/avatar-12.svg";
import avatar_13 from "../../_images/avatar-13.svg";
import avatar_14 from "../../_images/avatar-14.svg";
import avatar_15 from "../../_images/avatar-15.svg";
import avatar_16 from "../../_images/avatar-16.svg";
import avatar_17 from "../../_images/avatar-17.svg";
import avatar_18 from "../../_images/avatar-18.svg";
import avatar_19 from "../../_images/avatar-19.svg";
import avatar_20 from "../../_images/avatar-20.svg";
import avatar_21 from "../../_images/avatar-21.svg";
import avatar_22 from "../../_images/avatar-22.svg";
import avatar_23 from "../../_images/avatar-23.svg";
import avatar_24 from "../../_images/avatar-24.svg";
import avatar_25 from "../../_images/avatar-25.svg";
import avatar_26 from "../../_images/avatar-26.svg";
import avatar_27 from "../../_images/avatar-27.svg";
import avatar_28 from "../../_images/avatar-28.svg";
import avatar_29 from "../../_images/avatar-29.svg";

import Sms2 from "../ui/sms2";
import { CometChat } from "@cometchat-pro/chat";

class RegisterForm1 extends FormValidator {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      elements: {
        type: {
          value: "person",
          error: false,
          message: "",
        },
        name: {
          value: "",
          error: false,
          message: "",
        },
        email: {
          value: "",
          error: false,
          message: "",
        },
        password: {
          value: "",
          error: false,
          message: "",
          visible: false,
          criterias: {
            minlen: "",
            lowcaps: "",
            numbers: "",
          },
        },
        repassword: {
          value: "",
          error: false,
          message: "",
          visible: false,
        },
        terms: {
          value: "",
          error: false,
          message: "",
        },
        company: {
          value: "",
          error: false,
          message: "",
        },
        profession: {
          value: "",
          error: false,
          message: "",
        },
        country: {
          value: "",
          error: false,
          message: "",
        },
        gender: {
          value: "",
          error: false,
          message: "",
        },
        birthdate: {
          value: "",
          error: false,
          message: "",
        },
        bio: {
          value: "",
          error: false,
          message: "",
        },
        cellphone: {
          value: "",
          error: false,
          message: "",
        },
        avatar: {
          value: "avatar-01",
          error: false,
          message: "",
        },
        picture: {
          value: "",
          error: false,
          message: "",
        },
        sms: {
          value: "",
          error: false,
          message: "",
          code: false,
        },
        position: {
          value: "",
          error: false,
          message: "",
        },
      },
      type: "avatar",
      loading: false,
    };

    this.submitHandler = this.submitHandler.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.changeCellPhone = this.changeCellPhone.bind(this);
    this.changePassword = this.changePassword.bind(this);
    this.viewPassword = this.viewPassword.bind(this);
    this.viewPassword2 = this.viewPassword2.bind(this);
    this.handleChangeType = this.handleChangeType.bind(this);
    this.changeHandlerTerms = this.changeHandlerTerms.bind(this);
    this.nextStep = this.nextStep.bind(this);
    this.prevStep = this.prevStep.bind(this);
    this.changeSelectHandlerPro = this.changeSelectHandlerPro.bind(this);
    this.changeSelectHandlerCell = this.changeSelectHandlerCell.bind(this);
    this.changeSelectHandlerGen = this.changeSelectHandlerGen.bind(this);
    this.changeSelectHandlerCon = this.changeSelectHandlerCon.bind(this);

    this.cancelUpload = this.cancelUpload.bind(this);
    this.gotoPhoto = this.gotoPhoto.bind(this);
    this.onCropAvatar = this.onCropAvatar.bind(this);
    this.onCancelAvatar = this.onCancelAvatar.bind(this);
    // this.handleClick = this.handleClick.bind(this);
  }

  /* Form submit */
  submitHandler(e) {
    e.preventDefault();

    const isValid = this.validate(this.state.elements, [
      {
        field: "name",
        type: "required",
        message: "errors.name_required",
      },
      {
        field: "email",
        type: "required",
        message: "errors.email_required",
      },
      {
        field: "email",
        type: "email",
        message: "errors.email_format",
      },
      {
        field: "password",
        type: "required",
        message: "errors.password_required",
      },
      {
        field: "password",
        type: "min:8",
        message: "errors.password_minlength",
      },
      {
        field: "password",
        type: "hasnum",
        message: "errors.password_hasnum",
      },
      {
        field: "password",
        type: "lowercaps",
        message: "errors.password_lowercaps",
      },
      {
        field: "repassword",
        type: "required",
        message: "errors.repassword_required",
      },
      {
        field: "repassword",
        type: "equals:password",
        message: "errors.repassword_different",
      },
      {
        field: "terms",
        type: "required",
        message: "errors.terms_required",
      },
      {
        field: "profession",
        type: "required",
        message: "errors.profession_required",
      },

      {
        field: "country",
        type: "required",
        message: "errors.country_required",
      },
      {
        field: "gender",
        type: "required",
        message: "errors.gender_required",
      },
      {
        field: "birthdate",
        type: "required",
        message: "errors.birthdate_required",
      },
      {
        field: "bio",
        type: "required",
        message: "errors.bio_required",
      },
      {
        field: "cellphone",
        type: "phone",
        message: "errors.cellphone_required",
      },
      {
        field: "position",
        type: "required",
        message: "errors.position_required",
      },
    ]);

    if (isValid) {
      const {
        type,
        name,
        email,
        password,
        company,
        profession,
        country,
        gender,
        birthdate,
        bio,
        cellphone,
        avatar,
        position,
      } = this.state.elements;
      const lang = this.props.i18n.language;

      //Creando usuarios
      // let authKey = process.env.REACT_APP_COMETCHAT_AUTH_KEY;
      // var username = name.value;

      // var user = new CometChat.User(username);
      // user.setName(username);
      // CometChat.createUser(user, authKey).then(
      //   (user) => {
      //     console.log("Usuario creado", user);
      //   },
      //   (error) => {
      //     console.log("error", error);
      //   }
      // );

      this.props.register(lang, {
        type: type.value,
        name: name.value,
        email: email.value,
        password: password.value,
        company: company.value,
        profession: profession.value,
        country: country.value,
        gender: gender.value,
        birthdate: birthdate.value,
        bio: bio.value,
        cellphone: cellphone.value,
        avatar: avatar.value,
        position: position.value,
      });
    }
  }

  /* change step */

  nextStep = (e) => {
    e.preventDefault();
    const isValid = this.validate(this.state.elements, [
      {
        field: "name",
        type: "required",
        message: "errors.name_required",
      },
      {
        field: "email",
        type: "required",
        message: "errors.email_required",
      },
      {
        field: "email",
        type: "email",
        message: "errors.email_format",
      },
      {
        field: "password",
        type: "required",
        message: "errors.password_required",
      },
      {
        field: "password",
        type: "min:8",
        message: "errors.password_minlength",
      },
      {
        field: "password",
        type: "hasnum",
        message: "errors.password_hasnum",
      },
      {
        field: "password",
        type: "lowercaps",
        message: "errors.password_lowercaps",
      },
      {
        field: "repassword",
        type: "required",
        message: "errors.repassword_required",
      },
      {
        field: "repassword",
        type: "equals:password",
        message: "errors.repassword_different",
      },
      {
        field: "terms",
        type: "required",
        message: "errors.terms_required",
      },
    ]);

    if (isValid) {
      const { step } = this.state;
      this.setState({
        step: step + 1,
      });
    }
  };
  nextStep2 = (e) => {
    e.preventDefault();
    const isValid = this.validate(this.state.elements, [
      {
        field: "company",
        type: "company",
        message: "errors.company_required",
      },
      {
        field: "profession",
        type: "required",
        message: "errors.profession_required",
      },
      {
        field: "country",
        type: "required",
        message: "errors.country_required",
      },
      {
        field: "gender",
        type: "required",
        message: "errors.gender_required",
      },
      {
        field: "birthdate",
        type: "required",
        message: "errors.birthdate_required",
      },
      {
        field: "bio",
        type: "required",
        message: "errors.bio_required",
      },
      {
        field: "cellphone",
        type: "phone",
        message: "errors.cellphone_required",
      },
      {
        field: "position",
        type: "required",
        message: "errors.position_required",
      },
    ]);

    if (isValid) {
      const { step } = this.state;

      this.setState({
        step: step + 1,
      });
    }
  };

  nextStep3 = (e) => {
    e.preventDefault();
    const isValid = this.validate(this.state.elements, [
      {
        field: "sms",
        type: "required",
        message: "errors.sms_required",
      },
    ]);

    const { sms } = this.state.elements;

    if (sms.value === "12345") {
      sms.code = true;
    }

    if (isValid && sms.code) {
      const { step } = this.state;
      this.setState({
        step: step + 1,
      });
    }
  };

  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1,
    });
  };

  gotoPhoto() {
    const elements = this.state.elements;
    elements.avatar.value = "";
    this.setState({ type: "photo", elements: elements });
  }

  /* Cancel uplaod photo */
  cancelUpload(e) {
    e.preventDefault();
    const elements = this.state.elements;
    elements.avatar.value = "avatar-01";
    this.setState({ type: "avatar", elements: elements });
  }

  /* Selection of avatar */
  onCropAvatar(image) {
    const input = this.state.elements.picture;
    input.value = image.substr(image.indexOf(",") + 1);
    input.error = false;

    this.setState({ elements: this.state.elements });
  }

  /* Cancel selection of avatar */
  onCancelAvatar() {
    const input = this.state.elements.picture;
    input.value = "";
    input.error = false;

    this.setState({ elements: this.state.elements });
  }

  changeCellPhone() {
    const input = this.state.elements["cellphone"];
    console.log(input);
  }

  /* Controlled password */
  changePassword(e) {
    const value = e.target.value;
    const input = this.state.elements.password;
    let { criterias } = input;

    criterias.minlen = value.length >= 8;
    criterias.lowcaps = /^(?=.*[a-z])(?=.*[A-Z])/.test(value);
    criterias.numbers = /\d/.test(value);

    this.changeHandler(e);
  }

  /* Controlled checkbox */
  changeHandlerTerms(e) {
    const input = this.state.elements.terms;
    input.value = input.value ? "" : e.target.value;
    input.error = false;

    this.setState({ elements: this.state.elements });
  }

  /* Controlled radio */
  handleChangeType(e) {
    const input = this.state.elements.type;
    input.value = e.target.value;

    this.setState({ elements: this.state.elements });
  }

  /* Controlled input */
  changeHandler(e) {
    const input = this.state.elements[e.target.name];
    input.value = e.target.value;
    input.error = false;

    this.setState({ elements: this.state.elements });
  }

  changeSelectHandlerPro(e) {
    const input = this.state.elements["profession"];
    input.value = e.value;
    input.label = e.label;
    input.error = false;
    this.setState({ elements: this.state.elements });
  }
  changeSelectHandlerCell(e) {
    const input = this.state.elements["cellphone"];
    input.value = e.label;

    input.error = false;
    this.setState({ elements: this.state.elements });
  }
  changeSelectHandlerGen(e) {
    const input = this.state.elements["gender"];
    input.value = e.value;
    input.label = e.label;
    input.error = false;
    this.setState({ elements: this.state.elements });
  }
  changeSelectHandlerCon(e) {
    const input = this.state.elements["country"];
    input.value = e.value;
    input.label = e.label;
    input.error = false;
    this.setState({ elements: this.state.elements });
  }

  /* Show password */
  viewPassword = (e) => {
    e.preventDefault();
    this.state.elements.password.visible =
      !this.state.elements.password.visible;
    this.setState({ elements: this.state.elements });
  };

  /* Show password */
  viewPassword2 = (e) => {
    e.preventDefault();
    this.state.elements.repassword.visible =
      !this.state.elements.repassword.visible;
    this.setState({ elements: this.state.elements });
  };

  /* View */
  render() {
    const { t, i18n } = this.props;
    const lang = i18n.language;
    const {
      type,
      email,
      name,
      password,
      repassword,
      terms,
      company,
      bio,
      country,
      gender,
      profession,
      birthdate,
      cellphone,
      avatar,
      position,
    } = this.state.elements;

    const { step } = this.state;

    let pass_minlen = "";
    let pass_lowcaps = "";
    let pass_numbers = "";

    if (password.criterias.minlen === true) {
      pass_minlen = "validate";
    } else if (password.criterias.minlen === false) {
      pass_minlen = "error";
    }

    if (password.criterias.lowcaps === true) {
      pass_lowcaps = "validate";
    } else if (password.criterias.lowcaps === false) {
      pass_lowcaps = "error";
    }

    if (password.criterias.numbers === true) {
      pass_numbers = "validate";
    } else if (password.criterias.numbers === false) {
      pass_numbers = "error";
    }

    const appID = process.env.REACT_APP_COMETCHAT_APP_ID;
    const region = process.env.REACT_APP_COMETCHAT_REGION;
    const appSetting = new CometChat.AppSettingsBuilder()
      .subscribePresenceForAllUsers()
      .setRegion(region)
      .build();
    CometChat.init(appID, appSetting);

    switch (this.state.step) {
      case 1:
        return (
          <>
            <h1>
              <span className="title__highlight">{t("register.become")}.</span>
              <br />
              {t("register.ttl_easy")}
            </h1>
            <div className="registry__copy">{t("register.txt_claim")}</div>
            <form className="form-signin" onSubmit={this.nextStep}>
              {
                /* Error */
                this.props.problem && (
                  <div className={`form-label-group`}>
                    <div className="alert alert-danger text-center">
                      {t(`errors.${this.props.problem}`)}
                    </div>
                  </div>
                )
              }

              <div className="type__user">
                <span>{t("register.intro1")}</span>
                <div className="user__options d-flex">
                  {/* Person Check */}
                  <div className="form-check user__type">
                    <label
                      className="form-check-label bullet"
                      htmlFor="userTypePerson"
                    >
                      {t("register.opc_person")}
                      <input
                        className="form-check-input"
                        type="radio"
                        id="userTypePerson"
                        value="person"
                        checked={type.value === "person"}
                        onChange={this.handleChangeType}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>

                  {/* Company Check */}
                  <div className="form-check user__type">
                    <label
                      className="form-check-label bullet"
                      htmlFor="userTypeCompany"
                    >
                      {t("register.opc_company")}
                      <input
                        className="form-check-input"
                        type="radio"
                        id="userTypeCompany"
                        value="company"
                        checked={type.value === "company"}
                        onChange={this.handleChangeType}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>

              {
                /* DisplayName Person */
                <div
                  className={`form-label-group ${name.error ? "error" : ""}`}
                >
                  <input
                    type="text"
                    id="name"
                    name="name"
                    className="form-control"
                    placeholder={t("register.your_name")}
                    required=""
                    autoFocus=""
                    value={name.value}
                    onChange={this.changeHandler}
                  />
                  <label htmlFor="name">{t("register.your_name")}</label>
                  {name.error && (
                    <small id="passlHelp" className="form-text text-muted">
                      {t(name.message + "_person")}
                    </small>
                  )}
                </div>
              }

              {/* Email */}
              <div
                className={`form-label-group email__content ${
                  email.error ? "error" : ""
                }`}
              >
                <input
                  type="text"
                  id="email"
                  name="email"
                  className="form-control"
                  placeholder={t("register.form1_email")}
                  required=""
                  autoFocus=""
                  onChange={this.changeHandler}
                  value={email.value}
                  maxLength="80"
                />
                <label htmlFor="inputEmail">{t("register.form1_email")}</label>
                {email.error && (
                  <small id="passlHelp" className="form-text text-muted">
                    {t(email.message)}
                  </small>
                )}
              </div>

              {/* Password */}
              <div
                className={`form-label-group new__password confirmation1 ${
                  password.error ? "error" : ""
                }`}
              >
                <input
                  type={password.visible ? "text" : "password"}
                  id="password"
                  name="password"
                  className="form-control"
                  placeholder={t("register.form1_password")}
                  required=""
                  onChange={this.changePassword}
                  value={password.value}
                  maxLength="30"
                  autoComplete="off"
                />
                <div className="password__toggle">
                  <a
                    id="password__toggle"
                    href="/"
                    className="enable"
                    onClick={this.viewPassword}
                    tabIndex="-1"
                  >
                    {""}
                  </a>
                </div>
                <label htmlFor="inputPassword">
                  {t("register.form1_password")}
                </label>
                {password.error && (
                  <small id="passlHelp" className="form-text text-muted">
                    {t(password.message)}
                  </small>
                )}
              </div>

              {/* Repassword */}
              <div
                className={`form-label-group new__password confirmation2 ${
                  repassword.error ? "error" : ""
                }`}
              >
                <input
                  type={repassword.visible ? "text" : "password"}
                  id="repassword"
                  name="repassword"
                  className="form-control"
                  placeholder={t("register.form1_repassword")}
                  required=""
                  onChange={this.changeHandler}
                  value={repassword.value}
                  maxLength="30"
                  autoComplete="off"
                />
                <div className="password__toggle">
                  <a
                    id="passwordv__toggle"
                    href="/"
                    className="enable"
                    onClick={this.viewPassword2}
                    tabIndex="-1"
                  >
                    {""}
                  </a>
                </div>
                <label htmlFor="inputPassword">
                  {t("register.form1_repassword")}
                </label>
                {repassword.error && (
                  <small id="passlHelp" className="form-text text-muted">
                    {t(repassword.message)}
                  </small>
                )}
              </div>

              {/* Rules for Password */}
              <div className="password__validator">
                <span className="paas__instructions">
                  {t("register.pwd_intro")}:
                </span>
                <div className="password__conditions">
                  <div className={`condition__pass ${pass_minlen}`}>
                    <i className="far fa-check-circle"></i>
                    <span>{t("register.pwd_minchars")}</span>
                  </div>
                  <div className={`condition__pass ${pass_lowcaps}`}>
                    <i className="far fa-check-circle"></i>
                    <span>{t("register.pwd_lowper")}</span>
                  </div>
                  <div className={`condition__pass ${pass_numbers}`}>
                    <i className="far fa-check-circle"></i>
                    <span>{t("register.pwd_number")}</span>
                  </div>
                  <div className="clearfix"></div>
                </div>
              </div>

              {/* Terms */}
              <div className={`checkbox mb-3 ${terms.error ? "error" : ""}`}>
                <label className="checklist">
                  {`${t("register.terms_line1")} `}
                  <a
                    className="secondary__hyper"
                    href={`/${lang}/${t("url.sale")}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t("register.terms_link1")}
                  </a>
                  {` ${t("register.terms_line2")} `}
                  <a
                    className="secondary__hyper"
                    href={`/${lang}/${t("url.privacy")}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t("register.terms_link2")}.
                  </a>
                  <input
                    type="checkbox"
                    id="terms"
                    name="terms"
                    value="yes"
                    onChange={this.changeHandlerTerms}
                    defaultChecked={terms.value}
                  />
                  <span className="check-mark"></span>

                  {terms.error && (
                    <small className="form-text text-muted">
                      {t(terms.message)}
                    </small>
                  )}
                </label>
              </div>

              {/* Submit */}
              {this.props.loggingIn ? (
                <Spinner />
              ) : (
                <button
                  className="btn btn-lg btn-primary btn-block"
                  type="submit"
                >
                  {t("buttons.continue")}
                </button>
              )}
            </form>
          </>
        );

      case 2:
        return (
          <>
            <h1>
              <span className="title__highlight">
                {t("register.blue_create")}
              </span>
              <br />
              {t("register.black_create")}
            </h1>
            <form className="form-signin" onSubmit={this.submitHandler}>
              {
                /* Error */
                this.props.problem && (
                  <div className={`form-label-group`}>
                    <div className="alert alert-danger text-center">
                      {t(`errors.${this.props.problem}`)}
                    </div>
                  </div>
                )
              }

              {/* Company */}
              <div
                className={`form-label-group ${company.error ? "error" : ""}`}
              >
                <input
                  type="text"
                  id="company"
                  name="company"
                  className="form-control"
                  placeholder={t("register.your_company")}
                  autoFocus=""
                  value={company.value}
                  onChange={this.changeHandler}
                />
                <label htmlFor="company">{t("register.your_company")}</label>
                {company.error && (
                  <small className="form-text text-muted">
                    {t(company.message)}
                  </small>
                )}
              </div>

              {
                /* Profession */
                type.value === "person" && (
                  <div
                    className={`form-label-group profession ${
                      profession.error ? "error" : ""
                    }`}
                  >
                    <Profession
                      value={profession.label}
                      label={profession.label}
                      handler={this.changeSelectHandlerPro}
                    />

                    {profession.error && (
                      <small className="form-text text-muted ">
                        {t(profession.message)}
                      </small>
                    )}

                    <label htmlFor="companyName" className="no__floating">
                      {t("register.select_birthdate")}
                    </label>

                    <input
                      type="date"
                      id="birthdate"
                      className="form-control"
                      name="birthdate"
                      onChange={this.changeHandler}
                      value={birthdate.value}
                    />

                    {birthdate.error && (
                      <small id="passlHelp" className="form-text text-muted">
                        {t(birthdate.message)}
                      </small>
                    )}

                    <div
                      className={`form-label-group position ${
                        position.error ? "error" : ""
                      }`}
                    >
                      <label
                        htmlFor="inputDescription"
                        style={{
                          textAlign: "left",
                          paddingLeft: "0px",
                          width: "100%",
                          color: "#495057",
                        }}
                        className="no__floating"
                      >
                        {t("register.form2-position")}
                      </label>
                      <input
                        id="position"
                        name="position"
                        className="form-control"
                        style={{ width: "50%" }}
                        placeholder={t("register.form2_position")}
                        required=""
                        autoFocus=""
                        onChange={this.changeHandler}
                        defaultValue={position.value}
                      ></input>
                      {position.error && (
                        <small className="form-text text-muted">
                          {t(position.message)}
                        </small>
                      )}
                    </div>
                  </div>
                )
              }

              {
                /* Position */
                type.value === "company" && (
                  <>
                    <div
                      className={`half-l ${profession.error ? "error" : ""}`}
                    >
                      <Profession
                        value={profession.value}
                        label={profession.label}
                        handler={this.changeSelectHandlerPro}
                      />

                      {profession.error && (
                        <small className="form-text text-muted">
                          {t(profession.message)}
                        </small>
                      )}

                      <label
                        htmlFor="inputDescription"
                        className="no__floating"
                      >
                        {t("register.select_phone")}
                      </label>

                      <label htmlFor="companyName" className="no__floating">
                        {t("register.select_birthdate")}
                      </label>

                      <input
                        type="date"
                        id="birthdate"
                        className="form-control"
                        name="birthdate"
                        onChange={this.changeHandler}
                        value={birthdate.value}
                      />

                      {birthdate.error && (
                        <small id="passlHelp" className="form-text text-muted">
                          {t(birthdate.message)}
                        </small>
                      )}
                    </div>
                  </>
                )
              }

              {/* Country */}

              <div
                className={`form-label-group country ${
                  country.error ? "error" : ""
                }`}
              >
                <Country
                  value={country.label}
                  label={country.label}
                  handler={this.changeSelectHandlerCon}
                />

                {country.error && (
                  <small className="form-text text-muted">
                    {t(country.message)}
                  </small>
                )}

                <Gender
                  value={gender.label}
                  label={gender.label}
                  handler={this.changeSelectHandlerGen}
                />
                {gender.error && (
                  <small className="form-text text-muted">
                    {t(gender.message)}
                  </small>
                )}
              </div>

              {/* Biography */}
              <div
                className={`form-label-group no__floating description ${
                  bio.error ? "error" : ""
                }`}
              >
                <label htmlFor="inputDescription" className="no__floating">
                  {t("register.form2_description")}
                </label>
                <textarea
                  id="bio"
                  name="bio"
                  className="form-control"
                  placeholder={t("register.form2_bio")}
                  required=""
                  autoFocus=""
                  onChange={this.changeHandler}
                  defaultValue={bio.value}
                ></textarea>
                {bio.error && (
                  <small className="form-text text-muted">
                    {t(bio.message)}
                  </small>
                )}
              </div>

              {/* Submit */}
              {this.props.loading ? (
                <Spinner />
              ) : (
                <>
                  <button
                    className="btn btn-lg btn-primary btn-block"
                    onClick={this.nextStep2}
                  >
                    {t("buttons.continue")}
                  </button>
                  <button
                    className="btn btn-lg btn-outline-primary btn-block"
                    onClick={this.prevStep}
                  >
                    {t("buttons.back")}
                  </button>
                </>
              )}
            </form>
          </>
        );

      case 3:
        return (
          <>
            <h1>
              <span className="title__highlight">
                {t("register.blue_create")}
              </span>
              <br />
              {t("register.black_create")}
            </h1>
            <div className="registry__copy" style={{ marginBottom: "3rem" }}>
              {t("register.txt_phone")}
            </div>
            <Sms2
              cellphone={cellphone}
              nextStep={() => {
                this.setState({
                  step: step + 1,
                });
              }}
              loggingIn={this.props.loggingIn}
            />
            <button
              className="btn btn-lg btn-outline-primary btn-block mt-5"
              onClick={this.prevStep}
            >
              {t("buttons.back")}
            </button>
          </>
        );

      case 4:
        return (
          <>
            <h1>
              <span className="title__highlight">{`${t("register.say_hi")} ${
                name.value
              }`}</span>
              <br />
              {t("register.choose_image")}
            </h1>
            <form className="form__avatar" onSubmit={this.submitHandler}>
              <div className="all__avatars">
                <div className="other__avatar d-flex justify-content-between">
                  <span>{t("register.choose_avatar")}</span>
                  <div></div>
                </div>
                <div className="avatar__container">
                  <div className="form-check user__type">
                    <label
                      className="form-check-label bullet"
                      htmlFor="avatar1"
                    >
                      <img src={avatar_01} alt="Avatar" />
                      <input
                        className="form-check-input"
                        type="radio"
                        name="avatar"
                        id="avatar1"
                        value="avatar-01"
                        onChange={this.changeHandler}
                        checked={avatar.value === "avatar-01"}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <div className="form-check user__type">
                    <label
                      className="form-check-label bullet"
                      htmlFor="avatar2"
                    >
                      <img src={avatar_02} alt="Avatar" />
                      <input
                        className="form-check-input"
                        type="radio"
                        name="avatar"
                        id="avatar2"
                        value="avatar-02"
                        onChange={this.changeHandler}
                        checked={avatar.value === "avatar-02"}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <div className="form-check user__type">
                    <label
                      className="form-check-label bullet"
                      htmlFor="avatar3"
                    >
                      <img src={avatar_03} alt="Avatar" />
                      <input
                        className="form-check-input"
                        type="radio"
                        name="avatar"
                        id="avatar3"
                        value="avatar-03"
                        onChange={this.changeHandler}
                        checked={avatar.value === "avatar-03"}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <div className="form-check user__type">
                    <label
                      className="form-check-label bullet"
                      htmlFor="avatar4"
                    >
                      <img src={avatar_04} alt="Avatar" />
                      <input
                        className="form-check-input"
                        type="radio"
                        name="avatar"
                        id="avatar4"
                        value="avatar-04"
                        onChange={this.changeHandler}
                        checked={avatar.value === "avatar-04"}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <div className="form-check user__type">
                    <label
                      className="form-check-label bullet"
                      htmlFor="avatar5"
                    >
                      <img src={avatar_05} alt="Avatar" />
                      <input
                        className="form-check-input"
                        type="radio"
                        name="avatar"
                        id="avatar5"
                        value="avatar-05"
                        onChange={this.changeHandler}
                        checked={avatar.value === "avatar-05"}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <div className="form-check user__type">
                    <label
                      className="form-check-label bullet"
                      htmlFor="avatar6"
                    >
                      <img src={avatar_06} alt="Avatar" />
                      <input
                        className="form-check-input"
                        type="radio"
                        name="avatar"
                        id="avatar6"
                        value="avatar-06"
                        onChange={this.changeHandler}
                        checked={avatar.value === "avatar-06"}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <div className="form-check user__type">
                    <label
                      className="form-check-label bullet"
                      htmlFor="avatar7"
                    >
                      <img src={avatar_07} alt="Avatar" />
                      <input
                        className="form-check-input"
                        type="radio"
                        name="avatar"
                        id="avatar7"
                        value="avatar-07"
                        onChange={this.changeHandler}
                        checked={avatar.value === "avatar-07"}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <div className="form-check user__type">
                    <label
                      className="form-check-label bullet"
                      htmlFor="avatar8"
                    >
                      <img src={avatar_08} alt="Avatar" />
                      <input
                        className="form-check-input"
                        type="radio"
                        name="avatar"
                        id="avatar8"
                        value="avatar-08"
                        onChange={this.changeHandler}
                        checked={avatar.value === "avatar-08"}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <div className="form-check user__type">
                    <label
                      className="form-check-label bullet"
                      htmlFor="avatar9"
                    >
                      <img src={avatar_09} alt="Avatar" />
                      <input
                        className="form-check-input"
                        type="radio"
                        name="avatar"
                        id="avatar9"
                        value="avatar-09"
                        onChange={this.changeHandler}
                        checked={avatar.value === "avatar-09"}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <div className="form-check user__type">
                    <label
                      className="form-check-label bullet"
                      htmlFor="avatar10"
                    >
                      <img src={avatar_10} alt="Avatar" />
                      <input
                        className="form-check-input"
                        type="radio"
                        name="avatar"
                        id="avatar10"
                        value="avatar-10"
                        onChange={this.changeHandler}
                        checked={avatar.value === "avatar-10"}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <div className="form-check user__type">
                    <label
                      className="form-check-label bullet"
                      htmlFor="avatar11"
                    >
                      <img src={avatar_11} alt="Avatar" />
                      <input
                        className="form-check-input"
                        type="radio"
                        name="avatar"
                        id="avatar11"
                        value="avatar-11"
                        onChange={this.changeHandler}
                        checked={avatar.value === "avatar-11"}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <div className="form-check user__type">
                    <label
                      className="form-check-label bullet"
                      htmlFor="avatar12"
                    >
                      <img src={avatar_12} alt="Avatar" />
                      <input
                        className="form-check-input"
                        type="radio"
                        name="avatar"
                        id="avatar12"
                        value="avatar-12"
                        onChange={this.changeHandler}
                        checked={avatar.value === "avatar-12"}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <div className="form-check user__type">
                    <label
                      className="form-check-label bullet"
                      htmlFor="avatar13"
                    >
                      <img src={avatar_13} alt="Avatar" />
                      <input
                        className="form-check-input"
                        type="radio"
                        name="avatar"
                        id="avatar13"
                        value="avatar-13"
                        onChange={this.changeHandler}
                        checked={avatar.value === "avatar-13"}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <div className="form-check user__type">
                    <label
                      className="form-check-label bullet"
                      htmlFor="avatar14"
                    >
                      <img src={avatar_14} alt="Avatar" />
                      <input
                        className="form-check-input"
                        type="radio"
                        name="avatar"
                        id="avatar14"
                        value="avatar-14"
                        onChange={this.changeHandler}
                        checked={avatar.value === "avatar-14"}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <div className="form-check user__type">
                    <label
                      className="form-check-label bullet"
                      htmlFor="avatar15"
                    >
                      <img src={avatar_15} alt="Avatar" />
                      <input
                        className="form-check-input"
                        type="radio"
                        name="avatar"
                        id="avatar15"
                        value="avatar-15"
                        onChange={this.changeHandler}
                        checked={avatar.value === "avatar-15"}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <div className="form-check user__type">
                    <label
                      className="form-check-label bullet"
                      htmlFor="avatar16"
                    >
                      <img src={avatar_16} alt="Avatar" />
                      <input
                        className="form-check-input"
                        type="radio"
                        name="avatar"
                        id="avatar16"
                        value="avatar-16"
                        onChange={this.changeHandler}
                        checked={avatar.value === "avatar-16"}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <div className="form-check user__type">
                    <label
                      className="form-check-label bullet"
                      htmlFor="avatar17"
                    >
                      <img src={avatar_17} alt="Avatar" />
                      <input
                        className="form-check-input"
                        type="radio"
                        name="avatar"
                        id="avatar17"
                        value="avatar-17"
                        onChange={this.changeHandler}
                        checked={avatar.value === "avatar-17"}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <div className="form-check user__type">
                    <label
                      className="form-check-label bullet"
                      htmlFor="avatar18"
                    >
                      <img src={avatar_18} alt="Avatar" />
                      <input
                        className="form-check-input"
                        type="radio"
                        name="avatar"
                        id="avatar18"
                        value="avatar-18"
                        onChange={this.changeHandler}
                        checked={avatar.value === "avatar-18"}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <div className="form-check user__type">
                    <label
                      className="form-check-label bullet"
                      htmlFor="avatar19"
                    >
                      <img src={avatar_19} alt="Avatar" />
                      <input
                        className="form-check-input"
                        type="radio"
                        name="avatar"
                        id="avatar19"
                        value="avatar-19"
                        onChange={this.changeHandler}
                        checked={avatar.value === "avatar-19"}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <div className="form-check user__type">
                    <label
                      className="form-check-label bullet"
                      htmlFor="avatar20"
                    >
                      <img src={avatar_20} alt="Avatar" />
                      <input
                        className="form-check-input"
                        type="radio"
                        name="avatar"
                        id="avatar20"
                        value="avatar-20"
                        onChange={this.changeHandler}
                        checked={avatar.value === "avatar-20"}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <div className="form-check user__type">
                    <label
                      className="form-check-label bullet"
                      htmlFor="avatar21"
                    >
                      <img src={avatar_21} alt="Avatar" />
                      <input
                        className="form-check-input"
                        type="radio"
                        name="avatar"
                        id="avatar21"
                        value="avatar-21"
                        onChange={this.changeHandler}
                        checked={avatar.value === "avatar-21"}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <div className="form-check user__type">
                    <label
                      className="form-check-label bullet"
                      htmlFor="avatar22"
                    >
                      <img src={avatar_22} alt="Avatar" />
                      <input
                        className="form-check-input"
                        type="radio"
                        name="avatar"
                        id="avatar22"
                        value="avatar-22"
                        onChange={this.changeHandler}
                        checked={avatar.value === "avatar-22"}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <div className="form-check user__type">
                    <label
                      className="form-check-label bullet"
                      htmlFor="avatar23"
                    >
                      <img src={avatar_23} alt="Avatar" />
                      <input
                        className="form-check-input"
                        type="radio"
                        name="avatar"
                        id="avatar23"
                        value="avatar-23"
                        onChange={this.changeHandler}
                        checked={avatar.value === "avatar-23"}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <div className="form-check user__type">
                    <label
                      className="form-check-label bullet"
                      htmlFor="avatar24"
                    >
                      <img src={avatar_24} alt="Avatar" />
                      <input
                        className="form-check-input"
                        type="radio"
                        name="avatar"
                        id="avatar24"
                        value="avatar-24"
                        onChange={this.changeHandler}
                        checked={avatar.value === "avatar-24"}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <div className="form-check user__type">
                    <label
                      className="form-check-label bullet"
                      htmlFor="avatar25"
                    >
                      <img src={avatar_25} alt="Avatar" />
                      <input
                        className="form-check-input"
                        type="radio"
                        name="avatar"
                        id="avatar25"
                        value="avatar-25"
                        onChange={this.changeHandler}
                        checked={avatar.value === "avatar-25"}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <div className="form-check user__type">
                    <label
                      className="form-check-label bullet"
                      htmlFor="avatar26"
                    >
                      <img src={avatar_26} alt="Avatar" />
                      <input
                        className="form-check-input"
                        type="radio"
                        name="avatar"
                        id="avatar26"
                        value="avatar-26"
                        onChange={this.changeHandler}
                        checked={avatar.value === "avatar-26"}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <div className="form-check user__type">
                    <label
                      className="form-check-label bullet"
                      htmlFor="avatar27"
                    >
                      <img src={avatar_27} alt="Avatar" />
                      <input
                        className="form-check-input"
                        type="radio"
                        name="avatar"
                        id="avatar27"
                        value="avatar-27"
                        onChange={this.changeHandler}
                        checked={avatar.value === "avatar-27"}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <div className="form-check user__type">
                    <label
                      className="form-check-label bullet"
                      htmlFor="avatar28"
                    >
                      <img src={avatar_28} alt="Avatar" />
                      <input
                        className="form-check-input"
                        type="radio"
                        name="avatar"
                        id="avatar28"
                        value="avatar-28"
                        onChange={this.changeHandler}
                        checked={avatar.value === "avatar-28"}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <div className="form-check user__type">
                    <label
                      className="form-check-label bullet"
                      htmlFor="avatar29"
                    >
                      <img src={avatar_29} alt="Avatar" />
                      <input
                        className="form-check-input"
                        type="radio"
                        name="avatar"
                        id="avatar29"
                        value="avatar-29"
                        onChange={this.changeHandler}
                        checked={avatar.value === "avatar-29"}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <div className="clearfix"></div>
                </div>
              </div>

              <button
                className="btn btn-lg btn-primary btn-block"
                type="submit"
              >
                {t("buttons.finish")}
              </button>
              <button
                className="btn btn-lg btn-outline-primary btn-block"
                onClick={this.prevStep}
              >
                {t("buttons.back")}
              </button>
            </form>
          </>
        );
    }
  }
}

function mapState(state) {
  const { loggingIn, problem } = state.authentication;
  return { loggingIn, problem };
}

const actionCreators = {
  register: userActions.register,
};

const connectedRegisterForm1 = connect(
  mapState,
  actionCreators
)(withTranslation("common")(RegisterForm1));
export { connectedRegisterForm1 as RegisterForm1 };
