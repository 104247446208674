import React from "react";
import FormValidator from "../../_helpers/form-validator";
import { userActions } from "../../_actions";
import { modalActions } from "../../_actions";
import { Spinner } from "../ui/spinner";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";


class ForgotPassword extends FormValidator {
  constructor(props) {
    super(props);

    this.state = {
      elements: {
        email: {
          value: "",
          error: false,
          message: "",
        },
      },
    };

    this.submitHandler = this.submitHandler.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.handleBack = this.handleBack.bind(this);
  }

  /* Form submit */
  submitHandler(e) {
    e.preventDefault();

    const isValid = this.validate(this.state.elements, [
      {
        field: "email",
        type: "required",
        message: "errors.email_required",
      },
      {
        field: "email",
        type: "email",
        message: "errors.email_format",
      },
      
    ]);

    if (isValid) {
      const { email } = this.state.elements;
      const lang = this.props.i18n.language;
      this.props.forgot(lang, email.value);
    }
  }

  /* GO back to Login */
  handleBack(e) {
    e.preventDefault();
    this.props.showLogin();
  }

  /* Controlled input */
  changeHandler(e) {
    const input = this.state.elements[e.target.name];
    input.value = e.target.value;
    input.error = false;

    this.setState({ elements: this.state.elements });
  }

  render() {
    const { t } = this.props;
    const { email } = this.state.elements;

    return (
      <form className="form-signin password" onSubmit={this.submitHandler}>
        {
          /* Error */
          this.props.problem && (
            <div className={`form-label-group`}>
              <div className="alert alert-danger text-center">
                {t(`errors.${this.props.problem}`)}
              </div>
            </div>
          )
        }


        {/* Email */}
        <div
          className={`form-label-group email__content ${
            email.error ? "error" : ""
          }`}
        >
          <input
            type="text"
            id="email"
            name="email"
            className="form-control"
            placeholder={t("register.form1_email")}
            required=""
            autoFocus=""
            onChange={this.changeHandler}
            value={email.value}
            maxLength="80"
          />
          <label htmlFor="inputEmail">{t("register.form1_email")}</label>
          {email.error && (
            <small id="passlHelp" className="form-text text-muted">
              {t(email.message)}
            </small>
          )}
        </div>
       

        

        {/* Submit */}
        {this.props.loading ? (
          <Spinner />
        ) : (
          <>
            <button className="btn btn-lg btn-primary" type="submit">
              Send email
            </button>
            <div className="back__login">
              <a
                className="secondary__hyper underline"
                href="/"
                onClick={this.handleBack}
              >
                Back to login
              </a>
            </div>
          </>
        )}
      </form>
    );
  }
}

function mapState(state) {
  const { loading, problem } = state.users;
  return { loading, problem };
}

const actionCreators = {
  showLogin: modalActions.showLogin,
  forgot: userActions.forgotPassword,
};

const connectedForgotPassword = connect(
  mapState,
  actionCreators
)(withTranslation("common")(ForgotPassword));
export { connectedForgotPassword as ForgotPassword };
