import React from "react";
import ico_arrow from "../../_images/cards-arrow.svg";

export const EventSearch = ({ event }) => {
  return (
    <article
      itemScope=""
      itemType="http://schema.org/Article"
      className="col-12"
    >
      <div className="d-flex">
        <div className="col-8 row__article__left">
          <div className="article__information">
            <h2 itemProp="headline">
              <a href="/">Conference DMEXCO 2020 for Data Proffesionals</a>
            </h2>
          </div>
          <div className="date__place">
            <span className="city">Bogotá</span>
            <div className="d-flex flex-column flex-sm-row flex-md-row justify-content-between details__date">
              <div className="date">Jun 23, 2020</div>
              <div className="boton__date__cta">
                <a className="card__url" href="/">
                  View more
                  <span>
                    <img src={ico_arrow} alt="Go to" />
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-4 row__article__right">
          <figure className="article__image__content">
            <a href="/">
              <img
                itemProp="image"
                src="/images/temp/event-01.png"
                alt="Conference DMEXCO 2020 for Data Proffesionals"
              />
            </a>
          </figure>
        </div>
      </div>
    </article>
  );
};
