import { appConstants } from "../_constants";

export function app(state = {}, action) {
  switch (action.type) {
    case appConstants.INIT_REQUEST:
      return {
        loading: true,
      };
    case appConstants.INIT_SUCCESS:
      return {
        ...state,
        config: action.config,
        loading: false,
      };
    case appConstants.INIT_FAILURE:
      return {
        error: action.error,
        loading: false,
      };
    case appConstants.BANNERS_REQUEST:
      return {
        loading: true,
      };
    case appConstants.BANNERS_SUCCESS:
      return {
        ...state,
        banners: action.banners,
        loading: false,
      };
    case appConstants.BANNERS_FAILURE:
      return {
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
}
