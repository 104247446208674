import React, { useState, useEffect } from "react";
import { useLocation } from "@reach/router";
import { parse } from "query-string";
import SEO from "../_components/common/seo";
import Layout from "../_components/common/layout";
import { useTranslation } from "react-i18next";
import { articleService } from "../_services";
import { SearchFilters } from "../_components/search/search-filters";

import ArticleCategoryLatest from "../_components/ui/article-category-latest";

const RelatedPage = () => {
  const location = useLocation();
  const { tag } = parse(location.search);

  const { t, i18n } = useTranslation("common");
  const lang = i18n.language;
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    articleService
      .getArticleRelated(lang, null, tag)
      .then((res) => res.json())
      .then((response) => {
        setArticles(response);
      })
      .catch(console.log);
  }, [lang, tag, setArticles]);

  const handleCategoriesChange = (values) => {
    // this.setState({ categories: values });
  };

  return (
    <Layout>
      <SEO lang={lang} title={t("contents.related_title")} />
      <main
        role="main"
        itemScope=""
        itemProp="mainContentOfPage"
        itemType="http://schema.org/Blog"
      >
        <section className="search__results__box">
          <div className="container">
            <h1 className="search__results">
              {tag}: {articles.length}{" "}
              {`${t("search.results")}${articles.length !== 1 ? "s" : ""}`}
            </h1>
            <div className="details__filtering d-flex flex-column flex-sm-row flex-md-row align-items-center justify-content-start flex-nowrap">
              <SearchFilters setFilters={handleCategoriesChange} />
            </div>

            <div className="search__results__body d-flex flex-wrap">
              {/* Results Grid */}
              <div className="search__results__body__l col-12 col-sm-12 col-md-8 col-lg-8">
                {articles.map((article, id) => (
                  <ArticleCategoryLatest article={article} key={id} />
                ))}
              </div>

              {/* Ads Banners */}
              <div className="article__right__ads col-12 col-sm-12 col-md-4 col-lg-4">
                <div className="vertical__ads">
                  <img
                    src="/images/temp/article-ads-01.jpg"
                    alt="Article Ada"
                  />
                </div>
                <div className="vertical__ads">
                  <img
                    src="/images/temp/article-ads-02.jpg"
                    alt="Article Ada"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default RelatedPage;
