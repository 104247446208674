import React, { useEffect, useState } from "react";
import Fade from "@material-ui/core/Fade";
import logo_box from "../../_images/techfrenetic-logo.svg";
import { Sharer } from "./sharer";

export const ProgressBar = ({ id, title }) => {
  const [visibility, setVisibility] = useState(false);
  useEffect(() => {
    //Listen scroll when mouting.
    window.addEventListener("scroll", handleScroll);

    return () => {
      //Remove listener when unmount.
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  /* Listen scroll */
  const handleScroll = (e) => {
    if (window.scrollY > 0) {
      setVisibility(true);
    } else if (window.scrollY <= 0) {
      setVisibility(false);
    }

    //Control progress width
    var winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    var height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    var scrolled = (winScroll / height) * 100;
    document.getElementById("progressbar").style.width = scrolled + "%";
  };

  /* Render */
  return (
    <Fade in={visibility}>
      <div id="current__article" className="current__article">
        <div className="current__article__inner d-flex justify-content-between">
          <div className="article__title d-flex align-items-center col-10 col-sm-6 col-md-8">
            <div className="logo__box">
              <img src={logo_box} alt="Go to the home!" />
            </div>
            <h2>{title}</h2>
          </div>

          <Sharer
            id={id}
            className="floating__share__box d-flex justify-content-end col-2 col-sm-6 col-md-4"
          />
        </div>
        <div className="progress position-sticky">
          <div
            className="progress-bar"
            id="progressbar"
            role="progressbar"
            aria-valuenow="0"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
      </div>
    </Fade>
  );
};
