import React, { useState, useEffect } from "react";
import { taxonomys } from "../../_services/taxonomys.service";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { memo } from "react";

export const Country = ({ value, label, handler }) => {
  //Language hooks
  const { i18n, t } = useTranslation("common");
  const lang = i18n.language;
  const [country, setCountry] = useState([]);
  const options = [];
  useEffect(() => {
    /** Call Services  */
    taxonomys
      .getCountries(lang)
      .then((res) => res.json())
      .then((response) => {
        if (response) {
          setCountry(response);
        }
      })
      .catch(console.warn);

    return () => {
      setCountry([]);
    }
  }, [lang, setCountry]);

  country.map((item) => {
    options.push({
      value: item.id,
      label: t(item.category),
    });
  });


  return (
    <>
      <label htmlFor="inputDescription" className="no__floating">
            {t("register.select_country")}
          </label>
      <Select
        options={options}
        onChange={handler}
        defaultValue={{ label: value }}
      />
    </>
  );
};
