import React from "react";
import Layout from "../_components/common/layout";
import { useTranslation } from "react-i18next";
import SEO from "../_components/common/seo";
import "../css/terms.css";

const ReembolsoPage = () => {
  const { t, i18n } = useTranslation("common");
  const lang = i18n.language;
  return (
    <Layout>
      <SEO lang={lang} title={t("url.cookies")} />
      <main
        role="main"
        className="no__top"
        itemScope=""
        itemProp="mainContentOfPage"
        itemType="http://schema.org/Blog"
      >
        <div className="container d-flex  term_style">
          <div className="d-block">
            <h2>Politica de reembolso</h2>
            <br />
            <p>
              Dado que el Servicio ofrece bienes no tangibles e irrevocables, no
              proporcionamos reembolsos después de comprar el producto, lo cual
              usted reconoce antes de comprar cualquier producto en los
              Servicios. Asegúrese de haber leído detenidamente la descripción
              del servicio antes de realizar una compra.
            </p>
            <br />
            <h2>Contáctanos</h2>
            <br />
            <p>
              Si tiene alguna pregunta, inquietud o queja con respecto a esta
              Política, le animamos a que se ponga en contacto con nosotros
              utilizando los detalles a continuación:
            </p>
            <p>memo@techfrenetic.com</p>
            <span>
              Este documento fue actualizado por última vez el 17 de agosto de
              2021
            </span>
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default ReembolsoPage;
