import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { userService } from "../_services";
import Layout from "../_components/common/layout";
import SEO from "../_components/common/seo";
import ChatRoom from "./ChatRoom";


export default function ChatPage() {
  const [user, setUser] = useState([]);
  const { t, i18n } = useTranslation("common");
  const lang = i18n.language;


  useEffect(() => {
    const { id } = JSON.parse(localStorage.getItem("me"));

    userService
      .summary(lang, id)
      .then((res) => res.json())
      .then((response) => {
        const user = response[0];
        if (user) {
          setUser(user);
        }
      })
      .catch(console.log);
  }, [lang, setUser]);

  return (
    <Layout>
      <SEO lang={lang} title={t("profile.title")} />
      <main
        role="main"
        itemScope=""
        itemProp="mainContentOfPage"
        itemType="http://schema.org/Blog"
      ></main>
      <ChatRoom userInfo={user}/>
    </Layout>
  );
}
