import React, { useState, useEffect } from "react";
import { PostOwned } from "../ui/post-owned";
import { connect } from "react-redux";
import { PostQuote } from "../ui/post-quote";
import { Spinner } from "../ui/spinner";
import { LoadMore } from "../contents/load-more";
import { communityConstants } from "../../_constants";
import { communityService } from "../../_services";
import { useTranslation } from "react-i18next";

const MyActivity = ({ user }) => {
  const { i18n, t } = useTranslation("common");
  const lang = i18n.language;

  const [loading, setLoading] = useState(false);
  const [posts, setPosts] = useState([]);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);

  //Execute service call
  // eslint-disable-next-line
  const fetchResults = (lang, page) => {
    /** Call Services  */
    setLoading(true);
    communityService
      .getWallContent(lang, null, page)
      .then((res) => res.json())
      .then((response) => {
        if (response) {
          setTotal(response.results);
          setPosts(posts.concat(response.articles));
        }
        setLoading(false);
      })
      .catch(console.log);
  };

  const handleMore = () => {
    setPage(page + 1);
    fetchResults(lang, page + 1);
  };

  useEffect(() => {
    fetchResults(lang, page);
  }, [lang, page]);

  return (
    <div className="profile__body my__content">
      <div className="feeds__community my__acivity">
        <div class="my__acivity__title">
          <h1 class="activity__title">{t("menus.my_activity")}</h1>
        </div>

        {
          /* Render wall posts */
          posts.map((item, i) => {
            if (item.type === "Post" && item.user === user.current_user.name) {
              return (
                <>
                  <div class="activity__detail">
                    <p>
                      <strong>{item.display_name} </strong> shared a post
                    </p>
                  </div>
                  <PostQuote post={item} key={i} />
                </>
              );
            } else if (
              item.type === "Article" &&
              item.user === user.current_user.name
            ) {
              return (
                <>
                  <div class="activity__detail">
                    <p>
                      <strong>{item.display_name} </strong> post this
                    </p>
                  </div>
                  <PostOwned post={item} key={i} type="activity" />
                </>
              );
            }

            return null;
          })
        }
      </div>

      {
        /* Load More button */
        !loading ? (
          <LoadMore
            page={page}
            totalItems={total}
            itemsByPage={communityConstants.WALLPOSTS_BY_PAGE}
            onClick={handleMore}
          />
        ) : (
          <Spinner />
        )
      }
    </div>
  );
};

function mapState(state) {
  const { user } = state.authentication;
  return { user };
}

const connectedMyActivity = connect(mapState)(MyActivity);
export { connectedMyActivity as MyActivity };
