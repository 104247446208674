import React from "react";
import { Link } from "@reach/router";
import SEO from "../_components/common/seo";
import Layout from "../_components/common/layout";
import { useTranslation } from "react-i18next";

export default function AboutPage() {
  const { t, i18n } = useTranslation("common");
  const lang = i18n.language;

  return (
    <Layout>
      <SEO lang={lang} title={t("pages.title")} />
      <main
        className="home__page"
        role="main"
        itemScope=""
        itemProp="mainContentOfPage"
        itemType="http://schema.org/Blog"
      >
        <section className="body__text about">
          <div className="container">
            <div className="comment__style__box">
              <div className="inner">
                <h1>Hey, we're Techfrenetic.</h1>
              </div>
              <div className="triangle"></div>
            </div>

            <p>
              We were at Delaware USA, waiting for the CTO of a local company to
              present a proposal on CyberSecurity, when he arrived he told us:
              "Sorry for the delay, sometimes I spend so much time looking for
              answers, solutions and reliable providers on the internet that I
              spend the whole day without getting it, the worst thing is that
              there is so much information that I feel lost"...it was so easy to
              understand, in fact something similar was constantly happening to
              us.
            </p>
            <p>
              We talked about it afterwards on a beers night among friends from
              the Tech sector, we all agreed with the same idea:{" "}
            </p>
            <blockquote>
              Working in this sector from any position like production engineer,
              technology director, developer or technical specialist, we all
              suffer from trying to find solutions in innumerable web portals
              without feeling total reliability, astonished by so many paid
              comparison sites with advertising and so many good faith comments
              with unconfirmable information. Making a good decision seems like
              a trial and error practice”.
            </blockquote>
            <p>
              We realized that one of the factors that the clients we visited
              liked the most was the way we explained the solution we were
              presenting, we did not want to sell to the wrong client, so we did
              it with real cases and comparing the solution with those they
              existed in the market and the convenience of each one.
            </p>
            <p>
              They told us that they would love that all their decisions could
              be made based on a realistic, objective comparison, seeing the
              positive and negative side of each one and understanding that each
              solution can be the best depending on the situation.
            </p>
            <hr />
            <h2>What if we create a portal dedicated to that?</h2>
            <p>
              We can explain from our real experience, practical solutions
              giving a comparative vision to make decisions, understand
              opportunities and threats.
            </p>
            <p>
              Then we realized that it could not only be created by our team, we
              would lose the experience from so many friends and experts in the
              sector. Then TechFrenetic was born as a social portal for the Tech
              community.
            </p>
            <p>
              The story is just beginning, but intention, research and
              collaborators are a subject that has advanced.
            </p>
            <div className="comment__style__box present">
              <div className="inner">
                <p>
                  That is what we present to you now, if you want to be part of
                  this, it is your decision, if you only want to read, we will
                  be happy to become that source of information that you looking
                  for.
                </p>
              </div>
              <div className="triangle"></div>
            </div>

            <div className="frenetic__diference">
              <h2>What´s the ADN of a Tech Frenetic?</h2>
              <ul className="and__frenetic">
                <li>We are neutral</li>
                <li>We are tech passionate</li>
                <li>We are community lovers</li>
              </ul>
              <Link to={`/${lang}/${t("url.community")}`} className="btn-primary">
                Become frenetic
              </Link>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
}
