export function authHeader() {
  // return authorization header with jwt token
  let user = JSON.parse(localStorage.getItem("user"));

  if (user) {
    //return { 'Authorization': 'Bearer ' + user.token };
   
    return { "X-CSRF-Token": user.csrf_token };
  } else {
    return {};
  }
}

export function logoutHeader() {
  // return authorization header with jwt token
  let user = JSON.parse(localStorage.getItem("user"));

  if (user) {
    return { token: user.logout_token };
  } else {
    return {};
  }
}
