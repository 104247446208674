import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ico_bigstar from "../../_images/ico-bigstar.svg";
import { articleService } from "../../_services";

export default function Rater(props) {
  //Language
  const { t } = useTranslation("common");

  const stars = [5, 4, 3, 2, 1];
  const [starsSelected, selectStar] = useState(Math.ceil(props.qualification));
  const [qualification, setQualification] = useState(props.qualification);
  const [sum, setSum] = useState(props.sum);
  const [votes, setVotes] = useState(props.votes);
  const [blocked, setBlocked] = useState(false);

  const handleOnClick = (e) => {
    if (!blocked) {
      const value = Number(e.target.value);
      const total = sum + value;
      const rates = votes + 1;

      setQualification(total / rates);
      setVotes(rates);
      setSum(total);
      selectStar(value);
      setBlocked(true);

      //Persist vote
      articleService.rateArticle(props.id, value);
    }
  };

  return (
    <div
      className="rate__article d-flex flex-column flex-sm-row flex-md-row"
      itemProp="aggregateRating"
      itemScope=""
      itemType="http://schema.org/AggregateRating"
    >
      <div className="max__rating d-flex">
        <div className="big__rate">
          <img src={ico_bigstar} alt="Rate this" />
        </div>
        <div className="rate__numbers">
          <div className="rate__current">
            <span className="max__rating" itemProp="ratingValue">
              {qualification.toFixed(1)}
            </span>
            /{stars.length}
          </div>
          <span className="rates" itemProp="reviewCount">
            {
              //eslint-disable-next-line
              votes == 1
                ? `1 ${t("contents.one_vote")}`
                : `${votes} ${t("contents.some_votes")}`
            }
          </span>
        </div>
      </div>
      <div className="rate__this d-flex align-items-center">
        <div className="title__rate">
          <span>{t("contents.rate_this")}</span>
        </div>

        <form className="rating">
          {
            /* rating rating--item */
            stars.map((index) => (
              <div key={index} className="rating__item">
                <input
                  type="checkbox"
                  name="rating"
                  value={index}
                  id={index}
                  checked={index <= starsSelected}
                  onChange={handleOnClick}
                />
                <label htmlFor={index}></label>
              </div>
            ))
          }
        </form>
      </div>
    </div>
  );
}
