import React, { useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import ico_filters from "../../_images/ico-filters.svg";

export default function SearchFilters({ setFilters, config }) {
  //Language hooks
  const { t, i18n } = useTranslation("common");
  const lang = i18n.language;
  const [visibility, setVisibility] = useState(false);

  //Store categories.
  const [selectedCategories, setSelectedCategories] = useState([]);

  const handleFilterButton = (e) => {
    e.preventDefault();
    setVisibility(!visibility);
  };

  const handleClearFilters = (e) => {
    e.preventDefault();
    setSelectedCategories([]);
  };

  const handleChange = (e) => {
    let cats = [...selectedCategories];
    if (e.target.checked) {
      cats.push(e.target.value);
    } else {
      cats = cats.filter((item) => item !== e.target.value);
    }

    setSelectedCategories(cats);
  };

  const handleSendFilters = () => {
    setVisibility(false);
    setFilters(selectedCategories);
  };

  //Return labels for selected categories.
  const categoriesSelected = () => {
    const categories = [];
    config.categories[lang].forEach((item) => {
      if (selectedCategories.indexOf(item.id) >= 0) {
        categories.push(item.category);
      }
    });

    return categories.join(", ");
  };

  if (!config) {
    return "";
  }

  //Calculate how many by column.
  const categories = config.categories[lang];
  const limit = Math.ceil(categories.length / 3); //Display 3 columns.

  return (
    <>
      <a
        href="/"
        className="filter main__dropdown"
        id="search__filters"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        onClick={(e) => handleFilterButton(e)}
      >
        <img src={ico_filters} alt="Filter by" />
        <span>{t("search.filters")}</span>
      </a>
      {selectedCategories.length > 0 && (
        <div className="filter__ap">
          Filtered by category: {categoriesSelected()}
        </div>
      )}
      <div
        className={`dropdown-menu search__filters__box ${
          visibility ? "show" : ""
        }`}
        aria-labelledby="search__filters"
      >
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={handleFilterButton}
        >
          <span aria-hidden="true">×</span>
        </button>
        <span className="search__title__box">{t("search.categories")}</span>
        <div className="search__columns__box d-flex flex-column flex-sm-row flex-md-row flex-lg-row flex-wrap show">
          <div className="search__column col-12 col-sm-6 col-md-3 col-lg-3">
            {
              /* 1st Column */
              categories.slice(0, limit).map((item, id) => (
                <label className="checklist" key={id}>
                  {item.category}
                  <input
                    type="checkbox"
                    value={item.id}
                    onChange={handleChange}
                    checked={selectedCategories.indexOf(item.id) >= 0}
                  />
                  <span className="check-mark"></span>
                </label>
              ))
            }
          </div>

          <div className="search__column col-12 col-sm-6 col-md-3 col-lg-3">
            {
              /* 2nd Column */
              categories.slice(limit, limit * 2).map((item, id) => (
                <label className="checklist" key={id}>
                  {item.category}
                  <input
                    type="checkbox"
                    value={item.id}
                    onChange={handleChange}
                    checked={selectedCategories.indexOf(item.id) >= 0}
                  />
                  <span className="check-mark"></span>
                </label>
              ))
            }
          </div>

          <div className="search__column col-12 col-sm-6 col-md-3 col-lg-3">
            {
              /* 3rd Column */
              categories.slice(limit * 2).map((item, id) => (
                <label className="checklist" key={id}>
                  {item.category}
                  <input
                    type="checkbox"
                    value={item.id}
                    onChange={handleChange}
                    checked={selectedCategories.indexOf(item.id) >= 0}
                  />
                  <span className="check-mark"></span>
                </label>
              ))
            }
          </div>

          <div className="search__column col-12 col-sm-6 col-md-3 col-lg-3">
            <button
              type="button"
              className="btn-primary"
              onClick={handleSendFilters}
            >
              {t("search.btn_results")}
            </button>
            <a href="/" onClick={handleClearFilters}>
              <span>{t("search.btn_clear")}</span>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

function mapState(state) {
  const { config } = state.app;
  return { config };
}

const connectedSearchFilters = connect(mapState)(SearchFilters);
export { connectedSearchFilters as SearchFilters };
