import React from "react";
import Layout from "../_components/common/layout";
import { useTranslation } from "react-i18next";
import SEO from "../_components/common/seo";
import "../css/terms.css";

const InvitadosPage = () => {
  const { t, i18n } = useTranslation("common");
  const lang = i18n.language;
  return (
    <Layout>
      <SEO lang={lang} title={t("url.cookies")} />
      <main
        role="main"
        className="no__top"
        itemScope=""
        itemProp="mainContentOfPage"
        itemType="http://schema.org/Blog"
      >
        <div className="container d-flex  term_style">
          <div className="d-block">
            <h2>Acuerdo de publicación de invitados</h2>
            <br />
            <p>
              Gracias por mostrar interés en escribir una publicación de
              invitado para nosotros. Estamos contentos de que estés aquí.
            </p>
            <p>
              Tómese un momento para revisar toda esta página, ya que explica el
              directrices, el proceso de envío y otros detalles importantes.
            </p>
            <p>
              Esto asegurará que su envío cumpla con lo que estamos buscando. y
              mejorar sus posibilidades de publicar el artículo en nuestro sitio
              web.
            </p>
            <br />
            <h2>Directrices del artículo</h2>
            <br />
            <p>
              Antes de enviarnos su artículo, asegúrese de que sigue estos
              pautas:
            </p>
            <ul>
              <li>
                <p>El artículo debe tener un mínimo de 500 palabras</p>
              </li>
              <li>
                <p>El artículo debe estar libre de errores ortográficos</p>
              </li>
              <li>
                <p>El artículo debe estar libre de plagio</p>
              </li>
              <li>
                <p>
                  El artículo no debe violar ningún derecho de autor, patente o
                  marcas registradas
                </p>
              </li>
              <li>
                <p>
                  El artículo no debe contener contenido ilegal, odioso y
                  amenazante. idioma
                </p>
              </li>
              <li>
                <p>El artículo no debe contener blasfemias o lenguaje vulgar</p>
              </li>
              <li>
                <p>
                  El artículo puede incluir imágenes y videos relevantes con la
                  atribuciones, permiso de uso o licencias relacionadas
                </p>
              </li>
              <li>
                <p>
                  Puede incluir enlaces en el artículo siempre que hagan sentido
                  (sin enlaces de afiliados)
                </p>
              </li>
              <li>
                <p>El artículo debe enviarse en formato de texto sin formato</p>
              </li>
            </ul>
            <p>
              Puede enviar su artículo por correo electrónico a
              http://www.techfrenetic.com
            </p>
            <br />
            <h2>Propiedad y crédito</h2>
            <br />
            <p>
              Si su artículo es aceptado y publicado, su información será
              aparecer junto con ella. Como tal, asegúrese de incluir también
              una foto de ti mismo junto con la presentación de tu artículo.
            </p>
            <p>
              Todo lo enviado y publicado por nosotros seguirá siendo su
              propiedad. Usted entiende y acepta que nos da una exclusiva
              derecho a usar el artículo y los materiales relacionados según
              este convenio. Sin embargo, se le permite volver a publicar el
              artículo en su propio sitio web en cualquier momento después de
              que se haya publicado en nuestro sitio web.
            </p>
            <br />
            <h2>Proceso de revisión</h2>
            <br />
            <p>
              Haremos todo lo posible para revisar todos los artículos enviados
              lo antes posible. posible. Sin embargo, debido a la gran cantidad
              de envíos de artículos, recibe regularmente, puede llevar un
              tiempo responderle así que tenga paciencia y no vuelva a enviar el
              mismo artículo varias veces veces.
            </p>
            <p>
              No todos los artículos enviados serán aceptados. Nos reservamos el
              derecho rechazar cualquier artículo a nuestra sola discreción. Si
              su artículo es no aceptado, nos pondremos en contacto contigo para
              avisarte para que posiblemente podría hacer otra presentación.
            </p>
            <br />
            <h2>Publicación de artículos</h2>
            <p>
              Si su artículo es aceptado, será agregado a nuestra publicación.
              en cola y publicado de acuerdo con su programación.
            </p>
            <br />
            <h2>Promoción y compromiso</h2>
            <br />
            <p>
              Una vez que se publique su artículo, lo promocionaremos entre
              nuestra audiencia. para una exposición más amplia y una mejor
              participación. Esto puede incluir el envío un boletín, publicar en
              sus canales de redes sociales, hacer una anuncio o un enlace a él
              desde su propio sitio web.
            </p>
            <br />
            <h2>Aviso legal</h2>
            <br />
            <p>
              Este acuerdo de publicación de invitados ("Acuerdo") establece las
              condiciones generales lineamientos, requisitos, términos,
              condiciones, derechos y obligaciones cuando usted ("Escritor",
              "usted" o "su") desea enviar una artículo de publicación invitado
              ("Artículo") para el sitio web techfrenetic.com ("sitio web").
              Este Acuerdo es legalmente vinculante entre usted y TechFrenetic
              ("TechFrenetic", "nosotros", "nos" o "nuestro").
            </p>
            <p>
              Si está celebrando este acuerdo en nombre de una empresa o otra
              entidad legal, usted declara que tiene la autoridad para obligar a
              dicha entidad a este acuerdo, en cuyo caso los términos
              "escritor", "usted" o "su" se referirán a dicha entidad. Si lo
              haces no tiene tal autoridad, o si no está de acuerdo con el
              lineamientos, términos, condiciones, derechos u obligaciones, debe
              no acepta este acuerdo y no puede presentar ningún artículo.
            </p>
            <p>
              Al enviar un artículo para su publicación en el sitio web, usted
              reconoce que ha leído, entendido y acepta estar obligado por los
              términos de este Acuerdo. reconoces que esto Acuerdo es un
              contrato entre usted y TechFrenetic, aunque es electrónico y no
              está firmado físicamente por usted, y rige las presentaciones y
              publicaciones de sus artículos.
            </p>
            <br />
            <h2>Difamación y contenido censurable</h2>
            <br />
            <p>
              Valoramos la libertad de expresión y animamos a los Usuarios a ser
              respetuoso con el contenido que publica. No somos un editor de
              contenido del usuario y no están en condiciones de investigar la
              veracidad de demandas individuales por difamación o para
              determinar si ciertas el material que podamos encontrar objetable
              debe ser censurado. Sin embargo, nos reservamos el derecho de
              moderar, deshabilitar o eliminar cualquier contenido para evitar
              daños a otros o a nosotros o a los Servicios, como determinado a
              nuestro exclusivo criterio.
            </p>
            <ul>
              <li>
                Estado de contratista independiente
                <p>
                  Nada de lo contenido en este Acuerdo se interpretará como
                  crear o establecer cualquier sociedad o empresas conjuntas.
                  Usted reconoce que actúa como contratista independiente. Tú no
                  se consideran empleados y no tienen derecho a participar en
                  los planes, arreglos o distribuciones de TechFrenetic. No debe
                  actuar como agente de TechFrenetic y no debe proporcionar
                  ningún servicio bajo el nombre de TechFrenetic.
                </p>
                <p>
                  Usted no debe bajo ninguna circunstancia (i) entrar en ninguna
                  acuerdos en nombre de TechFrenetic, (ii) incurrir en cualquier
                  obligaciones en nombre de TechFrenetic, (iii) actuar por o
                  para vincular a TechFrenetic de ninguna manera, (iv) firmar el
                  nombre de TechFrenetic, (v) declara que TechFrenetic es de
                  alguna manera responsable de sus actos u omisiones (vi) se
                  refieren a TechFrenetic como cliente de cualquier manera o
                  formato, o (vii) usar nuestro logotipo o nombre de una manera
                  que implique que usted y TechFrenetic son socios o que te
                  hemos respaldado, tu sitio web o sus productos o servicios.
                  Debe obtener express permiso por escrito para cualquier uso de
                  los logotipos, marcas registradas o otra propiedad intelectual
                  que pertenezca a TechFrenetic.
                </p>
              </li>
              <li>
                Propiedad de la propiedad intelectual
                <p>
                  Al enviar un artículo, transfieres toda su propiedad derechos
                  de TechFrenetic y dar TechFrenetic un perpetuo, irrevocable,
                  mundial, no exclusiva, libre de regalías, derecho transferible
                  y totalmente sublicenciable y licencia para reproducir,
                  distribuir, exhibir públicamente, usar, realizar, hacer obras
                  derivadas o utilizar de otro modo el Artículo y la semejanza
                  en cualquier forma, medio o tecnología, ahora conocida o
                  desarrollada posteriormente para cualquier fin, incluidos
                  fines comerciales.
                </p>
              </li>
              <li>
                Limitación de responsabilidad
                <p>
                  En la máxima medida permitida por la ley aplicable, en ningún
                  caso TechFrenetic, sus afiliados, directores, funcionarios,
                  empleados, agentes, proveedores o licenciantes serán
                  responsables de cualquier persona por cualquier acto
                  indirecto, incidental, especial, punitivo, encubrimiento, o
                  daños consecuentes (incluyendo, sin limitación, daños por
                  lucro cesante, ingresos, ventas, fondo de comercio, uso de la
                  contenido, impacto en el negocio, interrupción del negocio,
                  pérdida de ahorros anticipados, pérdida de oportunidad de
                  negocio) sin embargo causado, bajo cualquier teoría de
                  responsabilidad, incluyendo, sin limitación, contrato,
                  agravio, garantía, incumplimiento de la ley deber, negligencia
                  o cualquier otra cosa, aun cuando el responsable haya sido
                  advertido sobre la posibilidad de tales daños o podría han
                  previsto tales daños. Usted asume toda la responsabilidad
                  legal por la precisión, el alcance, la calidad y cualquier
                  posible resultado como resultado de o relacionado con su
                  artículo.
                </p>
              </li>
              <li>
                Indemnización
                <p>
                  Usted acepta indemnizar y mantener a TechFrenetic y sus
                  afiliados, directores, funcionarios, empleados, agentes,
                  proveedores, y licenciantes de y contra cualquier
                  responsabilidad, pérdidas, daños o costos, incluidos los
                  abogados razonables honorarios, incurridos en relación con o
                  que surjan de cualquier tercero alegaciones, reclamos,
                  acciones, disputas o demandas de las partes afirmado contra
                  cualquiera de ellos como resultado de o en relación con su
                  Artículo, su uso de los Servicios o cualquier acto deliberado
                  mala conducta de su parte.
                </p>
              </li>
              <li>
                Divisibilidad
                <p>
                  Todos los derechos y restricciones contenidos en este Acuerdo
                  pueden ser ejercida y será aplicable y vinculante únicamente
                  para los medida en que no violen ninguna ley aplicable y sean
                  pretende limitarse en la medida necesaria para que no hará que
                  este Acuerdo sea ilegal, inválido o inaplicable Si alguna
                  disposición o parte de cualquier disposición de este Acuerdo
                  se considerará ilegal, inválido o inaplicable por un tribunal
                  de jurisdicción competente, es el intención de las partes de
                  que las restantes disposiciones o partes del mismo
                  constituirán su acuerdo con respecto al objeto del presente, y
                  todo lo restante disposiciones o partes de las mismas
                  permanecerán en pleno vigor y efecto.
                </p>
              </li>
              <li>
                Disputa de resolución
                <p>
                  La formación, interpretación y ejecución de este Acuerdo y
                  cualquier disputa que surja de él se regirá por las leyes
                  sustantivas y procesales de Colombia sin respecto a sus normas
                  sobre conflictos o elección de la ley aplicable y, a la en lo
                  aplicable, las leyes de Colombia. la exclusiva jurisdicción y
                  competencia para acciones relacionadas con la materia materia
                  del presente serán los tribunales ubicados en Colombia, y Ud.
                  someterse a la jurisdicción personal de dichos tribunales. Tú
                  por la presente renuncia a cualquier derecho a un juicio con
                  jurado en cualquier procedimiento que surja de o esté
                  relacionado con este Acuerdo. Los unidos Convención de las
                  Naciones Unidas sobre los Contratos de Compraventa
                  Internacional de Mercancías no se aplica a este Acuerdo.
                </p>
              </li>
              <li>
                Asignación
                <p>
                  No puede ceder, revender, sublicenciar o transferir de otro
                  modo o delegar cualquiera de sus derechos u obligaciones en
                  virtud del presente, en total o parcialmente, sin nuestro
                  consentimiento previo por escrito, que consentimiento será a
                  nuestra sola discreción y sin obligación; cualquier cesión o
                  transferencia será nula y vacío. Somos libres de ceder
                  cualquiera de sus derechos u obligaciones en virtud del
                  presente, en su totalidad o en parte, a cualquier tercero como
                  parte de la venta de todos o sustancialmente todos sus activos
                  o acciones o como parte de una fusión.
                </p>
              </li>
              <li>
                Cambios y enmiendas
                <p>
                  Nos reservamos el derecho de modificar este Acuerdo o sus
                  términos en en cualquier momento a nuestra discreción. Cuando
                  lo hagamos, revisaremos el fecha actualizada en la parte
                  inferior de esta página. Una versión actualizada de este
                  Acuerdo entrará en vigencia inmediatamente después de la
                  publicación del Acuerdo revisado a menos que se especifique lo
                  contrario. Su futuras presentaciones de artículos después de
                  la fecha de vigencia de la Acuerdo revisado (o cualquier otro
                  acto especificado en ese momento) constituirá su
                  consentimiento a dichos cambios.
                </p>
              </li>
              <li>
                Aceptación de estos términos
                <p>
                  Usted reconoce que ha leído este Acuerdo y acepta todos sus
                  términos y condiciones. Al enviar un artículo para publicación
                  en el sitio web, usted acepta estar sujeto a este Convenio. Si
                  no acepta cumplir con los términos de este Acuerdo, usted no
                  debe aceptar este acuerdo y no puede enviar cualquier
                  artículo.
                </p>
              </li>
            </ul>
            <br />
            <h2>Contáctanos</h2>
            <br />
            <p>
              Si tiene alguna pregunta, inquietud o queja con respecto a este
              Acuerdo, lo alentamos a que se comunique con nosotros utilizando
              los detalles a continuación:
            </p>
            <p>memo@techfrenetic.com</p>
            <span>
              {" "}
              Este documento fue actualizado por última vez el 17 de agosto de
              2021
            </span>
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default InvitadosPage;
