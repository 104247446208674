import React from "react";
import { ElementsConsumer, CardElement } from "@stripe/react-stripe-js";
import CardSection from "./CardSection";
import { userService } from "../_services";
import ModalSuccess from "./ModalSuccess";
import ModalRejected from "./ModalRejected";

class CheckoutForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      success: {
        value: "",
      },

      showModalSuccess: false,
      showModalRejected: false,
    };
  }

  handleSubmit = async (e) => {
    e.preventDefault();

    const { stripe, elements } = this.props;
    if (!stripe || !elements) {
      return;
    }

    const card = elements.getElement(CardElement);
    const result = await stripe.createToken(card);

    const {  paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: card,
    });

    const methodId = paymentMethod.id;

    this.paymentStripeForm(result.token.card, methodId);

    if (result.error) {
      console.log(result.error.message);
    } else {
      console.log(result.token);
    }
  };

  paymentStripeForm(token, methodId) {
    const { custom_language, name, custom_user_email } = JSON.parse(
      localStorage.getItem("me")
    );

    const { id, exp_month, exp_year } = token;
    const lang = custom_language;
    let month = "";

    if (exp_month < 10) {
      month = String(`0${exp_month}`);
    } else {
      month = String(exp_month);
    }

    let year = String(exp_year).substr(-2);

    const { success } = this.state;

    userService
      .paymentStripe(lang, custom_user_email, name, id, month, year, methodId)
      .then((res) => res.json())
      .then((response) => {
        success.value = response.state[0].value;
      }).then(() => {
        this.successHandler()
      })
      .catch(console.log);
  }

  successHandler() {
    const { success } = this.state;

    if (success.value === "completed") {
      this.setState({ showModalSuccess: true });
    } else {
      this.setState({ showModalRejected: true });
    }
  }

  render() {
    const { showModalSuccess, showModalRejected } = this.state;

    return (
      <div>
        <form onSubmit={this.handleSubmit} className="form__payments__stripe">
          <CardSection propStripe={!this.props.stripe} />
        </form>
        {showModalSuccess && <ModalSuccess success={showModalSuccess} />}
        {showModalRejected && <ModalRejected rejected={showModalRejected} />}
      </div>
    );
  }
}

export default function InjectedCheckoutForm() {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <CheckoutForm stripe={stripe} elements={elements} />
      )}
    </ElementsConsumer>
  );
}
