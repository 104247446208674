import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { userService } from "../../_services";
import { Avatar } from "../users/avatar";

export default function AuthorFace({ id, onData }) {
  //Language hooks
  const { i18n } = useTranslation("common");
  const lang = i18n.language;
  const [user, setUser] = useState({});

  useEffect(() => {
    /** Call Services  */
    if (id) {
      userService
        .summary(lang, id)
        .then((res) => res.json())
        .then((response) => {
          const data = response[0];
          if (data) {
            setUser(data);
            onData(data);
          }
        })
        .catch(console.log);
    }
  }, [lang, id, setUser]);

  if (!user) {
    return null;
  }

  return <Avatar user={user} alt={user.display_name} />;
}
