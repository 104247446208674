import React, { useState, useEffect } from "react";
import Layout from "../_components/common/layout";
import SEO from "../_components/common/seo";
import Rater from "../_components/contents/rater";
import Author from "../_components/contents/author";
import SharerMobile from "../_components/contents/sharer-mobile";
import { Comments } from "../_components/contents/comments";
import { Sharer } from "../_components/contents/sharer";
import { Level } from "../_components/contents/level";
import { PublishDate } from "../_components/ui/publish-date";
import { CategoryLink } from "../_components/ui/category-link";
import { Spinner } from "../_components/ui/spinner";
import { useTranslation } from "react-i18next";
import { articleService } from "../_services";
import { appConstants } from "../_constants";
import { path } from "../_helpers";
import he from "he";

import { ArticleNext } from "../_components/ui/article-next";
import TagsCategories from "../_components/common/tagsCategories";
import { TopArticles } from "../_components/lists/top-articles";
import { ProgressBar } from "../_components/contents/progress-bar";

import Survey from "../_components/contents/survey";



const ArticlePage = ({ slug }) => {
  //Language hooks
  const { i18n, t } = useTranslation("common");
  const lang = i18n.language;
  const [article, setArticle] = useState(null);

 

  useEffect(() => {
    /** Call Services  */
   
    articleService
      .getInfoArticle(lang, `${t("url.contents")}/${slug}`)
      .then((res) => res.json())
      .then((response) => {
        if (response[0]) {
          setArticle(response[0]);
        }
      })
      .catch(console.log);
  }, [lang, slug, setArticle]);

  //If no article loaded.
  if (!article) {
    return (
      <Layout withFade={true}>
        <SEO lang={lang} title={t("helpers.loading")} />
        <Spinner />
      </Layout>
    );
  }

  //If article already loaded.
  return (
    <Layout withFade={true}>
      <SEO
        lang={lang}
        title={he.decode(article.title)}
        description={article.summary}
      />

      {/* Beginning of the floating heeader of article */}
      <ProgressBar id={article.id} title={he.decode(article.title)} />

      <main
        className={`contents__inner ${
          article.is_video === "True" ? "video__article" : ""
        }`}
        role="main"
        itemScope=""
        itemProp="mainContentOfPage"
        itemType="http://schema.org/Blog"
      >

        <article itemScope="" itemType="http://schema.org/Article">
          <section className="main__content">
            {/* Headline */}
            <div className="article__headline">
              <div className="share__and__cat d-flex align-items-center justify-content-between">
                <div className="article__cat d-flex">
                  <p className="article__cat__tag" rel="category">
                    <CategoryLink name={article.category} />
                  </p>
                  <span className="article__cat__separator">•</span>
                  <PublishDate
                    className="article__hour__tag"
                    date={article.date}
                  />
                </div>
                <Sharer
                  id={article.id}
                  className="floating__share__box d-flex"
                />
              </div>

              {/* Title */}
              <h1 itemProp="headline">
                <a href="/">{he.decode(article.title)}</a>
              </h1>

              {/* Info */}
              <div
                className={`autor__container d-flex align-items-center ${
                  article.is_video === "False"
                    ? "justify-content-start"
                    : "justify-content-between col-md-10 col-lg-10"
                }`}
              >
                <div className="d-flex align-items-center justify-content-between">
                  {/* Author */}
                  <Author id={article.user} name={article.display_name} />

                  {/* Comments number */}
                  <div className="comments__counter d-flex">
                    <a href={`#${t("contents.some_comments")}`}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                      >
                        <path
                          d="M21 2.8L21 14.8C21 15.8 20.2 16.6 19.2 16.6L9 16.6 4.6 21 4.6 16.6 2.8 16.6C1.8 16.6 1 15.8 1 14.8L1 2.8C1 1.8 1.8 1 2.8 1L19.2 1C20.2 1 21 1.8 21 2.8Z"
                          fill="#EFEFEF"
                        />
                        <path
                          d="M7.3 12C7.2 12 7 11.9 6.9 11.8L4.2 9.5C4.1 9.4 4 9.2 4 9 4 8.8 4.1 8.6 4.2 8.5L6.9 6.2C7.2 5.9 7.6 6 7.8 6.2 8.1 6.5 8 7 7.8 7.2L5.7 9 7.8 10.8C8 11 8.1 11.5 7.8 11.8 7.7 11.9 7.5 12 7.3 12L7.3 12Z"
                          fill="#021431"
                        />
                        <path
                          d="M13.7 12C13.5 12 13.3 11.9 13.2 11.8 12.9 11.5 13 11 13.2 10.8L15.3 9 13.2 7.2C13 7 12.9 6.5 13.2 6.2 13.4 6 13.8 5.9 14.1 6.2L16.8 8.5C16.9 8.6 17 8.8 17 9 17 9.2 16.9 9.4 16.8 9.5L14.1 11.8C14 11.9 13.8 12 13.7 12L13.7 12Z"
                          fill="#021431"
                        />
                        <path
                          d="M9.6 13C9.6 13 9.5 13 9.5 13 9.1 12.9 8.9 12.5 9 12.1L10.7 5.5C10.8 5.1 11.2 4.9 11.5 5 11.9 5.1 12.1 5.5 12 5.9L10.3 12.5C10.2 12.8 9.9 13 9.6 13Z"
                          fill="#021431"
                        />
                        <path
                          d="M7 19L7 16.6 5.1 16.6C4 16.6 3.2 15.8 3.2 14.8L3.2 2.8C3.2 1.8 4 1 5.1 1L2.9 1C1.9 1 1 1.8 1 2.8L1 14.8C1 15.8 1.9 16.6 2.9 16.6L4.8 16.6 4.8 21 7 19Z"
                          fill="#D5DBE1"
                        />
                        <path
                          d="M4.1 21.9C4.2 22 4.3 22 4.4 22 4.5 22 4.7 21.9 4.8 21.8L9.2 17.4 19.5 17.4C20.9 17.4 22 16.3 22 14.9L22 2.5C22 1.1 20.9 0 19.5 0L2.5 0C1.1 0 0 1.1 0 2.5L0 14.9C0 16.3 1.1 17.4 2.5 17.4L3.7 17.4 3.7 21.3C3.7 21.6 3.8 21.8 4.1 21.9ZM1.4 2.5C1.4 1.9 1.9 1.4 2.5 1.4L19.5 1.4C20.1 1.4 20.6 1.9 20.6 2.5L20.6 14.9C20.6 15.5 20.1 16 19.5 16L8.9 16C8.8 16 8.6 16.1 8.5 16.2L5 19.7 5 16.7C5 16.3 4.7 16 4.4 16L2.5 16C1.9 16 1.4 15.5 1.4 14.9L1.4 2.5Z"
                          fill="#021431"
                        />
                      </svg>
                      <span>
                        {
                          //eslint-disable-next-line
                          article.comments == 1
                            ? `1 ${t("contents.one_comment")}`
                            : `${article.comments} ${t(
                                "contents.some_comments"
                              )}`
                        }
                      </span>
                    </a>
                  </div>
                </div>

                {/* Level */}
                <div>
                  {article.is_video === "True" && article.level && (
                    <Level grade={article.level} />
                  )}
                </div>
              </div>
            </div>

            {/* Body */}
            <div className="container d-flex flex-column flex-sm-column flex-md-row flex-lg-row">
              <div
                className={`col article__body ${
                  article.is_video === "True" ? "col-md-10 col-lg-10" : ""
                }`}
              >
                {
                  /* Image */
                  article.is_video !== "True" && (
                    <figure className="article__image__content figure-caption">
                      <a href="/">
                        <img
                          itemProp="image"
                          src={path(article.image)}
                          alt={article.title}
                        />
                      </a>
                      <figcaption>{article.caption}</figcaption>
                    </figure>
                  )
                }

                {
                  /* Video */
                  article.is_video === "True" && article.video && (
                    <video width="320" height="240" controls class="mb-5">
                      <source src={path(article.video)} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  )
                }

                {
                  /* Body */
                  React.createElement("div", {
                    dangerouslySetInnerHTML: {
                      __html: he.decode(article.body),
                    },
                  })
                }

                {
                  /* Related Article */
                  article.related_link && (
                    <ArticleNext
                      slug={String(article.related_link).substr(
                        appConstants.CHR
                      )}
                    />
                  )
                }

                {/* Article questions */}
                <Survey id={article.poll} />

                {
                  /* Related Categories */
                  article.tags && (
                    <div className="related__topics">
                      <div className="popular__s">{t("articles.related")}</div>
                      <div className="quick__cats d-flex flex-wrap">
                        <TagsCategories
                          url={`/${lang}/${t("url.results")}`}
                          tags={article.tags}
                        />
                      </div>
                    </div>
                  )
                }
                {
                  //eslint-disable-next-line
                  <a id={t("contents.some_comments")} />
                }

                <SharerMobile />
                <Rater
                  id={article.id}
                  qualification={Number("0" + article.qualification)}
                  votes={Number("0" + article.votes)}
                  sum={Number("0" + article.sum)}
                />

                <Comments id={article.id} quantity={article.comments} />
              </div>
              <div
                className={`article__right__ads ${
                  article.type === "video"
                    ? "col-md-2 col-lg-2"
                    : "col-md-4 col-lg-4"
                }`}
              ></div>
            </div>
          </section>
        </article>

        {/* Display a list of related articles. */}
        <TopArticles notId={article.id} category={article.category} />
      </main>
    </Layout>
  );
};

export default ArticlePage;
