import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Follower } from "../ui/follower";
import { useTranslation } from "react-i18next";

export const FollowersAll = ({
  followers,
  following,
  show,
  selected,
  onHide,
}) => {
  //Language hooks
  const { t } = useTranslation("common");
  const [activeTab, setActiveTab] = useState("1");

  useEffect(() => {
    setActiveTab(selected === "followers" ? "1" : "2");
  }, [selected, setActiveTab]);

  const onActiveTab = (e, tab) => {
    e.preventDefault();
    setActiveTab(tab);
  };

  if (!followers && !following) {
    return null;
  }

  return (
    <Modal show={show} onHide={onHide} centered>
      <div
        id="followers__box"
        tabIndex="followers__box"
        role="dialog"
        aria-labelledby="followers__box"
        aria-hidden="true"
      >
        <div className="modal-dialog follows" role="document">
          <div className="modal-content auto">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={onHide}
            >
              <span aria-hidden="true">×</span>
            </button>
            <div className="mobile__bar d-flex align-items-center">
              <span className="title__profile">{t("menus.my_profile")}</span>
            </div>
            <div className="follow__modal__tabs">
              <a
                href="#/"
                className={activeTab === "1" ? "active" : ""}
                onClick={(e) => onActiveTab(e, "1")}
              >
                {t("profile.followers")} ({followers.length})
              </a>
              <a
                href="#/"
                className={activeTab === "2" ? "active" : ""}
                onClick={(e) => onActiveTab(e, "2")}
              >
                {t("profile.following")} ({following.length})
              </a>
            </div>

            {
              /* List of users */
              activeTab === "1" ? (
                <div className="follows__listing">
                  {followers.map((user, i) => (
                    <Follower data={user} key={i} />
                  ))}
                </div>
              ) : (
                <div className="follows__listing following">
                  {following.map((user, i) => (
                    <Follower data={user} key={i} />
                  ))}
                </div>
              )
            }
          </div>
        </div>
      </div>
    </Modal>
  );
};
