import React from "react";
import ReactDOM from "react-dom";
import { App } from "./App";
import { I18nextProvider } from "react-i18next";
import i18n from "./_translations/i18n";
import { Provider } from "react-redux";
import { store } from "./_helpers";
import * as serviceWorker from "./_helpers/service-worker";

ReactDOM.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <App language={i18n.language} />
    </I18nextProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
