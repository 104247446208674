import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { userService } from "../../_services";

import ico_video from "../../_images/ico-video.svg";
import ico_publish from "../../_images/ico-publish.svg";
import ico_access from "../../_images/ico-access.svg";
import ico_community from "../../_images/ico-community.svg";
import ico_hands from "../../_images/ico-hands.svg";

export const PremiumSuccess = () => {
  const { t, i18n } = useTranslation("common");
  const lang = i18n.language;
  const { id } = JSON.parse(localStorage.getItem("me"));

  useEffect(() => {
    userService.userPremium(id).then().catch(console.log);
  }, [id]);

  return (
    <div
      className="modal-dialog modal-dialog-centered modal-lg"
      role="document"
    >
      <div className="modal-content welcome">
        <div className="welcome__content">
          <img
            className="welcome__ico"
            src={ico_hands}
            alt={t("register.welcome")}
          />
          <h1 className="welcome__title">
            <span className="title__highlight welcome">
              {`${t("register.user_premium")}`}
            </span>
            <h4 className="welcome__title">
              <span className="title__highlight welcome">
                {`${t("register.user_premium_msg")}`}
              </span>
              <br />
            </h4>
          </h1>

          <span className="you__can">{t("register.you_can")}</span>
          <div className="highlights__box row">
            <div className="highlight__01">
              <div className="highlight__ico">
                <img src={ico_publish} alt="Publish" />
              </div>
              <div className="highlight__txt">{t("register.advice1")}</div>
            </div>
            <div className="highlight__02">
              <div className="highlight__ico">
                <img src={ico_video} alt="Highlight" />
              </div>
              <div className="highlight__txt">{t("register.advice2")}</div>
            </div>
          </div>
          <div className="highlights__box row">
            <div className="highlight__01">
              <div className="highlight__ico">
                <img src={ico_access} alt="Access" />
              </div>
              <div className="highlight__txt">{t("register.advice3")}</div>
            </div>
            <div className="highlight__02">
              <div className="highlight__ico">
                <img src={ico_community} alt="Community" />
              </div>
              <div className="highlight__txt">{t("register.advice4")}</div>
            </div>
          </div>
          <a
            href={`/${lang}/`}
            className="btn btn-lg btn-primary float-left"
            type="button"
            data-dismiss="modal"
            aria-label="Close"
          >
            {t("buttons.start_now")}
          </a>
        </div>
      </div>
    </div>
  );
};

export default PremiumSuccess;
