import React, { useState, useEffect } from "react";
import Fade from "@material-ui/core/Fade";
import { useTranslation } from "react-i18next";
import { Link } from "@reach/router";

import ico_community from "../../_images/ico-floating-community.svg";
import ico_community_active from "../../_images/ico-floating-community-active.svg";
import ico_skills from "../../_images/ico-floating-home.svg";
import ico_skills_active from "../../_images/ico-floating-home-active.svg";
import ico_vendors from "../../_images/ico-floating-vendors.svg";
import ico_vendors_active from "../../_images/ico-floating-vendors-active.svg";
import ico_profile from "../../_images/ico-floating-profile.svg";
import ico_profile_active from "../../_images/ico-floating-profile-active.svg";
import { CreateOption } from "./create-option";

export const MobileMenu = ({ withFade, selected }) => {
  //Language hooks
  const { i18n, t } = useTranslation("common");
  const lang = i18n.language;
  const [visibility, setVisibility] = useState(false);

  useEffect(() => {
    if (withFade) {
      //Listen scroll when mouting.
      window.addEventListener("scroll", handleScroll);

      return () => {
        //Remove listener when unmount.
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, [withFade]);

  /* Listen scroll */
  const handleScroll = (e) => {
    if (window.scrollY > 0) {
      setVisibility(true);
    } else if (window.scrollY <= 0) {
      setVisibility(false);
    }
  };

  return (
    <Fade in={visibility}>
      <div className="floating__profile__nav d-flex">
        <div
          className={`floating__nav__item d-flex align-items-end justify-content-center ${
            selected === "community" ? "current" : ""
          }`}
        >
          <Link to={`/${lang}/${t("url.community")}`}>
            <img
              src={
                selected === "community" ? ico_community_active : ico_community
              }
              alt={t("menus.community")}
            />
            <span>{t("menus.short_community")}</span>
          </Link>
        </div>
        <div
          className={`floating__nav__item d-flex align-items-end justify-content-center ${
            selected === "skills" ? "current" : ""
          }`}
        >
          <Link to={`/${lang}/${t("url.contents")}`}>
            <img
              src={selected === "skills" ? ico_skills_active : ico_skills}
              alt={t("menus.skills")}
            />
            <span>{t("menus.short_skills")}</span>
          </Link>
        </div>
        <div
          className={`floating__nav__item d-flex align-items-end justify-content-center ${
            selected === "create" ? "current" : ""
          }`}
        >
          <CreateOption selected={selected} />
        </div>
        <div
          className={`floating__nav__item d-flex align-items-end justify-content-center ${
            selected === "vendors" ? "current" : ""
          }`}
        >
          <Link to={`/${lang}/${t("url.vendors")}`}>
            <img
              src={selected === "vendors" ? ico_vendors_active : ico_vendors}
              alt={t("menus.vendors")}
            />
            <span>{t("menus.short_vendors")}</span>
          </Link>
        </div>
        <div
          className={`floating__nav__item d-flex align-items-end justify-content-center ${
            selected === "profile" ? "current" : ""
          }`}
        >
          <Link to={`/${lang}/${t("url.profile")}`}>
            <img
              src={selected === "profile" ? ico_profile_active : ico_profile}
              alt={t("menus.my_profile")}
            />
            <span>{t("menus.short_profile")}</span>
          </Link>
        </div>
      </div>
    </Fade>
  );
};
