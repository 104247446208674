import React from "react";
import { useTranslation } from "react-i18next";
import { CategoryLink } from "../ui/category-link";

export const DetailProfile = ({ user }) => {
  const { t } = useTranslation("common");
  const certifications = String(user.certifications).split(",");
  const interests = String(user.interests).split(",");

  return (
    <div className="details__profile">
      {
        /* About */
        user.full_bio && (
          <div className="about">
            <div className="detail_header d-flex justify-content-between">
              <h3>{t("profile.about")}</h3>
            </div>
            <p>{user.full_bio}</p>
          </div>
        )
      }

      {
        /* Certifications */
        user.certifications && (
          <div className="certifications">
            <div className="detail_header d-flex justify-content-between">
              <h3>{t("profile.certifications")}</h3>
            </div>
            {certifications.map((item, id) => (
              <div className="certification_detail" key={id}>
                <span>{item}</span>
              </div>
            ))}
          </div>
        )
      }

      {
        /* Interests */
        user.interests && (
          <div className="interest">
            <div className="detail_header d-flex justify-content-between">
              <h3>{t("profile.interest")}</h3>
            </div>
            {interests.map((item, id) => (
              <div className="quick__cats d-flex flex-wrap" key={id}>
                <CategoryLink name={item} className="quick__cats__btn topics" />
              </div>
            ))}
          </div>
        )
      }
    </div>
  );
};
