import { modalConstants } from "../_constants";

export const modalActions = {
  showLogin,
  showRegister,
  showForgotPassword,
  showRecoveryPassword,
  showSearch,
  showNewsletter,
  showAddArticle,
  showCreateContent,

  hideLogin,
  hideRegister,
  hideForgotPassword,
  hideRecoveryPassword,
  hideSearch,
  hideNewsletter,
  hideAddArticle,
  hideCreateContent,
};

function showLogin() {
  return (dispatch) => {
    dispatch({ type: modalConstants.SHOW_LOGIN });
  };
}

function hideLogin() {
  return (dispatch) => {
    dispatch({ type: modalConstants.HIDE_LOGIN });
  };
}

function showRegister(step = modalConstants.REGISTER_STEP1) {
  return (dispatch) => {
    dispatch({ type: modalConstants.SHOW_REGISTER, step });
  };
}

function hideRegister() {
  return (dispatch) => {
    dispatch({ type: modalConstants.HIDE_REGISTER });
  };
}

function showForgotPassword(step = modalConstants.FORGOT_STEP1, email) {
  return (dispatch) => {
    dispatch({ type: modalConstants.SHOW_FORGOT_PASSWORD, step, email });
  };
}

function hideForgotPassword() {
  return (dispatch) => {
    dispatch({ type: modalConstants.HIDE_FORGOT_PASSWORD });
  };
}

function showRecoveryPassword(step = modalConstants.RESET_STEP1, token = null) {
  return (dispatch) => {
    dispatch({ type: modalConstants.SHOW_RECOVERY_PASSWORD, step, token });
  };
}

function hideRecoveryPassword() {
  return (dispatch) => {
    dispatch({ type: modalConstants.HIDE_RECOVERY_PASSWORD });
  };
}

function showSearch(query = "") {
  return (dispatch) => {
    dispatch({ type: modalConstants.SHOW_SEARCH, query });
  };
}

function hideSearch() {
  return (dispatch) => {
    dispatch({ type: modalConstants.HIDE_SEARCH });
  };
}

function showNewsletter() {
  return (dispatch) => {
    dispatch({ type: modalConstants.SHOW_NEWSLETTER });
  };
}

function hideNewsletter() {
  return (dispatch) => {
    dispatch({ type: modalConstants.HIDE_NEWSLETTER });
  };
}

function showAddArticle(content) {
  return (dispatch) => {
    dispatch({ type: modalConstants.SHOW_ADDARTICLE, content });
  };
}

function hideAddArticle() {
  return (dispatch) => {
    dispatch({ type: modalConstants.HIDE_ADDARTICLE });
  };
}

function showCreateContent() {
  return (dispatch) => {
    dispatch({ type: modalConstants.SHOW_CREATECONTENT });
  };
}

function hideCreateContent() {
  return (dispatch) => {
    dispatch({ type: modalConstants.HIDE_CREATECONTENT });
  };
}
