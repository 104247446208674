import React from "react";
import SEO from "../_components/common/seo";
import Layout from "../_components/common/layout";
import { useTranslation } from "react-i18next";

import "../css/terms.css";

const DescargoPagina = () => {
  const { t, i18n } = useTranslation("common");
  const lang = i18n.language;

  //Default page
  return (
    <Layout>
      <SEO lang={lang} title={t("url.sale")} />
      <main
        role="main"
        class="no__top"
        itemscope=""
        itemprop="mainContentOfPage"
        itemtype="http://schema.org/Blog"
      >
        <div className="container d-flex  term_style">
          <div className="d-block">
            <p>Descargo</p>
            <br />

            <p>
              Este descargo de responsabilidad ("Descargo de responsabilidad")
              establece las pautas generales, divulgaciones y términos de su uso
              del sitio web techfrenetic.com ("Sitio web"), la aplicación móvil
              "www.techfrenetic.com" ("Aplicación móvil") y cualquiera de sus
              productos y servicios relacionados (colectivamente, "Servicios").
              Este descargo de responsabilidad es un acuerdo legalmente
              vinculante entre usted ("Usuario", "usted" o "su") y TechFrenetic
              ("TechFrenetic", "nosotros", "nos" o "nuestro"). Si está
              celebrando este acuerdo en nombre de una empresa u otra entidad
              legal, declara que tiene la autoridad para obligar a dicha entidad
              a este acuerdo, en cuyo caso los términos "Usuario", "usted" o
              "su" se referirán a tal entidad. Si no tiene dicha autoridad, o si
              no está de acuerdo con los términos de este acuerdo, no debe
              aceptar este acuerdo y no puede acceder ni utilizar los Servicios.
              Al acceder y utilizar los Servicios, usted reconoce que ha leído,
              entendido y acepta estar sujeto a los términos de este Aviso
              legal. Usted reconoce que este Descargo de responsabilidad es un
              contrato entre usted y TechFrenetic, aunque sea electrónico y no
              esté firmado físicamente por usted, y rige su uso de los
              Servicios.
            </p>
            <p>Representación</p>
            <p>
              Los puntos de vista u opiniones representados en los Servicios
              pertenecen únicamente a los creadores de contenido y no
              representan los de las personas, instituciones u organizaciones
              con las que TechFrenetic o los creadores pueden o no estar
              asociados en su capacidad profesional o personal, a menos que se
              indique explícitamente. Cualquier punto de vista u opinión no
              pretende difamar a ninguna religión, grupo étnico, club,
              organización, empresa o individuo.
            </p>
            <p>Contenido y publicaciones</p>
            <p>
              No puede modificar, imprimir o copiar ninguna parte de los
              Servicios. Inclusión de cualquier parte de los Servicios en otra
              obra, ya sea en impreso o electrónico o de otra forma o inclusión
              de cualquier parte de los Servicios en otro recurso incrustando,
              enmarcando o de lo contrario sin el permiso expreso de
              TechFrenetic es prohibido.
            </p>
            <p>
              Puede enviar contenido nuevo y comentar el contenido existente en
              los servicios. Al cargar o poner a disposición de otro modo
              cualquier información a TechFrenetic, le otorga a TechFrenetic el
              acceso ilimitado, derecho perpetuo de distribuir, mostrar,
              publicar, reproducir, reutilizar y copiar la información contenida
              en el mismo. No deberias hacerse pasar por cualquier otra persona
              a través de los Servicios. No deberias publicar contenido
              difamatorio, fraudulento, obsceno, amenazante, invasivo de los
              derechos de privacidad de otra persona o que de otra manera es
              ilegal. No puede publicar contenido que infrinja los derechos de
              propiedad intelectual de cualquier otra persona o entidad. Tú no
              puede publicar ningún contenido que incluya virus informáticos u
              otros código diseñado para interrumpir, dañar o limitar el
              funcionamiento de cualquier software o hardware informático.
              Compensación y patrocinio
            </p>
            <p>
              Los Servicios pueden contener formas de publicidad, patrocinio,
              pagos inserciones u otras formas de compensación. En ciertas
              ocasiones TechFrenetic puede ser compensado por proporcionar
              opiniones sobre productos, servicios, u otros temas varios. La
              compensación recibida puede influir en dichas opiniones sobre el
              contenido o los temas anunciados disponible en los Servicios.
              Contenidos patrocinados y espacios publicitarios siempre se
              identificará como tal. Algunos de los enlaces en el Los servicios
              pueden ser enlaces de afiliados. Esto significa que si hace clic
              en el vincular y comprar un artículo, TechFrenetic recibirá un
              afiliado comisión. Fitness y descargo de responsabilidad médica
            </p>
            <p>
              La información disponible en los Servicios es para fines generales
              de salud. información solamente y no pretende ser un sustituto de
              asesoramiento, diagnóstico o tratamiento médico profesional.
              Debería no confiar exclusivamente en la información proporcionada
              en los Servicios para sus necesidades de salud. Todas las
              preguntas médicas específicas deben ser presentado a su propio
              proveedor de atención médica y debe buscar consejo médico sobre su
              salud y antes de iniciar cualquier nutrición, pérdida de peso o
              cualquier otro tipo de programa de entrenamiento.
            </p>
            <p>
              Si elige utilizar la información disponible en los Servicios sin
              la consulta previa y el consentimiento de su médico, usted está de
              acuerdo en aceptar la responsabilidad total de sus decisiones y
              acepta mantener indemne a TechFrenetic, sus agentes, empleados,
              contratistas y cualquier compañía afiliada de cualquier
              responsabilidad con con respecto a una lesión o enfermedad a usted
              o a su propiedad que surja de o conectado con su uso de esta
              información.
            </p>
            <p>
              Puede haber riesgos asociados con la participación en actividades
              presentados en los Servicios para personas con buena o mala salud
              o con condiciones preexistentes de salud física o mental. Si usted
              elige participar en estos riesgos, lo hace por su propia cuenta
              voluntad y acuerdo, asumiendo a sabiendas y voluntariamente todos
              los riesgos asociados con tales actividades.
            </p>
            <p>
              Los resultados obtenidos a partir de la información disponible en
              el Los servicios pueden variar y se basarán en sus necesidades
              individuales. antecedentes, salud física, experiencia previa,
              capacidad, capacidad de actuar, motivación y otras variables. No
              existen garantías sobre el nivel de éxito que puede experimentar.
              No Consejo legal
            </p>
            <p>
              La información proporcionada en los Servicios es de carácter
              general. únicamente con fines informativos y no es una alternativa
              a la legal asesoramiento de su abogado, otro proveedor de
              servicios profesionales, o experto. No tiene la intención de
              proporcionar asesoramiento legal u opiniones de cualquier tipo. No
              debe actuar, o abstenerse de actuar, basándose únicamente sobre la
              información proporcionada en los Servicios sin primero buscar el
              asesoramiento legal adecuado u otro tipo de asesoramiento
              profesional. Si usted tiene alguna pregunta específica sobre
              cualquier asunto legal, debe consulte a su abogado, a otro
              proveedor de servicios profesionales, o experto. Nunca debe
              retrasar la búsqueda de asesoramiento legal, ignore asesoramiento
              legal, o iniciar o suspender cualquier acción legal porque de la
              información sobre los Servicios.
            </p>
            <p>
              La información sobre los Servicios se proporciona para su
              conveniencia. solo. Esta información puede no tener valor
              probatorio y debe ser verificado con fuentes oficiales antes de
              que se use para cualquier propósitos Es su responsabilidad
              determinar si este la información es admisible en un determinado
              proceso judicial o administrativo. procedimiento y si existen
              otras pruebas o archivos requisitos Su uso de esta información es
              bajo su propio riesgo. No asesoría financiera
            </p>
            <p>
              La información sobre los Servicios se proporciona para su
              conveniencia. únicamente y no pretende ser tratado como
              financiero, de inversión, impuestos u otros consejos. Nada
              contenido en los Servicios constituye una solicitud,
              recomendación, respaldo u oferta por TechFrenetic, sus agentes,
              empleados, contratistas y cualquier compañías afiliadas para
              comprar o vender valores u otros instrumentos financieros.
            </p>
            <p>
              Todo el contenido de este sitio es información de carácter
              general. y no aborda las circunstancias de ningún particular
              individuo o entidad. Nada en los Servicios constituye
              asesoramiento profesional y/o financiero, ni información sobre los
              Servicios constituyen una declaración integral o completa de los
              asuntos discutidos o la ley relativa a los mismos. Tu solo asumir
              la responsabilidad exclusiva de evaluar los méritos y riesgos
              asociado con el uso de cualquier información u otro contenido en
              el Servicios antes de tomar cualquier decisión basada en dicha
              información. Usted acepta no retener a TechFrenetic, sus agentes,
              empleados, contratistas y cualquier empresa afiliada responsable
              de cualquier posible reclamar por daños y perjuicios que surjan de
              cualquier decisión que tome en base a la información puesta a su
              disposición a través del Sitio Web. No Consejo de inversión
            </p>
            <p>
              Todas las inversiones son de naturaleza altamente especulativa e
              implican riesgo sustancial de pérdida. Animamos a todos a invertir
              muy con cuidado. También animamos a los inversores a obtener
              asesoramiento personal de su asesor de inversiones profesional y
              para hacer independiente investigaciones antes de actuar sobre la
              información encontrada en los Servicios. De ninguna manera
              garantizamos ni garantizamos el éxito de cualquier acción que tome
              basándose en declaraciones o información disponible en los
              Servicios.
            </p>
            <p>
              El rendimiento pasado no es necesariamente indicativa de
              resultados futuros. Todas las inversiones conllevan un riesgo
              significativo y todas las inversiones decisiones de un individuo
              siguen siendo responsabilidad específica de ese individuo No hay
              garantía de que los sistemas, indicadores, o señales darán lugar a
              beneficios o que no darán lugar a pérdidas totales o parciales. Se
              recomienda a todos los inversores que comprender todos los riesgos
              asociados con cualquier tipo de inversión que elige hacer.Reseñas
              y testimonios
            </p>
            <p>
              Los testimonios se reciben en varias formas a través de una
              variedad de métodos de presentación. Son experiencias
              individuales, que reflejan experiencias de quienes han utilizado
              los Servicios de alguna manera o otro. Sin embargo, son resultados
              individuales y los resultados varían. No pretendemos que sean
              resultados típicos que los consumidores generalmente lograr. Los
              testimonios no son necesariamente representante de todos aquellos
              que utilizarán los Servicios, y TechFrenetic no se hace
              responsable de las opiniones o comentarios disponible en los
              Servicios, y no necesariamente los comparte. Todas las opiniones
              expresadas son estrictamente los puntos de vista de los revisores.
            </p>
            <p>
              Los testimonios mostrados se dan palabra por palabra excepto por
              correcciones de errores gramaticales o tipográficos. Algunos
              testimonios pueden han sido editados para mayor claridad, o
              abreviados en los casos en que el testimonio original incluía
              información extraña de no relevancia para el público en general.
              Los testimonios pueden ser revisados ​​para autenticidad antes de
              que estén disponibles para el público visualización. Indemnización
              y garantías
            </p>
            <p>
              Si bien hemos hecho todo lo posible para garantizar que la
              información contenida en los Servicios es correcta, TechFrenetic
              no es responsable de cualquier error u omisión, o de los
              resultados obtenidos del uso de esta información. Toda la
              información sobre el Los servicios se proporcionan "tal cual", sin
              garantía de integridad, exactitud, oportunidad o de los resultados
              obtenidos del uso de esta información, y sin garantía de ningún
              tipo, expresa o implícito. En ningún caso TechFrenetic, o sus
              socios, empleados o agentes, será responsable ante usted o
              cualquier otra persona por cualquier decisión tomada o acción
              tomada en base a la información de los Servicios, o por cualquier
              daño consecuente, especial o similar, incluso si se le ha
              advertido de la posibilidad de tales daños.
            </p>
            <p>
              Además, como con cualquier negocio, sus resultados pueden variar y
              basarse en su capacidad individual, experiencia, pericia y nivel
              de deseo. No hay garantías en cuanto al nivel de éxito que puede
              experimentar. No hay garantía de que usted hacer ningún ingreso y
              usted acepta el riesgo de que las ganancias y los estados de
              resultados difieren según el individuo. de cada individuo el éxito
              depende de sus antecedentes, dedicación, deseo y motivación. El
              uso de la información disponible en los Servicios debe basarse en
              su propia diligencia debida y acepta que TechFrenetic no es
              responsable del éxito o fracaso de su negocio que está directa o
              indirectamente relacionado con la compra y el uso de nuestra
              información, productos y servicios revisados ​​o anunciado en los
              Servicios. Información contenida en los Servicios están sujetos a
              cambios en cualquier momento y sin previo aviso.
            </p>
            <p>Cambios y enmiendas</p>
            <p>
              Nos reservamos el derecho de modificar este Aviso Legal o sus
              términos relacionados con los Servicios en cualquier momento a
              nuestra discreción. cuando lo hacemos, revisaremos la fecha
              actualizada en la parte inferior de esta página, publicaremos un
              notificación dentro de los Servicios. También podemos enviar un
              aviso a usted de otras maneras a nuestra discreción, como a través
              del contacto información que ha proporcionado.
            </p>
            <p>
              Una versión actualizada de este Descargo de responsabilidad
              entrará en vigencia inmediatamente después de la publicación del
              Descargo de responsabilidad revisado, a menos que especificado de
              otra manera. Su uso continuado de los Servicios después de la
              fecha de entrada en vigencia del Aviso legal revisado (o cualquier
              otro acto especificado en ese momento) constituirá su
              consentimiento para aquellos cambios.
            </p>
            <p>Aceptación de este descargo de responsabilidad</p>
            <p>
              Usted reconoce que ha leído este Descargo de responsabilidad y
              acepta todos sus términos y condiciones. Al acceder y utilizar los
              Servicios usted acepta estar sujeto a este Descargo de
              responsabilidad. Si no está de acuerdo con cumplir con los
              términos de este Descargo de responsabilidad, no está autorizado a
              acceder o utilizar los Servicios.
            </p>
            <p>Contáctanos</p>
            <p>
              Si tiene alguna pregunta, inquietud o queja con respecto a esta
              Política, le animamos a que se ponga en contacto con nosotros
              utilizando los detalles a continuación:
            </p>
            <p>memo@techfrenetic.com</p>
            <span>
              Este documento fue actualizado por última vez el 17 de agosto de
              2021
            </span>
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default DescargoPagina;
