import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import ico_back from "../../_images/ico-back.svg";
import { userService } from "../../_services";

export const EditAbout = ({ bio, show, onHide, me }) => {
  //Language hooks
  const { i18n, t } = useTranslation("common");
  const lang = i18n.language;
  const [about, setAbout] = useState(false);

  const handleTextAreaChange = ({ target }) => {
    setAbout(target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    document.querySelector("#buttonAbout").classList.add("lds-dual-ring");
    if (about !== false) {
      userService
        .updateProfile(lang, me, {
          field_biography: [
            {
              value: about,
            },
          ],
        })
        .then()
        .catch(console.log);
    }

    setTimeout(() => {
      document.querySelector("#edit_about .close").click();
      window.location.reload();
    }, 2500);
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <div
        id="edit_about"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="modalLabel_1"
        aria-hidden="true"
      >
        <div className="modal-dialog edit__profile" role="document">
          <div className="modal-content auto">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={onHide}
            >
              <span aria-hidden="true">×</span>
            </button>
            <div className="mobile__bar d-flex align-items-center">
              <a href="/" className="back__btn">
                <img src={ico_back} alt="Back" />
              </a>
              <span className="title__profile">{t("user.profile_button")}</span>
            </div>

            <div className="profile__camps">
              <form onSubmit={handleSubmit}>
                <h3>
                  <span>{t("profile.edit_about-blue")}</span>
                  {t("profile.edit_about")}
                </h3>
                <div className="formedit__camps d-flex flex-column flex-sm-column flex-md-row flex-lg-row">
                  <div className="form-label-group no__floating description">
                    <textarea
                      id="inputDescription"
                      className="form-control"
                      required=""
                      autoFocus=""
                      defaultValue={bio}
                      onChange={handleTextAreaChange}
                    ></textarea>
                  </div>
                </div>
                <button id="buttonAbout" className="btn-primary">
                  {t("profile.save")}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
