import React from "react";
import { FollowButton } from "../buttons/follow-button";
import { Avatar } from "../users/avatar";

export default function PublicInformation({
  user,
  followers,
  following,
  onShowAll,
  userA,
}) {

  const handleShowFollowers = (e, type) => {
    e.preventDefault();
    onShowAll(type);
  };

  const idUser = userA?.location.state.userA.user.id;


  return (
    <div className="profile__header">
      <div className="public__profile__box d-flex justify-content-between col-12">
        <div className="d-flex col-sm-12 col-md-6">
          <div className="profile__main__image">
            <Avatar user={user} alt={user.display_name} />
          </div>
          <div className="profile__information">
            <div className="profile__name d-flex">
              <h1 className="user__name">
                <span className="main__name">{user.display_name}</span>
                {/* {name.slice(1).join(" ")} */}
              </h1>
            </div>
            <span className="ocuppation">{user.field__professions}</span>
            <div className="user__follows d-flex">
              <div className="followers">
                <a
                  href="./"
                  onClick={(e) => handleShowFollowers(e, "followers")}
                >
                  <span className="f__counter">{followers}</span>Followers
                </a>
              </div>
              <div className="follows">
                <a
                  href="./"
                  onClick={(e) => handleShowFollowers(e, "following")}
                >
                  <span className="f__counter">{following}</span>Following
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="public__actions d-flex col-sm-12 col-md-6 align-items-center justify-content-end">
          <FollowButton
            id={idUser}
            uid={user.id}
            nick={user.name}
            className="btn btn-primary public__follow"
          />
        </div>
      </div>
    </div>
  );
}
