import React from "react";
import { useTranslation } from "react-i18next";
import "../../css/contentsPage.css";
import { Stream } from "@cloudflare/stream-react";
import { Link } from "@reach/router";

export const Course = ({ i, nid}) => {
  const { t, i18n } = useTranslation("common");
  const lang = i18n.language;

  return (
    <div className="course_content">
      <Stream controls src={i.field_cloudflare_id} section="wall" />
      <Link to={`/${lang}/${t("url.internal-course")}/${nid}`} state={{info: {i} }}>
        <p>{i.title}</p>
      </Link>
    </div>
  );
};
