import React from "react";
import { Link } from "@reach/router";
import { useTranslation } from "react-i18next";
import { url, path } from "../../_helpers";
import he from "he";

import ico_arrow from "../../_images/ico-all-work.svg";

export default function ArticleFrenetic({ article }) {
  const { t, i18n } = useTranslation("common");
  const lang = i18n.language;

  const link = url(article.slug);

  return (
    <div className="frenetic__box d-flex flex-column flex-sm-column flex-md-row flex-lg-row justify-content-center">
      <div className="frenetic__img col-3">
        <Link
          to={`/${lang}/${t("home.profile")}/${String(article.user).toLocaleLowerCase()}`}
          className="frenetic__img__profile"
        >
          {article.use_avatar === "True" ? (
            <img
              src={`/images/${article.avatar}.svg`}
              alt={article.display_name}
            />
          ) : (
            <img src={path(article.picture)} alt={article.display_name} />
          )}
        </Link>
      </div>
      <div className="frenetic__content col-9">
        <article itemScope="" itemType="http://schema.org/Article">
          <div className="autor__container d-flex">
            <p className="article__autor">
              <Link
                to={`/${lang}/${t("home.profile")}/${String(article.user).toLocaleLowerCase()}`}
              >
                <span itemProp="name">{article.display_name}</span>
              </Link>
            </p>
          </div>
          <div className="article__information">
            <h2 itemProp="headline">
              <Link to={link}>{he.decode(article.title)}</Link>
            </h2>
          </div>
          <Link to={link} className="all__work__btn">
            {t("articles.all_work")}
            <img src={ico_arrow} alt={t("articles.go_work")} />
          </Link>
        </article>
      </div>
    </div>
  );
}
