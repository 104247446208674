import React, { useEffect, useState } from "react";
import SEO from "../_components/common/seo";
import Layout from "../_components/common/layout";
import { useTranslation } from "react-i18next";
import { MobileMenu } from "../_components/navigation/mobile-menu";
import ico_back from "../_images/ico-back.svg";
import ico_website from "../_images/ico-website.svg";
import { vendorService } from "../_services";
import { path } from "../_helpers";
import { Link } from "@reach/router";
import he from "he";
import { ContactVendor } from "../_components/modals";

const VendorsBrandPage = ({ slug }) => {
  const { t, i18n } = useTranslation("common");
  const [data, setData] = useState({});
  const [showContactModal, setShowContactModal] = useState(false);
  const lang = i18n.language;

  //Partners
  useEffect(() => {
    vendorService
      .getVendor(lang, `/${t("url.brand")}/${slug}`)
      .then((result) => {
        setData(result[0]);
      })
      .catch(console.log);
  }, []);

  /**
   * Show contact modal.
   * @param {*} e
   */
  const handleContact = (e) => {
    e.preventDefault();
    setShowContactModal(true);
  };

  return (
    <Layout withFade={true} noFooter={true}>
      <SEO lang={lang} title={t("vendors.title")} />
      <main
        className="home__page"
        role="main"
        itemScope=""
        itemProp="mainContentOfPage"
        itemType="http://schema.org/Blog"
      >
        <div className="mobile__bar sticky d-flex align-items-center">
          <Link to={`/${lang}/${t("url.vendors")}`} className="back__btn">
            <img src={ico_back} alt="Back" />
          </Link>
          <span className="title__profile">{data.name}</span>
        </div>

        {/* Header vendor */}
        <section className="vendor__profile">
          <div className="container">
            <div className="breadcrumbs">
              <Link to={`/${lang}/${t("url.vendors")}`}>
                {t("menus.vendors")}
              </Link>
              <span className="b__separator">/</span>
              <span className="b__current">{data.name}</span>
            </div>
            <div className="profile__header d-flex flex-column flex-sm-column flex-md-row flex-lg-row">
              <div className="public__profile__box d-flex justify-content-between col-12 col-sm-12 col-md-8 col-lg-8">
                <div className="d-flex col-12">
                  <div className="p__grade vendor">{t("vendors.tag")}</div>
                  <div className="profile__main__image">
                    <img
                      src={
                        data.logo
                          ? path(data.logo)
                          : "/images/temp/vendor-placeholder.png"
                      }
                      alt={data.name}
                    />
                  </div>

                  <div className="profile__information">
                    <div className="profile__name">
                      <h1 className="user__name">{data.name}</h1>
                    </div>
                    <span className="ocuppation">{data.description}</span>

                    <div className="vendor__details row">
                      {data.website && (
                        <div className="website">
                          <img src={ico_website} alt="Go to website" />
                          <a href={data.website}>{data.website}</a>
                        </div>
                      )}
                      {data.location && (
                        <div className="partner__location d-flex align-items-end">
                          <span className="partner__location__tlt">
                            Location
                          </span>
                          <span className="partner__location__cities">
                            {data.location}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="vendor__details col-12 col-sm-12 col-md-4 col-ld-4 align-items-center row justify-content-end">
                <a href="/" className="btn-primary" onClick={handleContact}>
                  Contact
                </a>
              </div>
            </div>
          </div>
        </section>

        {/* Header vendor */}
        <section className="profile__page vendor">
          <div className="container d-flex flex-wrap">
            <div className="profile__body col-sm-12 col-lg-8">
              {data.body && (
                <div className="profile__dashboard">
                  <div className="details__vendor">
                    <div className="vendor__description">
                      {React.createElement("div", {
                        dangerouslySetInnerHTML: {
                          __html: he.decode(data.body),
                        },
                      })}
                    </div>
                    <a href="/" className="btn-primary">
                      Contact
                    </a>
                  </div>
                </div>
              )}
            </div>
            <div className="vendor__complements col-12 col-sm-12 col-sm-4 col-lg-4">
              <div className="vendor__certifications">
                <h3>Categories</h3>

                {data.categories && (
                  <div className="vendor__categories">
                    <div className="vendor__categories__labels">
                      {String(data.categories)
                        .split(",")
                        .map((category, idx) => (
                          <span key={idx}>{category}</span>
                        ))}
                    </div>
                  </div>
                )}

                <Link
                  to={`/${lang}/${t("url.vendors")}/${t("url.search")}?v=${
                    data.id
                  }`}
                  className="btn btn-lg btn-secondary"
                  data-toggle="modal"
                  data-target="#followers__box"
                >
                  See distributors &amp; Partners
                </Link>
              </div>
            </div>
          </div>
        </section>
      </main>
      <MobileMenu selected="vendors" withFade={true} />
      <ContactVendor
        vendor={data}
        visible={showContactModal}
        onHide={() => setShowContactModal(false)}
      />
    </Layout>
  );
};

export default VendorsBrandPage;
