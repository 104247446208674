import React from "react";
import FormValidator from "../../_helpers/form-validator";
import { Spinner } from "../ui/spinner";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { userActions } from "../../_actions";
import { Subject } from "../ui/subject";

class RegisterContact extends FormValidator {
  constructor(props) {
    super(props);

    this.state = {
      elements: {
        name: {
          value: "",
          error: false,
          message: "",
        },
        email: {
          value: "",
          error: false,
          message: "",
        },
        phone: {
          value: "",
          error: false,
          message: "",
        },
        message: {
          value: "",
          error: false,
          message: "",
        },
        subject: {
          value: "",
          label: "",
          error: false,
          message: "",
        },
        terms: {
          value: "",
          error: false,
          message: "",
        },
      },
    };

    this.submitHandler = this.submitHandler.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.changeHandlerSubject = this.changeHandlerSubject.bind(this);
    this.changeHandlerTerms = this.changeHandlerTerms.bind(this);
  }

  /* Form submit */
  submitHandler(e) {
    e.preventDefault();

    const isValid = this.validate(this.state.elements, [
      {
        field: "name",
        type: "required",
        message: "errors.name_required",
      },
      {
        field: "email",
        type: "required",
        message: "errors.email_required",
      },
      {
        field: "email",
        type: "email",
        message: "errors.email_format",
      },
      {
        field: "phone",
        type: "required",
        message: "errors.phone_required",
      },
      {
        field: "message",
        type: "required",
        message: "errors.message_required",
      },
      {
        field: "subject",
        type: "required",
        message: "errors.message_required",
      },
      {
        field: "terms",
        type: "required",
        message: "errors.terms_required",
      },
    ]);

    if (isValid) {
      const { name, email, phone, message, subject } = this.state.elements;
      const lang = this.props.i18n.language;

      this.props.contact(lang, {
        name: name.value,
        email: email.value,
        phone: phone.value,
        message: message.value,
        subject: subject.value,
      });

      document.querySelector("#form-contact").setAttribute("disabled", "");
      document.querySelector("#contact__form").classList.add("opacy");
      document.querySelector("#form-contact").classList.add("lds-dual-ring");
      setTimeout(() => {
        alert("Tu Mensaje ha sido enviado con exito!");
      }, 8500);
    }
  }

  /* Controlled checkbox */
  changeHandlerTerms(e) {
    const input = this.state.elements.terms;
    input.value = input.value ? "" : e.target.value;
    input.error = false;

    this.setState({ elements: this.state.elements });
  }

  /* Controlled input */
  changeHandler(e) {
    const input = this.state.elements[e.target.name];
    input.value = e.target.value;
    input.error = false;

    this.setState({ elements: this.state.elements });
  }
  changeHandlerSubject(e) {
    const input = this.state.elements["subject"];
    input.value = e.value;
    input.label = e.label;
    input.error = false;
    this.setState({ elements: this.state.elements });
  }

  /* View */
  render() {
    const { t } = this.props;
    const { name, email, phone, message, subject, terms } = this.state.elements;

    return (
      <form className="form-signin" onSubmit={this.submitHandler}>
        {
          /* Error */
          this.props.problem && (
            <div className={`form-label-group`}>
              <div className="alert alert-danger text-center">
                {t(`errors.${this.props.problem}`)}
              </div>
            </div>
          )
        }

        {
          <div
            id="form-contact"
            className={`form-label-group ${name.error ? "error" : ""}`}
          >
            <input
              type="text"
              id="inputName"
              name="name"
              className="form-control"
              placeholder={t("contact.name_p")}
              required=""
              autoFocus=""
              value={name.value}
              onChange={this.changeHandler}
            />
            <label htmlFor="inputName">{t("contact.name")}</label>
            {name.error && (
              <small id="passlHelp" className="form-text text-muted">
                {t(name.message + "_person")}
              </small>
            )}
          </div>
        }

        {/* Email */}
        <div
          className={`form-label-group new__password confirmation1 ${
            email.error ? "error" : ""
          }`}
        >
          <input
            type="email"
            id="inputEmail"
            name="email"
            className="form-control"
            placeholder={t("contact.email_p")}
            required=""
            autoFocus=""
            onChange={this.changeHandler}
            value={email.value}
            maxLength="80"
          />
          <label htmlFor="inputEmail">{t("contact.email_form")}</label>
          {email.error && (
            <small id="passlHelp" className="form-text text-muted">
              {t(email.message)}
            </small>
          )}
        </div>

        {
          <div
            className={`form-label-group new__password confirmation2 ${
              phone.error ? "error" : ""
            }`}
          >
            <input
              type="text"
              id="inputPhone"
              name="phone"
              className="form-control"
              placeholder={t("contact.phone_p")}
              required=""
              autoFocus=""
              maxLength="10"
              pattern="^[0-9]{3,15}"
              title="Por favor diligencia con numeros."
              value={phone.value}
              onChange={this.changeHandler}
            />
            <label htmlFor="inputPhone">{t("contact.phone_form")}</label>
            {phone.error && (
              <small id="passlHelp" className="form-text text-muted">
                {t(phone.message + "_person")}
              </small>
            )}
          </div>
        }

        <Subject
          value={subject.value}
          label={subject.label}
          handler={this.changeHandlerSubject}
        />

        {
          <div
            className={`form-label-group no__floating description ${
              message.error ? "error" : ""
            }`}
          >
            <label htmlFor="inputMessage" className="no__floating">
              {t("contact.message")}
            </label>
            <textarea
              type="text"
              id="inputMessage"
              name="message"
              className="form-control"
              placeholder={t("contact.message_p")}
              required=""
              autoFocus=""
              value={message.value}
              onChange={this.changeHandler}
            ></textarea>
            {message.error && (
              <small id="passlHelp" className="form-text text-muted">
                {t(message.message + "_person")}
              </small>
            )}
          </div>
        }
        <div className={`checkbox mb-3 ${terms.error ? "error" : ""}`}>
          <label>
            <input
              type="checkbox"
              value="remember-me"
              id="terms"
              name="terms"
              onChange={this.changeHandlerTerms}
              defaultChecked={terms.value}
            />
            {t("contact.term1")}{" "}
            <a
              className="secondary__hyper"
              href={t("url.sale")}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("contact.terms")}
            </a>{" "}
            {t("contact.term2")}{" "}
            <a
              className="secondary__hyper"
              href={t("url.privacy")}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("contact.policy")}
            </a>
            <span className="check-mark"></span>
            {terms.error && (
              <small className="form-text text-muted">{t(terms.message)}</small>
            )}
          </label>
        </div>

        {/* Submit */}
        {this.props.loggingIn ? (
          <Spinner />
        ) : (
          <button
            id="submit-contact"
            className="btn btn-lg btn-primary btn-block"
            type="submit"
          >
            {t("contact.send")}
          </button>
        )}
      </form>
    );
  }
}

function mapState(state) {
  const { loggingIn, problem } = state.authentication;
  return { loggingIn, problem };
}

const actionCreators = {
  contact: userActions.contact,
};

const connectedRegisterContact = connect(
  mapState,
  actionCreators
)(withTranslation("common")(RegisterContact));
export { connectedRegisterContact as RegisterContact };
