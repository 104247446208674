import React, { useState, useEffect } from "react";
import SEO from "../_components/common/seo";
import Layout from "../_components/common/layout";
import { useTranslation } from "react-i18next";
import { MobileMenu } from "../_components/navigation/mobile-menu";

import CommunityMenu from "../_components/navigation/community-menu";
import GroupsMenu from "../_components/navigation/groups-menu";
import GroupCard from "../_components/ui/group-card";
import Credits from "../_components/common/credits";

import ico_back from "../_images/ico-back.svg";
import { appService } from "../_services";

const DiscoverGroupPage = () => {
  const { t, i18n } = useTranslation("common");
  const lang = i18n.language;

  const [images, setImages] = useState([]);
  const [blocks, setBlocks] = useState(0);

  useEffect(() => {
    /** Call Services  */
    appService
      .getMoreGroups2(lang, blocks)
      .then((res) => res.json())
      .then((response) => {
        if (response) {
          setImages(response);
        }
      })
      .catch(console.log);
  }, [lang, setImages]);

  const more = async () => {
    appService
      .getMoreGroups2(lang, blocks + 1)
      .then((res) => res.json())
      .then((response) => {
        if (response) {
          const more_images = response;
          setBlocks((prev) => prev + 1);
          setImages((prev) => [...prev, ...more_images]);
        }
      })
      .catch(console.log);
  };



  return (
    <Layout withFade={true} noFooter="true">
      <SEO lang={lang} title={t("groups.tab_discover")} />
      <main
        className="groups"
        itemScope=""
        itemProp="mainContentOfPage"
        itemType="http://schema.org/Blog"
      >
        <section className="profile__page feed group">
          <div className="container d-flex flex-wrap">
            <div className="profile__menu col-lg-2 col-md-3">
              <CommunityMenu selected="groups" />
            </div>
            <div className="profile__body col-lg-10 col-md-9">
              <div className="groups__options">
                <div className="tabs__container groups__tabs group__menu__lv2">
                  <div className="mobile__bar d-flex align-items-center">
                    <a href="/" className="back__btn">
                      <img src={ico_back} alt="Back" />
                    </a>
                    <span className="title__profile">Groups</span>
                  </div>

                  <GroupsMenu selected="tab1" />
                </div>
                <h1>{t("groups.may_like")}</h1>

                <div className="group__cards__container d-flex flex-wrap">
                  {images.map((item, id) => (
                    <div
                      key={id}
                      className="group__card col-12 col-sm-12 col-md-4 col-lg-4"
                    >
                      <GroupCard
                        image={item.field_logo}
                        name={item.label}
                        id={item.id}
                        type={item.type}
                      />
                    </div>
                  ))}
                </div>
                <button className="load__more mt-4 mb-5" onClick={more}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                  >
                    <path d="M8.3 14.4C4.9 14.4 2.2 11.6 2.2 8.2 2.2 4.8 4.9 2.1 8.3 2.1 10 2.1 11.6 2.8 12.6 4L13.1 4.5 12.6 5 12 5.6 15.5 5.6 15.5 1.9 14.5 2.9 14 2.4C12.5 0.9 10.5 0 8.2 0 5.9 0 3.9 0.9 2.4 2.4 0.9 3.9 0 6 0 8.2 0 10.5 0.9 12.5 2.4 14 3.9 15.5 5.9 16.4 8.2 16.4 10.2 16.4 12.1 15.7 13.5 14.5L12 13C11 13.9 9.7 14.4 8.3 14.4L8.3 14.4Z"></path>
                  </svg>
                  {t("menus.view_more")}
                </button>
              </div>

              <Credits />
            </div>
          </div>
        </section>
      </main>
      <MobileMenu selected="community" withFade={true} />
    </Layout>
    //   <div className="App">
    //   <div style={{ display: 'flex', flexDirection: 'column' }}>
    //     {images.map((el, i) => (
    //       <img
    //         key={`img${i}`}
    //         src={el.download_url}
    //         style={{
    //           width: '150px',
    //           height: 'auto',
    //           marginBottom: '10px',
    //           borderRadius: '3px',
    //         }}
    //         alt=""
    //       />
    //     ))}
    //   </div>
    //   <button style={{ marginBottom: '100px' }} onClick={more}>
    //     more images
    //   </button>
    // </div>
  );
};

export default DiscoverGroupPage;
