import React from "react";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import ico_back from "../../_images/ico-back.svg";
import { Interest } from "../ui/interest";

export const AddInterest = ({ show, onHide }) => {
  //Language hooks
  const { t } = useTranslation("common");

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      className="modal-add-certification"
      id="modal-add-interest"
    >
      <div
        id="_2"
        tabIndex="0"
        role="dialog"
        aria-labelledby="modalLabel_2"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered edit__profile"
          role="document"
        >
          <div className="modal-content auto">
            <button
              type="button"
              className="close"
              id="close_add_edit_certifications"
              data-dismiss="modal"
              aria-label="Close"
              onClick={onHide}
            >
              <span aria-hidden="true">×</span>
            </button>
            <div className="mobile__bar d-flex align-items-center">
              <a href="/" className="back__btn">
                <img src={ico_back} alt="Back" />
              </a>
              <span className="title__profile">{t("user.profile_button")}</span>
            </div>
            <div className="profile__camps certifications">
              <form className=" certification__box" onSubmit={handleSubmit}>
                <Interest />
                <button type="submit" className="btn btn-outline-primary">
                  Guardar
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
