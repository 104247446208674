import React from "react";
import Modal from "react-bootstrap/Modal";
import { LoginForm } from "../forms";
import { modalActions } from "../../_actions";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

class Login extends React.Component {
  handleForgotPassword = (e) => {
    e.preventDefault();
    this.props.showForgotPassword();
  };

  handleRegister = (e) => {
    e.preventDefault();
    this.props.showRegister();
  };

  handleClose = (e) => {
    if (e) {
      e.preventDefault();
    }
    this.props.hideLogin();
  };

  render() {
    const { t, login: visibility } = this.props;

    return (
      <Modal
        className="fullsize"
        show={visibility}
        onHide={this.handleClose}
        animation={false}
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="row login__box">
              <div className="col-md-5 images__login"></div>
              <div className="col-md-7 login__content">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={this.handleClose}
                >
                  <span aria-hidden="true">×</span>
                </button>

                {/* Intro */}
                <h1>
                  <span className="title__highlight">
                    {t("login.blue_title")}
                  </span>
                  <br />
                  {t("login.black_title")}
                </h1>
                <div className="create__account__box">
                  {t("login.intro_text")}
                  <a href="/" onClick={this.handleRegister}>
                    {t("login.create_one")}
                  </a>
                </div>

                {/* Form */}
                <LoginForm showForgot={this.handleForgotPassword} />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

function mapState(state) {
  const { loggedIn } = state.authentication;
  const { login } = state.modals;
  return { login, loggedIn };
}

const actionCreators = {
  hideLogin: modalActions.hideLogin,
  showLogin: modalActions.showLogin,
  showRegister: modalActions.showRegister,
  showForgotPassword: modalActions.showForgotPassword,
};

const connectedLogin = connect(
  mapState,
  actionCreators
)(withTranslation("common")(Login));
export { connectedLogin as Login };
