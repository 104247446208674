import React from "react";
import { Header } from "./header";
import Footer from "./footer";

const Layout = ({ children, withFade, noFooter }) => {
  return (
    <>
      <Header withFade={withFade} />
      {children}
      <Footer visibility={noFooter} />
    </>
  );
};

export default Layout;
