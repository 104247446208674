import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import { appService } from "../../_services";
import { ProfileGroupMembers } from "../ui/ProfileGroup-members";


export const MembersGroup = ({ id_group }) => {
  //Language hooks
  const { i18n, t } = useTranslation("common");
  const lang = i18n.language;

  const [members, setMembers] = useState("");
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (e) => {
    e.preventDefault();
    setShow(true);
  };

  useEffect(() => {
    /** Call Services  */
    appService
      .getMembers(lang, id_group)
      .then((res) => res.json())
      .then((response) => {
        if (response) {
          setMembers(response);
        }
      })
      .catch(console.log);
  }, [lang, setMembers]);

  const members_map = Object.values(members);

  const identificatorMember = members_map.map(function (e) {
    if (localStorage.getItem("exist-member")) {
      localStorage.removeItem("exist-member");
    }
    return e.uid;
  });

  (() => {
    const { id } = JSON.parse(localStorage.getItem("me"));
    const member = identificatorMember.find((e) => e === `${id}`);
    // if(localStorage.getItem("exist-member") !== "undefined"){
    localStorage.setItem("exist-member", JSON.stringify(member));
    // }

    localStorage.setItem("memberLength", JSON.stringify(members.length));
  })();

  return (
    <>
      <h3 className="members__title">
        {t("groups.members")}
        <span>{members.length}</span>
      </h3>

      <div className="members__content d-flex flex-wrap">
        {members_map.map((item, id) => (
          <span key={id}>
            <ProfileGroupMembers item={item} />
          </span>
        ))}
      </div>

      <a href="/" className="more__groups" onClick={(e) => handleShow(e)}>
        {t("groups.view")}
      </a>

      <Modal show={show} onHide={handleClose} centered>
        <div
          id="edit_about_members"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="modalLabel_1"
          aria-hidden="true"
        >
          <div className="modal-dialog edit__profile" role="document">
            <div className="modal-content auto">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={handleClose}
              >
                <span aria-hidden="true">×</span>
              </button>

              <div className="members-modal">
                <h2>{t("groups.members")} </h2>
                {members_map.map((item, id) => (
                  <div className="card-members" key={id}>
                    <ProfileGroupMembers item={item} />
                    <div className="card-info">
                      <h3>{item.name}</h3>
                      <p>{item.field__professions}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
