import React from "react";
import { Link } from "@reach/router";
import { useTranslation } from "react-i18next";

export default function CommunityMenu({ selected }) {
  const { t, i18n } = useTranslation("common");
  const lang = i18n.language;

  return (
    <ul className="profile__tabs nav community__tabs" role="tablist">
      <li
        role="presentation"
        className={`${selected === "feed" ? "active" : ""}`}
      >
        <Link
          to={`/${lang}/${t("url.community")}`}
          aria-controls="home"
          role="tab"
          data-toggle="tab"
        >
          <span>{t("menus.feed")}</span>
        </Link>
      </li>
      <li
        role="presentation"
        className={`${selected === "meetups" ? "active" : ""}`}
      >
        <Link
          to={`/${lang}/${t("url.meetups")}`}
          aria-controls="profile"
          role="tab"
          data-toggle="tab"
        >
          <span>{t("menus.meetups")}</span>
        </Link>
      </li>
      <li
        role="presentation"
        className={`${selected === "groups" ? "active" : ""}`}
      >
        <Link
          to={`/${lang}/${t("url.groups")}`}
          aria-controls="messages"
          role="tab"
          data-toggle="tab"
        >
          <span>{t("menus.groups")}</span>
        </Link>
      </li>
    </ul>
  );
}
