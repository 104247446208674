import {Upload} from "tus-js-client";

export const cloudflare = (file, onSuccessCallback = false) => {
    let mediaId = '';
    const options = {
      endpoint: 'https://api.cloudflare.com/client/v4/accounts/2109b8cde692b67fa7bfc9813be44b5f/stream',
      headers: {
        ['X-Auth-Email']: 'amorales@seedem.co',
        ['X-Auth-Key']: 'da707d74993bcbec86e113023ca82b0cefc3f',
      },
      chunkSize: 50 * 1024 * 1024, // Required a minimum chunk size of 5MB, here we use 50MB.
      resume: true,
      metadata: {
        filename: file.name,
        filetype: file.type,
        defaulttimestamppct: 0.5,
      },
      uploadSize: file.size,
      onError: function (error) {
        throw error;
      },
      onProgress: function (bytesUploaded, bytesTotal) {
        var percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
        var progress = document.getElementById("progress__bar");

        progress.style.width = percentage+"%";
        progress.textContent = percentage+"%";

        console.log(bytesUploaded, bytesTotal, percentage + '%');
      },
      onSuccess: function () {
        if (onSuccessCallback && typeof onSuccessCallback == "function") {
          onSuccessCallback(mediaId);
        }
      },
      onAfterResponse: function (req, res) {
        return new Promise(resolve => {
          var mediaIdHeader = res.getHeader('stream-media-id');
          if (mediaIdHeader) {
            mediaId = mediaIdHeader;
          }
          resolve();
        });
      },
    };
    const upload = new Upload(file, options);
    upload.start();
}