import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import EventThumb from "../../_components/ui/event-thumb";
import { eventService } from "../../_services/event.service";

import * as moment from "moment";

import { path } from "../../_helpers";

export default function UpcomingEvents() {
  //Language hooks
  const { t, i18n } = useTranslation("common");
  const lang = i18n.language;

  const [data, setData] = useState([]);

  useEffect(() => {
    /** Call Services  */
    eventService
      .getEventsUpcommig(lang)
      .then((res) => res.json())
      .then((response) => {
        setData(response);
      })
      .catch((error) => console.log(error));
  }, [lang, setData]);

  return (
    <div className="container">
      <div className="featured__heading d-flex justify-content-between">
        <h3 className="title__home bold">
          <span className="title__highlight">{t("events.upcoming_blue")}</span>
          {t("events.upcoming_black")}
        </h3>
      </div>
      <div className="featured__articles d-flex flex-column flex-sm-column flex-md-row flex-lg-row">
        {data.map((dataArticle) => (
          <div
            className="col-12 col-sm-12 col-md-4 col-lg-4 small__column__articles"
            key={dataArticle.nid}
          >
            <EventThumb
              slug={dataArticle.field_path}
              img={path(dataArticle.field_image)}
              title={dataArticle.field_name_evente}
              location={dataArticle.field_location}
              startDate={moment(dataArticle.field_start_date).format(
                "MMM DD, YYYY"
              )}
              category={dataArticle.field_principal_category}
              description={dataArticle.field_summary_short}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
