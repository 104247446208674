import React, { useState, useEffect } from "react";
import { articleService } from "../../_services";
import { useTranslation } from "react-i18next";
import ArticleCategoryBig from "../ui/article-category-big";
import ArticleCategorySmall from "../ui/article-category-small";
import CategoryTabs from "../navigation/category-tabs";

export const CategoryArticles = ({ title, category }) => {
  //Language hooks
  const { i18n } = useTranslation("common");
  const lang = i18n.language;
  const [articles, setArticles] = useState([]);
  const [tab, setTab] = useState("Top trending");

  useEffect(() => {
    /** Call Services  */
    articleService
      .getArticleFeatured(lang, category, tab)
      .then((res) => res.json())
      .then((response) => {
        if (response) {
          setArticles(response);
        }
      })
      .catch(console.log);
  }, [lang, category, tab, setArticles]);

  return (
    <section className="category__tabs">
      <div className="container">
        <h1 className="cat__title">{title}</h1>

        {/* Beginning tabs nav */}
        <div className="tabs__container">
          <CategoryTabs current={tab} setTab={setTab} />
        </div>

        {articles.length > 0 && (
          <div className="d-flex flex-column flex-sm-column flex-md-row flex-lg-row tab__content">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 tab__content1">
              <ArticleCategoryBig article={articles.slice(0, 1)[0]} />
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 tab__content2">
              {articles.slice(1, 3).map((article, id) => (
                <ArticleCategorySmall article={article} key={id} />
              ))}
            </div>
          </div>
        )}
      </div>
    </section>
  );
};
