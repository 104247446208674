import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyD3erwDzExYVxiKyBSOsFxBqJJ8TbjYQJc",
  authDomain: "techfreneticsms-c4fc2.firebaseapp.com",
  projectId: "techfreneticsms-c4fc2",
  storageBucket: "techfreneticsms-c4fc2.appspot.com",
  messagingSenderId: "152682173679",
  appId: "1:152682173679:web:f09f91c4a1b1ec96fc771a"
};

const app = initializeApp(firebaseConfig);

export const authentication = getAuth(app);