import React, { useState } from "react";
import { useTranslation } from "react-i18next";

export default function CategoryTabs({ current, setTab }) {
  //Language hooks
  const { t } = useTranslation("common");
  const [selected, setSelected] = useState(current);

  const handleTabChange = (e, value) => {
    e.preventDefault();
    setSelected(value);
    setTab(value);
  };

  return (
    <ul className="nav nav-tabs draggable draggable-center" role="tablist">
      <li
        role="presentation"
        className={selected === "Top trending" ? "active" : ""}
      >
        <a
          href="./"
          aria-controls="trendy"
          role="tab"
          data-toggle="tab"
          onClick={(e) => handleTabChange(e, "Top trending")}
        >
          {t("contents.tab_trendy")}
        </a>
      </li>
      <li
        role="presentation"
        className={selected === "Technologies" ? "active" : ""}
      >
        <a
          href="./"
          aria-controls="Technologies"
          role="tab"
          data-toggle="tab"
          onClick={(e) => handleTabChange(e, "Technologies")}
        >
          {t("contents.tab_technologies")}
        </a>
      </li>
      <li
        role="presentation"
        className={selected === "Best practices" ? "active" : ""}
      >
        <a
          href="./"
          aria-controls="practices"
          role="tab"
          data-toggle="tab"
          onClick={(e) => handleTabChange(e, "Best practices")}
        >
          {t("contents.tab_practices")}
        </a>
      </li>
      <li
        role="presentation"
        className={selected === "Concepts" ? "active" : ""}
      >
        <a
          href="./"
          aria-controls="concepts"
          role="tab"
          data-toggle="tab"
          onClick={(e) => handleTabChange(e, "Concepts")}
        >
          {t("contents.tab_concepts")}
        </a>
      </li>
      <li
        role="presentation"
        className={selected === "Use cases" ? "active" : ""}
      >
        <a
          href="./"
          aria-controls="cases"
          role="tab"
          data-toggle="tab"
          onClick={(e) => handleTabChange(e, "Use cases")}
        >
          {t("contents.tab_cases")}
        </a>
      </li>
    </ul>
  );
}
