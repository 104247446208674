import React, { useState } from "react";
import ico_edit from "../../_images/ico-edit.svg";
import { CertificationForm } from "./add-edit-certification";

export const EditCertificationsModal = ({
  id,
  url_image,
  name,
  setCertification,
}) => {
  const [showCertifications, setCertifications] = useState(false);
  //Language hooks
  const handleCloseCertifications = () => setCertifications(false);
  const handleShowCertifications = (e) => {
    setCertifications(true);
    e.preventDefault();
  };
  return (
    <>
      <a
        href="/s"
        className="edit__certification"
        onClick={handleShowCertifications}
      >
        <img src={ico_edit} alt="Edit certification" />
      </a>
      <CertificationForm
        show={showCertifications}
        onHide={handleCloseCertifications}
        certification={setCertification}
        data={{
          id: id,
          url_image: url_image,
          name: name,
        }}
      />
    </>
  );
};
