import React from "react";
import { Link } from "@reach/router";
import { useTranslation } from "react-i18next";

export default function EventBanner({ slug, img, title, startDate, location }) {
  const { i18n, t } = useTranslation("common");
  const lang = i18n.language;

  const formatSlug = slug.substring(8);
  const link = `/${lang}/${t("url.events")}/${formatSlug}`;

  return (
    <section className="featured__event">
      <div className="container d-flex flex-column flex-sm-column flex-md-row flex-lg-row">
        <div
          style={{
            backgroundImage: `url("${img}")`,
          }}
          className="featured__image col-12 col-sm-12 col-md-7 col-lg-7"
        ></div>
        <div className="description__featured col-12 col-sm-12 col-md-5 col-lg-5">
          <h1>{title}</h1>
          <div className="event__date">
            <span className="event__location">{location}</span>
            <span className="event__date">{startDate}</span>
          </div>
          <div className="event__cta">
            <Link to={link}>{t("events.details")}</Link>
            <a href="https://www.allticketscol.com/" target="_blank">{t("events.buy_tickets")}</a>
          </div>
        </div>
      </div>
    </section>
  );
}
