import React from "react";
import { connect } from "react-redux";
import { modalActions } from "../_actions";
import { useTranslation } from "react-i18next";
import SEO from "../_components/common/seo";
import Layout from "../_components/common/layout";
import img_empty from "../_images/ico-404.svg";

const AuthPage = ({ me, login }) => {
  const { t, i18n } = useTranslation("common");
  const lang = i18n.language;

  const handleLogin = (e) => {
    e.preventDefault();
    login();
  };

  if (!me) {
    login();
  }
  return (
    <Layout>
      <SEO lang={lang} title={t("pages.title")} />
      <main
        role="main"
        className="no__top"
        itemScope=""
        itemProp="mainContentOfPage"
        itemType="http://schema.org/Blog"
      >
        <section className="notifications error">
          <div className="container">
            <div className="profile__header d-flex flex-column">
              <img src={img_empty} alt="Nothing here" />
              <h1 className="error404">Unathorized</h1>
              <span>You can’t access to this section</span>
              <a href="./" className="btn btn-primary" onClick={handleLogin}>
                Login
              </a>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};

function mapState(state) {
  const { user, me } = state.authentication;
  return { user, me };
}

const actionCreators = {
  login: modalActions.showLogin,
};

const connectedAuthPage = connect(mapState, actionCreators)(AuthPage);
export { connectedAuthPage as AuthPage };
