import React from "react";
import SEO from "../_components/common/seo";
import Layout from "../_components/common/layout";
import { useTranslation } from "react-i18next";
import graph from "../_images/trend-data-graph.png";
import { ProfileMenu } from "../_components/navigation/profile-menu";
import ProfileViewers from "../_components/users/profile-viewers";
import Credits from "../_components/common/credits";

const ProfileTrends = () => {
  const { t, i18n } = useTranslation("common");
  const lang = i18n.language;

  return (
    <Layout>
      <SEO lang={lang} title={t("profile.title")} />
      <main
        role="main"
        itemScope=""
        itemProp="mainContentOfPage"
        itemType="http://schema.org/Blog"
      ></main>

      <section className="profile__page feed">
        <div className="container d-flex flex-wrap">
          <ProfileMenu selected="profile" />

          <div className="profile__body col-lg-10 col-md-9">
            <div className="groups__options trends">
              <div className="d-flex flex-column flex-sm-column flex-md-column flex-lg-row align-items-center align-items-sm-center align-items-md-center align-items-lg-center align-items-sm-center align-items-md-start align-items-lg-start trends__title">
                <h2 className="new__content">{t("profile.trends")}</h2>
                <p>{t("profile.trends-view")}</p>
              </div>
              <div className="d-flex flex-column flex-sm-column flex-md-column flex-lg-row align-items-center align-items-sm-center align-items-md-center align-items-lg-start viewers">
                <h3>43</h3>
                <p>{t("profile.year-profile")}</p>
              </div>
              <div className="viewers__trends">
                <div className="data__trends__container">
                  <img src={graph} alt="Trends" />
                </div>
                <div className="viewers__container">
                  <h2>
                    {t("profile.viewers")} <span>(12)</span>
                  </h2>
                  <div className="d-flex flex-wrap viewers__cards__container">
                    <ProfileViewers />
                  </div>
                </div>
              </div>
            </div>
            <Credits></Credits>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ProfileTrends;
