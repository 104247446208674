import React from "react";
import SEO from "../_components/common/seo";
import Layout from "../_components/common/layout";
import { useTranslation } from "react-i18next";

import "../css/terms.css";

const DisclaimerPage = () => {
  const { t, i18n } = useTranslation("common");
  const lang = i18n.language;

  //Default page
  return (
    <Layout>
      <SEO lang={lang} title={t("url.sale")} />
      <main
        role="main"
        class="no__top"
        itemscope=""
        itemprop="mainContentOfPage"
        itemtype="http://schema.org/Blog"
      >
        <div className="container d-flex  term_style">
          <div className="d-block">
            <p>Disclaimer</p>
            <br />

            <p>
              This disclaimer (“Disclaimer”) sets forth the general guidelines,
              disclosures, and terms of your use of the techfrenetic.com website
              (“Website”), “www.techfrenetic.com” mobile application (“Mobile
              Application”) and any of their related products and services
              (collectively, “Services”). This Disclaimer is a legally binding
              agreement between you (“User”, “you” or “your”) and TechFrenetic
              (“TechFrenetic”, “we”, “us” or “our”). If you are entering into
              this agreement on behalf of a business or other legal entity, you
              represent that you have the authority to bind such entity to this
              agreement, in which case the terms “User”, “you” or “your” shall
              refer to such entity. If you do not have such authority, or if you
              do not agree with the terms of this agreement, you must not accept
              this agreement and may not access and use the Services. By
              accessing and using the Services, you acknowledge that you have
              read, understood, and agree to be bound by the terms of this
              Disclaimer. You acknowledge that this Disclaimer is a contract
              between you and TechFrenetic, even though it is electronic and is
              not physically signed by you, and it governs your use of the
              Services.
            </p>
            <p>Representation</p>
            <p>
              Any views or opinions represented on the Services belong solely to
              the content creators and do not represent those of people,
              institutions or organizations that TechFrenetic or creators may or
              may not be associated with in professional or personal capacity,
              unless explicitly stated. Any views or opinions are not intended
              to malign any religion, ethnic group, club, organization, company,
              or individual.
            </p>
            <p>Content and postings</p>
            <p>
              You may not modify, print or copy any part of the Services.
              Inclusion of any part of the Services in another work, whether in
              printed or electronic or another form or inclusion of any part of
              the Services on another resource by embedding, framing or
              otherwise without the express permission of TechFrenetic is
              prohibited.
            </p>
            <p>
              You may submit new content and comment on the existing content on
              the Services. By uploading or otherwise making available any
              information to TechFrenetic, you grant TechFrenetic the unlimited,
              perpetual right to distribute, display, publish, reproduce, reuse
              and copy the information contained therein. You may not
              impersonate any other person through the Services. You may not
              post content that is defamatory, fraudulent, obscene, threatening,
              invasive of another person’s privacy rights or that is otherwise
              unlawful. You may not post content that infringes on the
              intellectual property rights of any other person or entity. You
              may not post any content that includes any computer virus or other
              code designed to disrupt, damage, or limit the functioning of any
              computer software or hardware.Compensation and sponsorship
            </p>
            <p>
              The Services may contain forms of advertising, sponsorship, paid
              insertions or other forms of compensation. On certain occasions
              TechFrenetic may be compensated to provide opinions on products,
              services, or various other topics. The compensation received may
              influence such opinions of the advertised content or topics
              available on the Services. Sponsored content and advertising space
              will always be identified as such. Some of the links on the
              Services may be affiliate links. This means if you click on the
              link and purchase an item, TechFrenetic will receive an affiliate
              commission.Fitness and medical disclaimer
            </p>
            <p>
              The information available on the Services is for general health
              information only and is not intended to be a substitute for
              professional medical advice, diagnosis or treatment. You should
              not rely exclusively on information provided on the Services for
              your health needs. All specific medical questions should be
              presented to your own health care provider and you should seek
              medical advice regarding your health and before starting any
              nutrition, weight loss or any other type of workout program.
            </p>
            <p>
              If you choose to use the information available on the Services
              without prior consultation with and consent of your physician, you
              are agreeing to accept full responsibility for your decisions and
              agreeing to hold harmless TechFrenetic, its agents, employees,
              contractors, and any affiliated companies from any liability with
              respect to injury or illness to you or your property arising out
              of or connected with your use of this information.
            </p>
            <p>
              There may be risks associated with participating in activities
              presented on the Services for people in good or poor health or
              with pre-existing physical or mental health conditions. If you
              choose to participate in these risks, you do so of your own free
              will and accord, knowingly and voluntarily assuming all risks
              associated with such activities.
            </p>
            <p>
              The results obtained from the information available on the
              Services may vary, and will be based on your individual
              background, physical health, previous experience, capacity,
              ability to act, motivation and other variables. There are no
              guarantees concerning the level of success you may experience.Not
              legal advice
            </p>
            <p>
              The information provided on the Services is for general
              information purposes only and is not an alternative to legal
              advice from your lawyer, other professional services provider, or
              expert. It is not intended to provide legal advice or opinions of
              any kind. You should not act, or refrain from acting, based solely
              upon the information provided on the Services without first
              seeking appropriate legal or other professional advice. If you
              have any specific questions about any legal matter, you should
              consult your lawyer, other professional services provider, or
              expert. You should never delay seeking legal advice, disregard
              legal advice, or commence or discontinue any legal action because
              of the information on the Services.
            </p>
            <p>
              The information on the Services is provided for your convenience
              only. This information may have no evidentiary value and should be
              checked against official sources before it is used for any
              purposes. It is your responsibility to determine whether this
              information is admissible in a given judicial or administrative
              proceeding and whether there are any other evidentiary or filing
              requirements. Your use of this information is at your own risk.Not
              financial advice
            </p>
            <p>
              The information on the Services is provided for your convenience
              only and is not intended to be treated as financial, investment,
              tax, or other advice. Nothing contained on the Services
              constitutes a solicitation, recommendation, endorsement, or offer
              by TechFrenetic, its agents, employees, contractors, and any
              affiliated companies to buy or sell any securities or other
              financial instruments.
            </p>
            <p>
              All content on this site is the information of a general nature
              and does not address the circumstances of any particular
              individual or entity. Nothing on the Services constitutes
              professional and/or financial advice, nor does any information on
              the Services constitute a comprehensive or complete statement of
              the matters discussed or the law relating thereto. You alone
              assume the sole responsibility of evaluating the merits and risks
              associated with the use of any information or other content on the
              Services before making any decisions based on such information.
              You agree not to hold TechFrenetic, its agents, employees,
              contractors, and any affiliated companies liable for any possible
              claim for damages arising from any decision you make based on the
              information made available to you through the Website.Not
              investment advice
            </p>
            <p>
              All investments are highly speculative in nature and involve
              substantial risk of loss. We encourage everyone to invest very
              carefully. We also encourage investors to get personal advice from
              your professional investment advisor and to make independent
              investigations before acting on information found on the Services.
              We do not in any way whatsoever warrant or guarantee the success
              of any action you take in reliance on statements or information
              available on the Services.
            </p>
            <p>
              Past performance is not necessarily indicative of future results.
              All investments carry significant risk and all investment
              decisions of an individual remain the specific responsibility of
              that individual. There is no guarantee that systems, indicators,
              or signals will result in profits or that they will not result in
              full or partial losses. All investors are advised to fully
              understand all risks associated with any kind of investing they
              choose to do.Reviews and testimonials
            </p>
            <p>
              Testimonials are received in various forms through a variety of
              submission methods. They are individual experiences, reflecting
              experiences of those who have used the Services in some way or
              another. However, they are individual results and results do vary.
              We do not claim that they are typical results that consumers will
              generally achieve. The testimonials are not necessarily
              representative of all of those who will use Services, and
              TechFrenetic is not responsible for the opinions or comments
              available on the Services, and does not necessarily share them.
              All opinions expressed are strictly the views of the reviewers.
            </p>
            <p>
              The testimonials displayed are given verbatim except for
              grammatical or typing error corrections. Some testimonials may
              have been edited for clarity, or shortened in cases where the
              original testimonial included extraneous information of no
              relevance to the general public. Testimonials may be reviewed for
              authenticity before they are available for public
              viewing.Indemnification and warranties
            </p>
            <p>
              While we have made every attempt to ensure that the information
              contained on the Services is correct, TechFrenetic is not
              responsible for any errors or omissions, or for the results
              obtained from the use of this information. All information on the
              Services is provided “as is”, with no guarantee of completeness,
              accuracy, timeliness or of the results obtained from the use of
              this information, and without warranty of any kind, express or
              implied. In no event will TechFrenetic, or its partners, employees
              or agents, be liable to you or anyone else for any decision made
              or action taken in reliance on the information on the Services, or
              for any consequential, special or similar damages, even if advised
              of the possibility of such damages.
            </p>
            <p>
              Furthermore, as with any business, your results may vary and will
              be based on your individual capacity, experience, expertise, and
              level of desire. There are no guarantees concerning the level of
              success you may experience. There is no guarantee that you will
              make any income at all and you accept the risk that the earnings
              and income statements differ by individual. Each individual’s
              success depends on his or her background, dedication, desire and
              motivation. The use of the information available on the Services
              should be based on your own due diligence and you agree that
              TechFrenetic is not liable for any success or failure of your
              business that is directly or indirectly related to the purchase
              and use of our information, products, and services reviewed or
              advertised on the Services. Information contained on the Services
              are subject to change at any time and without warning.
            </p>
            <p>Changes and amendments</p>
            <p>
              We reserve the right to modify this Disclaimer or its terms
              related to the Services at any time at our discretion. When we do,
              we will revise the updated date at the bottom of this page, post a
              notification within the Services. We may also provide notice to
              you in other ways at our discretion, such as through the contact
              information you have provided.
            </p>
            <p>
              An updated version of this Disclaimer will be effective
              immediately upon the posting of the revised Disclaimer unless
              otherwise specified. Your continued use of the Services after the
              effective date of the revised Disclaimer (or such other act
              specified at that time) will constitute your consent to those
              changes.
            </p>
            <p>Acceptance of this disclaimer</p>
            <p>
              You acknowledge that you have read this Disclaimer and agree to
              all its terms and conditions. By accessing and using the Services
              you agree to be bound by this Disclaimer. If you do not agree to
              abide by the terms of this Disclaimer, you are not authorized to
              access or use the Services.
            </p>
            <p>Contacting us</p>
            <p>
              If you have any questions, concerns, or complaints regarding this
              Agreement, we encourage you to contact us using the details below:
            </p>
            <p>memo@techfrenetic.com</p>
            <p>This document was last updated on August 17, 2021</p>
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default DisclaimerPage;
