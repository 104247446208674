import React from "react";
import SEO from "../_components/common/seo";
import Layout from "../_components/common/layout";
import { useTranslation } from "react-i18next";


import "../css/terms.css"

const PolicyPage = () => {
  const { t, i18n } = useTranslation("common");
  const lang = i18n.language;

  //Default page
  return (
    <Layout>
      <SEO lang={lang} title={t("url.privacy")} />
      <main
        role="main"
        class="no__top"
        itemscope=""
        itemprop="mainContentOfPage"
        itemtype="http://schema.org/Blog"
      >
        <div
          className="container d-flex  term_style"
        >
          <div className="d-block">
            <h2>{t("policy.title")}</h2>
            <br />
            <p>{t("policy.p1")}</p>
            <br />
            <p>{t("policy.p2")}</p>
            <br />
            <p>{t("policy.p3")}</p>
            <br />
            <p>{t("policy.p4")}</p>
            <br />
            <p>{t("policy.p5")}</p>
            <br />
            <p>{t("policy.p6")}</p>
            <br />
            <p>{t("policy.p7")}</p>
            <br />
            <p>{t("policy.p8")}</p>
            <br />
            <p>{t("policy.p9_1")}</p>
            <p>{t("policy.p9_2")}</p>
            <p>{t("policy.p9_3")}</p>
            <p>{t("policy.p9_4")}</p>
            <p>{t("policy.p9_5")}</p>
            <p>{t("policy.p9_6")}</p>
            <p>{t("policy.p9_7")}</p>
            <p>{t("policy.p9_8")}</p>
            <p>{t("policy.p9_9")}</p>
            <p>{t("policy.p9_10")}</p>
            <p>{t("policy.p9_11")}</p>
            <p>{t("policy.p9_12")}</p>
            <p>{t("policy.p9_13")}</p>
            <p>{t("policy.p9_14")}</p>
            <p>{t("policy.p9_15")}</p>
            <p>{t("policy.p9_16")}</p>
            <p>{t("policy.p9_17")}</p>
            <p>{t("policy.p9_18")}</p>
            <br />
            <p>{t("policy.p10")}</p>
            <br />
            <p>{t("policy.p11")}</p>
            <br />
            <p>{t("policy.p12")}</p>
            <br />
            <p>{t("policy.p13")}</p>
            <br />
            <p>{t("policy.p14")}</p>
            <br />
            <p>{t("policy.p15")}</p>
            <br />
            <p>{t("policy.p16")}</p>
            <br />
            <p>&nbsp;{t("policy.p17")}</p>
            <br />
            <p>{t("policy.p18")}</p>
            <br />
            <p>{t("policy.p19")}</p>
            <br />
            <p>{t("policy.p20_0")}</p>
            <p>{t("policy.p20_1")}</p>
            <p>{t("policy.p20_2")}</p>
            <p>{t("policy.p20_3")}</p>
            <p>{t("policy.p20_4")}</p>
            <p>{t("policy.p20_5")}</p>
            <p>{t("policy.p20_6")}</p>
            <p>{t("policy.p20_7")}</p>
            <p>{t("policy.p20_8")}</p>
            <p>{t("policy.p20_9")}</p>
            <p>{t("policy.p20_10")}</p>
            <p>{t("policy.p20_11")}</p>
            <p>{t("policy.p20_12")}</p>
            <p>{t("policy.p20_13")}</p>
            <p>{t("policy.p20_14")}</p>
            <p>{t("policy.p20_15")}</p>
            <p>{t("policy.p20_16")}</p>
            <br />
            <p>{t("policy.p21")}</p>
            <br />
            <p>{t("policy.p22")}</p>
            <br />
            <p>{t("policy.p22_0")}</p>
            <p>{t("policy.p22_1")}</p>
            <br />
            <p>&nbsp;{t("policy.p23")}</p>
            <br />
            <p>{t("policy.p24")}</p>
            <br />
            <p>{t("policy.p25")}</p>
            <br />
            <p>{t("policy.p26")}</p>
            <br />
            <p>{t("policy.p27")}</p>
            <br />
            <p>{t("policy.p28")}</p>
            <br />
            <p>{t("policy.p29")}</p>
            <br />
            <p>{t("policy.p30")}</p>
            <br />
            <p>{t("policy.p31")}</p>
            <br />
            <p>{t("policy.p32")}</p>
            <br />
            <br />
            <p>{t("policy.p33_0")}</p>
            <p>{t("policy.p33_1")}</p>
            <p>{t("policy.p33_2")}</p>
            <p>{t("policy.p33_3")}</p>
            <p>{t("policy.p33_4")}</p>
            <p>{t("policy.p33_5")}</p>
            <p>{t("policy.p33_6")}</p>
            <p>{t("policy.p33_7")}</p>
            <p>{t("policy.p33_8")}</p>
            <p>{t("policy.p33_9")}</p>
            <p>{t("policy.p33_10")}</p>
            <p>{t("policy.p33_11")}</p>
            <p>{t("policy.p33_12")}</p>
            <p>{t("policy.p33_13")}</p>
            <p>{t("policy.p33_14")}</p>
            <p>{t("policy.p33_15")}</p>
            <br />
            <p>{t("policy.p34")}</p>
            <br />
            <p>{t("policy.p35")}</p>
            <br />
            <p>{t("policy.p36")}</p>
            <br />
            <p>{t("policy.p37")}</p>
            <br />
            <p>{t("policy.p38")}</p>
            <br />
            <p>{t("policy.p39")}</p>
            <br />
            <p>{t("policy.p40")}</p>
            <br />
            <p>{t("policy.p41")}</p>
            <br />
            <p>{t("policy.p42")}</p>
            <br />
            <p>{t("policy.p43")}</p>
            <br />
            <p>{t("policy.p44")}</p>
            <br />
            <p>{t("policy.p45")}</p>
            <br />
            <p>{t("policy.p46")}</p>
            <br />
            <p>{t("policy.p47")}</p>
            <br />
            <p>{t("policy.p48")}</p>
            <br />
            <p>{t("policy.p49")}</p>
            <br />
            <p>{t("policy.p50")}</p>
            <br />
            <p>{t("policy.p51")}</p>
            <br />
            <p>{t("policy.p52")}</p>
            <br />
            <p>{t("policy.p53")}</p>
            <br />
            <p>{t("policy.p54")}</p>
            <br />
            <p>{t("policy.p55")}</p>
            <br />
            <p>{t("policy.p56")}</p>
            <br />
            <p>{t("policy.p57")}</p>
            <br />
            <p>{t("policy.p58")}</p>
            <br />
            <p>{t("policy.p59")}</p>
            <br />
            <p>{t("policy.p60")}</p>
            <br />
            <p>{t("policy.p61")}</p>
            <br />
            <p>{t("policy.p62")}</p>
            <br />
            <p>{t("policy.p63")}</p>
            <br />
            <p>{t("policy.p64")}</p>
            <br />
            <p>{t("policy.p65")}</p>
            <br />
            <p>{t("policy.p66")}</p>
            <br />
            <p>{t("policy.p67")}</p>
            <br />
            <p>{t("policy.p68")}</p>
            <br />
            <p>{t("policy.p69")}</p>
            <br />
            <p>{t("policy.p70")}</p>
            <br />
            <p>{t("policy.p71")}</p>
            <br />
            <p>{t("policy.p72")}</p>
            <br />
            <p>{t("policy.p73")}</p>
            <br />
            <p>{t("policy.p74")}</p>
            <br />
            <p>{t("policy.p75")}</p>
            <br />
            <p>{t("policy.p76")}</p>
            <br />
            <p>{t("policy.p77")}</p>
            <br />
            <p>{t("policy.p78")}</p>
            <br />
            <p>{t("policy.p79")}</p>
            <br />
            <p>{t("policy.p80")}</p>
            <br />
            <p>{t("policy.p81")}</p>
            <br />
            <p>{t("policy.p82")}</p>
            <br />
            <p>{t("policy.p83")}</p>
            <br />
            <p>{t("policy.p84")}</p>
            <br />
            <p>{t("policy.p85")}</p>
            <br />
            <p>{t("policy.p86")}</p>
            <br />
            <p>{t("policy.p87")}</p>
            <br />
            <p>{t("policy.p88")}</p>
            <br />
            <p>{t("policy.p89")}</p>
            <br />
            <p>{t("policy.p90")}</p>
            <br />
            <p>{t("policy.p91")}</p>
            <br />
            <p>{t("policy.p92")}</p>           
            <p>{t("policy.p93")}</p>
            <br />
            <p>{t("policy.p94")}</p>
            <br />
            <p>{t("policy.p95")}</p>
            <br />
            <br />
            <br />
            <br />
            <br />
            <h2>{t("policy.p96")}</h2>
            <br />
            <p>{t("policy.p97")}</p>
            <br />
            <p>{t("policy.p98")}</p>
            <br />
            <p>{t("policy.p99")}</p>
            <br />
            <p>{t("policy.p100")}</p>
            <br />
            <p>{t("policy.p101")}</p>
            <br />
            <p>{t("policy.p102")}</p>
            <br />
            <p>{t("policy.p103")}</p>
            <br />
            <p>{t("policy.p104")}</p>
            <br />
            <p>{t("policy.p105")}</p>
            <br />
            <p>{t("policy.p106")}</p>
            <br />
            <p>{t("policy.p107")}</p>
            <br />
            <p>{t("policy.p108")}</p>
            <br />
            <p>{t("policy.p109")}</p>
            <br />
            <p>{t("policy.p110")}</p>
            <br />
            <p>{t("policy.p111")}</p>
            <br />
            <p>{t("policy.p112")}</p>
            <br />
            <p>{t("policy.p113")}</p>
            <br />
            <p>{t("policy.p114")}</p>
            <br />
            <p>{t("policy.p115")}</p>
            <br />
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default PolicyPage;
