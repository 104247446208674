import React from "react";
import { Link } from "@reach/router";
import { useTranslation } from "react-i18next";

export default function GroupsMenu({ selected }) {
  const { t, i18n } = useTranslation("common");
  const lang = i18n.language;

  return (
    <ul
      className="nav nav-tabs draggable draggable-center ui-draggable ui-draggable-handle"
      role="tablist"
      style={{ position: "relative", width: "100%" }}
    >
      <li role="presentation" className={selected === "tab1" ? "active" : ""}>
        <Link
          to={`/${lang}/${t("url.groups")}/${t("url.groups_discover")}`}
          aria-controls="home"
          role="tab"
          data-toggle="tab"
        >
          {t("groups.tab_discover")}
        </Link>
      </li>
      <li role="presentation" className={selected === "tab2" ? "active" : ""}>
        <Link to={`/${lang}/${t("url.groups")}`} role="tab">
          {t("groups.tab_groups")}
        </Link>
      </li>
      <li role="presentation" className={selected === "tab3" ? "active" : ""}>
        <Link
          to={`/${lang}/${t("url.groups")}/${t("url.groups_new")}`}
          role="tab"
        >
          {t("groups.tab_create")}
        </Link>
      </li>
    </ul>
  );
}
