import React, { useState } from "react";
import SEO from "../_components/common/seo";
import Layout from "../_components/common/layout";
import { Link } from "@reach/router";
import { useTranslation } from "react-i18next";
import { MobileMenu } from "../_components/navigation/mobile-menu";
import { MeetupForm } from "../_components/forms";
import { MeetupCreated } from "../_components/modals/meetup-created";
import CommunityMenu from "../_components/navigation/community-menu";
import ico_back from "../_images/ico-back.svg";

const MeetupsPage = () => {
  const { t, i18n } = useTranslation("common");
  const lang = i18n.language;

  const [showMeetupsSucess, setShowMeetupsSucess] = useState(false);
  const handleCloseMeetupsSucess = () => setShowMeetupsSucess(false);

  return (
    <Layout withFade={true} noFooter={true}>
      <SEO lang={lang} title={t("meetups.title")} />
      <main
        role="main"
        itemScope=""
        itemProp="mainContentOfPage"
        itemType="http://schema.org/Blog"
      >
        <section className="profile__page">
          <div className="container d-flex">
            <div className="profile__menu col-lg-2 col-md-3">
              <CommunityMenu selected="meetups" />
            </div>
            <div className="profile__body col-lg-10 col-md-9">
              <div className="profile__header meetups__listing">
                <Link to={`/${lang}/${t("url.meetups")}`} className="back__btn">
                  <img src={ico_back} alt="Back" />
                  {t("buttons.back")}
                </Link>
                <h1 className="highlighted text-center">
                  <span>{t("meetups.host_blue")}</span>
                  {t("meetups.host_black")}
                </h1>
                <p className="meetups__txt text-center">
                  {t("meetups.host_intro")}
                </p>
                <div className="profile__form create__meetup">
                  <MeetupForm onSuccess={setShowMeetupsSucess} />
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <MobileMenu selected="community" withFade={true} />
      <MeetupCreated
        show={showMeetupsSucess}
        onHide={handleCloseMeetupsSucess}
      />
    </Layout>
  );
};

export default MeetupsPage;
