import React from "react";
import { useTranslation } from "react-i18next";

import level_1 from "../../_images/ico-video-level1.svg";
import level_2 from "../../_images/ico-video-level2.svg";
import level_3 from "../../_images/ico-video-level3.svg";

export const Level = ({ grade }) => {
  //Language hooks
  const { t } = useTranslation("common");

  return (
    <div className="video__level__box">
      {t("articles.level_label")}:<span>{t(`articles.level_${grade}`)}</span>
      {grade === "basic" && (
        <img src={level_1} alt={t("articles.level_claim")} />
      )}
      {grade === "medium" && (
        <img src={level_2} alt={t("articles.level_claim")} />
      )}
      {grade === "advanced" && (
        <img src={level_3} alt={t("articles.level_claim")} />
      )}
    </div>
  );
};
