import { authHeader, logoutHeader } from "../_helpers";
import { appConstants } from "../_constants";
import { navigate } from "@reach/router";

export const userService = {
  login,
  logout,
  proxy,
  register,
  registerStep2,
  registerStep3,
  forgotPassword,
  resetPassword,
  updateProfile,
  update,
  summary,
  followers,
  following,
  follow,
  favorites,
  myContents,
  editPhone,
  userPremium,
  techPremium,
  paymentStripe,
};

/**
 *
 * @param {*} uid
 * @param {*} page
 */
function myContents(uid, page = 0) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };
  return fetch(
    `${appConstants.API}/v1/my-contents?_format=json&user=${uid}&page=${page}`,
    requestOptions
  ).then(handleResponse);
}

function proxy(lang) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
    // headers: { "Content-Type": "application/json" },
  };

  return (
    fetch(`${appConstants.API}/${lang}/json_proxy`, requestOptions)
      .then(handleResponse)
      // .then((res) => res.json())
      .then((response) => {
        if (response) {
          let res = false;
          if (response.data.valor_proxy == "1") {
            res = true;
          }

          return { res };
        }
      })
      .catch(console.warn)
  );
}

/**
 *
 * @param {*} uid
 * @param {*} list
 */
function follow(uid, list) {
  //Setup object according drupal's format.
  const payload = {
    field_following: [],
  };
  for (let i = 0; i < list.length; i++) {
    payload.field_following.push({ target_id: list[i].id });
  }

  const requestOptions = {
    method: "PATCH",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  };

  return fetch(
    `${appConstants.API}/user/${uid}?_format=json`,
    requestOptions
  ).then(handleResponse);
}

function editPhone(id, phone) {
  const payload = {
    field_cellphone: [
      {
        value: phone,
      },
    ],
  };

  const requestOptions = {
    method: "PATCH",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  };

  return fetch(
    `${appConstants.API}/user/${id}?_format=json`,
    requestOptions
  ).then(handleResponse);
}

function userPremium(id) {
  const payload = {
    roles: [
      {
        target_id: "premiun",
      },
    ],
  };

  const requestOptions = {
    method: "PATCH",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  };

  return fetch(
    `${appConstants.API}/user/${id}?_format=json`,
    requestOptions
  ).then(handleResponse);
}

function techPremium(lang) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${appConstants.API}/${lang}/v1/product-by-sku/TFPREMIUM`,
    requestOptions
  );
}

function paymentStripe(lang, email, name, cardId, month, year, methodId) {
  const payload = {
    order: {
      type: "default",
      email: email,
      store: 1,
      order_items: [
        {
          type: "default",
          title: "Knit Hat in Midnight Plumkkkk",
          quantity: 1,

          purchased_entity: {
            sku: "TFPREMIUM",
          },
          unit_price: {
            number: 10,
            currency_code: "USD",
          },
        },
      ],
    },
    user: {
      mail: email,
      name: name,
      status: "TRUE",
    },
    payment: {
      gateway: "pago_premium",
      type: "credit_card",
      details: {
        type: "credit_card",
        number: cardId,
        expiration: {
          month: month,
          year: year,
        },
        stripe_payment_method_id: methodId,
      },
    },
  };

  const requestOptions = {
    method: "POST",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  };

  return fetch(
    `${appConstants.API}/${lang}/commerce/order/create?_format=json`,
    requestOptions
  )
}

/**
 *
 * @param {*} uid
 */
function followers(uid) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${appConstants.API}/v1/followers?_format=json&user=${uid}`,
    requestOptions
  )
    .then(handleResponse)
    .then((followers) => {
      if (followers.length > 0) {
        return followers;
      }
      return [];
    });
}

/**
 *
 * @param {*} uid
 */
function following(uid) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${appConstants.API}/v1/following?_format=json&user=${uid}`,
    requestOptions
  )
    .then(handleResponse)
    .then((response) => {
      if (response[0]) {
        const { users, followers } = response[0];
        if (users && followers) {
          const u = users.split(",");
          const f = followers.split(",");
          let fwing = [];
          for (let i = 0; i < u.length; i++) {
            fwing.push({
              id: String(u[i]).trim(),
              user: String(f[i]).trim(),
            });
          }
          return fwing;
        }
      }

      return [];
    });
}

/**
 *
 * @param {*} uid
 */
function favorites(uid) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${appConstants.API}/v1/likes?_format=json&user=${uid}`,
    requestOptions
  )
    .then(handleResponse)
    .then((followers) => {
      if (followers.length > 0) {
        return followers;
      }
      return [];
    });
}

/**
 *
 * @param {*} lang
 * @param {*} uid
 */
function summary(lang, uid) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${appConstants.API}/${lang}/v1/user-name/${uid}`,
    requestOptions
  );
}

/**
 *
 * @param {*} lang
 * @param {*} name
 * @param {*} pass
 * @param {*} url_redirect
 */
function login(lang, name, pass, url_redirect) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ name, pass }),
    credentials: "same-origin",
  };

  return fetch(`${appConstants.API}/user/login?_format=json`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem("user", JSON.stringify(user));
      //Sequencially call user full profile.
      return summary(lang, user.current_user.uid)
        .then(handleResponse)
        .then((response) => {
          //store current user session.
          const me = response[0];
          me.shortName = me.display_name.substr(
            0,
            me.display_name.indexOf(" ")
          );
          // me.custom_user_certification = me.custom_user_certification && JSON.parse(me.custom_user_certification);
          localStorage.setItem("me", JSON.stringify(me));
          //Redirect to community
          navigate(url_redirect);

          //Return user.
          return { user, me };
        });
    });
}

/**
 *
 */
function logout() {
  const logout = logoutHeader();
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
  };

  return fetch(
    `${appConstants.API}/user/logout?_format=json&token=${logout.token}`,
    requestOptions
  ).then((response) => {
    // remove user from local storage to log user out
    localStorage.removeItem("user");
    localStorage.removeItem("me");

    //Redirect to home
    navigate("/");
  });
}

/**
 *
 * @param {*} lang
 * @param {*} param1
 */

function register(
  lang,
  {
    name,
    email,
    password,
    type,
    company,
    gender,
    profession,
    cellphone,
    country,
    birthdate,
    bio,
    avatar,
    position,
  }
) {
  let protocol = proxy(lang);

  //Setup object according drupal's format.
  const payload = {
    _links: {
      type: {
        href: protocol
          ? `http://${appConstants.LOCATION}/rest/type/user/user`
          : `https://${appConstants.LOCATION}/rest/type/user/user`,
      },
    },
    name: {
      value: name,
    },
    mail: {
      value: email,
    },
    pass: {
      value: password,
    },
    field_name: {
      value: name,
    },
    field_user_type: {
      value: type,
    },
    field_company: {
      value: company,
    },

    field_biography: {
      value: bio,
    },
    field_country: [
      {
        target_id: country,
      },
    ],
    field_gender: [
      {
        target_id: gender,
      },
    ],
    field__professions: [
      {
        target_id: profession,
      },
    ],
    field_birthdate: {
      value: birthdate,
    },
    field_cellphone: {
      value: cellphone,
    },
    field_position: {
      value: position,
    },

    field_user_avatar: { value: avatar },
  };

  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/hal+json" },
    body: JSON.stringify(payload),
  };

  return fetch(
    `${appConstants.API}/user/register?_format=hal_json`,
    requestOptions
  )
    .then(handleResponse)
    .then((response) => {
      //Login after register.
      return login(lang, email, password);
    });
}

/*----- */

/**
 *
 * @param {*} lang
 * @param {*} user
 * @param {*} param2
 */
function registerStep2(
  lang,
  user,
  { company, profession, country, bio, birthdate, gender }
) {
  //Setup object according drupal's format.
  let protocol = proxy(lang);
  const payload = {
    _links: {
      type: {
        href: protocol
          ? `http://${appConstants.LOCATION}/rest/type/user/user`
          : `https://${appConstants.LOCATION}/rest/type/user/user`,
      },
    },
    field_company: {
      value: company,
    },

    field_biography: {
      value: bio,
    },
    field_country: {
      value: country,
    },
    field__professions: {
      value: profession,
    },
    field_birthdate: {
      value: birthdate,
    },
    field_gender: {
      value: gender,
    },
  };

  const requestOptions = {
    method: "PATCH",
    headers: {
      ...authHeader(),
      "Content-Type": "application/hal+json",
    },
    body: JSON.stringify(payload),
  };

  return fetch(
    `${appConstants.API}/user/${user.uid}?_format=hal_json`,
    requestOptions
  )
    .then(handleResponse)
    .then(() => {
      //Sequencially call user summary.
      return summary(lang, user.uid)
        .then(handleResponse)
        .then((response) => {
          //store current user session.
          const me = response[0];
          me.shortName = me.display_name.substr(
            0,
            me.display_name.indexOf(" ")
          );
          localStorage.setItem("me", JSON.stringify(me));

          //Return user.
          return { me };
        });
    });
}

/**
 *
 * @param {*} lang
 * @param {*} user
 * @param {*} param2
 */
function registerStep3(lang, user, { type, avatar, picture }) {
  //Check picture flow.
  if (type === "photo") {
    return uploadPhoto(lang, user, picture);
  } else {
    //Update selected avatar
    const payload = {
      _links: {
        type: {
          href: `${appConstants.API}/rest/type/user/user`,
        },
      },
      field_user_avatar: { value: avatar },
      field_use_avatar: { value: true },
    };

    const requestOptions = {
      method: "PATCH",
      headers: {
        ...authHeader(),
        "Content-Type": "application/hal+json",
      },
      body: JSON.stringify(payload),
    };

    return fetch(
      `${appConstants.API}/user/${user.uid}?_format=hal_json`,
      requestOptions
    )
      .then(handleResponse)
      .then(() => {
        //Sequencially call user summary.
        return summary(lang, user.uid)
          .then(handleResponse)
          .then((response) => {
            //store current user session.
            const me = response[0];
            me.shortName = me.display_name.substr(
              0,
              me.display_name.indexOf(" ")
            );
            localStorage.setItem("me", JSON.stringify(me));

            //Return user.
            return { me };
          });
      });
  }
}

/**
 *
 * @param {*} me
 * @param {*} data
 */
function updateProfile(lang, me, data) {
  // let protocol = proxy(lang);
  //Setup object according drupal's format.
  const payload = {
    // _links: {
    //   type: {
    //     href: protocol
    //       ? `http://${appConstants.LOCATION}/rest/type/user/user`
    //       : `https://${appConstants.LOCATION}/rest/type/user/user`,
    //   },
    // },
    ...data,
  };

  const requestOptions = {
    method: "PATCH",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
    // credentials: "same-origin",
  };

  return fetch(`${appConstants.API}/user/${me.id}?_format=json`, requestOptions)
    .then(handleResponse)
    .then(() => {
      //Sequencially call user summary.
      return summary(lang, me.id)
        .then(handleResponse)
        .then((response) => {
          //store current user session.
          const me = response[0];
          me.shortName = me.display_name.substr(
            0,
            me.display_name.indexOf(" ")
          );
          localStorage.setItem("me", JSON.stringify(me));
          //Return user.
          return { me };
        });
    });
}

/**
 *
 * @param {*} user
 */
function update(user) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(user),
  };

  return fetch(`${appConstants.API}/users/${user.id}`, requestOptions).then(
    handleResponse
  );
}

/**
 *
 * @param {*} lang
 * @param {*} user
 * @param {*} src
 */
function uploadPhoto(lang, user, src) {
  const filename = user.name;
  const imageOptions = {
    method: "POST",
    headers: {
      ...authHeader(),
      "Content-Type": "application/hal+json",
    },
    body: JSON.stringify({
      _links: {
        type: {
          href: `${appConstants.API}/rest/type/file/image`,
        },
      },
      type: [{ target_id: "image" }],
      filename: [{ value: `${filename}.png` }],
      filemime: { value: "image/png" },
      uri: [{ value: `public://${filename}.png` }],
      data: [{ value: src }],
    }),
  };

  //Upload photo
  return fetch(`${appConstants.API}/entity/file?_format=json`, imageOptions)
    .then(handleResponse)
    .then((image) => {
      const requestOptions = {
        method: "PATCH",
        headers: {
          ...authHeader(),
          "Content-Type": "application/hal+json",
        },
        body: JSON.stringify({
          _links: {
            type: {
              href: `${appConstants.API}/rest/type/user/user`,
            },
          },
          user_picture: [{ target_id: image.fid[0].value }],
          field_use_avatar: { value: false },
        }),
      };

      //Update Record
      return fetch(
        `${appConstants.API}/user/${user.uid}?_format=hal_json`,
        requestOptions
      )
        .then(handleResponse)
        .then(() => {
          //Sequencially call user summary.
          return summary(lang, user.uid)
            .then(handleResponse)
            .then((response) => {
              //store current user session.
              const me = response[0];
              me.shortName = me.display_name.substr(
                0,
                me.display_name.indexOf(" ")
              );
              localStorage.setItem("me", JSON.stringify(me));

              //Return user.
              return { me };
            });
        });
    });
}

/**
 *
 * @param {*} email
 */
function forgotPassword(lang, email) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      lang: lang,
      mail: email,
    }),
  };

  return fetch(
    `${appConstants.API}/user/lost-password?_format=json`,
    requestOptions
  ).then(handleResponse);
}

/**
 *
 * @param {*} email
 */
function resetPassword(lang, token, password) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      temp_pass: token,
      new_pass: password,
    }),
  };

  return fetch(
    `${appConstants.API}/user/lost-password-reset?_format=json`,
    requestOptions
  ).then(handleResponse);
}

/**
 *
 * @param {*} response
 */
function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        window.location.reload(true);
      }
      const error = (data && data.message) || response.statusText;

      //Filter Drupal errors.
      let result = String(
        error
          .toString()
          .toLocaleLowerCase()
          .match(/[a-z]+/g)
      ).replace(/,/g, "_");

      if (result.substr("validation_failed_mail_the_email_address") >= 0) {
        result = "validation_failed_mail_the_email_address";
        return Promise.reject(result);
      }

      return Promise.reject(error);
    }
    return data;
  });
}

export const createCertification = async (
  me,
  value,
  { base64, name, type },
  lang
) => {
  try {
    const fid = await saveFile(base64, name, type, value, me.id);
    const payloadParagraph = {
      _links: {
        type: {
          href: `http://${appConstants.LOCATION}/rest/type/paragraph/certifications`,
        },
      },
      type: [
        {
          target_id: "certifications",
        },
      ],
      field_add_certifications: [
        {
          target_id: fid,
        },
      ],
      field_name: [
        {
          value: value,
        },
      ],
      parent_id: [
        {
          value: me.id,
        },
      ],
      parent_type: [
        {
          value: "user",
        },
      ],
      parent_field_name: [
        {
          value: "field_certificaciones",
        },
      ],
    };
    const requestParagraph = {
      method: "POST",
      headers: {
        ...authHeader(),
        "Content-Type": "application/hal+json",
      },
      body: JSON.stringify(payloadParagraph),
      credentials: "same-origin",
    };
    const respParagraph = await fetch(
      `${appConstants.API}/entity/paragraph?_format=hal_json`,
      requestParagraph
    );
    const { id } = await respParagraph.json();
    const certificados = [
      {
        target_id: id[0].value,
        target_revision_id: id[0].value,
      },
    ];
    me.custom_user_certification.map((cert) => {
      certificados.push({
        target_id: cert.id,
        target_revision_id: cert.id,
      });
    });
    const newme = await updateProfile(lang, me, {
      field_certificaciones: certificados,
    });
    return newme;
  } catch (error) {
    console.warn(error);
  }
};

export const updateCertification = async (
  id,
  me,
  value,
  { base64, name, type },
  lang
) => {
  let requestImg = {};
  try {
    if (name) {
      const fid = await saveFile(base64, name, type, value, me.id);
      requestImg = {
        field_add_certifications: [
          {
            target_id: fid,
          },
        ],
      };
    }
    const payloadParagraph = {
      _links: {
        type: {
          href: `http://${appConstants.LOCATION}/rest/type/paragraph/certifications`,
        },
      },
      id: [
        {
          value: id,
        },
      ],
      type: [
        {
          target_id: "certifications",
        },
      ],
      field_name: [
        {
          value: value,
        },
      ],
      parent_id: [
        {
          value: me.id,
        },
      ],
      parent_type: [
        {
          value: "user",
        },
      ],
      parent_field_name: [
        {
          value: "field_certificaciones",
        },
      ],
    };
    const payload = { ...payloadParagraph, ...requestImg };
    const requestParagraph = {
      method: "PATCH",
      headers: {
        ...authHeader(),
        "Content-Type": "application/hal+json",
      },
      body: JSON.stringify(payload),
      credentials: "same-origin",
    };
    const respParagraph = await fetch(
      `${appConstants.API}/entity/paragraph/${id}?_format=hal_json`,
      requestParagraph
    );
    await respParagraph.json();
    const response = await summary(lang, me.id);
    const resp = await response.json();
    localStorage.setItem("me", JSON.stringify(resp[0]));
    return resp[0];
  } catch (error) {
    console.warn(error);
  }
};

export const deleteCertification = async (id, me, lang) => {
  const requestParagraph = {
    method: "DELETE",
    headers: authHeader(),
  };
  try {
    const respParagraph = await fetch(
      `${appConstants.API}/entity/paragraph/${id}?_format=hal_json`,
      requestParagraph
    );
    await respParagraph.text();
    const response = await summary(lang, me.id);
    const resp = await response.json();
    localStorage.setItem("me", JSON.stringify(resp[0]));
    return resp[0];
  } catch (error) {
    console.warn(error);
  }
};

export const saveFile = async (base64, name, type, alt_text, uid) => {
  const base64result = base64.split("base64,");
  //Setup object according drupal's format.
  const payload = {
    _links: {
      type: {
        href: `http://${appConstants.LOCATION}/rest/type/file/image`,
      },
    },
    uri: [
      {
        value: `public://${name}`,
      },
    ],
    filename: {
      value: name,
    },
    filemime: {
      value: type,
    },
    type: {
      target_id: "image",
    },
    data: [
      {
        value: base64result[1],
      },
    ],
    uid: [
      {
        target_id: uid,
      },
    ],
    field_image_alt_text: [
      {
        value: alt_text,
      },
    ],
  };
  const requestOptions = {
    method: "POST",
    headers: {
      ...authHeader(),
      "Content-Type": "application/hal+json",
    },
    body: JSON.stringify(payload),
    credentials: "same-origin",
  };
  try {
    const resp = await fetch(
      `${appConstants.API}/entity/file?_format=hal_json`,
      requestOptions
    );
    const { fid } = await resp.json();
    return fid[0].value;
  } catch (error) {
    console.warn(error);
  }
};
