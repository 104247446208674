import React from "react";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import ico_meetup from "../../_images/ico-meetup-created.svg";

export const MeetupCreated = ({ show, onHide }) => {
  //Language hooks
  const { t } = useTranslation("common");

  return (
    <Modal show={show} onHide={onHide} centered>
      <div
        id="_1"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="modalLabel_1"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered shared"
          role="document"
        >
          <div className="modal-content auto meetup__created">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={onHide}
            >
              <span aria-hidden="true">×</span>
            </button>
            <div className="article__shared">
              <img src={ico_meetup} alt="Meetup created" />
              <h4>{t("meetups.meetup_created")} </h4>
              <p className="main__message">{t("meetups.meetup_desc")}</p>
            </div>
            <button
              type="button"
              className="btn btn-primary"
              data-dismiss="modal"
              aria-label="Close"
              onClick={onHide}
            >
              Ok
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
