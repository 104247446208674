import React from "react";

export const Youtube = ({ link }) => {
  return (
    <a
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      className="social__btn youtube"
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
        <circle className="st0" cx="18" cy="18" r="17.5" />
        <path
          className="st1"
          d="M24.1 11.6H11.9c-1.3 0-2.3 1-2.3 2.3v8.2c0 1.3 1 2.3 2.3 2.3h12.2c1.3 0 2.3-1 2.3-2.3v-8.2C26.4 12.6 25.4 11.6 24.1 11.6zM15.5 21.5v-7l6.1 3.4L15.5 21.5z"
        />
      </svg>
    </a>
  );
};
