import React from "react";
import {useTranslation} from "react-i18next";
import {Link} from "@reach/router";

export default function Followers({title, type, users, onShowAll, icon, selected, redirect = false}) {
  const {i18n, t} = useTranslation("common");
  const lang = i18n.language;

  return (
    <li
      role="presentation"
      className={`${selected === type ? "active" : ""}`}
    >
      {redirect && (
        <Link
          to={`/${lang}/${t("url." + type + "")}`}
          aria-controls={type}
          role="tab"
          data-toggle="tab"
        >
        <span className="icon-followers">
          {icon}
        </span>
          <h3 className="followers__title">
            {title}
            <span>({users.length})</span>
          </h3>
        </Link>
      )}
      {redirect == false && (
        <>
          <span className="icon-followers">
            {icon}
          </span>
          <h3 className="followers__title">
            {title}
            <span>({users.length})</span>
          </h3>
        </>
      )}
    </li>
  );
}
