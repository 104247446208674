export const modalConstants = {
  SHOW_LOGIN: "MODAL_SHOW_LOGIN",
  SHOW_REGISTER: "MODAL_SHOW_REGISTER",
  SHOW_FORGOT_PASSWORD: "MODAL_SHOW_FORGOT_PASSWORD",
  SHOW_RECOVERY_PASSWORD: "MODAL_SHOW_RECOVERY_PASSWORD",
  SHOW_SEARCH: "MODAL_SHOW_SEARCH",
  SHOW_NEWSLETTER: "MODAL_SHOW_NEWSLETTER",
  SHOW_ADDARTICLE: "MODAL_SHOW_ARTICLE",
  SHOW_CREATECONTENT: "MODAL_SHOW_CREATE",

  HIDE_LOGIN: "MODAL_HIDE_LOGIN",
  HIDE_REGISTER: "MODAL_HIDE_REGISTER",
  HIDE_FORGOT_PASSWORD: "MODAL_HIDE_FORGOT_PASSWORD",
  HIDE_RECOVERY_PASSWORD: "MODAL_HIDE_RECOVERY_PASSWORD",
  HIDE_SEARCH: "MODAL_HIDE_SEARCH",
  HIDE_NEWSLETTER: "MODAL_HIDE_NEWSLETTER",
  HIDE_ADDARTICLE: "MODAL_HIDE_ARTICLE",
  HIDE_CREATECONTENT: "MODAL_HIDE_CREATE",

  REGISTER_STEP1: "step1",
  REGISTER_STEP2: "step2",
  REGISTER_STEP3: "step3",
  REGISTER_STEP4: "step4",

  FORGOT_STEP1: "step1",
  FORGOT_STEP2: "step2",

  RESET_STEP1: "step1",
  RESET_STEP2: "step2",
};
