import React  from "react";
import { useTranslation } from "react-i18next";
import { CardElement } from "@stripe/react-stripe-js";


const CardSection = ({ propStripe}) => {
  const { t } = useTranslation("common");

  return (
    <>
      <CardElement />
      <button disabled={propStripe}>
        {t("premium.start")}
      </button>
    </>
  );
};

export default CardSection;
