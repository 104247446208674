import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { TeachContent } from "../ui/teach-content";
import { communityService, addArticle } from "../../_services";
import "jodit";
import "jodit/build/jodit.min.css";
import FormValidator from "../../_helpers/form-validator";
import ChipInput from "material-ui-chip-input";
import video_cover from "../../_images/video-cover.png";
import ico_delete from "../../_images/ico-delete.svg";
import { cloudflare } from "../../_services/cloudflare";

import baseArticle from "./base-article";

class ArticleForm extends FormValidator {
  constructor(props) {
    super(props);

    const empty = {
      photo: {
        value: "",
        error: false,
        message: "",
        base64: "",
        name: "",
        type: "",
      },
      title: {
        value: "",
        error: false,
        message: "",
        visible: false,
      },
      body: {
        value: "",
        error: false,
        message: "",
        visible: false,
      },
      video: {
        value: "",
        url: false,
        file: false,
        error: false,
        message: "",
        visible: false,
      },
      category: {
        value: "",
        error: false,
        message: "",
        visible: false,
      },
      description: {
        value: "",
        error: false,
        message: "",
        visible: false,
      },
      tags: {
        value: [],
        error: false,
        message: "",
        visible: false,
      },
      tag: {
        value: [],
        error: false,
        message: "",
        visible: false,
      },
    };

    this.state = {
      empty: empty,
      type: "publish",
      elements: Object.assign({}, empty),
      problem: "",
      result: "",
      loading: false,
    };

    this.submitHandler = this.submitHandler.bind(this);
    this.photoHandler = this.photoHandler.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.changeHtml = this.changeHtml.bind(this);
    this.chipsHandler = this.chipsHandler.bind(this);
    this.chipsDelete = this.chipsDelete.bind(this);
    this.cancelHandler = this.cancelHandler.bind(this);
    this.videoHandler = this.videoHandler.bind(this);
    this.videoClick = this.videoClick.bind(this);
  }

  videoClick(e) {
    e.preventDefault();
    const input = this.state.elements.video;
    input.error = false;
    if (input.value) {
      input.value = "";
      input.url = false;
      document.getElementById("video").value = "";
      this.setState({ elements: this.state.elements });
    } else {
      document.getElementById("video").click();
    }
  }

  /* Controlled file input */
  videoHandler(e) {
    const file = e.target.files[0];
    const url = URL.createObjectURL(file);
    const input = this.state.elements[e.target.name];
    input.value = String(e.target.value);
    input.value = input.value.substr(input.value.lastIndexOf("\\") + 1);
    input.error = false;
    input.url = url;
    input.file = file;

    this.setState({ elements: this.state.elements });
  }

  /* Controlled input */
  updateContent(value) {
    const input = this.state.elements.body;
    input.value = value;
    input.error = false;

    this.setState({ elements: this.state.elements });
  }

  /* Controlled input */
  photoHandler(value) {
    const input = this.state.elements.photo;
    input.value = value;
    input.error = false;
    let files = value[0].file;
    let fileReader = new FileReader();
    fileReader.readAsDataURL(files);
    fileReader.onload = (event) => {
      input.base64 = event.target.result;
      input.name = files.name;
      input.type = files.type;
      this.setState({ elements: this.state.elements });
    };
  }

  /* Controlled input */
  changeHandler(e) {
    const input = this.state.elements[e.target.name];
    input.value = e.target.value;
    input.error = false;

    this.setState({ elements: this.state.elements });
  }

  /* Controlled html */
  changeHtml(e) {
    const input = this.state.elements["body"];
    input.value = e.target.innerHTML;
    input.error = false;

    this.setState({ elements: this.state.elements });
  }

  /* Save chips */
  chipsHandler(chips) {
    const { tag } = this.state.elements;
    tag.value.push(chips[0]);
    chips.pop();

    const input = this.state.elements.tags;
    input.value = tag.value;
    input.error = false;

    if (input.value.length <= 10) {
      this.setState({ elements: this.state.elements });
    } else {
      tag.value.pop();
    }
  }

  /* Save chips */
  chipsDelete(chip) {
    const { tag } = this.state.elements;
    tag.value = tag.value.filter((item) => item !== chip);

    const input = this.state.elements.tags;
    input.value = tag.value;

    input.error = false;
    this.setState({ elements: this.state.elements });
  }

  /* Cancel writing */
  cancelHandler() {
    const empty = {
      photo: {
        value: "",
        error: false,
        message: "",
      },
      title: {
        value: "",
        error: false,
        message: "",
        visible: false,
      },
      body: {
        value: "",
        error: false,
        message: "",
        visible: false,
      },
      video: {
        value: "",
        error: false,
        message: "",
        visible: false,
      },
      category: {
        value: "",
        error: false,
        message: "",
        visible: false,
      },
      description: {
        value: "",
        error: false,
        message: "",
        visible: false,
      },
      tags: {
        value: [],
        error: false,
        message: "",
        visible: false,
      },
    };

    this.setState({ elements: empty });
    document.getElementById("close").click();
  }

  /* Submit form */
  submitHandler(e) {
    e.preventDefault();
    const { t, i18n } = this.props;
    const lang = i18n.language;
    const isValid = this.validate(this.state.elements, [
      {
        field: "photo",
        type: this.props.type !== "video" ? "required" : "",
        message: "errors.photo_required",
      },
      {
        field: "title",
        type: "required",
        message: "errors.title_required",
      },
      {
        field: "body",
        type: this.props.type !== "video" ? "required" : "",
        message: "errors.body_required",
      },
      {
        field: "video",
        type: this.props.type === "video" ? "required" : "",
        message: "errors.video_required",
      },
      {
        field: "category",
        type: "required",
        message: "errors.category_required",
      },
      {
        field: "description",
        type: "required",
        message: "errors.description_required",
      },
      {
        field: "tags",
        type: "required",
        message: "errors.tags_required",
      },
    ]);

    if (isValid) {
      this.setState({ problem: "", result: "", loading: true });

      const { photo, video, title, body, category, description, tags } =
        this.state.elements;

      const data = {
        title: title.value,
        body: body.value,
        category: category.value,
        description: description.value,
        video: video.value,
        tags: tags.value,
      };
      const { id } = JSON.parse(localStorage.getItem("me"));

      /* Post video */
      if (this.props.type === "video") {
       
        cloudflare(video.file, (id_video) => {
          if (id_video) {
            console.log(id_video);
            addArticle(
              lang,
              data,
              this.state.type === "draft",
              id_video,
              (photo.base64 = baseArticle.global.base64),
              (photo.name = `${id_video}.jpg`),
              (photo.type = "image/jpeg"),
              title.value,
              id
            )
              .then((response) => {
                this.setState({ result: "success", loading: false });
                console.log(response);
                document.location.href = `/${lang}/${t("url.community")}`;
              })
              .catch((err) => {
                this.setState({
                  result: "error",
                  problem: err,
                  loading: false,
                });
              });
          }
        });
      } else {
        addArticle(
          lang,
          data,
          this.state.type === "draft",
          null,
          photo.base64,
          photo.name,
          photo.type,
          title.value,
          id
        )
          .then((res) => res.json())
          .then((response) => {
            this.setState({ result: "success", loading: false });

            // const { id } = JSON.parse(localStorage.getItem("me"));
            const group_id = JSON.parse(localStorage.getItem("group_id"));

            if (localStorage.getItem("groupArticle")) {
              const article_id =
                response.nid !== undefined && response.nid !== "undefined"
                  ? response.nid[0].value
                  : "";
              communityService
                .postArticleGroup(id, group_id, article_id)
                .then((res) => res.json())
                .then()
                .catch(console.log);
              localStorage.removeItem("groupArticle");
            }

            document.location.href = `/${lang}/${t("url.community")}`;
          })
          .catch((err) => {
            this.setState({ result: "error", problem: err, loading: false });
          });
      }
    }
  }

  /* View */
  render() {
    const { t, type } = this.props;
    const lang = this.props.i18n.language;
    const categories = this.props.config.categories[lang];
    const { photo, title, body, video, category, description, tags } =
      this.state.elements;
    const { problem, result } = this.state;
    
    //Limit tags 
    var inputTags = document.getElementsByClassName("MuiInputBase-input");

    if(inputTags){
      setTimeout(() => {
        inputTags[0].setAttribute("maxlength", 12)
      },1000)
    }

    return (
      <form method="post" onSubmit={this.submitHandler}>
        {type !== "video" && (
          <div className={`form-label-group ${photo.error ? "error" : ""}`}>
            <span>
              <strong style={{ fontSize: "18px" }}>
                {t(`createArticle.upload`)}
              </strong>{" "}
              {t(`createArticle.image`)}
            </span>
            <TeachContent
              type={type}
              onChange={this.photoHandler}
              value={photo.value}
            />
            {photo.error && (
              <small id="passlHelp" className="form-text text-muted">
                {t(photo.message)}
              </small>
            )}
          </div>
        )}
        <div className={`form-label-group ${title.error ? "error" : ""}`}>
          <input
            id="title"
            name="title"
            type="text"
            className="add__title form-control"
            placeholder={t(`createArticle.placeholder`)}
            maxLength="60"
            value={title.value}
            onChange={this.changeHandler}
          />
          {title.error && (
            <small id="passlHelp" className="form-text text-muted">
              {t(title.message)}
            </small>
          )}
          <span className="add__txt">{t(`createArticle.description`)}</span>
        </div>
        <div
          className={`text__insert__box form-label-group ${
            body.error ? "error" : ""
          }`}
        >
          <textarea
            type="text"
            className="form-control"
            id="body"
            name="body"
            value={body.value}
            onChange={this.changeHandler}
            style={{ border: "1px solid" }}
          />

          {body.error && (
            <small id="passlHelp" className="form-text text-muted">
              {t(body.message)}
            </small>
          )}
        </div>
        {type === "video" && (
          <div
            className={`form-label-group video__cover ${
              video.error ? "error" : ""
            }`}
          >
            <span>Video</span>
            <span>{t(`createArticle.video`)}</span>
            <input
              type="file"
              name="video"
              id="video"
              onChange={this.videoHandler}
              style={{ position: "absolute" }}
              accept=".mov,.mp4"
            />
            {video.url && (
              <video
                className="VideoInput_video"
                width="400px"
                height="300px"
                controls
                src={video.url}
              />
            )}
            <a
              href="./"
              onClick={this.videoClick}
              className="d-flex align-items-center"
              style={{ gap: "10px" }}
            >
              {video.value ? (
                <>
                  <img
                    src={ico_delete}
                    alt="icon delete"
                    style={{ width: "30px" }}
                  />
                  <span>
                    <strong>{t("articles.remove")}</strong>
                  </span>
                </>
              ) : (
                <img src={video_cover} alt="Add video cover" />
              )}
            </a>

            {video.error && (
              <small id="passlHelp" className="form-text text-muted">
                {t(video.message)}
              </small>
            )}
          </div>
        )}
        <div className="content__details">
          <div
            className={`form-label-group category ${
              category.error ? "error" : ""
            }`}
          >
            <label>{t(`createArticle.category`)}</label>
            <select
              className="form-control"
              id="category"
              name="category"
              value={category.value}
              onChange={this.changeHandler}
            >
              <option value=""></option>
              {categories.map((item, idx) => (
                <option value={item.id} key={idx}>
                  {item.category}
                </option>
              ))}
            </select>
            {category.error && (
              <small id="passlHelp" className="form-text text-muted">
                {t(category.message)}
              </small>
            )}
          </div>
          <div
            className={`form-label-group category ${
              description.error ? "error" : ""
            }`}
          >
            <label>{t(`createArticle.short_description`)}</label>
            <input
              type="text"
              className="form-control"
              id="description"
              name="description"
              value={description.value}
              onChange={this.changeHandler}
            />
            {description.error && (
              <small id="passlHelp" className="form-text text-muted">
                {t(description.message)}
              </small>
            )}
          </div>
          <div className="cat__tags">
            <span>{t(`createArticle.tags`)}</span>
            <ChipInput
              size="small"
              className="form-control"
              dataSource={tags.value}
              value={tags.value}
              onChange={(this.chipsHandler)}
              onDelete={this.chipsDelete}
            />
            <div className="extra__details__tags d-flex justify-content-between">
              <span className="detail__tags">
                {t(`createArticle.detail_tags`)}
              </span>
              <span className="tags__counter">{tags.value.length}/10</span>
            </div>
          </div>
        </div>
        <div className="buttons send__content row">
          {this.state.loading ? (
            // <Spinner />
            video.url ? (
              <div className="progress" style={{ width: "100%" }}>
                <div
                  className="progress-bar h-100"
                  id="progress__bar"
                  role="progressbar"
                  aria-valuenow="50"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style={{ width: "0%" }}
                >
                  0%
                </div>
              </div>
            ) : (
              <div className="progress" style={{ width: "100%" }}>
                <div
                  className="progress-bar h-100"
                  id="progress__bar"
                  role="progressbar"
                  aria-valuenow="50"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style={{ width: "100%" }}
                >
                  100%
                </div>
              </div>
            )
          ) : (
            <>
              <button
                type="submit"
                className="btn btn-primary public__follow"
                onClick={() => this.setState({ type: "publish" })}
              >
                {t(`createArticle.button`)}
              </button>
              <button
                type="button"
                className="btn btn-complement public__contact"
                onClick={this.cancelHandler}
              >
                {t(`createArticle.cancel`)}
              </button>
            </>
          )}
        </div>
        {
          /* Error */
          result === "error" && (
            <div className={`form-label-group`}>
              <div className="alert alert-danger text-center">
                {t(`errors.${problem}`)}
              </div>
            </div>
          )
        }

        {
          /* Success */
          result === "successr" && (
            <div className={`form-label-group`}>
              <div className="alert alert-success text-center">
                {t(`success.message`)}
              </div>
            </div>
          )
        }
      </form>
    );
  }
}

function mapState(state) {
  const { config } = state.app;
  return { config };
}

const connectedArticleForm = connect(
  mapState,
  null
)(withTranslation("common")(ArticleForm));
export { connectedArticleForm as ArticleForm };
