import React from "react";
import { Link } from "@reach/router";
import { useTranslation } from "react-i18next";
import { PublishDate } from "./publish-date";
import { CategoryLink } from "./category-link";
import { url, path } from "../../_helpers";
import { PostReactions } from "./post-reactions";
import { PostActions } from "./post-actions";
import ico_player from "../../_images/ico-player.svg";
import he from "he";

export const ArticleProfile = ({ display, article, onDelete }) => {
  //Language hooks
  const { i18n, t } = useTranslation("common");
  const lang = i18n.language;
  const link = url(article.slug);

  return (
    <article>
      {display === "private" && (
        <PostActions post={article} onDelete={onDelete} useVisibility={true} />
      )}

      <div className="d-flex">
        <div className="article__information col-7">
          <div className="article__cat d-flex">
            <p className="article__cat__tag" rel="category">
              <CategoryLink name={article.category} />
            </p>
            <span className="article__cat__separator">•</span>
            <PublishDate className="article__hour__tag" date={article.date} />
          </div>
          <h2 itemProp="headline">
            <Link to={link}>{he.decode(article.title)}</Link>
          </h2>
          <div className="autor__container d-flex">
            <p className="article__autor">
              <Link
                to={`/${lang}/${t("home.profile")}/${String(article.user).toLocaleLowerCase()}`}
              >
                <span itemProp="name">{article.display_name}</span>
              </Link>
            </p>
          </div>
        </div>
        <figure className="article__image__content col-5">
          {article.is_video === "True" && article.duration && (
            <span className="player__counter">{article.duration}</span>
          )}
          {article.is_video === "True" && (
            <Link className="player__btn" to={link}>
              <img
                itemProp="image"
                src={ico_player}
                alt={t("articles.play_video")}
              />
            </Link>
          )}
          <Link to={link}>
            <img
              itemProp="image"
              src={path(article.thumbnail)}
              alt={he.decode(article.title)}
            />
          </Link>
        </figure>
      </div>

      <PostReactions post={article} qualification={true} actions={false} />
    </article>
  );
};
