import React from "react";
import SEO from "../_components/common/seo";
import Layout from "../_components/common/layout";
import { ProfileMenu } from "../_components/navigation/profile-menu";
import { ProfileInformation } from "../_components/ui/profile-information";
import { useTranslation } from "react-i18next";
import { ProfileDashboard } from "../_components/contents/profile-dashboard";
import Credits from "../_components/common/credits";
import { MobileMenu } from "../_components/navigation/mobile-menu";
import { PublicProfile } from "./PublicProfile";

export const ProfilePage = ({ slug }) => {
  const { t, i18n } = useTranslation("common");
  const lang = i18n.language;

  //Gateway to user public profile
  if (!!slug) {
    if (slug.indexOf("id=") >= 0) {
      return <PublicProfile />;
    }
  }
  

  return (
    <Layout withFade={true} noFooter={true}>
      <SEO lang={lang} title={t("profile.title")} />
      <main
        role="main"
        itemScope=""
        itemProp="mainContentOfPage"
        itemType="http://schema.org/Blog"
      ></main>
      <section className="profile__page">
        <div className="container d-flex">
          <ProfileMenu selected="profile" />

          <div className="profile__body col-lg-8 col-md-9">
            <ProfileInformation />
            <ProfileDashboard />
            <Credits />
          </div>
          {/* <div className="col-3 d-none col-lg-2 d-md-none d-lg-block">
            <ProfileFollowers />
          </div> */}
        </div>
      </section>
      <MobileMenu selected="profile" withFade={true} />
    </Layout>
  );
};
