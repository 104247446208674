import React from "react";
import FormValidator from "../../_helpers/form-validator";
import { withTranslation } from "react-i18next";
import he from "he";

class NewsletterForm extends FormValidator {
  constructor(props) {
    super(props);

    this.state = {
      elements: {
        email: {
          value: "",
          error: false,
          message: "",
        },
      },
      loading: false,
    };

    this.submitHandler = this.submitHandler.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
  }

  /* Form submit */
  submitHandler(e) {
    e.preventDefault();

    const isValid = this.validate(this.state.elements, [
      {
        field: "email",
        type: "required",
        message: "errors.email_required",
      },
      {
        field: "email",
        type: "email",
        message: "errors.email_format",
      },
    ]);

    if (isValid) {
      this.setState({ loading: true });
      this.props.onSuccess(e);
    }
  }

  /* Controlled input */
  changeHandler(e) {
    const input = this.state.elements[e.target.name];
    input.value = e.target.value;

    this.setState({ elements: this.state.elements });
  }

  /* Render component */
  render() {
    const { categories, handleChangeCheck, checkedCtg, t, i18n } = this.props;
    const lang = i18n.language;
    const { email } = this.state.elements;
    this.props.emailNew(email);

    return (
      <form onSubmit={this.submitHandler}>
        <div className="topics__container row">
          <div className="topics__columns col-6">
            {categories[1].map((item, i) => (
              <label className="checklist" key={i}>
                {he.decode(item.category)}
                <input
                  type="checkbox"
                  value={he.decode(item.id)}
                  onChange={handleChangeCheck}
                  checked={checkedCtg.indexOf(he.decode(item.id)) >= 0}
                />
                <span className="check-mark"></span>
              </label>
            ))}
          </div>
          <div className="topics__columns col-6">
            {categories[2].map((item, i) => (
              <label className="checklist" key={i}>
                {he.decode(item.category)}
                <input
                  type="checkbox"
                  value={he.decode(item.id)}
                  onChange={handleChangeCheck}
                  checked={checkedCtg.indexOf(he.decode(item.id)) >= 0}
                />
                <span className="check-mark"></span>
              </label>
            ))}
          </div>
        </div>

        {/* Email Input */}
        <div
          className={`form-label-group email__content ${
            email.error ? "error" : ""
          }`}
        >
          <input
            type="text"
            id="email"
            name="email"
            className="form-control"
            placeholder="Email address"
            value={email.value}
            onChange={this.changeHandler}
            required=""
            autoFocus=""
          />
          <label htmlFor="email">{t("newsletter.input")}</label>
          {email.error && (
            <small id="passlHelp" className="form-text text-muted">
              {t(email.message)}
            </small>
          )}
          <small id="passlHelp" className="form-text text-muted">
            {t("newsletter.recaptcha")}
            <a
              className="secondary__hyper underline"
              href={`/${lang}/${t("url.privacy")}`}
            >
              <span>{t("menus.privacy")}</span>
            </a>
            {t("newsletter.and")}
            <a
              className="secondary__hyper underline"
              href={`/${lang}/${t("url.sale")}`}
            >
              {t("menus.sale")}
            </a>
            {t("newsletter.apply")}
          </small>
        </div>
        <div className="captcha">
          <img src="_images/captcha.jpeg" alt="" />
        </div>
        <button
          className="btn btn-lg btn-primary"
          type="submit"
          // onClick={onSuccess}
        >
          {t("buttons.submit")}
        </button>
      </form>
    );
  }
}

export default withTranslation("common")(NewsletterForm);
