import React, { useState, useEffect } from "react";
import SEO from "../_components/common/seo";
import Layout from "../_components/common/layout";
import { useTranslation } from "react-i18next";
import { appService } from "../_services";

const PoliticsPage = ({ slug }) => {
  const { t, i18n } = useTranslation("common");
  const lang = i18n.language;
  const [politics, setPolitics] = useState(null);
  const [service, setService] = useState([]);

  useEffect(() => {
    appService
      .getPolitics(lang)
      .then((res) => res.json())
      .then((response) => {
        if (response) {
          setService(response)
        }
      })
      .catch(console.log);

    if (!!service) {
      for (let i = 0; service.length; i++) {
        let item = service[i];
        if (item.view_node.indexOf(`/${slug}`) >= 0) {
          setPolitics(item);
          break;
        }
      }
    }
  }, [service, slug, lang]);

  if (!politics) {
    return (
      <Layout>
        <SEO lang={lang} title={t("politics.title")} />
        <main>Loading...</main>
      </Layout>
    );
  }

  return (
    <Layout>
      <SEO lang={lang} title={t("politics.title")}/>
      <main
        className="home__page"
        role="main"
        itemScope=""
        itemProp="mainContentOfPage"
        itemType="http://schema.org/Blog"
      >
        <section className="body__text about">
          <div className="container">
            <div className="comment__style__box">
              <div className="inner">
                <h1>{politics.title}</h1>
              </div>
              <div className="triangle"></div>
            </div>

            <p>
            {politics.body}
            </p>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default PoliticsPage;