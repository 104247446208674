import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import EventThumb from "../../_components/ui/event-thumb";
import { eventService } from "../../_services/event.service";

import LoadMore from "./LoadMore";
import * as moment from "moment";

import { path } from "../../_helpers";

export default function RecentEvents() {
  const [data, setData] = useState([]);
  const { t, i18n } = useTranslation("common");
  const lang = i18n.language;

  const NUMBER_ITEM_PAGINATION = 3;

  const { numberItemsPagination, loadMoreItems, loadMoreReset } = LoadMore(
    NUMBER_ITEM_PAGINATION
  );

  useEffect(() => {
    /** Call Services  */
    eventService
      .getEventsRecent(lang)
      .then((res) => res.json())
      .then((response) => {
        setData(response);
      })
      .catch((error) => console.log(error));
  }, [setData, lang]);

  return (
    <div className="content">
      <div className="featured__heading d-flex justify-content-between">
        <h3 className="title__home bold">
          <span className="title__highlight">{t("events.recents_blue")}</span>
          {t("events.recents_black")}
        </h3>
      </div>
      <div className="featured__allitems">
        {data.slice(0, numberItemsPagination).map((item) => (
          <div
            className="col-12 col-sm-12 col-md-4 col-lg-4 small__column__articles"
            key={item.nid}
          >
            {/* <div>{item.label}</div> */}
            <EventThumb
              slug={item.field_path}
              img={path(item.field_image)}
              title={item.field_name_evente}
              location={item.field_location}
              startDate={moment(item.field_start_date).format("MMM DD, YYYY")}
              category={item.field_principal_category}
              description={item.field_summary_short}
            />
          </div>
        ))}
      </div>
      <button
        className={
          data.length > numberItemsPagination
            ? "load__more"
            : "load__more disabled"
        }
        onClick={() => loadMoreItems(data.length)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="17"
          viewBox="0 0 16 17"
        >
          <path d="M8.3 14.4C4.9 14.4 2.2 11.6 2.2 8.2 2.2 4.8 4.9 2.1 8.3 2.1 10 2.1 11.6 2.8 12.6 4L13.1 4.5 12.6 5 12 5.6 15.5 5.6 15.5 1.9 14.5 2.9 14 2.4C12.5 0.9 10.5 0 8.2 0 5.9 0 3.9 0.9 2.4 2.4 0.9 3.9 0 6 0 8.2 0 10.5 0.9 12.5 2.4 14 3.9 15.5 5.9 16.4 8.2 16.4 10.2 16.4 12.1 15.7 13.5 14.5L12 13C11 13.9 9.7 14.4 8.3 14.4L8.3 14.4Z"></path>
        </svg>
        View more
      </button>
      <button
        className={
          data.length < 4
            ? "load__more load__more--reset disabled"
            : data.length > numberItemsPagination
            ? "load__more load__more--reset"
            : "load__more load__more--reset view"
        }
        onClick={() => loadMoreReset(NUMBER_ITEM_PAGINATION)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="17"
          viewBox="0 0 16 17"
        >
          <path d="M8.3 14.4C4.9 14.4 2.2 11.6 2.2 8.2 2.2 4.8 4.9 2.1 8.3 2.1 10 2.1 11.6 2.8 12.6 4L13.1 4.5 12.6 5 12 5.6 15.5 5.6 15.5 1.9 14.5 2.9 14 2.4C12.5 0.9 10.5 0 8.2 0 5.9 0 3.9 0.9 2.4 2.4 0.9 3.9 0 6 0 8.2 0 10.5 0.9 12.5 2.4 14 3.9 15.5 5.9 16.4 8.2 16.4 10.2 16.4 12.1 15.7 13.5 14.5L12 13C11 13.9 9.7 14.4 8.3 14.4L8.3 14.4Z"></path>
        </svg>
        Reset
      </button>
    </div>
  );
}
