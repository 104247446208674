import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import he from "he";

const CategorySelect = ({ config }) => {
  //Language hooks
  const { i18n, t } = useTranslation("common");
  const lang = i18n.language;

  if (config) {
    //If any category was found.
    return (
      <select className="form-control" id="category">
        <option value="">{t("events.category")}</option>
        {config.categories[lang].map((item, id) => (
          <option key={id} value={item.link}>{he.decode(item.category)}</option>
        ))}
      </select>
    );
  }

  return "";
};

function mapState(state) {
  const { config } = state.app;
  return { config };
}

const connectedCategorySelect = connect(mapState)(CategorySelect);
export { connectedCategorySelect as CategorySelect };
