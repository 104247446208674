import React, { useEffect, useState } from "react";
import SEO from "../_components/common/seo";
import Layout from "../_components/common/layout";
import { navigate } from "@reach/router";
import { Spinner } from "../_components/ui/spinner";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { MobileMenu } from "../_components/navigation/mobile-menu";
import { SearchVendorForm } from "../_components/forms/search-vendor-form";
import { vendorService } from "../_services";
import { useLocation, Link } from "@reach/router";



/**
 * Custom hook to return params as array.
 * @param {*} queryParam
 */
export const useQuery = (queryParam) => {
  const search = new URLSearchParams(useLocation().search);
  let value = search.get(queryParam);
  if (value) {
    return value.split(",");
  }
  return [];
};

const SearchVendorPage = ({ categories }) => {
  //Language
  const { t, i18n } = useTranslation("common");
  const lang = i18n.language;

  //Filters
  const [filters, setFilters] = useState({
    k: useQuery("k"),
    v: useQuery("v"),
    c: useQuery("c"),
    t: useQuery("t"),
    q: useQuery("q"),
    d: useQuery("d"),
    s: useQuery("s"),
  });

  //States
  const [vendors, setVendors] = useState([]);
  const [providers, setProviders] = useState([]);

  //Pagination
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);

  //Load Vendors
  useEffect(() => {
    let provider = JSON.parse(sessionStorage.getItem("vendors"));
    if (!provider) {
      vendorService
        .getFeaturedVendors(lang)
        .then((result) => {
          sessionStorage.setItem("vendors", JSON.stringify(result));
          setVendors(result);
        })
        .catch(console.log);
    } else {
      setVendors(provider);
    }
  }, [lang]);

  //Load Partners
  useEffect(() => {
    loadprovider(filters);
  }, [filters]);

  /**
   * Receive submit on search.
   * @param {*} f Filters selected
   */
  const handleChangeFilters = (f) => {
    const ff = { ...filters, ...f };
    setFilters(ff);

    let query = "";
    Object.keys(ff).map((idx) => {
      if (ff[idx].length) {
        query += !query ? `?${idx}=${ff[idx]}` : `&${idx}=${ff[idx]}`;
      }
    });

    navigate(`/${lang}/${t("url.vendors")}/${t("url.search")}${query}`);
    loadprovider(ff, 0, true);
  };

  /**
   * Execute api to retrieve partners and vendors
   * @param {*} filters
   */
  const loadprovider = (filters, p, reset = false) => {
    setPage(p);

    // vendorService
    //   .getAllProviders(lang, filters)
    //   .then((response) => {
    //     setTotal(response.results);
    //     if (reset) {
    //       setProviders(response.articles);
    //     } else {
    //       setProviders(providers.concat(response.articles));
    //     }
    //   })
    //   .catch(console.log);
    vendorService.getAllProviders2(lang, filters).then((response) => {
      setTotal(response.length);
      setProviders(response);
    });
  };

  /**
   * Move to next page.
   */
  const handleLoadMore = () => {
    loadprovider(filters, page + 1);
  };

  /**
   * Notice dropdown sort change
   */

  //Loading dependencies
  if (!categories && !vendors) {
    return <Spinner />;
  }

  

  //Show page
  return (
    <Layout withFade={true} noFooter={true}>
      <SEO lang={lang} title={t("vendors.title")} />
      <main
        className="home__page"
        role="main"
        itemScope=""
        itemProp="mainContentOfPage"
        itemType="http://schema.org/Blog"
      >
        <section className="partners__search__results">
          {/* Mobile only */}
          <div className="profile__menu sticky col-lg-2 col-md-3">
            <div className="mobile__bar d-flex align-items-center">
              {/*
              <a href="/" className="back__btn">
                <img src="_images/ico-back.svg" alt="Back" />
              </a>
              */}
              <span className="title__profile">{t("vendors.lbl_results")}</span>
            </div>
          </div>

          <div className="container ">
            <div className="breadcrumbs">
              <Link to={`/${lang}/${t("url.vendors")}`}>
                {t("menus.vendors")}
              </Link>
              <span className="b__separator">/</span>
              <span className="b__current">{t("vendors.ttl_search")}</span>
            </div>
            <h2 className="title__home">
              <span className="title__highlight">
                {t("vendors.title_blue")}
              </span>
              {t("vendors.title_black")}
            </h2>
            <div className="col-lg-10 partners__search__form">
              <div>
                <SearchVendorForm
                  className="d-flex flex-column flex-sm-column flex-md-row flex-lg-row align-items-center"
                  onSubmit={handleChangeFilters}
                />
              </div>
            </div>
          </div>
        </section>

        <section className="category__cards__partners partners__results pt-2">
          <div className="container d-flex row">
            {/* <div className="col-12 col-sm-12 col-md-3 col-lg-3 vendors__filtering">
              {categories && (
                <VendorFilters
                  categories={categories}
                  vendors={vendors}
                  filters={filters}
                  onChange={handleChangeFilters}
                />
              )}
            </div> */}
            <div className="col-12 col-sm-12 col-md-9 col-lg-9 vendors__listing">
              <div className="search__filters__partners d-flex flex-column flex-sm-column flex-md-row flex-lg-row justify-content-between">
                <div className="search__results d-flex justify-content-between justify-content-sm-between align-items-end">
                  <span className="sr__1">{t("vendors.search_results")}</span>
                  <span className="sr__2">
                    {total}{" "}
                    {Number(total) === 1
                      ? t("vendors.s_results")
                      : t("vendors.m_results")}
                  </span>
                </div>
                {/* <div className="order__by">
                  <select
                    className="form-control"
                    id="orderby"
                    defaultValue={filters.s ? filters.s[0] : ""}
                    onChange={handleSort}
                  >
                    <option value="">{t("vendors.sort_by")}</option>
                    <option value="casc">{t("vendors.sort_cat_up")}</option>
                    <option value="cdes">{t("vendors.sort_cat_dw")}</option>
                    <option value="nasc">{t("vendors.sort_name_up")}</option>
                    <option value="ndes">{t("vendors.sort_name_dw")}</option>
                  </select>
                </div> */}
              </div>
              <button
                type="button"
                className="btn btn-primary filter"
                provider-toggle="modal"
                provider-target="#_1"
              >
                {t("buttons.filter")}
              </button>

              {/* GRID RESULTS */}
              {/* <div className="partners__cards d-flex flex-column flex-sm-column flex-md-row flex-wrap flex-lg-row">
                {providers.map((provider, idx) => {
                  if (provider.type === "Partner") {
                    const link = `/${lang}/${t("url.vendors")}/${t(
                      "url.search"
                    )}?k=${vendorsConstants.KIND_PARTNER}`;
                    return (
                      <VendorArticle
                        provider={provider}
                        key={idx}
                        type={t(`vendors.filter_partner`)}
                        more={link}
                      />
                    );
                  }

                  if (provider.type === "Vendor") {
                    const link = `/${lang}/${t("url.vendors")}/${t(
                      "url.search"
                    )}?k=${vendorsConstants.KIND_VENDOR}`;
                    return (
                      <VendorArticle
                        provider={provider}
                        key={idx}
                        type={t(`vendors.filter_vendor`)}
                        more={link}
                      />
                    );
                  }
                })}
              </div> */}

              <div className="partners__cards d-flex flex-column flex-sm-column flex-md-row flex-wrap flex-lg-row">
                {providers.map((provider, idx) => (
                  <div
                    className="col-12 col-sm-12 col-md-4 col-lg-4 small__column__articles"
                    key={idx}
                  >
                    <article itemScope="" itemType="http://schema.org/Article">
                      <div className="partner__information__box">
                        <div className="partner__img d-flex align-items-end justify-content-between">
                          <img
                            src={provider.field_photo}
                           
                          />
                        </div>
                        <div className="partner__information">
                          <h2>{provider.title}</h2>
                          <p itemProp="headline">{provider.field_phone}</p>
                        </div>
                        
                      </div>
                    </article>
                  </div>
                ))}
              </div>

              {total > providers.length && (
                <button className="load__more" onClick={handleLoadMore}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                  >
                    <path d="M8.3 14.4C4.9 14.4 2.2 11.6 2.2 8.2 2.2 4.8 4.9 2.1 8.3 2.1 10 2.1 11.6 2.8 12.6 4L13.1 4.5 12.6 5 12 5.6 15.5 5.6 15.5 1.9 14.5 2.9 14 2.4C12.5 0.9 10.5 0 8.2 0 5.9 0 3.9 0.9 2.4 2.4 0.9 3.9 0 6 0 8.2 0 10.5 0.9 12.5 2.4 14 3.9 15.5 5.9 16.4 8.2 16.4 10.2 16.4 12.1 15.7 13.5 14.5L12 13C11 13.9 9.7 14.4 8.3 14.4L8.3 14.4Z"></path>
                  </svg>
                  {t("buttons.view_more")}
                </button>
              )}
            </div>
          </div>
        </section>
      </main>
      <MobileMenu selected="vendors" withFade={true} />
    </Layout>
  );
};

function mapState(state) {
  const { config } = state.app;
  if (config) {
    return { categories: config.categories };
  }

  return { categories: null };
}

const connectedSearchVendorPage = connect(mapState)(SearchVendorPage);
export { connectedSearchVendorPage as SearchVendorPage };
