import React from "react";

export const Instagram = ({ link }) => {
  return (
    <a
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      className="social__btn instagram"
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
        <circle className="st0" cx="18" cy="18" r="17.5" />
        <path
          className="st1"
          d="M11.2 11.2c0.5-0.5 0.9-0.7 1.4-0.9 0.5-0.2 1.1-0.3 2-0.4 0.9 0 1.1 0 3.4 0 2.2 0 2.5 0 3.4 0 0.9 0 1.5 0.2 2 0.4 0.5 0.2 1 0.5 1.4 0.9 0.5 0.5 0.7 0.9 0.9 1.4 0.2 0.5 0.3 1.1 0.4 2 0 0.9 0 1.1 0 3.4 0 2.2 0 2.5 0 3.4 0 0.9-0.2 1.5-0.4 2 -0.2 0.5-0.5 1-0.9 1.4 -0.5 0.5-0.9 0.7-1.4 0.9 -0.5 0.2-1.1 0.3-2 0.4 -0.9 0-1.1 0-3.4 0 -2.2 0-2.5 0-3.4 0 -0.9 0-1.5-0.2-2-0.4 -0.5-0.2-1-0.5-1.4-0.9 -0.5-0.5-0.7-0.9-0.9-1.4 -0.2-0.5-0.3-1.1-0.4-2 0-0.9 0-1.1 0-3.4 0-2.2 0-2.5 0-3.4 0-0.9 0.2-1.5 0.4-2C10.5 12.1 10.8 11.7 11.2 11.2zM14.7 24.6c-0.8 0-1.2-0.2-1.5-0.3 -0.4-0.1-0.7-0.3-0.9-0.6 -0.3-0.3-0.5-0.6-0.6-0.9 -0.1-0.3-0.2-0.7-0.3-1.5 0-0.9 0-1.1 0-3.3 0-2.2 0-2.4 0-3.3 0-0.8 0.2-1.2 0.3-1.5C11.8 12.8 12 12.5 12.2 12.2 12.5 12 12.8 11.8 13.2 11.6c0.3-0.1 0.7-0.2 1.5-0.3 0.9 0 1.1 0 3.3 0 2.2 0 2.4 0 3.3 0 0.8 0 1.2 0.2 1.5 0.3 0.4 0.1 0.7 0.3 0.9 0.6 0.3 0.3 0.5 0.6 0.6 0.9 0.1 0.3 0.2 0.7 0.3 1.5 0 0.9 0 1.1 0 3.3 0 2.2 0 2.4 0 3.3 0 0.8-0.2 1.2-0.3 1.5 -0.1 0.4-0.3 0.7-0.6 0.9 -0.3 0.3-0.6 0.5-0.9 0.6 -0.3 0.1-0.7 0.2-1.5 0.3 -0.9 0-1.1 0-3.3 0C15.8 24.7 15.6 24.7 14.7 24.6zM22.4 14.6c-0.5 0-1-0.4-1-1s0.4-1 1-1c0.5 0 1 0.4 1 1S22.9 14.6 22.4 14.6zM13.8 18c0 2.3 1.9 4.2 4.2 4.2 2.3 0 4.2-1.9 4.2-4.2 0-2.3-1.9-4.2-4.2-4.2C15.7 13.8 13.8 15.7 13.8 18zM18 15.3C16.5 15.3 15.3 16.5 15.3 18c0 1.5 1.2 2.7 2.7 2.7 1.5 0 2.7-1.2 2.7-2.7C20.7 16.5 19.5 15.3 18 15.3z"
        />
      </svg>
    </a>
  );
};
